import {
  interpolate,
  interpolateColors,
  Series,
  spring,
  useCurrentFrame,
  useVideoConfig,
} from 'remotion';
import { CaptionSettings, Subtitle, SubtitleWord } from '../remotion-types';
import {
  makeTransform,
  scale,
  translateX,
  translateY,
} from '@remotion/animation-utils';
import React, { Fragment, useEffect, useState } from 'react';
import { loadGoogleFont } from '../remotion-helpers/google-fonts';

const SubtitleDisplay: React.FC<{
  subtitle: Subtitle;
  settings: CaptionSettings;
  absoluteFrame: number;
  duration: number;
  isFirst?: boolean;
  isRtl?: boolean;
}> = ({
  subtitle,
  settings,
  absoluteFrame,
  duration,
  isFirst = false,
  isRtl = false,
}) => {
  const { fps } = useVideoConfig();
  const currentFrame = useCurrentFrame();
  const fontFamily = settings.fontFamily;

  // Add entrance animation
  const enter = spring({
    frame: currentFrame,
    fps,
    config: {
      damping: 100, // Lower damping for more gentle deceleration
      mass: 2.5, // Higher mass for more floaty, cloud-like movement
      stiffness: 15, // Much lower stiffness for a softer, drifting motion
    },
    durationInFrames: 15,
  });

  const isHighlightColor = settings.currentWordColor;

  const isWordActive = (word: SubtitleWord) => {
    // console.log(
    //   `word: ${word.text}, start: ${word.startTime * fps}/${absoluteFrame}/${
    //     word.endTime * fps
    //   }`
    // );
    return (
      absoluteFrame >= word.startTime * fps &&
      absoluteFrame <= word.endTime * fps
    );
  };

  const isGroupActive =
    absoluteFrame >= subtitle.startTime * fps &&
    absoluteFrame <= subtitle.endTime * fps + 0.5 * fps;

  if (!isGroupActive) {
    // console.log(isGroupActive, absoluteFrame, absoluteFrame * fps, subtitle);
    // return false;
  }

  let wordsGroupAnimation: any = [];

  if (!isHighlightColor) {
    wordsGroupAnimation = [
      // scale(interpolate(enter, [0, 1], [0.9, 1])),
      scale(interpolate(enter, [0, 1], [0.9, 1])),
      // translateY(interpolate(enter, [0, 1], [15, 0])),
    ];
  }

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        direction: isRtl ? 'rtl' : 'ltr',
        justifyContent: 'center',
        alignItems: 'center',
        // width: `${settings.textBoxWidthInPercent}%`,
        width: '100%',
        // margin: '0 auto',
        transform: isFirst
          ? 'initial'
          : makeTransform(
              wordsGroupAnimation
              // [
              // scale(interpolate(enter, [0, 1], [0.9, 1])),
              // translateX(interpolate(enter, [0, 1], [25, 0])),
              // ]
            ),
      }}
    >
      {subtitle.words.map((word, index) => {
        const isActiveByTime = isWordActive(word);

        const isLast = index === subtitle.words.length - 1;

        const isAllPreviousDisabled = subtitle.words
          .slice(0, -1)
          .every((word) => {
            return !isWordActive(word);
          });

        const prevSub = subtitle.words[index - 1];
        const isPreviousSubPassed = prevSub
          ? prevSub.endTime * fps <= absoluteFrame
          : true;

        const isActive =
          isActiveByTime ||
          (isLast && isAllPreviousDisabled && isPreviousSubPassed); //  && ((currentFrame > (duration / subtitle.words.length) * index  || isAllPreviousDisabled)));

        if (isLast) {
          // console.log('1', word, prevSub)
          // console.log('2', absoluteFrame/30, subtitle.words.slice(0, -1))
          // console.log(`3 ${word.text}: isACtiveByTime: ${isActiveByTime}`)
          // console.log(`4 ${word.text}: isAllPreviousDisabled: ${isAllPreviousDisabled}`)
          // console.log(`5 ${word.text}: isPreviousSubPassed: ${isPreviousSubPassed}`)
          // console.log(`6 ${word.text}: currentFrame: ${currentFrame} - ${(duration / subtitle.words.length) * index }`)
        }

        const outlineColor = settings.shadowColor;
        const outlineWidth = settings.shadowBlur;

        // Add word animation
        const wordSpring = spring({
          frame: absoluteFrame,
          fps,
          config: {
            damping: 100,
          },
          durationInFrames: 10,
        });

        const color = interpolateColors(
          wordSpring,
          [0, 1],
          isActive
            ? [
                settings.textColor,
                settings.currentWordColor || settings.textColor,
              ]
            : [settings.textColor, settings.textColor]
        );

        const backgroundColor = interpolateColors(
          wordSpring,
          [0, 1],
          isActive
            ? [
                'transparent',
                settings.currentWordBackgroundColor || 'transparent',
              ]
            : ['transparent', 'transparent']
        );

        const dynamicStyle: any = {};

        if (isRtl) {
          dynamicStyle['marginRight'] = index === 0 ? 0 : 30;
        } else {
          dynamicStyle['marginLeft'] = index === 0 ? 0 : 30;
        }

        let wordAnimation = [
          // scale(interpolate(wordSpring, [0, 1], [1, 1.1])),
          scale(interpolate(enter, [0, 1], [1, 1.1])),
          // translateY(interpolate(enter, [0, 1], [0, -1])),
        ];

        if (!isHighlightColor) {
          wordAnimation = [];
        }

        return (
          <div
            key={index}
            style={{
              ...dynamicStyle,
              display: 'inline-block',
              fontSize: settings.fontSize,
              fontFamily,
              // fontWeight: settings.fontWeight,
              textShadow: outlineColor
                ? `${outlineWidth}px ${outlineWidth}px 0 ${outlineColor},
     -${outlineWidth}px ${outlineWidth}px 0 ${outlineColor},
     -${outlineWidth}px -${outlineWidth}px 0 ${outlineColor},
     ${outlineWidth}px -${outlineWidth}px 0 ${outlineColor},
     ${outlineWidth * 2}px ${outlineWidth * 2}px ${
                    outlineWidth * 3
                  }px rgba(0, 0, 0, 0.6)`
                : 'none',

              color,
              backgroundColor,
              lineHeight: `${settings.fontSize + 40}px`,
              borderRadius: isActive ? '10px' : undefined,
              transform: isActive ? makeTransform(wordAnimation) : undefined,
            }}
          >
            {settings.uppercase ? word.text.toUpperCase() : word.text}
          </div>
        );
      })}
    </div>
  );
};

export const SubtitleSeries: React.FC<{
  subtitles: Subtitle[];
  settings: CaptionSettings;
  absoluteFrame: number;
  isRtl: boolean;
}> = ({ subtitles, settings, absoluteFrame, isRtl }) => {
  const { fps } = useVideoConfig();

  return (
    <div
      style={{
        position: 'absolute',
        bottom: `${settings.positionY}%`,
        left: 0,
        right: 0,
      }}
    >
      <Series>
        {subtitles.map((subtitle, index) => {
          let durationInFrames =
            index !== 0
              ? (subtitle.endTime - subtitle.startTime) * fps
              : subtitle.endTime * fps;

          const nextSubs = subtitles[index + 1];
          const prevSubs = subtitles[index - 1];
          const diffToNextSubs = nextSubs
            ? nextSubs.startTime - subtitle.endTime
            : 0;
          const diffToPrevSubs = prevSubs
            ? subtitle.startTime - prevSubs.endTime
            : 0;

          if (nextSubs) {
            const isNextStartSame = nextSubs.startTime === subtitle.endTime;

            if (!isNextStartSame) {
              durationInFrames += diffToNextSubs * fps;
            }
          }

          if (durationInFrames <= 0) {
            return null;
          }

          return (
            <Fragment key={index}>
              <Series.Sequence key={index} durationInFrames={durationInFrames}>
                <SubtitleDisplay
                  isRtl={isRtl}
                  isFirst={index === 0}
                  subtitle={subtitle}
                  duration={durationInFrames}
                  settings={settings}
                  absoluteFrame={absoluteFrame}
                />
              </Series.Sequence>
            </Fragment>
          );
        })}
      </Series>
    </div>
  );
};
