import React from 'react';
import { Flex, Image, Typography } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import couponApply from './coupon-apply.png';

const { Title } = Typography;

const faqItems = [
  {
    key: '1',
    question: 'What is a credit and how does it work?',
    answer:
      'Approximately 10 credits are needed to generate one 1-minute video. You can check the number of credits included in each plan to estimate how many videos you can create. Credits refresh monthly with your subscription.',
  },
  {
    key: '2',
    question: 'Can I upgrade or downgrade my plan at any time?',
    answer: `Yes, you can change your plan at any time. When upgrading, you'll get immediate access to the new features. When downgrading, the changes will take effect in your next billing cycle.`,
  },
  {
    key: '2.5',
    question: 'Can I get a refund?',
    answer: `Yes! You can get a full refund for all the unused credits within 3 days of purchase. Just drop us a message, we'll do it immediately.`,
  },
  {
    key: '3',
    question: 'What happens if I run out of credits?',
    answer:
      'If you run out of credits, consider upgrading your plan for higher credit limits. Need a custom solution? Our support team is here to help - just reach out through the chat at the bottom of the screen.',
  },
  {
    key: '3.5',
    question: 'How do i apply the coupon code?',
    answer: (
      <Flex style={{ flexDirection: 'column' }}>
        <span>Click on "I have a coupon code" and insert your code.</span>
        <Image src={couponApply} />
      </Flex>
    ),
  },
  {
    key: '4',
    question: 'What payment methods do you accept?',
    answer:
      'We process payments through PayPro Global, supporting all payment methods available through their platform.',
  },
  {
    key: '5',
    question: 'Is there a free trial available?',
    answer:
      'We do not offer a free trial as AI technology involves significant computational costs. However, our plans are designed to provide excellent value for the features and capabilities provided.',
  },
  {
    key: '6',
    question: 'What does auto-publish to TikTok/YouTube mean?',
    answer:
      'The "Series" feature automatically creates and publishes videos for you based on your chosen topic or script. It saves time by handling both creation and publishing, making it ideal for consistent, automated content on TikTok or YouTube.',
  },
  {
    key: '7',
    question: 'Credits Usage',
    answer:
      'Your credits reset every month according to your billing cycle. Unused credits do not roll over to the next month.',
  },
  {
    key: '8',
    question: 'How long does payment processing take?',
    answer:
      "Payment processing may take a few minutes to complete. If you don't see your payment reflected immediately, please wait a moment and refresh the page to check your updated status. In any case, you can always contact our support team through the chat at the bottom of the screen for assistance.",
  },
  {
    key: '9',
    question: `Can I try the product before subscribing?`,
    answer:
      '💡 Our product operates on a paid subscription model to ensure the best experience for our users.\n' +
      'However, we understand that you may want to explore it before committing. 😊\n' +
      '\n' +
      '✨ If you’re genuinely interested and serious about trying it out, feel free to reach out to us!\n' +
      'We’d be happy to provide a trial so you can experience its value firsthand. 🚀',
  },
  {
    key: '10',
    question: `Have a question or feature request? We'd love to hear from you! 💭`,
    answer:
      'Our friendly support team is always here to help! Whether you have questions, feedback, or ideas for new features, please reach out through the chat at the bottom of the screen. We value your input and are committed to making your experience awesome! 🌟',
  },
];

export const FAQSection = () => {
  const [expandedKey, setExpandedKey] = React.useState<string | null>(null);

  return (
    <div style={{ padding: '40px 24px' }}>
      <Title level={2} style={{ marginBottom: 32 }}>
        Frequently Asked Questions
      </Title>
      <div
        style={{
          border: '1px solid #f0f0f0',
          borderRadius: 8,
          overflow: 'hidden',
        }}
      >
        {faqItems.map((item) => (
          <div
            key={item.key}
            style={{
              borderBottom:
                item.key !== faqItems[faqItems.length - 1].key
                  ? '1px solid #f0f0f0'
                  : 'none',
            }}
          >
            <div
              onClick={() =>
                setExpandedKey(expandedKey === item.key ? null : item.key)
              }
              style={{
                padding: '16px 24px',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                background: '#fff',
                transition: 'background-color 0.3s',
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = '#fafafa';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = '#fff';
              }}
            >
              <span style={{ fontSize: 16 }}>{item.question}</span>
              <RightOutlined
                style={{
                  transform:
                    expandedKey === item.key ? 'rotate(90deg)' : 'none',
                  transition: 'transform 0.3s',
                }}
              />
            </div>
            <div
              style={{
                padding: expandedKey === item.key ? '16px 24px' : '0 24px',
                maxHeight: expandedKey === item.key ? '500px' : '0',
                overflow: 'hidden',
                transition: 'all 0.3s ease-in-out',
                background: '#fafafa',
              }}
            >
              {item.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQSection;
