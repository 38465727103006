import React from 'react';
import { Typography, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const { Text } = Typography;

const SubscribeCTA = () => {
  const navigate = useNavigate();

  return (
    <div style={{ textAlign: 'center', padding: '12px 0' }}>
      <Text type="secondary">
        🌟 Unlock video creation and join our growing community of creators! ✨
        <br />
        🎥 Subscribe now to start sharing your amazing content 🚀
      </Text>

      <div style={{ marginTop: 16 }}>
        <Button
          type="primary"
          onClick={() => navigate('/settings/billing')}
          style={{
            background: '#1677ff',
            paddingLeft: 24,
            paddingRight: 24,
            height: 40,
            borderRadius: 6,
            fontWeight: 500,
          }}
        >
          Upgrade Now 🎉
        </Button>
      </div>
    </div>
  );
};

export default SubscribeCTA;
