export enum PrivacyStatus {
  Private = 'private',
  Public = 'public',
  Unlisted = 'unlisted',
}

export enum YoutubeCategories {
  FilmAnimation = '1',
  AutosVehicles = '2',
  Music = '10',
  PetsAnimals = '15',
  Sports = '17',
  ShortMovies = '18',
  TravelEvents = '19',
  Gaming = '20',
  Videoblogging = '21',
  PeopleBlogs = '22',
  Comedy = '23',
  Entertainment = '24',
  NewsPolitics = '25',
  HowtoStyle = '26',
  Education = '27',
  ScienceTech = '28',
  NonprofitsActivism = '29',
  Movies = '30',
  AnimeAnimation = '31',
  ActionAdventure = '32',
  Classics = '33',
  Documentary = '35',
  Drama = '36',
  Family = '37',
  Foreign = '38',
  Horror = '39',
  SciFiFantasy = '40',
  Thriller = '41',
  Shorts = '42',
  Shows = '43',
  Trailers = '44',
}

export interface YouTubeMetadata {
  privacyStatus: PrivacyStatus;
  category: YoutubeCategories;
}

export const youtubeDefaultMetadata: YouTubeMetadata = {
  privacyStatus: PrivacyStatus.Public,
  category: YoutubeCategories.Shorts,
};

export const YoutubeCategoryLabels: Record<YoutubeCategories, string> = {
  [YoutubeCategories.FilmAnimation]: 'Film & Animation',
  [YoutubeCategories.AutosVehicles]: 'Autos & Vehicles',
  [YoutubeCategories.Music]: 'Music',
  [YoutubeCategories.PetsAnimals]: 'Pets & Animals',
  [YoutubeCategories.Sports]: 'Sports',
  [YoutubeCategories.ShortMovies]: 'Short Movies',
  [YoutubeCategories.TravelEvents]: 'Travel & Events',
  [YoutubeCategories.Gaming]: 'Gaming',
  [YoutubeCategories.Videoblogging]: 'Videoblogging',
  [YoutubeCategories.PeopleBlogs]: 'People & Blogs',
  [YoutubeCategories.Comedy]: 'Comedy',
  [YoutubeCategories.Entertainment]: 'Entertainment',
  [YoutubeCategories.NewsPolitics]: 'News & Politics',
  [YoutubeCategories.HowtoStyle]: 'Howto & Style',
  [YoutubeCategories.Education]: 'Education',
  [YoutubeCategories.ScienceTech]: 'Science & Technology',
  [YoutubeCategories.NonprofitsActivism]: 'Nonprofits & Activism',
  [YoutubeCategories.Movies]: 'Movies',
  [YoutubeCategories.AnimeAnimation]: 'Anime & Animation',
  [YoutubeCategories.ActionAdventure]: 'Action & Adventure',
  [YoutubeCategories.Classics]: 'Classics',
  [YoutubeCategories.Documentary]: 'Documentary',
  [YoutubeCategories.Drama]: 'Drama',
  [YoutubeCategories.Family]: 'Family',
  [YoutubeCategories.Foreign]: 'Foreign',
  [YoutubeCategories.Horror]: 'Horror',
  [YoutubeCategories.SciFiFantasy]: 'Sci-Fi & Fantasy',
  [YoutubeCategories.Thriller]: 'Thriller',
  [YoutubeCategories.Shorts]: 'Shorts',
  [YoutubeCategories.Shows]: 'Shows',
  [YoutubeCategories.Trailers]: 'Trailers',
};
