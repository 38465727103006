import { MotionType } from '@monorepo/types';
import { Easing } from 'remotion';
import { MotionResult } from './effects-types';

export const circularMotions: Record<
  string,
  (progress: number) => MotionResult
> = {
  // Circular motion clockwise
  [MotionType.CIRCULAR_CLOCKWISE]: (progress: number): MotionResult => {
    const angle = (progress / 100) * 2 * Math.PI;
    const radius = 33;
    const scale = 1.7;
    const translateX = radius * Math.cos(angle);
    const translateY = radius * Math.sin(angle);

    return {
      transform: `translate3d(${translateX}%, ${translateY}%, 0px) scale(${scale}) rotate(0deg)`,
      recommendedEasing: Easing.cubic,
      startPosition: {
        scale: 1.7,
        translateX: radius,
        translateY: 0,
        rotate: 0,
      },
      currentPosition: {
        scale,
        translateX,
        translateY,
        rotate: 0,
      },
    };
  },

  // Circular motion counterclockwise
  [MotionType.CIRCULAR_COUNTERCLOCKWISE]: (progress: number): MotionResult => {
    const angle = -(progress / 100) * 2 * Math.PI;
    const radius = 33;
    const scale = 1.7;
    const translateX = radius * Math.cos(angle);
    const translateY = radius * Math.sin(angle);

    return {
      transform: `translate3d(${translateX}%, ${translateY}%, 0px) scale(${scale}) rotate(0deg)`,
      recommendedEasing: Easing.cubic,
      startPosition: {
        scale: 1.7,
        translateX: radius,
        translateY: 0,
        rotate: 0,
      },
      currentPosition: {
        scale,
        translateX,
        translateY,
        rotate: 0,
      },
    };
  },

  // Spiral motion inward
  [MotionType.SPIRAL_TOWARD_CENTER]: (progress: number): MotionResult => {
    const angle = -(progress / 100) * 2 * Math.PI;
    const startRadius = 50;
    const endRadius = 0;
    const radius = startRadius - (progress / 100) * (startRadius - endRadius);
    const scale = 2 + (progress / 100) * 1;
    const translateX =
      radius * Math.cos(angle) * Math.sin((progress / 100) * 2 * Math.PI);
    const translateY = radius * Math.sin(angle);

    return {
      transform: `translate3d(${translateX}%, ${translateY}%, 0px) scale(${scale}) rotate(0deg)`,
      recommendedEasing: Easing.cubic,
      startPosition: {
        scale: 2,
        translateX: startRadius,
        translateY: 0,
        rotate: 0,
      },
      currentPosition: {
        scale,
        translateX,
        translateY,
        rotate: 0,
      },
    };
  },

  // Spiral motion outward
  [MotionType.SPIRAL_OUT_FROM_CENTER]: (progress: number): MotionResult => {
    const angle = (progress / 100) * 2 * Math.PI;
    const startRadius = 50;
    const endRadius = 0;
    const radius = startRadius - (progress / 100) * (startRadius - endRadius);
    const scale = 3 - (progress / 100) * 1;
    const translateX =
      radius * Math.cos(angle) * Math.sin((progress / 100) * 2 * Math.PI);
    const translateY = radius * Math.sin(angle);

    return {
      transform: `translate3d(${translateX}%, ${translateY}%, 0px) scale(${scale}) rotate(0deg)`,
      recommendedEasing: Easing.cubic,
      startPosition: {
        scale: 3,
        translateX: 0,
        translateY: 0,
        rotate: 0,
      },
      currentPosition: {
        scale,
        translateX,
        translateY,
        rotate: 0,
      },
    };
  },

  // Figure-eight motion
  [MotionType.FIGURE_EIGHT]: (progress: number): MotionResult => {
    const angle = (progress / 100) * 2 * Math.PI;
    const translateX = 33 * Math.sin(angle);
    const translateY = 16 * Math.sin(2 * angle);
    const scale = 1.7;

    return {
      transform: `translate3d(${translateX}%, ${translateY}%, 0px) scale(${scale}) rotate(0deg)`,
      recommendedEasing: Easing.cubic,
      startPosition: {
        scale: 1.7,
        translateX: 0,
        translateY: 0,
        rotate: 0,
      },
      currentPosition: {
        scale,
        translateX,
        translateY,
        rotate: 0,
      },
    };
  },
};
