import React from 'react';
import {
  AbsoluteFill,
  Easing,
  Img,
  interpolate,
  OffthreadVideo,
  useCurrentFrame,
  useVideoConfig,
} from 'remotion';
import { TransitionSeries } from '@remotion/transitions';
import { Fragment } from 'react';
import { Asset, TRANSITION_FRAMES } from '../remotion-types';
import { TransitionEffect, MotionType } from '@monorepo/types';
import { createTransition } from '../remotion-helpers/create-transition';
import {
  circularMotions,
  panMotions,
  randomMotions,
  zoomMotions,
} from '../effects';

export const allMotions: any = {
  ...zoomMotions,
  ...panMotions,
  ...circularMotions,
  ...randomMotions,
};

export const AssetDisplay: React.FC<{
  asset: Asset;
  index: number;
  nextAsset?: Asset;
  startFrom?: number;
}> = ({ asset, nextAsset, startFrom = 0, index }) => {
  if (!asset.src) return null;

  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();
  const durationInFrames = fps * (asset?.duration || 1);

  // Split the duration: 90% main motion, 10% transition
  const mainMotionEndFrame = durationInFrames * 0.95;

  const motion = asset.motion;
  let motionFn = allMotions[asset.motion];
  const nextMotionFn = nextAsset ? allMotions[nextAsset.motion] : null;

  if (!motionFn) {
    console.warn(`missing motion fn: ${motion}`);
    motionFn = allMotions[MotionType.NO_MOTION];
  }

  const rawProgress = frame / durationInFrames;

  const progress = interpolate(
    frame, // Linear progress from 0 to 1
    [0, durationInFrames],
    [0, rawProgress],
    {
      easing: Easing.bezier(0, 0.65, 1, 0.35),
      extrapolateLeft: 'clamp',
      extrapolateRight: 'clamp',
    }
  );

  const progressPercentage = progress * 100;
  const motionResult = motionFn(progressPercentage, index);
  const transform = motionResult.transform;

  // If we're in transition phase and have a next motion
  // if (
  //   frame > mainMotionEndFrame &&
  //   nextMotionFn &&
  //   !motionResult.disableConnectToNext
  // ) {
  //   const transitionProgress =
  //     (frame - mainMotionEndFrame) / (durationInFrames - mainMotionEndFrame);
  //   const motionAt90 = motionFn(95);
  //   const nextInitial = nextMotionFn(0);
  //
  //   // Get scale and positions from transform strings
  //   const currentScale = motionAt90.currentPosition?.scale || 1.2;
  //   const nextScale = nextInitial.startPosition?.scale || 1.2;
  //
  //   const currentTranslateX = motionAt90.currentPosition?.translateX || 0;
  //   const nextTranslateX = nextInitial.startPosition?.translateX || 0;
  //
  //   const currentTranslateY = motionAt90.currentPosition?.translateY || 0;
  //   const nextTranslateY = nextInitial.startPosition?.translateY || 0;
  //
  //   const currentRotate = motionAt90.currentPosition?.rotate || 0;
  //   const nextRotate = nextInitial.startPosition?.rotate || 0;
  //
  //   // Interpolate between them
  //   const scale = interpolate(
  //     transitionProgress,
  //     [0, 1],
  //     [currentScale, nextScale],
  //     {
  //       extrapolateLeft: 'clamp',
  //       extrapolateRight: 'clamp',
  //     }
  //   );
  //   const translateX = interpolate(
  //     transitionProgress,
  //     [0, 1],
  //     [currentTranslateX, nextTranslateX],
  //     {
  //       extrapolateLeft: 'clamp',
  //       extrapolateRight: 'clamp',
  //     }
  //   );
  //   const translateY = interpolate(
  //     transitionProgress,
  //     [0, 1],
  //     [currentTranslateY, nextTranslateY],
  //     {
  //       extrapolateLeft: 'clamp',
  //       extrapolateRight: 'clamp',
  //     }
  //   );
  //   const rotate = interpolate(
  //     transitionProgress,
  //     [0, 1],
  //     [currentRotate, nextRotate],
  //     {
  //       extrapolateLeft: 'clamp',
  //       extrapolateRight: 'clamp',
  //     }
  //   );
  //
  //   transform = `translate3d(${translateX}%, ${translateY}%, 0px) scale(${scale}) rotate(${rotate}deg)`;
  // }

  const src = asset.src;

  return (
    <AbsoluteFill style={{ overflow: 'hidden' }}>
      <AbsoluteFill
        style={{
          transform,
          width: '100%',
          height: '100%',
          transformOrigin: 'center center',
          backfaceVisibility: 'hidden',
          willChange: 'transform',
        }}
      >
        {asset.type === 'video' ? (
          <OffthreadVideo
            pauseWhenBuffering={false}
            src={src}
            startFrom={startFrom}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        ) : (
          <Img
            pauseWhenLoading={false}
            src={src}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              objectPosition: 'center center',
            }}
          />
        )}
      </AbsoluteFill>
    </AbsoluteFill>
  );
};

export const AssetTransitionSeries: React.FC<{
  assets: Array<Asset>;
}> = ({ assets }) => {
  const { fps, width, height } = useVideoConfig();
  const absoulteFrame = useCurrentFrame();
  let cumulative = 0;

  return (
    <AbsoluteFill>
      <TransitionSeries>
        {assets.map((asset, index) => {
          const durationInFrames = (asset.duration || 0) * fps;
          cumulative += durationInFrames;

          const nextAsset = assets[index + 1];
          const nextAssetSrc = nextAsset?.src || asset.src;

          const transitionDuration = TRANSITION_FRAMES;

          const transitionResult = createTransition({
            width,
            height,
            effect: asset.transition || TransitionEffect.None,
            fromSrc: asset.src,
            toSrc: nextAssetSrc,
          });

          return (
            <Fragment key={index}>
              <TransitionSeries.Sequence
                premountFor={fps * 4}
                durationInFrames={
                  durationInFrames + transitionResult.durationInFrames
                }
              >
                <AssetDisplay
                  index={index}
                  nextAsset={nextAsset}
                  asset={asset}
                />
              </TransitionSeries.Sequence>
              {index < assets.length - 1 && (
                <TransitionSeries.Transition
                  timing={transitionResult.timing}
                  //@ts-expect-error zubi
                  presentation={transitionResult.presentation}
                />
              )}
            </Fragment>
          );
        })}
      </TransitionSeries>
      {/*<NoiseComp />*/}
      {/*<SpaceDust />*/}
    </AbsoluteFill>
  );
};
