import { MotionType, TransitionEffect } from '@monorepo/types';
import { OverlayTrackItem } from './remotion-overlays';

export interface SubtitleWord {
  startTime: number;
  endTime: number;
  text: string;
}

export interface Subtitle {
  startTime: number;
  endTime: number;
  words: SubtitleWord[];
}

export interface CaptionSettings {
  display: boolean;
  fontSize: number;
  numberOfWords: number;
  textColor: string;
  fontWeight?: number;
  positionY: number;
  fontFamily: string;
  uppercase?: boolean;
  textAlign?: 'center' | 'left';
  textBoxWidthInPercent?: number;
  fadeInAnimation?: boolean;
  currentWordColor?: string;
  currentWordBackgroundColor: string;
  shadowColor: string;
  shadowBlur: number;
}

export interface Asset {
  type: 'image' | 'video';
  src: string;
  contentType: string;
  duration?: number;
  transition?: TransitionEffect;
  motion: MotionType;
}

export interface AudioTrack {
  src: string;
  volume?: number;
  duration?: number;
  startAt: number;
  loop?: boolean;
}

export interface RemotionConfig {
  overlayTracks: OverlayTrackItem[];
  assets: Asset[];
  audioTracks?: AudioTrack[][];
  subtitles: Subtitle[];
  captionSettings?: Partial<CaptionSettings>;
  width: number;
  height: number;
  fps: number;
  durationInFrames: number;
  isRtl: boolean;
}

export const DEFAULT_CAPTION_SETTINGS: CaptionSettings = {
  display: true,
  numberOfWords: 2,
  fontSize: 80,
  uppercase: true,
  fontFamily: 'Permanent Marker',
  textColor: '#FFFFFF',
  textAlign: 'center',
  currentWordBackgroundColor: 'transparent',
  shadowColor: '#000000',
  shadowBlur: 5,
  positionY: 50,
};

export const TRANSITION_FRAMES = 1;
export const AUDIO_DELAY_FRAMES = 1;
