import React, { FunctionComponent, useRef } from 'react';
import { Button, Flex } from 'antd';
import { SubtitlesConfig } from '@monorepo/types';
import SubtitleStyleModal, {
  SubtitleStyleModalActions,
} from './subtitles-preset-modal';
import { BlockOutlined } from '@ant-design/icons';
import SubtitlesEditor from '../../subtitles/subtitles-editor';

interface Props {
  updateSubtitles: (config: SubtitlesConfig) => Promise<void>;
  subtitlesConfig: SubtitlesConfig;
}

const VideoSubtitles: FunctionComponent<Props> = ({
  subtitlesConfig,
  updateSubtitles,
}) => {
  const styleModalRef = useRef<SubtitleStyleModalActions>(null);

  const openStyleModal = () => {
    styleModalRef.current?.open();
  };

  const onPresetApply = (preset: SubtitlesConfig) => {
    updateSubtitles(preset);
  };

  return (
    <div>
      <Flex justify={'start'}>
        <Button
          style={{ width: '100%', marginBottom: '15px' }}
          icon={<BlockOutlined />}
          onClick={openStyleModal}
        >
          Browse Style Presets
        </Button>
        <SubtitleStyleModal onSave={onPresetApply} ref={styleModalRef} />
      </Flex>
      <SubtitlesEditor
        subtitlesConfig={subtitlesConfig}
        onUpdate={updateSubtitles}
      />
    </div>
  );
};

export default VideoSubtitles;
