import React, { useState, useEffect } from 'react';
import { Card, Typography, Button, message, Alert } from 'antd';
import {
  ClockCircleOutlined,
  CopyOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';

const { Title, Text } = Typography;

const styles = {
  timer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    color: '#666',
  },
  couponContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    background: '#f5f5f5',
    padding: '12px',
    borderRadius: '4px',
    marginTop: '16px',
    marginBottom: '16px',
  },
  couponCode: {
    fontFamily: 'monospace',
    fontSize: '18px',
    color: '#722ed1',
    flex: 1,
    margin: 0,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',
  },
  discount: {
    color: '#ff4d4f',
    margin: 0,
  },
  subtitle: {
    fontSize: '14px',
    color: '#000000',
    marginTop: '4px',
    fontWeight: 'normal',
  },
};

const CouponDisplay = () => {
  const [timeLeft, setTimeLeft] = useState(
    dayjs().add(1, 'day').subtract(5, 'minutes').diff(dayjs(), 'second')
  );
  const [copied, setCopied] = useState(false);
  const couponCode = 'JAN_25';

  useEffect(() => {
    if (timeLeft <= 0) return;

    const timer = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  const formatTime = (seconds) => {
    const days = Math.floor(seconds / (3600 * 24));
    const hours = Math.floor((seconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${days}d ${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(couponCode);
    setCopied(true);
    message.success('Coupon code copied!');
    setTimeout(() => setCopied(false), 2000);
  };

  if (timeLeft <= 0) {
    return (
      <Alert
        message="Coupon Expired"
        description="This coupon is no longer available."
        type="error"
        showIcon
      />
    );
  }

  return (
    <Card style={{ height: '100%' }}>
      <div style={styles.header}>
        <div>
          <Title level={3} style={styles.discount}>
            <div>FLASH SALE: 30% OFF!</div>
            <Typography.Text type={'secondary'}>
              End Soon, Hurry Up and grab your discount!
            </Typography.Text>
          </Title>
        </div>
      </div>

      <div style={styles.couponContainer}>
        <Text style={styles.couponCode}>{couponCode}</Text>
        <div style={styles.timer}>
          <ClockCircleOutlined />
          <Text>{formatTime(timeLeft)}</Text>
        </div>
        <Button
          type="text"
          icon={copied ? <CheckOutlined /> : <CopyOutlined />}
          onClick={copyToClipboard}
        />
      </div>

      <Text type="danger">
        *Apply this code at checkout to get 30% lifetime discount.
      </Text>
    </Card>
  );
};

export default CouponDisplay;
