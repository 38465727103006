import { useVideoContext } from '../../../hooks/use-video';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { Button, Card, Form, Space, Typography } from 'antd';
import {
  FormInput,
  FormSelectTags,
  FormTextArea,
} from '@monorepo/react-components';
import { Video } from '@monorepo/types';
import CopyText from '../../../components/copy-text/copy-text';

const VideoMetadata = () => {
  const { video, updateMetadata } = useVideoContext();
  const form = useForm<Pick<Video, 'title' | 'description' | 'hashtags'>>({
    defaultValues: {
      title: '',
      description: '',
      hashtags: [],
    },
  });
  const {
    formState: { errors },
  } = form;

  useEffect(() => {
    if (video) {
      form.reset({
        title: video.title || '',
        description: video.description || '',
        hashtags: video.hashtags || '',
      });
    }
  }, [video?._id]);

  useEffect(() => {
    const { unsubscribe } = form.watch((value) => {
      if (video) {
        const hashtags = value.hashtags || video.hashtags;
        const cleanHashtags = hashtags.filter((tag) => tag !== undefined);

        updateMetadata({
          title: value.title || video.title,
          description: value.description || video.description,
          hashtags: cleanHashtags,
        });
      }
    });
    return () => unsubscribe();
  }, [form.watch]);

  const onSubmit = (data) => {
    console.log('Form data:', data);
  };

  return (
    <div className="video-metadata">
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Space direction="vertical" size="small" style={{ width: '100%' }}>
          <span>
            Title
            <CopyText text={form.getValues('title') || ''} />
          </span>
          <FormTextArea
            form={form}
            controllerProps={{
              name: 'title',
            }}
            placeholder="Enter title"
            textareaProps={{
              maxLength: 100,
              showCount: true,
              autoSize: true,
              status: errors.description ? 'error' : undefined,
            }}
          />

          <span>
            <div>
              Description
              <CopyText text={form.getValues('description') || ''} />
            </div>
            <Typography.Text
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                fontSize: '12px',
                margin: 0,
              }}
              type={'secondary'}
            >
              <div>
                Will be used as <b>Title</b> in <b>TikTok.</b>
              </div>
              <div>
                {' '}
                <b>TikTok</b> limits is <b>2,200</b> chars.
              </div>
            </Typography.Text>
          </span>
          <FormTextArea
            form={form}
            controllerProps={{
              name: 'description',
            }}
            placeholder="Enter description"
            textareaProps={{
              rows: 12,
              maxLength: 5000,
              showCount: true,
              status: errors.description ? 'error' : undefined,
            }}
          />
          <span>
            <div>
              Hashtags
              <CopyText
                text={
                  form
                    .getValues('hashtags')
                    .map((tag) => {
                      return tag[0] === '#' ? tag : `#${tag}`;
                    })
                    .join(' ') || ''
                }
              />
            </div>
            <Typography.Text
              style={{ fontSize: '12px', margin: 0 }}
              type={'secondary'}
            >
              Type your hashtag and press enter.
            </Typography.Text>
          </span>
          <FormSelectTags
            form={form}
            maxCount={6}
            controllerProps={{
              name: 'hashtags',
            }}
            options={form
              .watch('hashtags')
              .map((tag) => ({ value: tag, label: tag }))}
          />
        </Space>
      </form>
    </div>
  );
};

export default VideoMetadata;
