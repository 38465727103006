import { NinjaJob } from '@monorepo/types';

export interface PollingOptions {
  interval?: number;
  maxAttempts?: number;
}

export type SinglePollingOptions = PollingOptions & {
  onFinish?: (job: NinjaJob) => Promise<void>;
  onFail?: (job: NinjaJob) => void;
  onStart?: () => void;
};

export interface StartSinglePollingParams {
  jobId: string;
  options?: SinglePollingOptions;
}

export const DEFAULT_GROUP_OPTIONS: Required<PollingOptions> = {
  interval: 5000,
  maxAttempts: 30,
};

export const DEFAULT_SINGLE_OPTIONS: Required<PollingOptions> = {
  interval: 5000,
  maxAttempts: 12,
};

export interface UseVideoJobsResult {
  allJobs: NinjaJob[];
  activeJobs: NinjaJob[];
  failedJobs: NinjaJob[];
  finishedJobs: NinjaJob[];
  isJobActive: (jobId: string) => boolean;
  startPolling: (options?: PollingOptions) => void;
  stopPolling: () => void;
  startSinglePolling: (singleJobPayload: StartSinglePollingParams) => void;
  stopSinglePolling: (jobId: string) => void;
}
