import { Modal, ModalFuncProps } from 'antd';
import { ReactNode } from 'react';

interface InfoModalProps extends Omit<ModalFuncProps, 'type'> {
  title?: string;
  content: ReactNode;
  width?: number;
  centered?: boolean;
  closable?: boolean;
  maskClosable?: boolean;
  okText?: string;
  cancelText?: string;
  onOk?: () => void;
  onCancel?: () => void;
  showCancelButton?: boolean;
}

// Main utility function to show info modal
export const showInfoModal = ({
  title = 'Information',
  content,
  width = 520,
  centered = true,
  closable = true,
  maskClosable = true,
  okText = 'OK',
  cancelText = 'Cancel',
  onOk,
  onCancel,
  showCancelButton = false,
  ...rest
}: InfoModalProps): ReturnType<typeof Modal.info> => {
  const modalConfig: ModalFuncProps = {
    title,
    content,
    width,
    centered,
    closable,
    maskClosable,
    okText,
    onOk: () => {
      Modal.destroyAll();
      onOk?.();
    },
    ...rest,
  };

  // Only add cancel button and handler if showCancelButton is true
  if (showCancelButton) {
    modalConfig.cancelText = cancelText;
    modalConfig.onCancel = () => {
      Modal.destroyAll();
      onCancel?.();
    };
  }

  return Modal.info(modalConfig);
};

// Example usage with types
const handleShowModal = (): void => {
  showInfoModal({
    title: 'Welcome',
    content: 'This is a simple information modal.',
    onOk: () => {
      console.log('OK clicked');
    },
    showCancelButton: true,
    onCancel: () => {
      console.log('Cancel clicked');
    },
  });
};

// More specific example functions with proper return types
export const showSuccessModal = (
  content: ReactNode
): ReturnType<typeof Modal.info> => {
  return showInfoModal({
    title: 'Success',
    content,
    okText: 'Great!',
  });
};
