import { useEffect, useRef, useState } from 'react';
import { message } from 'antd';
import {
  NinjaJob,
  NinjaJobsMessage,
  NinjaJobStatus,
  NinjaJobType,
} from '@monorepo/types';
import { useStore } from '../helpers/use-store';
import { pollingManager } from '../managers/polling-manager';
import { useNotification } from './use-notification';
import {
  DEFAULT_GROUP_OPTIONS,
  DEFAULT_SINGLE_OPTIONS,
  PollingOptions,
  StartSinglePollingParams,
  UseVideoJobsResult,
} from './ninja-polling.types';

export const GROUP_POLLING_ID = 'video-jobs-group';

const creationJobTypes = [
  NinjaJobType.CreateSceneVoiceover,
  NinjaJobType.CreateSceneAsset,
  NinjaJobType.CreateScenes,
  NinjaJobType.CreateThumbnailVoiceover,
];

export const useVideoJobs = (
  videoId: string,
  { refetchAssets }: { refetchAssets: () => void }
): UseVideoJobsResult => {
  const notificationApi = useNotification();
  const {
    dataStore: { ninjaJobStore },
  } = useStore();

  const [allJobs, setAllJobs] = useState<NinjaJob[]>([]);
  const [singleJobs, setSingleJobs] = useState<Map<string, NinjaJob>>(
    new Map()
  );
  const acknowledgedJobs = useRef<Set<string>>(new Set());

  // console.log(`allJobs`, allJobs)
  // console.log(`singleJobs`, singleJobs)
  // console.log(`acknowledgedJobs`, acknowledgedJobs)

  const activeJobs = allJobs.filter(
    (job) =>
      job.status === NinjaJobStatus.InProgress ||
      job.status === NinjaJobStatus.Registered
  );

  const failedJobs = allJobs.filter(
    (job) => job.status === NinjaJobStatus.Failed
  );

  const finishedJobs = allJobs.filter(
    (job) => job.status === NinjaJobStatus.Finished
  );

  const isJobActive = (jobId?: string) => {
    if (!jobId) {
      return false;
    }

    let searchedJob = singleJobs.get(jobId);

    if (!searchedJob) {
      searchedJob = allJobs.find((job) => job._id === jobId);
    }

    if (!searchedJob) {
      return false;
    }

    return (
      searchedJob.status === NinjaJobStatus.InProgress ||
      searchedJob.status === NinjaJobStatus.Registered
    );
  };

  // -----
  const stopSinglePolling = (jobId: string) => {
    pollingManager.stopPolling(jobId);

    setSingleJobs((prev) => {
      prev.delete(jobId);

      return new Map(prev);
    });
  };

  const startSinglePolling = async ({
    jobId,
    options,
  }: StartSinglePollingParams) => {
    stopSinglePolling(jobId);

    const { interval, maxAttempts, onFinish, onFail, onStart } = {
      ...DEFAULT_SINGLE_OPTIONS,
      ...options,
    };

    const handleInterval = async () => {
      const updatedJob = (await ninjaJobStore.getStatus(jobId)) as NinjaJob;

      if (acknowledgedJobs.current.has(jobId)) {
        stopSinglePolling(jobId);
        return;
      }

      setSingleJobs((prev) => {
        prev.set(jobId, updatedJob);

        return new Map(prev);
      });

      if (updatedJob.status === NinjaJobStatus.Finished) {
        stopSinglePolling(jobId);
        await onFinish?.(updatedJob);
        acknowledgedJobs.current.add(jobId);
      }

      if (updatedJob.status === NinjaJobStatus.Failed) {
        stopSinglePolling(jobId);
        onFail?.(updatedJob);
        acknowledgedJobs.current.add(jobId);
      }
    };

    pollingManager.startPolling(
      jobId,
      {
        onInterval: handleInterval,
        onStart,
        onFail: (error) => {
          console.error('Polling error:', error);
        },
      },
      interval,
      maxAttempts
    );
  };

  // -----

  const handleJobStatusChange = (prevJob: NinjaJob, updatedJob: NinjaJob) => {
    // Skip if the job is being individually polled
    if (
      pollingManager.isBeingPolled(updatedJob._id) ||
      acknowledgedJobs.current.has(updatedJob._id)
    ) {
      console.log(`${updatedJob._id} already exists`);
      return;
    }

    if (prevJob.status === updatedJob.status) return;

    if (creationJobTypes.includes(updatedJob.type)) {
      refetchAssets();
      return;
    }

    if (updatedJob.status === NinjaJobStatus.Finished) {
      notificationApi.success({
        placement: 'top',
        message: 'Success',
        description: NinjaJobsMessage[updatedJob.type].success,
      });
    }

    if (updatedJob.status === NinjaJobStatus.Failed) {
      notificationApi.error({
        placement: 'top',
        message: 'Error',
        description: NinjaJobsMessage[updatedJob.type].fail,
      });
    }

    acknowledgedJobs.current.add(updatedJob._id);
  };

  const stopPolling = () => {
    pollingManager.stopPolling(GROUP_POLLING_ID);
    pollingManager.cleanup();
  };

  useEffect(() => {
    return () => stopPolling();
  }, []);

  const startPolling = (options?: PollingOptions) => {
    const { interval, maxAttempts } = {
      ...DEFAULT_GROUP_OPTIONS,
      ...options,
    };

    const handleInterval = async () => {
      try {
        const newJobs = await ninjaJobStore.getByVideo(videoId);

        setAllJobs((oldJobs) => {
          // Check for status changes
          oldJobs.forEach((prevJob) => {
            const updatedJob = newJobs.find((j) => j._id === prevJob._id);

            if (updatedJob) {
              handleJobStatusChange(prevJob, updatedJob);
            }
          });

          return newJobs;
        });

        const hasActiveJobs = newJobs.some(
          (job) =>
            job.status === NinjaJobStatus.InProgress ||
            job.status === NinjaJobStatus.Registered
        );

        if (!hasActiveJobs) {
          console.log('Polling stopped: No active jobs');
          stopPolling();
        }
      } catch (error) {
        message.error('Failed to fetch jobs status');
        throw error;
      }
    };

    pollingManager.startPolling(
      GROUP_POLLING_ID,
      {
        onInterval: handleInterval,
        onFail: (error) => console.error('Polling error:', error),
      },
      interval,
      maxAttempts
    );
  };

  return {
    allJobs,
    activeJobs,
    failedJobs,
    finishedJobs,
    startPolling,
    stopPolling,
    startSinglePolling,
    stopSinglePolling,
    isJobActive,
  };
};
