import { TiktokMetadata } from '@monorepo/types';
import React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

const TiktokAgreement = ({ metadata }: { metadata: TiktokMetadata }) => {
  const links = {
    music:
      'https://www.tiktok.com/legal/page/global/music-usage-confirmation/en',
    branded: 'https://www.tiktok.com/legal/page/global/bc-policy/en',
  };

  const MusicLink = () => (
    <a href={links.music} target="_blank" rel="noopener noreferrer">
      Music Usage Confirmation
    </a>
  );

  const BrandedLink = () => (
    <a href={links.branded} target="_blank" rel="noopener noreferrer">
      Branded Content Policy
    </a>
  );

  const getDeclarationText = () => {
    if (metadata.brandOptions.brandedContent) {
      return (
        <>
          By posting, you agree to TikTok's <BrandedLink /> and <MusicLink />.
        </>
      );
    }
    return (
      <>
        By posting, you agree to TikTok's <MusicLink />.
      </>
    );
  };

  return (
    <div style={{ borderTop: '1px solid #f0f0f0', paddingTop: '5px' }}>
      <Text type="secondary">{getDeclarationText()}</Text>
    </div>
  );
};

export default TiktokAgreement;
