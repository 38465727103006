import React from 'react';
import { Typography, Radio, Card, Row, Col, Flex } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { AiAssetStyles } from '@monorepo/types';

const { Text } = Typography;

export interface StylePreview {
  name: string;
  icon: string;
  imageUrls: string[];
}

export const STYLE_MAPPING: Record<AiAssetStyles, StylePreview> = {
  [AiAssetStyles.CINEMATIC]: {
    name: 'Cinematic',
    icon: '🎬',
    imageUrls: [
      'https://assets.shortsninja.com/ai-styles/Cinematic_1.png',
      'https://assets.shortsninja.com/ai-styles/Cinematic_2.png',
    ],
  },
  [AiAssetStyles.PLAYGROUND]: {
    name: 'Playground',
    icon: '🎮',
    imageUrls: [
      'https://assets.shortsninja.com/ai-styles/Playground_1.png',
      'https://assets.shortsninja.com/ai-styles/Playground_2.png',
    ],
  },
  [AiAssetStyles.JAPANESE_INK]: {
    name: 'Japanese Ink',
    icon: '🖌️',
    imageUrls: [
      'https://assets.shortsninja.com/ai-styles/Japanese_Ink_1.png',
      'https://assets.shortsninja.com/ai-styles/Japanese_Ink_2.png',
    ],
  },
  [AiAssetStyles.LEGO]: {
    name: 'Lego',
    icon: '🎮',
    imageUrls: [
      'https://assets.shortsninja.com/user/6707de22976dc499ff1491b8/iiOAnsIjTTTQPLFNx8lR.jpeg?date=1737034342999',
      'https://assets.shortsninja.com/user/6707de22976dc499ff1491b8/EiD5LcIzbqCzg5FKbf8f.jpeg?date=1737034382141',
    ],
  },
  [AiAssetStyles.REALISTIC_4K]: {
    name: '4k realistic',
    icon: '📷',
    imageUrls: [
      'https://assets.shortsninja.com/ai-styles/4k_realistic_1.png',
      'https://assets.shortsninja.com/ai-styles/4k_realistic_2.png',
    ],
  },
  [AiAssetStyles.MOTIVATIONAL]: {
    name: 'Motivational',
    icon: '🏃‍♂️',
    imageUrls: [
      'https://assets.shortsninja.com/user/6707de22976dc499ff1491b8/2rHdlpkmkhSX9CdwDKYA.jpeg?date=1737041583633',
      'https://assets.shortsninja.com/user/6707de22976dc499ff1491b8/Pv1AF0p3gMFvAPCkk2WA.jpeg?date=1737041675400',
    ],
  },
  [AiAssetStyles.ANALOG_FILM]: {
    name: 'Analog Film',
    icon: '📷️',
    imageUrls: [
      'https://assets.shortsninja.com/user/6707de22976dc499ff1491b8/SGGrfDhzZG47ItO3GRJ4.jpeg?date=1737097250905',
      'https://assets.shortsninja.com/user/6707de22976dc499ff1491b8/hYKrSRdsDjw8J2bcJe3D.jpeg?date=1737109059346',
    ],
  },
  [AiAssetStyles.CARTOON]: {
    name: 'Cartoon',
    icon: '🎨',
    imageUrls: [
      'https://assets.shortsninja.com/ai-styles/Cartoon_1.png',
      'https://assets.shortsninja.com/ai-styles/Cartoon_2.png',
    ],
  },
  [AiAssetStyles.COLLAGE]: {
    name: 'Collage',
    icon: '🎭',
    imageUrls: [
      'https://assets.shortsninja.com/ai-styles/Collage_1.png',
      'https://assets.shortsninja.com/ai-styles/Collage_2.png',
    ],
  },
  [AiAssetStyles.KAWAII]: {
    name: 'Kawaii',
    icon: '🌸',
    imageUrls: [
      'https://assets.shortsninja.com/ai-styles/Kawaii_1.png',
      'https://assets.shortsninja.com/ai-styles/Kawaii_2.png',
    ],
  },
  [AiAssetStyles.DIGITAL_ART]: {
    name: 'Digital Art',
    icon: '🎯',
    imageUrls: [
      'https://assets.shortsninja.com/ai-styles/Digital_Art_1.png',
      'https://assets.shortsninja.com/ai-styles/Digital_Art_2.png',
    ],
  },
  [AiAssetStyles.LINE_ART]: {
    name: 'Line Art',
    icon: '✏️',
    imageUrls: [
      'https://assets.shortsninja.com/ai-styles/Line_Art_1.png',
      'https://assets.shortsninja.com/ai-styles/Line_Art_2.png',
    ],
  },
  [AiAssetStyles.NEON_FUTURISTIC]: {
    name: 'Neon Futuristic',
    icon: '⚡',
    imageUrls: [
      'https://assets.shortsninja.com/ai-styles/Neon_Futuristic_1.png',
      'https://assets.shortsninja.com/ai-styles/Neon_Futuristic_2.png',
    ],
  },
  [AiAssetStyles.COMIC_BOOK]: {
    name: 'Comic Book',
    icon: '💫',
    imageUrls: [
      'https://assets.shortsninja.com/ai-styles/Comic_Book_1.png',
      'https://assets.shortsninja.com/ai-styles/Comic_Book_2.png',
    ],
  },
  [AiAssetStyles.ANIME]: {
    name: 'Anime',
    icon: '🇯🇵',
    imageUrls: [
      'https://assets.shortsninja.com/ai-styles/Anime_1.png',
      'https://assets.shortsninja.com/ai-styles/Anime_2.png',
    ],
  },
};
interface StylePreviewCardsProps {
  value: string;
  onChange: (value: AiAssetStyles) => void;
}

export const AiImageStylesComponent: React.FC<StylePreviewCardsProps> = ({
  value,
  onChange,
}) => {
  return (
    <div
      className={'scroller'}
      style={{
        maxWidth: '80vw',
        overflowX: 'scroll',
        padding: '5px',
        borderRadius: '6px',
        paddingBottom: '15px',
      }}
    >
      <Flex>
        {Object.entries(STYLE_MAPPING).map(([key, style]) => (
          <Card
            key={style.name}
            hoverable
            onClick={() => onChange(key as AiAssetStyles)}
            style={{
              width: '200px',
              marginRight: '25px',
              border:
                value === style.name
                  ? '1px solid #1890ff'
                  : '1px solid #e3e3e3',
              transition: 'all 0.2s ease',
            }}
            cover={
              <div>
                <div
                  style={{
                    width: '100%',
                    display: `flex`,
                    justifyContent: 'space-between',
                    height: `170px`,
                    padding: '5px',
                  }}
                >
                  {style.imageUrls.map((imageUrl) => {
                    return (
                      <div
                        key={imageUrl}
                        style={{
                          background: `url(${imageUrl})`,
                          backgroundSize: 'contain',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center center',
                          width: '140px',
                          height: '170px',
                        }}
                      />
                    );
                  })}
                </div>
                <Card.Meta
                  description={
                    value === style.name && (
                      <div
                        style={{
                          position: 'absolute',
                          top: '8px',
                          right: '8px',
                          background: 'rgba(255,255,255,0.9)',
                          borderRadius: '6px',
                          padding: '2px',
                          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                          zIndex: 1,
                        }}
                      >
                        <CheckCircleFilled
                          style={{
                            color: '#1890ff',
                            fontSize: '18px',
                          }}
                        />
                      </div>
                    )
                  }
                />
                {/* Selected Indicator */}
              </div>
            }
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '6px',
              }}
            >
              <span style={{ fontSize: '18px' }}>{style.icon}</span>
              <Text
                strong
                style={{
                  fontSize: '16px',
                  margin: 0,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {style.name}
              </Text>
            </div>
          </Card>
        ))}
      </Flex>
    </div>
  );
};
