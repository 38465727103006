import { Form, Switch, Select, Space, Row, Col } from 'antd';
import {
  RobotOutlined,
  ThunderboltOutlined,
  PictureOutlined,
} from '@ant-design/icons';
import { FunctionComponent, useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ThumbnailType } from '@monorepo/types';

interface VideoOptionsFormProps {
  form: UseFormReturn<any>;
}

export const VideoOptionsForm: FunctionComponent<VideoOptionsFormProps> = ({
  form,
}) => {
  const thumbnailEnabled = form.watch('thumbnailConfig.enabled');

  useEffect(() => {
    if (thumbnailEnabled) {
      form.setValue('thumbnailConfig.enableVoiceover', true, {
        shouldValidate: false,
      });
    } else {
      form.setValue('thumbnailConfig.enableVoiceover', false, {
        shouldValidate: false,
      });
    }
  }, [thumbnailEnabled]);

  return (
    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12}>
          <Form.Item
            label={
              <Space>
                <RobotOutlined />
                <span>Enable AI Motions</span>
              </Space>
            }
            tooltip={{
              title:
                'When enabled, AI will automatically select motion movements for your scenes.',
              placement: 'right',
              overlayStyle: { maxWidth: 300 },
              color: '#1677ff',
            }}
            style={{ marginBottom: 0 }}
          >
            <Switch
              checked={form.watch('options.enableAiMotions')}
              onChange={(checked) => {
                form.setValue('options.enableAiMotions', checked, {
                  shouldValidate: true,
                });
              }}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12}>
          <Form.Item
            label={
              <Space>
                <ThunderboltOutlined />
                <span>Video Speed</span>
              </Space>
            }
            tooltip={{
              title: 'Adjust the playback speed of your video.',
              placement: 'right',
              overlayStyle: { maxWidth: 300 },
              color: '#1677ff',
            }}
            style={{ marginBottom: 0 }}
          >
            <Select
              style={{ width: 80 }}
              value={form.watch('playbackRate') || 1}
              onChange={(value) => {
                form.setValue('playbackRate', value, {
                  shouldValidate: true,
                });
              }}
              options={[
                { value: 1, label: '1.0x' },
                { value: 1.1, label: '1.1x' },
                { value: 1.2, label: '1.2x' },
                { value: 1.3, label: '1.3x' },
                { value: 1.4, label: '1.4x' },
                { value: 1.5, label: '1.5x' },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Form.Item
            label={
              <Space>
                <PictureOutlined />
                <span>Enable Thumbnail</span>
              </Space>
            }
            tooltip={{
              title: 'Generate a custom thumbnail for your video',
              placement: 'right',
              overlayStyle: { maxWidth: 300 },
              color: '#1677ff',
            }}
            style={{ marginBottom: 0 }}
          >
            <Switch
              defaultChecked={true}
              checked={form.watch('thumbnailConfig.enabled')}
              onChange={(checked) => {
                form.setValue('thumbnailConfig.enabled', checked, {
                  shouldValidate: true,
                });
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      {thumbnailEnabled && (
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12}>
            <Form.Item label="Thumbnail Type" style={{ marginBottom: 0 }}>
              <Select
                defaultValue={ThumbnailType.TikTok}
                value={form.watch('thumbnailConfig.type')}
                onChange={(value) => {
                  form.setValue('thumbnailConfig.type', value, {
                    shouldValidate: true,
                  });
                }}
                options={[
                  { value: ThumbnailType.Reddit, label: 'Reddit' },
                  { value: ThumbnailType.TikTok, label: 'TikTok' },
                  { value: ThumbnailType.Text, label: 'Text' },
                ]}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item label="Enable Voiceover" style={{ marginBottom: 0 }}>
              <Switch
                defaultChecked={true}
                checked={form.watch('thumbnailConfig.enableVoiceover')}
                onChange={(checked) => {
                  form.setValue('thumbnailConfig.enableVoiceover', checked, {
                    shouldValidate: true,
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
    </Space>
  );
};
