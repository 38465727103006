import React, { FunctionComponent } from 'react';
import { TikTokOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useNotification } from '../../../hooks/use-notification';
import { useStore } from '../../../helpers/use-store';
import DisabledText from '../disabled-social-text';

interface Props {
  onAccountAdded: () => void;
}

const TikTokAuth: FunctionComponent<Props> = ({ onAccountAdded }) => {
  const {
    dataStore: { socialAccountStore, userStore },
  } = useStore();
  const notificationApi = useNotification();

  const onSuccess = async (data: { code: string; state: string }) => {
    try {
      const response = await socialAccountStore.tiktokAuth(data);

      if (response) {
        notificationApi.success({
          placement: 'top',
          icon: '',
          message: 'Success',
          description: 'TikTok Account added successfully',
        });

        onAccountAdded();
      } else {
        onError();
      }
    } catch (e) {
      console.error(`failed tiktok auth`, e);
    }
  };

  const onError = () => {
    notificationApi.error({
      placement: 'top',
      icon: '',
      message: 'Error',
      description: 'Failed adding TikTok account, please contact support.',
    });
  };

  const handleAuth = async () => {
    const authUrl = await socialAccountStore.getTiktokUrl();

    console.log(authUrl);

    // Center the popup window
    const width = 500;
    const height = 700;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2.5;

    // Store the callbacks for the popup to use
    window.onTikTokAuthSuccess = onSuccess;
    window.onTikTokAuthError = onError;

    // Open popup
    window.open(
      authUrl.toString(),
      'TikTok Auth',
      `width=${width},height=${height},left=${left},top=${top},scrollbars=yes`
    );
  };

  const isDisabled = userStore.currentUser.applicationConfig?.disableTiktok;

  return (
    <>
      <Button
        disabled={isDisabled}
        onClick={handleAuth}
        type="primary"
        icon={<TikTokOutlined />}
        block
        style={{ backgroundColor: isDisabled ? '' : '#000' }}
      >
        <span style={{ width: '80px' }}>TikTok</span>
      </Button>
      {isDisabled ? <DisabledText /> : null}
    </>
  );
};

export default TikTokAuth;
