import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Col, Modal, Row, Space, Typography, Input } from 'antd';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  RedditShareButton,
  RedditIcon,
  EmailShareButton,
  EmailIcon,
} from 'react-share';

const { Text, Paragraph } = Typography;

export interface ShareModalActions {
  open: (data: ShareModalData) => void;
}

interface ShareModalData {
  title: string;
  description: string;
  url: string;
}

const ShareModal = forwardRef<ShareModalActions>((_, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [shareData, setShareData] = useState<ShareModalData>({
    title: '',
    description: '',
    url: '',
  });

  useImperativeHandle(ref, () => ({
    open: (data: ShareModalData) => {
      setShareData(data);
      setIsOpen(true);
    },
  }));

  const handleCancel = () => {
    setIsOpen(false);
  };

  const iconSize = 40;
  const buttonStyle = {
    cursor: 'pointer',
    transition: 'opacity 0.2s',
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    gap: '8px',
  };

  const shareButtonProps = {
    url: shareData.url,
    title: shareData.title,
    style: buttonStyle,
  };

  return (
    <Modal
      title="Share"
      open={isOpen}
      onCancel={handleCancel}
      footer={[
        <Button key="close" onClick={handleCancel}>
          Close
        </Button>,
      ]}
      width={600}
    >
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Text strong>Share this video</Text>
            <Paragraph type="secondary" style={{ margin: 0 }}>
              Choose your preferred platform to share this video, although we
              recommend to download the video and upload it directly for better
              quality.
            </Paragraph>
          </Space>
        </Col>

        <Col span={24}>
          <Space
            style={{ width: '100%', justifyContent: 'center' }}
            size="large"
          >
            <FacebookShareButton {...shareButtonProps}>
              <FacebookIcon size={iconSize} round />
              <Text>Facebook</Text>
            </FacebookShareButton>

            <TwitterShareButton {...shareButtonProps}>
              <TwitterIcon size={iconSize} round />
              <Text>Twitter</Text>
            </TwitterShareButton>

            <LinkedinShareButton
              {...shareButtonProps}
              summary={shareData.description}
              source={window.location.hostname}
            >
              <LinkedinIcon size={iconSize} round />
              <Text>LinkedIn</Text>
            </LinkedinShareButton>

            <RedditShareButton {...shareButtonProps}>
              <RedditIcon size={iconSize} round />
              <Text>Reddit</Text>
            </RedditShareButton>

            <EmailShareButton
              {...shareButtonProps}
              subject={shareData.title}
              body={shareData.description}
            >
              <EmailIcon size={iconSize} round />
              <Text>Email</Text>
            </EmailShareButton>
          </Space>
        </Col>

        <Col span={24}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Text strong>Share Link</Text>
            <Input.Group compact>
              <Input
                style={{ width: 'calc(100% - 80px)' }}
                value={shareData.url}
                readOnly
              />
              <Button
                type="primary"
                onClick={() => {
                  navigator.clipboard.writeText(shareData.url);
                }}
              >
                Copy
              </Button>
            </Input.Group>
          </Space>
        </Col>
      </Row>
    </Modal>
  );
});

export default ShareModal;
