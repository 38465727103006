import { MotionType } from '@monorepo/types';
import { Easing } from 'remotion';
import { MotionResult } from './effects-types';

export const zoomMotions: Record<
  string,
  (progress: number, ...args: any) => MotionResult
> = {
  [MotionType.NO_MOTION]: (progress: number): MotionResult => {
    return {
      disableConnectToNext: true,
      transform: `translate3d(0%, 0%, 0px) scale(1) rotate(0deg)`,
      recommendedEasing: Easing.cubic,
      startPosition: {
        scale: 1.2,
        translateX: 0,
        translateY: 0,
        rotate: 0,
      },
      currentPosition: {
        scale: 1.2,
        translateX: 0,
        translateY: 0,
        rotate: 0,
      },
    };
  },

  [MotionType.GENTLE_RANDOM_MOTION]: (
    progress: number,
    index = 0
  ): MotionResult => {
    // Use index to create deterministic but different starting positions
    const indexMod = index % 4; // Create 4 different patterns

    // Generate different directions based on index
    const yDirectionMultiplier = indexMod % 2 === 0 ? 1 : -1; // Alternate up/down
    const rotateAmount = 1.5; // Maximum rotation in degrees
    const rotateDirectionMultiplier = indexMod <= 1 ? 1 : -1; // Alternate between left/right tilt

    // Starting positions vary by index
    const startScale = 1.25;
    const startTranslateY = -5 * yDirectionMultiplier;
    const startRotate = rotateDirectionMultiplier * 2; // Start with slight tilt

    // Calculate current positions
    const scale = startScale + (progress / 100) * 0.35;
    const rotate =
      startRotate + (progress / 100) * rotateAmount * rotateDirectionMultiplier;
    const translateY = -(5 - (progress / 100) * 5) * yDirectionMultiplier;

    return {
      disableConnectToNext: true,
      transform: `translate3d(0%, ${translateY}%, 0px) scale(${scale}) rotate(${rotate}deg)`,
      recommendedEasing: Easing.inOut(Easing.ease),
      startPosition: {
        scale: startScale,
        translateX: 0,
        translateY: startTranslateY,
        rotate: startRotate,
      },
      currentPosition: {
        scale,
        translateX: 0,
        translateY,
        rotate,
      },
    };
  },

  [MotionType.ZOOM_INTO_TOP_LEFT]: (progress: number): MotionResult => {
    const scale = 1.2 + (progress / 100) * 0.8;
    const translateX = (progress / 100) * 25;
    const translateY = (progress / 100) * 20;

    return {
      transform: `translate3d(${translateX}%, ${translateY}%, 0px) scale(${scale}) rotate(0deg)`,
      recommendedEasing: Easing.exp,
      startPosition: {
        scale: 1.2,
        translateX: 0,
        translateY: 0,
        rotate: 0,
      },
      currentPosition: {
        scale,
        translateX,
        translateY,
        rotate: 0,
      },
    };
  },

  [MotionType.GENTLE_ZOOM_FROM_TOP]: (progress: number): MotionResult => {
    // Subtle scale range from 1.15 to 1.0 (just 15% zoom)
    const scale = 1.25 - (progress / 100) * 0.15;

    // Slight upward movement (5% of height) as we zoom out
    const translateY = -(5 - (progress / 100) * 5);

    return {
      disableConnectToNext: true,
      transform: `translate3d(0%, ${translateY}%, 0px) scale(${scale}) rotate(0deg)`,
      recommendedEasing: Easing.inOut(Easing.ease), // Smoother easing for subtle motion
      startPosition: {
        scale: 1.25,
        translateX: 0,
        translateY: -5,
        rotate: 0,
      },
      currentPosition: {
        scale,
        translateX: 0,
        translateY,
        rotate: 0,
      },
    };
  },

  [MotionType.ZOOM_OUT_FROM_CENTER]: (progress: number): MotionResult => {
    const scale = 2.2 - (progress / 100) * 0.7;

    return {
      transform: `translate3d(0%, 0%, 0px) scale(${scale}) rotate(0deg)`,
      recommendedEasing: Easing.circle,
      startPosition: {
        scale: 2.2,
        translateX: 0,
        translateY: 0,
        rotate: 0,
      },
      currentPosition: {
        scale,
        translateX: 0,
        translateY: 0,
        rotate: 0,
      },
    };
  },

  [MotionType.DIAGONAL_MOVE_WITH_ZOOM_IN]: (progress: number): MotionResult => {
    const scale = 1.3 + (progress / 100) * 0.8;
    const translateX = -15 + (progress / 100) * 30;
    const translateY = -15 + (progress / 100) * 30;

    return {
      transform: `translate3d(${translateX}%, ${translateY}%, 0px) scale(${scale}) rotate(0deg)`,
      recommendedEasing: Easing.cubic,
      startPosition: {
        scale: 1.7,
        translateX: -15,
        translateY: -15,
        rotate: 0,
      },
      currentPosition: {
        scale,
        translateX,
        translateY,
        rotate: 0,
      },
    };
  },

  [MotionType.ZOOM_INTO_BOTTOM_RIGHT]: (progress: number): MotionResult => {
    const scale = 1.2 + (progress / 100) * 0.8;
    const translateX = (progress / 100) * -25;
    const translateY = (progress / 100) * -20;

    return {
      transform: `translate3d(${translateX}%, ${translateY}%, 0px) scale(${scale}) rotate(0deg)`,
      recommendedEasing: Easing.exp,
      startPosition: {
        scale: 1.2,
        translateX: 0,
        translateY: 0,
        rotate: 0,
      },
      currentPosition: {
        scale,
        translateX,
        translateY,
        rotate: 0,
      },
    };
  },

  [MotionType.ZOOM_IN_WHILE_PANNING_RIGHT]: (
    progress: number
  ): MotionResult => {
    const scale = 1.2 + (progress / 100) * 0.8;
    const translateX = (progress / 100) * -20;

    return {
      transform: `translate3d(${translateX}%, 0%, 0px) scale(${scale}) rotate(0deg)`,
      recommendedEasing: Easing.cubic,
      startPosition: {
        scale: 1.2,
        translateX: 0,
        translateY: 0,
        rotate: 0,
      },
      currentPosition: {
        scale,
        translateX,
        translateY: 0,
        rotate: 0,
      },
    };
  },

  [MotionType.ZOOM_OUT_WHILE_PANNING_UP]: (progress: number): MotionResult => {
    const scale = 2.5 - (progress / 100) * 0.7;
    const translateY = (progress / 100) * 30;

    return {
      transform: `translate3d(0%, ${translateY}%, 0px) scale(${scale}) rotate(0deg)`,
      recommendedEasing: Easing.circle,
      startPosition: {
        scale: 2.2,
        translateX: 0,
        translateY: 0,
        rotate: 0,
      },
      currentPosition: {
        scale,
        translateX: 0,
        translateY,
        rotate: 0,
      },
    };
  },

  [MotionType.SLOW_ZOOM_IN_AND_PAN_LEFT]: (progress: number): MotionResult => {
    const scale = 1.2 + (progress / 100) * 0.5;
    const translateX = (progress / 100) * 20;

    return {
      transform: `translate3d(${translateX}%, 0%, 0px) scale(${scale}) rotate(0deg)`,
      recommendedEasing: Easing.cubic,
      startPosition: {
        scale: 1,
        translateX: 0,
        translateY: 0,
        rotate: 0,
      },
      currentPosition: {
        scale,
        translateX,
        translateY: 0,
        rotate: 0,
      },
    };
  },

  [MotionType.FAST_ZOOM_OUT_WITH_SLIGHT_ROTATE]: (
    progress: number
  ): MotionResult => {
    const scale = 2.2 - (progress / 100) * 0.7;
    const rotate = (progress / 100) * 10;

    return {
      transform: `translate3d(0%, 0%, 0px) scale(${scale}) rotate(${rotate}deg)`,
      recommendedEasing: Easing.circle,
      startPosition: {
        scale: 1,
        translateX: 0,
        translateY: 0,
        rotate: 0,
      },
      currentPosition: {
        scale,
        translateX: 0,
        translateY: 0,
        rotate,
      },
    };
  },

  [MotionType.HEARTBEAT_ZOOM]: (progress: number): MotionResult => {
    const baseScale = 1.35 + Math.sin((progress / 100) * 2 * Math.PI) * 0.15;

    return {
      transform: `translate3d(0%, 0%, 0px) scale(${baseScale}) rotate(0deg)`,
      recommendedEasing: Easing.elastic(1),
      startPosition: {
        scale: 1.35,
        translateX: 0,
        translateY: 0,
        rotate: 0,
      },
      currentPosition: {
        scale: baseScale,
        translateX: 0,
        translateY: 0,
        rotate: 0,
      },
    };
  },

  [MotionType.ZOOM_IN_AND_ROTATE]: (progress: number): MotionResult => {
    const scale = 1.8 + (progress / 100) * 2;
    const rotate = (progress / 100) * 360;

    return {
      transform: `translate3d(0%, 0%, 0px) scale(${scale}) rotate(${rotate}deg)`,
      recommendedEasing: Easing.elastic(1),
      startPosition: {
        scale: 1.2,
        translateX: 0,
        translateY: 0,
        rotate: 0,
      },
      currentPosition: {
        scale,
        translateX: 0,
        translateY: 0,
        rotate,
      },
    };
  },

  [MotionType.PULSE_ZOOM_IN_AND_OUT]: (progress: number): MotionResult => {
    const baseScale = 1.4 + Math.sin((progress / 100) * Math.PI) * 0.3;

    return {
      transform: `translate3d(0%, 0%, 0px) scale(${baseScale}) rotate(0deg)`,
      recommendedEasing: Easing.bounce,
      startPosition: {
        scale: 1.4,
        translateX: 0,
        translateY: 0,
        rotate: 0,
      },
      currentPosition: {
        scale: baseScale,
        translateX: 0,
        translateY: 0,
        rotate: 0,
      },
    };
  },
};
