import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Input, Modal, Typography, Form } from 'antd';
import { LoadingState, useLoading } from '@monorepo/react-components';
import { WordWithMetadata } from '../timeline';
import { useNotification } from '../../../../../hooks/use-notification';
import { useStore } from '../../../../../helpers/use-store';
import { useVideoContext } from '../../../../../hooks/use-video';

const { Text } = Typography;

export interface EditWordModalActions {
  open: (word: WordWithMetadata) => void;
}

const EditWordModal = forwardRef<EditWordModalActions>((_, ref) => {
  const { scenes, updateSceneWords } = useVideoContext();
  const [isOpen, setIsOpen] = useState(false);
  const [currentWord, setCurrentWord] = useState<WordWithMetadata | null>(null);
  const [newWordText, setNewWordText] = useState('');
  const { loadingState, updateLoadingState } = useLoading();
  const notificationApi = useNotification();
  const {
    dataStore: { videoStore },
  } = useStore();

  useImperativeHandle(ref, () => ({
    open: (word: WordWithMetadata) => {
      setCurrentWord(word);
      setNewWordText(word.word);
      setIsOpen(true);
    },
  }));

  const handleCancel = () => {
    setIsOpen(false);
    setCurrentWord(null);
    setNewWordText('');
  };

  const handleSave = async () => {
    if (!currentWord) return;

    try {
      updateLoadingState(LoadingState.Loading);

      const newWord = {
        word: newWordText,
        start: currentWord.originalStartTime,
        end: currentWord.originalEndTime,
      };

      const foundScene = scenes.find(
        (scene) => scene._id === currentWord.sceneId
      );

      if (!foundScene) {
        throw new Error(`Scene: ${currentWord.sceneId} not found here!`);
      }

      const wordIndex = foundScene.words.findIndex(
        (sceneWord) =>
          sceneWord.start === newWord.start && sceneWord.end === newWord.end
      );

      if (wordIndex === -1) {
        throw new Error(`can't find wordIndex for: ${JSON.stringify(newWord)}`);
      }

      foundScene.words[wordIndex] = newWord;

      await updateSceneWords({
        sceneId: foundScene._id,
        videoId: foundScene.videoId,
        words: foundScene.words,
      });

      notificationApi.success({
        placement: 'top',
        message: 'Word updated successfully',
        description: `Changed "${currentWord.word}" to "${newWordText}"`,
      });

      handleCancel();
    } catch (error) {
      console.error('Failed to update word:', error);
      notificationApi.error({
        placement: 'top',
        message: 'Failed to update word',
        description:
          'An error occurred while updating the word. Please try again.',
      });
    } finally {
      updateLoadingState(LoadingState.Loaded);
    }
  };

  if (!currentWord) {
    return null;
  }

  const formattedTime = `${currentWord.start.toFixed(
    2
  )}s - ${currentWord.end.toFixed(2)}s`;

  return (
    <Modal
      title={
        <Typography.Title style={{ margin: 0 }} level={5}>
          Edit word{' '}
          <Text type="secondary" style={{ marginLeft: 8 }}>
            ({formattedTime})
          </Text>
        </Typography.Title>
      }
      open={isOpen}
      onCancel={(e) => {
        e.stopPropagation();

        handleCancel();
      }}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="save"
          type="primary"
          onClick={handleSave}
          loading={loadingState === LoadingState.Loading}
          disabled={newWordText === currentWord.word}
        >
          Save Changes
        </Button>,
      ]}
    >
      <Form layout="vertical">
        <Form.Item
          label={
            <div>
              <div></div>
              <Typography.Text type={'secondary'}>
                Editing the word won't affect the voiceover but just the
                subtitle.
              </Typography.Text>
            </div>
          }
        >
          <Input
            onKeyDown={(e) => {
              if (e.key === ' ') {
                e.preventDefault();
              }
            }}
            value={newWordText}
            onChange={(e) => setNewWordText(e.target.value)}
            placeholder="Enter new word"
            autoFocus
          />
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default EditWordModal;
