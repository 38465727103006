import React, { FunctionComponent } from 'react';
import { useStore } from '../../../helpers/use-store';
import { useVideoContext } from '../../../hooks/use-video';
import { LoadingState, useLoading } from '@monorepo/react-components';
import { MusicList } from '../../../components/music-list/music-list';

import './music.scss';
import UploadMusic from './upload-music/upload-music';

interface Props {
  musicId?: string;
  backgroundMusicVolume?: number;
  backgroundMusicUrl?: string;
}

const Music: FunctionComponent<Props> = ({
  musicId,
  backgroundMusicVolume,
  backgroundMusicUrl,
}) => {
  const {
    dataStore: { musicStore },
  } = useStore();
  const { updateBackgroundMusic } = useVideoContext();
  const { updateLoadingState, loadingState } = useLoading();

  const handleMusicUpdate = async (
    selectedMusicId: string | null,
    backgroundMusicVolume?: number
  ) => {
    try {
      updateLoadingState(LoadingState.Loading);
      await updateBackgroundMusic(
        selectedMusicId ? selectedMusicId : undefined,
        backgroundMusicVolume
      );
    } catch (e) {
      console.error('failed updating background music', e);
    } finally {
      updateLoadingState(LoadingState.Loaded);
    }
  };

  return (
    <div className={'music'}>
      <UploadMusic
        cancelMusic={() => handleMusicUpdate(null)}
        existingUrl={backgroundMusicUrl}
      />
      <MusicList
        musicId={musicId}
        backgroundMusicVolume={backgroundMusicVolume}
        musics={musicStore.items}
        onButtonClick={handleMusicUpdate}
        showUpdateButton
        updateButtonText="Update"
        loading={loadingState === LoadingState.Loading}
      />
    </div>
  );
};

export default Music;
