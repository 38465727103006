import { Form, Switch, Select, Space, Row, Col } from 'antd';
import { FunctionComponent, useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { YoutubeCategories, YoutubeCategoryLabels } from '@monorepo/types'; // Adjust the import path

interface SeriesOptionsFormProps {
  form: UseFormReturn<any>;
}

export const SeriesOptionsForm: FunctionComponent<SeriesOptionsFormProps> = ({
  form,
}) => {
  const youtubeCategoryId = form.watch('settings.youtubeCategoryId');

  return (
    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12}>
          <Form.Item
            label="YouTube Category"
            tooltip={{
              title: 'Select the category for your series on YouTube.',
              placement: 'right',
              overlayStyle: { maxWidth: 300 },
              color: '#1677ff',
            }}
            style={{ marginBottom: 0 }}
          >
            <Select
              value={youtubeCategoryId}
              onChange={(value) => {
                form.setValue('settings.youtubeCategoryId', value, {
                  shouldValidate: true,
                });
              }}
              options={Object.entries(YoutubeCategoryLabels).map(
                ([key, label]) => ({
                  value: key,
                  label: label,
                })
              )}
            />
          </Form.Item>
        </Col>
      </Row>

      {/* Add more fields for series settings here */}
    </Space>
  );
};
