import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Button, List, Slider, Typography } from 'antd';
import { Music } from '@monorepo/types';
import CardWithSound from '../card-with-sound/card-with-sound';
import { SoundOutlined } from '@ant-design/icons';

import musicalNote from './musical-note.svg';

import './music-list.scss';
import { orderBy } from 'lodash';

interface MusicListProps {
  musicId?: string;
  backgroundMusicVolume?: number;
  shouldDisplayFirst?: boolean;
  gridColumns?: number;
  musics: Music[];
  onMusicSelect?: (musicId: string | null) => void;
  onButtonClick?: (
    musicId: string | null,
    backgroundMusicVolume?: number
  ) => void;
  showUpdateButton?: boolean;
  updateButtonText?: string;
  updateDescription?: string;
  loading?: boolean;
}

export const sortItemsById = <T extends { _id: string; order?: number }>(
  items: T[],
  priorityId?: string
): T[] => {
  if (!items || items.length === 0) {
    return [];
  }

  return [...items].sort((a, b) => {
    // First, handle priority ID sorting
    if (priorityId) {
      if (a._id === priorityId) return -1;
      if (b._id === priorityId) return 1;
    }

    // Then, sort by order field
    const orderA = a.order ?? Number.MAX_SAFE_INTEGER;
    const orderB = b.order ?? Number.MAX_SAFE_INTEGER;
    return orderA - orderB;
  });
};

export const MusicList: FunctionComponent<MusicListProps> = ({
  musicId,
  backgroundMusicVolume = 0.1,
  musics,
  onMusicSelect,
  onButtonClick,
  gridColumns = 1,
  shouldDisplayFirst = true,
  showUpdateButton = false,
  updateButtonText = 'Update',
  updateDescription,
  loading = false,
}) => {
  const [selectedMusic, setSelectedMusic] = useState<string | null>(
    musicId || null
  );
  const [playingMusic, setPlayingMusic] = useState<string | null>(null);
  const [speed, setSpeed] = useState<number>(1);
  const [volume, setVolume] = useState<number>(backgroundMusicVolume || 0.3);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
      }
    };
  }, []);

  const selectMusic = (id: string) => {
    if (id === selectedMusic) {
      setSelectedMusic(null);
      onMusicSelect && onMusicSelect(null);
      return;
    }

    setSelectedMusic(id);
    onMusicSelect && onMusicSelect(id);
  };

  const toggleAudio = (id: string, url: string, event: React.MouseEvent) => {
    event.stopPropagation();

    if (playingMusic === id) {
      audioRef.current?.pause();
      setPlayingMusic(null);
    } else {
      if (audioRef.current) {
        audioRef.current.pause();
      }
      audioRef.current = new Audio(url);
      audioRef.current.playbackRate = speed;
      audioRef.current.play();
      audioRef.current.onended = () => setPlayingMusic(null);
      setPlayingMusic(id);
    }
  };

  const sortedItems = shouldDisplayFirst
    ? sortItemsById(musics, musicId)
    : orderBy(musics, 'order', 'asc');

  return (
    <div
      className="music"
      style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
    >
      <div style={{ overflowX: 'hidden' }} className="music-content scroller">
        <List
          grid={{
            gutter: 5,
            column: gridColumns,
          }}
          dataSource={sortedItems}
          renderItem={(item) => (
            <List.Item
              className={`list-item ${
                selectedMusic === item._id ? 'selected' : ''
              }`}
              style={{
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '5px',
                alignItems: 'stretch',
              }}
            >
              <CardWithSound
                id={item._id}
                avatarIcon={
                  <img
                    style={{ width: '18px', height: '18px' }}
                    src={musicalNote}
                  />
                }
                name={item.name}
                description={item.description}
                audioUrl={item.audioUrl}
                tags={item.tags}
                isSelected={selectedMusic === item._id}
                isPlaying={playingMusic === item._id}
                onPlay={toggleAudio}
                onSelect={selectMusic}
              />
            </List.Item>
          )}
        />
      </div>

      {showUpdateButton ? (
        <div>
          <Typography.Text style={{ margin: 0 }}>Volume</Typography.Text>
          <Slider
            style={{ margin: 0 }}
            tooltip={{
              formatter: (value) => `${((value || 0) * 100).toFixed(0)}%`,
            }}
            value={volume}
            onChange={(newVol) => setVolume(newVol)}
            min={0.05}
            max={1.5}
            step={0.05}
          />
        </div>
      ) : null}

      {showUpdateButton && (
        <>
          <Button
            disabled={
              selectedMusic === musicId && volume === backgroundMusicVolume
            }
            loading={loading}
            onClick={() =>
              onButtonClick && onButtonClick(selectedMusic, volume)
            }
            type="primary"
            style={{ width: '100%' }}
          >
            {updateButtonText}
          </Button>
          {updateDescription && (
            <Typography.Text type="secondary">
              {updateDescription}
            </Typography.Text>
          )}
        </>
      )}
    </div>
  );
};
