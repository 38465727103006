import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useMemo,
  useEffect,
} from 'react';
import { Button, Col, Modal, Row, Select, Space, Typography } from 'antd';
import { Languages, Voiceover, VoiceoverProvider } from '@monorepo/types';
import { getLanguageOptions } from '../../../features/base-video-form/base-video-form';

const { Text, Paragraph } = Typography;

export interface VoiceoverFilterState {
  language: Languages; // Changed from languages array to single string
  tags: string[];
  provider: VoiceoverProvider[];
  gender: string[];
}

export interface FilterModalActions {
  open: () => void;
}

interface Props {
  voiceovers: Voiceover[];
  showLanguage: boolean;
  filters: VoiceoverFilterState;
  onApplyFilters: (filters: VoiceoverFilterState) => void;
}

const VoiceoverFiltersModal = forwardRef<FilterModalActions, Props>(
  ({ voiceovers, filters, onApplyFilters, showLanguage }, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const [localFilters, setLocalFilters] =
      useState<VoiceoverFilterState>(filters);

    useEffect(() => {
      setLocalFilters(filters);
    }, [filters]);

    const { providers } = useMemo(
      () => ({
        providers: [
          VoiceoverProvider.ElevenLabs,
          VoiceoverProvider.Speechify,
          VoiceoverProvider.OpenAi,
        ],
      }),
      [voiceovers]
    );

    const uniqueTags = Array.from(new Set(voiceovers.flatMap((v) => v.tags)));
    const uniqueGenders = Array.from(new Set(voiceovers.map((v) => v.gender)));

    useImperativeHandle(ref, () => ({
      open: () => {
        setIsOpen(true);
        setLocalFilters(filters);
      },
    }));

    const handleApply = () => {
      onApplyFilters(localFilters);
      setIsOpen(false);
    };

    const handleCancel = () => {
      setIsOpen(false);
      setLocalFilters(filters);
    };

    const updateLocalFilter = (key: keyof VoiceoverFilterState, value: any) => {
      setLocalFilters((prev) => ({ ...prev, [key]: value }));
    };

    return (
      <Modal
        title="Filter Voiceovers"
        open={isOpen}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="apply" type="primary" onClick={handleApply}>
            Apply Filters
          </Button>,
        ]}
        width={800}
      >
        <Row gutter={[24, 24]}>
          {showLanguage ? (
            <Col span={12}>
              <Space direction="vertical" style={{ width: '100%' }}>
                <Text strong>Language</Text>
                <Paragraph type="secondary" style={{ margin: 0 }}>
                  Select language to filter by
                </Paragraph>
                <Select
                  value={localFilters.language}
                  style={{ width: '100%' }}
                  onChange={(value) => updateLocalFilter('language', value)}
                  options={getLanguageOptions()}
                />
              </Space>
            </Col>
          ) : null}

          <Col span={12}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Text strong>Tags</Text>
              <Paragraph type="secondary" style={{ margin: 0 }}>
                Filter by voice characteristics
              </Paragraph>
              <Select
                placeholder={'Relaxed'}
                mode="multiple"
                value={localFilters.tags}
                style={{ width: '100%' }}
                onChange={(value) => updateLocalFilter('tags', value)}
                options={uniqueTags.map((tag) => ({
                  label: tag,
                  value: tag,
                }))}
              />
            </Space>
          </Col>

          <Col span={12}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Text strong>Provider</Text>
              <Paragraph type="secondary" style={{ margin: 0 }}>
                Select voice providers
              </Paragraph>
              <Select
                mode="multiple"
                value={localFilters.provider}
                style={{ width: '100%' }}
                onChange={(value) => updateLocalFilter('provider', value)}
                options={providers.map((provider) => ({
                  label: provider,
                  value: provider,
                }))}
              />
            </Space>
          </Col>

          <Col span={12}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Text strong>Gender</Text>
              <Paragraph type="secondary" style={{ margin: 0 }}>
                Filter by voice gender
              </Paragraph>
              <Select
                placeholder={'Male'}
                mode="multiple"
                value={localFilters.gender}
                style={{ width: '100%' }}
                onChange={(value) => updateLocalFilter('gender', value)}
                options={uniqueGenders.map((gender) => ({
                  label: gender,
                  value: gender,
                }))}
              />
            </Space>
          </Col>
        </Row>
      </Modal>
    );
  }
);

export default VoiceoverFiltersModal;
