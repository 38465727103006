import {
  BaseResponse,
  CrudService,
  HttpService,
} from '@monorepo/client-common';
import { ClientUser, SocialAccount } from '@monorepo/types';

const routeName = 'social-account';

class SocialAccountService extends CrudService<SocialAccount> {
  protected override route = routeName;

  constructor({ httpService }: { httpService: HttpService }) {
    super({ route: routeName, httpService });
  }

  async updateSocialAccountInfo(socialAccountId: string): Promise<boolean> {
    const url = `${this.path}/latest/${socialAccountId}`;

    const result = await this.httpService.post<null, BaseResponse<boolean>>(
      url
    );

    return result.data;
  }

  async youtubeAuth(code: string): Promise<boolean> {
    const url = `${this.path}/youtube/auth`;

    const result = await this.httpService.post<{ code }, BaseResponse<boolean>>(
      url,
      { code }
    );

    return result.data;
  }

  async tiktokAuth(data: { code: string; state: string }): Promise<boolean> {
    const url = `${this.path}/tiktok/auth`;

    const result = await this.httpService.post<
      { code: string; state: string },
      BaseResponse<boolean>
    >(url, data);

    return result.data;
  }

  async getTiktokAuthUrl(): Promise<string> {
    const url = `${this.path}/tiktok/url`;

    const result = await this.httpService.get<BaseResponse<string>>(url);

    return result.data;
  }
}

export default SocialAccountService;
