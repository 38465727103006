import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Modal, Segmented, Typography } from 'antd';
import { Asset, AssetOrigin } from '@monorepo/types';

import './assets-modal.scss';
import CurrentAsset from './tabs/current-asset';
import ManualUpload from './tabs/manual-upload';
import MyAssets from './tabs/my-assets/my-assets';
import { useVideoContext } from '../../../hooks/use-video';
import { LoadingState, useLoading } from '@monorepo/react-components';
import StockFootage from './tabs/stock-footage/stock-footage';
import { PartialAsset } from '../../../components/selectable-asset/selectable-asset';
import { useStore } from '../../../helpers/use-store';
import AIImageGenerator from './tabs/ai-image/ai-image';
import AIVideoGenerator from './tabs/ai-video/ai-video';
import scene from '../scenes/scene/scene';

const { Title, Text } = Typography;

export enum AssetTab {
  Current = 'Current',
  MyAssets = 'My Assets',
  StockMedia = 'Stock Media',
  AiImages = 'AI Images',
  AiVideos = 'AI Videos',
  Manual = 'Upload Manually',
}

function getAssetTabDescription(tab: AssetTab): string {
  const descriptions: Record<AssetTab, string> = {
    [AssetTab.Current]: '🎬 Currently selected media of the scene',
    [AssetTab.MyAssets]:
      '📁 Your personal library of previously uploaded media',
    [AssetTab.StockMedia]:
      '🌟 Browse our collection of free-to-use stock photos and videos',
    [AssetTab.AiImages]:
      '🤖 Generate unique images using AI - perfect for custom content',
    [AssetTab.Manual]: '📤 Upload your own images, videos, or audio files',
    [AssetTab.AiVideos]:
      '🎥 Transform your images into dynamic videos using AI',
  };

  return descriptions[tab] || '📌 Select a media source for your video';
}

const tabs = [
  AssetTab.Current,
  AssetTab.MyAssets,
  AssetTab.StockMedia,
  AssetTab.AiImages,
  AssetTab.AiVideos,
  AssetTab.Manual,
];

interface Props {
  videoId?: string;
}

export interface AssetSelectionModalActions {
  open: () => void;
}

const AssetSelectionModal = forwardRef<AssetSelectionModalActions, Props>(
  ({ videoId }, ref) => {
    const { loadingState, updateLoadingState } = useLoading();
    const [isOpen, setIsOpen] = useState(false);
    const {
      dataStore: { assetStore },
    } = useStore();
    const [selectedTab, setSelectedTab] = useState(AssetTab.Current);
    const [selectedAsset, setSelectedAsset] = useState<
      Asset | PartialAsset | null
    >(null);
    const {
      updateScene,
      getSceneStartFrame,
      fetchAsset,
      selectedScene,
      assets,
    } = useVideoContext();

    const sceneId = selectedScene?._id;
    const currentAsset = assets.find(
      (asset) => asset._id === selectedScene?.assetId
    );

    useImperativeHandle(ref, () => ({
      open: () => setIsOpen(true),
    }));

    const handleOk = async () => {
      if (!sceneId || !videoId || !selectedAsset) {
        return;
      }

      updateLoadingState(LoadingState.Loading);

      try {
        let assetId = (selectedAsset as Asset)._id;

        if (!assetId) {
          const uploadedAsset = await assetStore.uploadByUrl({
            url: selectedAsset.url,
            type: selectedAsset.type,
            sceneId,
            videoId,
            origin: AssetOrigin.StockImages,
            prompt: selectedAsset.prompt || '',
          });

          assetId = uploadedAsset._id;
        }

        // const frame = getSceneStartFrame(sceneId);

        await updateScene({
          sceneId,
          videoId,
          updateDto: {
            assetId,
          },
          frameToSeek: -1,
          shouldUpdateState: true,
        });

        await fetchAsset(assetId);

        handleCancel();
      } catch (e) {
        console.error(`failed saving`, e);
      } finally {
        updateLoadingState(LoadingState.Loaded);
      }
    };

    const handleCancel = () => {
      setIsOpen(false);
      setSelectedTab(AssetTab.Current);
      setSelectedAsset(null);
    };

    const selectExistingAsset = (asset: Asset) => {
      if (currentAsset?._id === asset._id) {
        setSelectedAsset(null);
        return;
      }

      setSelectedAsset(asset);
      setSelectedTab(AssetTab.Current);
    };

    const selectNonExistingAssetByUrl = (partialAsset: PartialAsset) => {
      if (currentAsset?.url === partialAsset.url) {
        setSelectedAsset(null);
        return;
      }

      setSelectedAsset(partialAsset as Asset);
    };

    const onManualAssetsUpload = (assets: Asset | Asset[]) => {
      if (Array.isArray(assets)) {
        setSelectedTab(AssetTab.MyAssets);
      } else {
        setSelectedAsset(assets);
        setSelectedTab(AssetTab.Current);
      }
    };

    const revertToOriginalAsset = async () => {
      if (!selectedScene?.originalAssetId) {
        return;
      }

      try {
        const originalAsset = await assetStore.fetch(
          selectedScene?.originalAssetId
        );

        selectExistingAsset(originalAsset);
      } catch (e) {
        console.error(`failed reverting to original asset`, e);
      }
    };

    const nowAsset = selectedAsset ? selectedAsset : (currentAsset as Asset);

    return (
      <Modal
        className="assets-modal"
        title={
          <div>
            <Title style={{ marginTop: 0, marginBottom: 0 }} level={4}>
              Assets
            </Title>
            <Text type="secondary" style={{ marginTop: 0 }}>
              {getAssetTabDescription(selectedTab)}
            </Text>
          </div>
        }
        open={isOpen}
        okText={'Save'}
        onOk={handleOk}
        okButtonProps={{
          disabled: !selectedAsset,
          loading: loadingState === LoadingState.Loading,
        }}
        onCancel={handleCancel}
        width={1000}
        style={{ overflow: 'auto' }}
      >
        <Segmented
          options={tabs}
          value={selectedTab}
          onChange={(value) => setSelectedTab(value)}
          style={{ marginBottom: 16 }}
        />
        {selectedTab === AssetTab.Current ? (
          <CurrentAsset
            revertToOriginalAsset={revertToOriginalAsset}
            originalAssetId={selectedScene?.originalAssetId as string}
            asset={nowAsset}
          />
        ) : null}
        {selectedTab === AssetTab.Manual ? (
          <ManualUpload
            sceneId={sceneId}
            videoId={videoId}
            onUpload={onManualAssetsUpload}
          />
        ) : null}
        {selectedTab === AssetTab.MyAssets ? (
          <MyAssets
            sceneId={sceneId}
            videoId={videoId}
            selectedAsset={nowAsset}
            onSelect={selectExistingAsset}
          />
        ) : null}
        {selectedTab === AssetTab.StockMedia ? (
          <StockFootage
            onSelect={selectNonExistingAssetByUrl}
            asset={nowAsset}
          />
        ) : null}
        {selectedTab === AssetTab.AiImages ? (
          <AIImageGenerator
            sceneId={sceneId}
            videoId={videoId}
            onSelect={selectExistingAsset}
            asset={nowAsset}
          />
        ) : null}
        {selectedTab === AssetTab.AiVideos ? (
          <AIVideoGenerator
            sceneId={sceneId}
            videoId={videoId}
            onSelect={selectExistingAsset}
            asset={nowAsset}
          />
        ) : null}
      </Modal>
    );
  }
);

export default AssetSelectionModal;
