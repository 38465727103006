import { useForm, UseFormReturn } from 'react-hook-form';
import {
  AiAssetStyles,
  AssetOrigin,
  defaultThumbnailConfig,
  defaultVideoOptions,
  Video,
} from '@monorepo/types';
import { Button, Card, Typography } from 'antd';
import { LoadingState, useLoading } from '@monorepo/react-components';
import { useStore } from '../../helpers/use-store';
import { ThunderboltOutlined } from '@ant-design/icons';

import './video-form.scss';
import { useNavigate } from 'react-router-dom';
import BaseVideoForm, {
  BaseVideoFormType,
} from '../base-video-form/base-video-form';
import { useNotification } from '../../hooks/use-notification';
import SubscribeCTA from '../../components/subscribe-cta/subscribe-cta';
import { Helmet } from 'react-helmet';

const VideoForm = () => {
  const {
    dataStore: { voiceoverStore, userStore, videoStore },
  } = useStore();
  const { updateLoadingState, loadingState } = useLoading();
  const notificationApi = useNotification();
  const navigate = useNavigate();

  const form = useForm<Video>({
    mode: 'all',
    defaultValues: {
      title: Date.now().toString(),
      script: '',
      language: 'en',
      voiceoverId:
        voiceoverStore.items.find((voiceover) => voiceover.name === 'Brian')
          ?._id || '',
      backgroundMusicId: '',
      assetOrigin: AssetOrigin.AIImages,
      assetStyle: AiAssetStyles.CINEMATIC,
      options: defaultVideoOptions,
      thumbnailConfig: {
        ...defaultThumbnailConfig,
        enabled: true,
        enableVoiceover: true,
      },
    },
  });

  const onSubmit = async (data) => {
    updateLoadingState(LoadingState.Loading);

    try {
      console.log('Form submitted:', data);
      const videoId = (await videoStore.create(data)) as unknown as number;

      userStore.getLatestUser();

      notificationApi.success({
        placement: 'top',
        message: 'Success',
        description: 'Video created successfully.',
      });

      navigate(`/videos/${videoId}`);
    } catch (e) {
      notificationApi.error({
        placement: 'top',
        icon: '',
        message: 'Error',
        description:
          'An error occurred. This could be due to insufficient credits or a server issue. Please check your balance or contact support for assistance.',
      });

      console.error(`failed creating video`, e);
    } finally {
      updateLoadingState(LoadingState.Loaded);
    }
  };

  const isLoading = loadingState === LoadingState.Loading;

  const isAllowedToCreate = userStore.isSubscriptionActive;

  return (
    <div className="video-form">
      <Helmet>
        <title>Create New Video | ShortsNinja</title>
        <meta
          name="description"
          content="Create a new faceless video with AI voiceover. Set up your video automation parameters and start creating content."
        />
        <meta
          name="keywords"
          content="create video, new video, ShortsNinja, faceless videos, ai video automation, ai voiceover"
        />
      </Helmet>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Card>
          <BaseVideoForm
            useCollapse={false}
            showScriptWriter={true}
            form={form as unknown as UseFormReturn<BaseVideoFormType>}
          />
        </Card>
        <div className={'create-button'}>
          <Button
            style={{ marginTop: '15px' }}
            disabled={!form.formState.isValid || !isAllowedToCreate}
            loading={isLoading}
            icon={
              <ThunderboltOutlined
                style={{ fontSize: '18px', marginTop: '2px' }}
              />
            }
            type="primary"
            htmlType="submit"
            size="large"
            block
          >
            Create Video
          </Button>
          {!isAllowedToCreate ? <SubscribeCTA /> : null}
          {isLoading ? (
            <Typography.Text
              style={{ display: 'flex', justifyContent: 'center' }}
              type={'secondary'}
            >
              Creating your video... This may take a few minutes ⚡️
            </Typography.Text>
          ) : null}
        </div>
      </form>
    </div>
  );
};

export default VideoForm;
