import { IdIdentifier, Timestamps } from '../common';

export type ApplicationConfig = IdIdentifier &
  Timestamps & {
    disableTiktok: boolean;
    disableYoutube: boolean;
    maxCharsForScript: number;
    changelogDate?: string; // 06/01/2025
  };

export const MAX_CHARS_DEFAULT = 2000;
