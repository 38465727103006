import { Button, Flex } from 'antd';
import { CrownOutlined, DesktopOutlined } from '@ant-design/icons';
import React, { FunctionComponent } from 'react';
import { useStore } from '../../helpers/use-store';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';

interface Props {
  displayCredits: boolean;
  displaySeries: boolean;
  displayCTA: boolean;
}

const Subscription: FunctionComponent<Props> = ({
  displayCredits,
  displaySeries,
  displayCTA,
}) => {
  const navigate = useNavigate();
  const {
    dataStore: { userStore, seriesStore },
  } = useStore();

  return (
    <Flex
      justify={'center'}
      align={'center'}
      style={{ flexDirection: 'column' }}
      className="subscription-info"
    >
      {displayCredits ? (
        <div className="subscription-info-item">
          <CrownOutlined />
          Credits: {Math.floor(userStore.userSubscription.credits)}
        </div>
      ) : null}
      {displaySeries ? (
        <div className="subscription-info-item">
          <DesktopOutlined />
          Allowed Series: {seriesStore.items.length}/
          {userStore.userSubscription.allowedSeriesAmount}
        </div>
      ) : null}
      {displayCTA ? (
        <div className={'subscription-info-item'}>
          {userStore.userSubscription.credits < 50 ? (
            <Button
              onClick={() => navigate('/settings/billing')}
              type={'primary'}
              style={{ width: '80%', marginTop: '10px' }}
            >
              Upgrade
            </Button>
          ) : (
            <span style={{ marginTop: '10px' }}></span>
          )}
        </div>
      ) : null}
    </Flex>
  );
};

export default observer(Subscription);
