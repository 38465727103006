import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Modal, Space } from 'antd';
import { Scene } from '@monorepo/types';
import { useVideoContext } from '../../../../hooks/use-video';
import { MotionGroupList } from './motion-group-list';

export interface MotionModalActions {
  open: (scene: Scene) => void;
}

const MotionSelectModal = forwardRef<MotionModalActions>((_, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentScene, setCurrentScene] = useState<Scene | null>(null);

  const { updateScene, assets, getSceneStartFrame } = useVideoContext();

  useImperativeHandle(ref, () => ({
    open: (scene: Scene) => {
      setCurrentScene(scene);
      setIsOpen(true);
    },
  }));

  const handleCancel = () => {
    setIsOpen(false);
    setCurrentScene(null);
  };

  const handleSave = async () => {
    if (!currentScene) return;

    const frame = getSceneStartFrame(currentScene._id);

    await updateScene({
      sceneId: currentScene._id,
      videoId: currentScene.videoId,
      updateDto: { motion: currentScene.motion },
      frameToSeek: -1,
      shouldUpdateState: true,
    });

    handleCancel();
  };

  const sceneAsset = currentScene
    ? assets.find((asset) => asset._id === currentScene.assetId)
    : null;

  if (!currentScene || !sceneAsset) {
    return null;
  }

  return (
    <Modal
      title="Select Motion Effect"
      open={isOpen}
      onCancel={handleCancel}
      width={1100}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="save" type="primary" onClick={handleSave}>
          Save Changes
        </Button>,
      ]}
    >
      <Space
        direction="vertical"
        className={'scroller'}
        style={{
          width: '100%',
          maxHeight: '500px',
          padding: '5px',
          overflowY: 'scroll',
        }}
      >
        {sceneAsset ? (
          <MotionGroupList
            selectedMotion={currentScene.motion}
            onSelect={(motion) => {
              setCurrentScene({
                ...currentScene,
                motion,
              });
            }}
            imageUrl={sceneAsset.url}
          />
        ) : null}
      </Space>
    </Modal>
  );
});

export default MotionSelectModal;
