import { IdIdentifier, Timestamps, Timezone, UserIdentifier } from '../common';
import { SubtitlesConfig } from './subtitles';
import { AiAssetStyles, AssetOrigin } from '../asset';
import {
  SocialAccountMetadata,
  SocialProvider,
} from '../social-account/social-account';
import { ThumbnailConfig } from './thumbnail';

export type Video = IdIdentifier &
  Timestamps &
  UserIdentifier &
  VideoSettings & {
    seriesId?: string;
    title: string;
    script?: string;
    description?: string;
    status: VideoStatus;
    progress: VideoProgress[];
    videoUrl?: string;
    subtitlesConfig: SubtitlesConfig;
    isRendering: boolean;
    renderProgress: number;
    renderFailures: number;
    hashtags: string[];
    renderDate?: Date;
    renderStartDate?: Date;
    renderRequested?: boolean;
    renderCounter: number;
    socialPublishInfo?: SocialPublishInfo;
    generatedAssets?: boolean;
    episodeNumber?: number;
    duration: number;
    isFavorite?: boolean;
    creationJobIds: string[];
  };

// TEST DEPLOY

export enum VideoProgress {
  Scenes = 'scenes',
  Voiceover = 'voiceover',
  Assets = 'assets',
}

export enum VideoStatus {
  Initial = 'initial',
  Ready = 'ready',
  Published = 'published',
  // AssetsCreationFailed = 'assets-failed',
  CreationFailed = 'creation-failed',
}

export interface VideoSettings {
  voiceoverSpeed: number;
  voiceoverId: string;
  language: string; // language ISO Code
  backgroundMusicId?: string;
  backgroundMusicUrl?: string;
  backgroundMusicVolume?: number;
  assetOrigin: AssetOrigin;
  assetStyle: VideoAssetStyle;
  options: VideoOptions;
  playbackRate?: number;
  thumbnailConfig: ThumbnailConfig;
}

export interface VideoOptions {
  enableAiMotions?: boolean;
}

export const defaultVideoOptions: VideoOptions = {
  enableAiMotions: false,
};

export const supportedAssetsOrigins = [
  AssetOrigin.StockImages,
  AssetOrigin.AIImages,
  AssetOrigin.StockVideos,
];

export type VideoAssetStyle = AiAssetStyles;

export interface SocialPublishInfo {
  socialAccountId: string;
  socialPlatform: SocialProvider;
  publishAt?: Date;
  automaticPublishAt?: Date;
  externalVideoId?: string;
  needsReschedule?: boolean;
  timezone?: Timezone;
  metadata?: SocialAccountMetadata;
  retryAmount?: number;
}
