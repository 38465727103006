import React, {
  FunctionComponent,
  memo,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { TimelineImage } from './timeline';
import { Skeleton } from 'antd';
import AssetComponent from '../../../../components/asset/asset';
import { AssetType } from '@monorepo/types';
import { imageBlobManager } from '../../../../managers/image-blob-manager';

const getImagesHash = (images: TimelineImage[]) => {
  return images.map((i) => `${i.url}-${i.endTime}`).join('-');
};

interface Props {
  images: TimelineImage[];
  totalFrames: number;
  onClick: (e: any) => void;
}

const TimelineTrack: FunctionComponent<Props> = ({
  images,
  totalFrames,
  onClick,
}) => {
  const imagesHash = useRef<string>(getImagesHash(images));

  useEffect(() => {
    const hash = getImagesHash(images);

    imagesHash.current = hash;
  }, [images, totalFrames]);

  const renderTimelineImages = useCallback(() => {
    return images.map((image, index) => {
      const nextImage = images[index + 1];
      const endTime = nextImage ? nextImage.startTime : totalFrames;

      let style: any = {
        border: '2px solid #000',
        left: `${(image.startTime / totalFrames) * 100}%`,
        right: nextImage
          ? `${(1 - nextImage.startTime / totalFrames) * 100}%`
          : '0', // Use right instead of width
      };

      const isVideo = image.url?.endsWith('mp4');
      const shouldShowImage = image.url && !isVideo;

      if (shouldShowImage) {
        style = {
          ...style,
          backgroundImage: `url(${imageBlobManager.getImage(image.url)})`,
          backgroundRepeat: 'repeat', // Repeats the image
          backgroundSize: 'contain', // Ensure the image is scaled appropriately
          backgroundPosition: 'center', // Align the image in the center
        };
      }

      return (
        <div
          onClick={onClick}
          key={index}
          className="timeline-clip"
          style={style}
        >
          <span className="timeline-clip-number">{index + 1}</span>
          {!image.url ? (
            <Skeleton.Node style={{ width: '100%', height: '100%' }} />
          ) : null}
          {isVideo ? (
            <AssetComponent
              shouldLazy={false}
              width={'100%'}
              height={'100%'}
              asset={{ url: image.url, type: AssetType.Video }}
            />
          ) : null}
        </div>
      );
    });
  }, [imagesHash.current, totalFrames]);

  return <div className="clips-container">{renderTimelineImages()}</div>;
};

export default memo(TimelineTrack, (oldProps, newProps) => {
  const oldHash = getImagesHash(oldProps.images);
  const newHash = getImagesHash(newProps.images);

  return oldHash === newHash;
});
