import { Button, Col, Row, Typography } from 'antd';
import {
  FormTextArea,
  LoadingState,
  useLoading,
} from '@monorepo/react-components';
import { MAX_CHARS_DEFAULT, Scene } from '@monorepo/types';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useVideoContext } from '../../../../hooks/use-video';
import { useNotification } from '../../../../hooks/use-notification';
import { useStore } from '../../../../helpers/use-store';

export type EditSceneTextForm = Pick<Scene, 'text'>;

const EditSceneText = () => {
  const { loadingState, updateLoadingState } = useLoading();
  const form = useForm<EditSceneTextForm>();
  const {
    updateScene,
    scenes,
    selectedScene: scene,
    getSceneStartFrame,
  } = useVideoContext();
  const notificationApi = useNotification();
  const {
    dataStore: { userStore },
  } = useStore();

  const resetForm = () => {
    if (scene) {
      form.reset({
        text: scene.text,
      });
    }
  };

  useEffect(() => {
    resetForm();
  }, [scene]);

  const save = async () => {
    if (!scene) {
      return;
    }

    if (!form.formState.isDirty) {
      return;
    }

    const values = form.getValues();

    try {
      updateLoadingState(LoadingState.Loading);

      // const frame = getSceneStartFrame(scene._id);

      await updateScene({
        sceneId: scene._id,
        videoId: scene.videoId,
        updateDto: {
          text: values.text,
        },
        frameToSeek: -1,
        shouldUpdateState: true,
      });

      userStore.getLatestUser();

      notificationApi.success({
        placement: 'top',
        message: 'Success',
        description: 'Text and Voiceover updated successfully.',
      });
    } catch (e) {
      notificationApi.error({
        placement: 'top',
        icon: '',
        message: 'Error',
        description:
          'An error occurred. This could be due to insufficient credits or a server issue. Please check your balance or contact support for assistance.',
      });

      console.error(`failed`, e);
    } finally {
      updateLoadingState(LoadingState.Loaded);

      form.reset({ text: values.text });
    }
  };

  const globalMaxLength =
    userStore.currentUser.applicationConfig?.maxCharsForScript ||
    MAX_CHARS_DEFAULT;
  const scenesTotal = scenes.map((scene) => scene.text).join(' ').length;
  const sceneMaxLeft =
    globalMaxLength - (scenesTotal - (form.getValues('text') || '').length);

  return (
    <Row gutter={[25, 25]} style={{ marginBottom: '10px' }}>
      <Col span={24}>
        <Typography.Title style={{ margin: 0, marginBottom: '5px' }} level={5}>
          Voiceover Text
        </Typography.Title>
        <FormTextArea
          textareaProps={{
            maxLength: sceneMaxLeft,
            showCount: true,
            rows: 3,
          }}
          form={form}
          controllerProps={{
            name: 'text',
          }}
        />

        <Button
          type={'primary'}
          style={{ width: '100%', marginTop: '25px' }}
          {...{
            loading: loadingState === LoadingState.Loading,
            disabled: !form.formState.isDirty,
            onClick: save,
          }}
        >
          Update
        </Button>
        <Typography.Text style={{ fontSize: '12px' }} type={'secondary'}>
          Updating the text will regenerate the voiceover and subtitles of this
          scene.
        </Typography.Text>
      </Col>
    </Row>
  );
};

export default EditSceneText;
