import React, { useMemo } from 'react';
import './timeline-markers.scss';

const getSegmentByZoom = (totalSeconds) => {
  // Base segments for videos under 1 minute
  if (totalSeconds <= 60) {
    return {
      1: 5,
      2: 5,
      3: 5,
      4: 5,
      5: 5,
    };
  }
  // For videos 1-2 minutes
  else if (totalSeconds <= 120) {
    return {
      1: 6,
      2: 6,
      3: 6,
      4: 6,
      5: 6,
    };
  }
  // For videos 2-5 minutes
  else if (totalSeconds <= 300) {
    return {
      1: 18,
      2: 18,
      3: 18,
      4: 18,
      5: 18,
    };
  }
  // For videos > 5 minutes
  else {
    return {
      1: 30,
      2: 30,
      3: 30,
      4: 30,
      5: 30,
    };
  }
};

const TimelineMarkers = ({ totalFrames, fps = 30, zoom = 1 }) => {
  const formatTime = (frame) => {
    const totalSeconds = frame / fps;
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = Math.floor(totalSeconds % 60);

    // For videos between 1-90 seconds with minutes
    if (minutes > 0) {
      if (seconds === 0) {
        return `${minutes}m`;
      }
      // Only show seconds if they're significant (not zero)
      return `${minutes}m${seconds}s`;
    }

    // For videos under a minute
    return `${seconds}s`;
  };

  // Updated calculation
  const { segmentSeconds, numSegments } = useMemo(() => {
    const totalSeconds = totalFrames / fps;
    const segmentByZoom = getSegmentByZoom(totalSeconds);
    const baseSegmentSize = segmentByZoom[zoom];
    const segmentSeconds = Math.max(1, baseSegmentSize / zoom);
    const numSegments = Math.ceil(totalSeconds / segmentSeconds);
    return { segmentSeconds, numSegments };
  }, [totalFrames, fps, zoom]);

  return (
    <>
      <div className="time-markers">
        {Array.from({ length: numSegments + 1 }).map((_, i) => {
          const frame = i * segmentSeconds * fps;
          const position = (frame / totalFrames) * 100;

          // if (position > 100) return null;

          return (
            <span
              key={i}
              style={{
                position: 'absolute',
                left: `${position}%`,
              }}
            >
              {formatTime(frame)}
            </span>
          );
        })}
      </div>
    </>
  );
};

export default TimelineMarkers;
