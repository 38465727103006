import React, { FunctionComponent, useEffect, useState } from 'react';
import { Switch, Typography, Tooltip, Space } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import type { SwitchChangeEventHandler } from 'antd/es/switch';
import {
  defaultLumaOptions,
  LumaOptions as LumaOptionsType,
  NinjaModelsOptions,
} from '@monorepo/types';

const { Text } = Typography;

interface Props {
  updateOptions: (options: NinjaModelsOptions) => void;
}

const LumaOptions: FunctionComponent<Props> = ({ updateOptions }) => {
  const [options, setOptions] = useState<LumaOptionsType>(defaultLumaOptions);

  const handleSwitchChange: SwitchChangeEventHandler = (checked) => {
    setOptions((prev) => {
      const newOptions = {
        ...prev,
        loop: checked,
      };

      updateOptions(newOptions);

      return newOptions;
    });
  };

  return (
    <Space style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <Switch checked={options.loop} onChange={handleSwitchChange} />
      <Text>Loop</Text>
      <Tooltip
        placement={'right'}
        title={
          <span style={{ fontSize: '12px' }}>
            Whether the video should loop (end of video is blended with the
            beginning
          </span>
        }
      >
        <InfoCircleOutlined style={{ cursor: 'pointer' }} />
      </Tooltip>
    </Space>
  );
};

export default LumaOptions;
