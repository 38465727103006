import React, { useEffect, useState } from 'react';
import { Modal, Space, Typography, Spin } from 'antd';
import { CheckCircleFilled, PauseCircleFilled } from '@ant-design/icons';
import { useVideoContext } from '../../../hooks/use-video';
import { useNotification } from '../../../hooks/use-notification';
import { VideoStatus, VideoProgress } from '@monorepo/types';
import { useNavigate } from 'react-router-dom';

const { Text, Title } = Typography;

const PROGRESS_ITEMS = [
  {
    key: VideoProgress.Scenes,
    label: 'Creating your scenes...',
    doneLabel: 'Your scenes are ready',
    emoji: '🤖',
  },
  {
    key: VideoProgress.Assets,
    label: 'Creating your assets...',
    doneLabel: 'Your assets are ready',
    emoji: '🎨',
  },
  {
    key: VideoProgress.Voiceover,
    label: 'Creating your voiceover...',
    doneLabel: 'Your voiceover is ready',
    emoji: '🎤',
  },
];

const VideoCreationModal = () => {
  const { video } = useVideoContext();
  const notificationApi = useNotification();
  const navigate = useNavigate();
  const [wasInitial, setWasInitial] = useState(false);

  useEffect(() => {
    if (video?.status === VideoStatus.Initial) {
      setWasInitial(true);
    }
    if (wasInitial && video?.status === VideoStatus.Ready) {
      notificationApi.success({
        placement: 'top',
        icon: '',
        message: 'Success',
        description:
          'Your video has been initialized successfully. You can now start editing.',
      });
    }
    if (wasInitial && video?.status === VideoStatus.CreationFailed) {
      notificationApi.error({
        placement: 'top',
        icon: '',
        message: 'Error',
        description:
          'Some assets failed to create. We are retrying to fix this. Please come back in a few minutes to continue editing your video. If the issue persists, please contact support',
      });

      setTimeout(() => {
        navigate('/videos');
      }, 500);
    }
  }, [video?.status]);

  if (!video || video.status !== VideoStatus.Initial) {
    return null;
  }

  const areScenesDone = video.progress?.includes(VideoProgress.Scenes);

  return (
    <Modal
      title={
        <Title level={4} style={{ textAlign: 'center', margin: 0 }}>
          <span style={{ marginRight: '15px' }}>🥷</span>Our Ninja Magic in
          Progress
        </Title>
      }
      open={true}
      footer={null}
      closable={false}
      width={600}
    >
      <Space
        direction="vertical"
        size="large"
        style={{ width: '100%', padding: '24px 0', fontSize: '20px' }}
      >
        {PROGRESS_ITEMS.map(({ key, label, doneLabel, emoji }) => {
          const isDone = video.progress?.includes(key);
          const isDependent = key !== VideoProgress.Scenes && !areScenesDone;

          return (
            <div
              key={key}
              style={{
                display: 'flex',
                gap: '8px',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '20px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'left',
                  fontSize: '18px',
                  marginRight: '10px',
                }}
              >
                {isDone ? (
                  <CheckCircleFilled style={{ color: '#52c41a' }} />
                ) : isDependent ? (
                  <PauseCircleFilled style={{ color: '#d9d9d9' }} />
                ) : (
                  <Spin />
                )}
              </div>
              <Text
                style={{
                  marginLeft: '5px',
                  marginRight: 'px',
                  fontSize: '20px',
                }}
              >
                {emoji}
              </Text>
              <Text
                style={{ width: '250px', textAlign: 'left', fontSize: '18px' }}
              >
                {isDone ? doneLabel : label}
              </Text>
            </div>
          );
        })}
      </Space>
    </Modal>
  );
};

export default VideoCreationModal;
