import { observer } from 'mobx-react-lite';
import {
  Alert,
  Button,
  Card,
  Col,
  Modal,
  Popconfirm,
  Row,
  Space,
  Switch,
  Tooltip,
  Typography,
} from 'antd';
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { DeleteOutlined, DownOutlined, RightOutlined } from '@ant-design/icons';
import Title from 'antd/es/typography/Title';
import { useNavigate } from 'react-router-dom';
import { Series } from '@monorepo/types';
import { useNotification } from '../../../hooks/use-notification';
import { useStore } from '../../../helpers/use-store';
import SeriesForm from '../../series-form/series-form';
import { useResponsiveReady } from '../../../hooks/use-responive-ready';
import DeleteSeriesModal, {
  DeleteSeriesModalActions,
} from './delete-series-modal';

import './series-settings.scss';
import UpdateSeriesStatus, {
  UpdateSeriesStatusModalActions,
} from './update-series-status';

export interface SeriesSettingsRef {
  close: () => void;
  open: () => void;
}

interface Props {
  seriesId: string;
}

export const SeriesSettingsModal = forwardRef<SeriesSettingsRef, Props>(
  ({ seriesId }, ref) => {
    const toggleModalRef = useRef<UpdateSeriesStatusModalActions>(null);
    const deleteModalRef = useRef<DeleteSeriesModalActions>(null);
    const navigate = useNavigate();
    const [collapsed, setCollapsed] = useState(true);
    const notificationApi = useNotification();
    const {
      dataStore: { seriesStore },
    } = useStore();
    const series = seriesStore.get(seriesId) || null;
    const { isMobile } = useResponsiveReady();

    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
      setIsOpen(true);
    };

    const closeModal = () => {
      setIsOpen(false);
    };

    useImperativeHandle(ref, () => ({
      open: openModal,
      close: closeModal,
    }));

    if (!series) {
      return null;
    }

    const onCollapse = () => {
      setCollapsed(!collapsed);
    };

    const handleDeleteClick = (e: any) => {
      e.stopPropagation();
      deleteModalRef.current?.open(series._id);
    };

    const updateIsActive = async () => {
      toggleModalRef.current?.open(series._id, series.isActive);
    };

    const onSubmit = async (data: Partial<Series>) => {
      try {
        if (!data._id) {
          notificationApi.error({
            placement: 'top',
            icon: '',
            message: 'Error',
            description: 'Could not update Series',
          });
          return;
        }

        await seriesStore.update(data._id, data);
        notificationApi.success({
          placement: 'top',
          message: 'Success',
          description: 'Series Updated successfully.',
        });
        closeModal();
      } catch (e) {
        notificationApi.error({
          placement: 'top',
          icon: '',
          message: 'Error',
          description: 'Could not update Series',
        });
        console.error(`failed handling series`, e);
      }
    };
    return (
      <Modal
        className={`${isMobile ? 'mobile' : ''} series-settings`}
        open={isOpen}
        onCancel={closeModal}
        width={1000}
        footer={null}
        destroyOnClose
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          <Title style={{ marginBottom: 0 }} level={2}>
            Series Settings
          </Title>
          <Typography.Text
            type={'secondary'}
            style={{ margin: 0, fontSize: '12px' }}
          >
            Updates will be applied on new videos.
          </Typography.Text>
          <Card
            styles={{ body: { padding: 0 } }}
            title={
              <Row justify="space-between" align="middle">
                <Col>
                  <Space>
                    <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                      {series.name}
                    </span>
                    <Button
                      icon={collapsed ? <RightOutlined /> : <DownOutlined />}
                      onClick={onCollapse}
                      type={'text'}
                    ></Button>
                  </Space>
                </Col>
                <Col>
                  <Space>
                    <Tooltip
                      title={`${
                        series.isActive ? 'Disable Series' : 'Activate Series'
                      }`}
                    >
                      <Switch
                        onClick={updateIsActive}
                        checked={series.isActive}
                        size="small"
                      />
                    </Tooltip>

                    <Button
                      onClick={handleDeleteClick}
                      icon={<DeleteOutlined />}
                      danger
                    />
                  </Space>
                </Col>
              </Row>
            }
          >
            <SeriesForm
              collapsed={collapsed}
              series={series}
              onSubmit={onSubmit}
              sectionsText
            />
          </Card>
        </Space>
        <DeleteSeriesModal ref={deleteModalRef} />
        <UpdateSeriesStatus ref={toggleModalRef} />
      </Modal>
    );
  }
);

export default observer(SeriesSettingsModal);
