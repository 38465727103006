import { Button, Flex, Layout, Menu, Typography } from 'antd';
import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { layoutItems, useCurrentPage } from '../../../hooks/use-current-page';
import Logo from '../../../components/logo/logo';
import { useStore } from '../../../helpers/use-store';
import { observer } from 'mobx-react-lite';
import { useResponsiveReady } from '../../../hooks/use-responive-ready';
import Subscription from '../../subscription/subscription';
import { CHANGELOG_KEY } from '../../../components/changelog/changelog';

const { Sider } = Layout;
const { Text } = Typography;

interface Props {
  collapsed: boolean;
  setCollapsed: (flag: boolean) => void;
}

const Navbar: FunctionComponent<Props> = ({ collapsed, setCollapsed }) => {
  const {
    dataStore: { userStore, seriesStore },
  } = useStore();
  const navigate = useNavigate();
  const { firstPage: currentPage } = useCurrentPage();
  const { isMobile } = useResponsiveReady();

  return (
    <Sider collapsedWidth={0} collapsed={isMobile ? collapsed : false}>
      <div className="logo">
        <Logo />
        <Text style={{ marginLeft: '15px' }} strong>
          ShortsNinja
        </Text>
      </div>
      <Menu
        selectedKeys={[currentPage]}
        mode="inline"
        defaultSelectedKeys={['Home']}
        onClick={(item) => {
          const isChangelog = layoutItems.find((i) => item.key === 'changelog');
          const isFeedback = layoutItems.find((i) => item.key === 'feedback');

          if (isFeedback) {
            window.open('https://shortsninja.featurebase.app', '_blank');
            return;
          }
          if (isChangelog) {
            localStorage.setItem(
              CHANGELOG_KEY,
              userStore.currentUser.applicationConfig?.changelogDate || ''
            );
            userStore.setChangelogCount(0);
            window.open(
              'https://shortsninja.featurebase.app/changelog',
              '_blank'
            );
            return;
          }
          navigate(`/${item.key}`);
          setCollapsed(true);
        }}
        items={layoutItems
          .filter((item) => !item.hidden)
          .map((item) => {
            const { createButton, disableClick, ...cleanItem } = item;

            return cleanItem;
          })}
      />
      <Subscription
        displaySeries={true}
        displayCredits={true}
        displayCTA={true}
      />
    </Sider>
  );
};

export default observer(Navbar);
