import React, { FunctionComponent, useState } from 'react';
import { Input, message, Space, Button } from 'antd';
import { Asset, SearchResult } from '@monorepo/types';
import { useStore } from '../../../../../helpers/use-store';
import { LoadingState, useLoading } from '@monorepo/react-components';
import SelectableAssetList from '../../../../../components/selectable-asset/selectable-asset-list';
import { PartialAsset } from '../../../../../components/selectable-asset/selectable-asset';

interface Props {
  asset: Asset | PartialAsset;
  onSelect: (partialAsset: PartialAsset) => void;
}

const StockFootage: FunctionComponent<Props> = ({ asset, onSelect }) => {
  const [results, setResults] = useState<SearchResult[]>([]);
  const [prompt, setPrompt] = useState(asset?.prompt || '');
  const { loadingState, updateLoadingState } = useLoading();
  const {
    dataStore: { assetStore },
  } = useStore();

  const handleSearch = async () => {
    if (!prompt.trim()) {
      message.warning('Please enter a search term');
      return;
    }

    updateLoadingState(LoadingState.Loading);
    try {
      const searchResults = await assetStore.searchStockFootage(prompt);
      setResults(searchResults);
    } catch (error) {
      console.error('Error searching stock footage:', error);
      message.error('Failed to search stock footage. Please try again.');
    } finally {
      updateLoadingState(LoadingState.Loaded);
    }
  };

  const isLoading = loadingState === LoadingState.Loading;

  return (
    <Space direction="vertical" size="small" style={{ width: '100%' }}>
      <Input.TextArea
        rows={4}
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
        placeholder="Describe the image or video you're looking for..."
      />
      <Button
        type="primary"
        style={{ width: '100%' }}
        onClick={handleSearch}
        loading={isLoading}
      >
        Search
      </Button>
      <SelectableAssetList
        currentAsset={asset}
        assets={results.map((result) => {
          return {
            ...result,
            prompt,
          };
        })}
        onSelect={onSelect}
      />
    </Space>
  );
};

export default StockFootage;
