import React, { useMemo } from 'react';
import {
  Card,
  Timeline,
  List,
  Typography,
  Button,
  Row,
  Col,
  Space,
  Flex,
} from 'antd';
import Logo from '../../components/logo/logo';
import { useStore } from '../../helpers/use-store';

const { Title, Paragraph, Text } = Typography;

const AffiliateProgram = () => {
  const {
    dataStore: { userStore },
  } = useStore();

  const shortLink = userStore.currentUser.affiliateInfo?.shortUrl;

  return (
    <Card
      style={{ maxWidth: '800px', margin: '24px auto', padding: '24px' }}
      bordered={false}
    >
      {/* Header Section */}
      <Flex justify={'center'}>
        <Logo />
      </Flex>
      <Title
        level={2}
        style={{ textAlign: 'center', color: '#1890ff', marginBottom: '16px' }}
      >
        Join the ShortsNinja Affiliate Program
      </Title>
      <Paragraph
        style={{ textAlign: 'center', fontSize: '16px', marginBottom: '32px' }}
      >
        Discover the power of ShortsNinja's affiliate system and earn a
        commission for every sale you refer. It's as simple as that - join our
        affiliate program today!
      </Paragraph>

      {/* Big Button for Registration */}
      <div style={{ textAlign: 'center', marginBottom: '40px' }}>
        <Button
          type="primary"
          size="large"
          href="http://cc.payproglobal.com/AffiliateSignup/8DD21BDCCF3E213"
          target="_blank"
        >
          {shortLink ? 'Dashboard' : 'Sign Up as an Affiliate'}
        </Button>
      </div>

      {/* Key Features with Icons */}
      <Row
        gutter={[24, 24]}
        style={{ marginBottom: '40px', textAlign: 'center' }}
      >
        <Col xs={24} sm={8}>
          <span style={{ fontSize: '32px', marginBottom: '8px' }}>💰</span>
          <Title level={4} style={{ marginBottom: '8px' }}>
            30% Commission
          </Title>
          <Paragraph style={{ margin: 0 }}>
            Earn a generous 30% commission on every sale you refer.
          </Paragraph>
        </Col>
        <Col xs={24} sm={8}>
          <span style={{ fontSize: '32px', marginBottom: '8px' }}>🔄</span>
          <Title level={4} style={{ marginBottom: '8px' }}>
            Lifetime Commission
          </Title>
          <Paragraph style={{ margin: 0 }}>
            Recurring commissions for as long as the customer stays subscribed.
          </Paragraph>
        </Col>
        <Col xs={24} sm={8}>
          <span style={{ fontSize: '32px', marginBottom: '8px' }}>💳</span>
          <Title level={4} style={{ marginBottom: '8px' }}>
            Monthly Payouts
          </Title>
          <Paragraph style={{ margin: 0 }}>
            Get paid every month with flexible payment options.
          </Paragraph>
        </Col>
      </Row>

      {/* Steps to Get Started with Timeline */}
      <Title level={3} style={{ marginBottom: '24px' }}>
        So, where do you start? 🚀
      </Title>
      <Timeline style={{ marginBottom: '40px' }}>
        <Timeline.Item>
          <strong>Register:</strong> Sign up as an affiliate to ShortsNinja by
          clicking the button above. 📝
        </Timeline.Item>
        <Timeline.Item>
          <strong>Get Approved:</strong> Once your affiliate agreement is
          activated by ShortsNinja, you'll be good to go. ✅
        </Timeline.Item>
        <Timeline.Item>
          <strong>Promote:</strong> Share your unique affiliate link and start
          promoting ShortsNinja! 🌟
        </Timeline.Item>
        <Timeline.Item>
          <strong>Earn:</strong> Watch the income from your referred sales roll
          in and receive monthly payouts. 💰
        </Timeline.Item>
      </Timeline>

      {/* FAQ Section with Emojis */}
      <Title level={3} style={{ marginBottom: '24px' }}>
        Frequently Asked Questions
      </Title>
      <List
        bordered
        dataSource={[
          {
            emoji: '🤔',
            title: 'How does the ShortsNinja affiliate program work?',
            content: (
              <>
                The ShortsNinja affiliate program allows you to earn a
                commission by referring customers to our platform. When someone
                clicks your affiliate link and makes a purchase, you earn a{' '}
                <Text strong>30% recurring commission</Text> for as long as they
                remain a customer.
              </>
            ),
          },
          {
            emoji: '💸',
            title: 'How are payouts handled?',
            content: (
              <>
                Payouts are made <Text strong>every month</Text>. You can choose
                a payment method that works best for you, The minimum amount for
                payout is $51.
              </>
            ),
          },
          {
            emoji: '📊',
            title: 'How do I track my performance?',
            content:
              'You can monitor and analyze your referral sales with detailed reports provided in your affiliate dashboard.',
          },
          {
            emoji: '📅',
            title: 'What is the attribution period?',
            content: (
              <>
                The attribution period is <Text strong>30 days</Text>. If a
                customer makes a purchase within 30 days of clicking your
                affiliate link, you'll earn a commission.
              </>
            ),
          },
          {
            emoji: '🌌',
            title: 'How much can I earn?',
            content: (
              <>
                The sky is the limit! Your earnings depend on how many customers
                you refer. With a <Text strong>30% recurring commission</Text>,
                your income can grow significantly over time.
              </>
            ),
          },
          {
            emoji: '🚫',
            title: 'Can I run ads?',
            content: (
              <>
                No, running search engine ads, social media ads, or other ads
                that compete with our own marketing efforts is prohibited.
              </>
            ),
          },
          {
            emoji: '📧', // Updated FAQ item
            title: 'What email should I use to register?',
            content: (
              <>
                The email you use to register as an affiliate should be the same
                email address registered to our platform. This ensures that your
                account and earnings are properly tracked and linked.
                <br />
                <br />
                <Text strong>⚠️ Important:</Text> Make sure to use the same
                email as the one you registered to our platform. Using a
                different email may result in issues with tracking your
                referrals and earnings.
              </>
            ),
          },
          {
            emoji: '😊',
            title: 'Is there a cost to join the affiliate program?',
            content:
              'No, joining the ShortsNinja affiliate program is completely free. 😊',
          },
        ]}
        renderItem={(item) => (
          <List.Item>
            <Space align="start">
              <span style={{ fontSize: '18px' }}>{item.emoji}</span>
              <div>
                <strong>{item.title}</strong>
                <Paragraph style={{ marginTop: '8px', marginBottom: 0 }}>
                  {item.content}
                </Paragraph>
              </div>
            </Space>
          </List.Item>
        )}
      />
    </Card>
  );
};

export default AffiliateProgram;
