import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Col, Modal, Row, Typography } from 'antd';
import { SubtitlesConfig } from '@monorepo/types';
import {
  SubtitlePresetName,
  subtitlePresets,
} from '../../subtitles/subtitle-preset';
import SubtitlePresetSelector from '../../subtitles/subtitles-preset';

const { Paragraph } = Typography;

export interface SubtitleStyleModalActions {
  open: () => void;
}

interface Props {
  onSave: (config: SubtitlesConfig) => void;
}

const SubtitleStyleModal = forwardRef<SubtitleStyleModalActions, Props>(
  ({ onSave }, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedPreset, setSelectedPreset] = useState<SubtitlePresetName>(
      SubtitlePresetName.LUCKY_DEFAULT
    );
    const [currentConfig, setCurrentConfig] = useState<SubtitlesConfig>(
      subtitlePresets[SubtitlePresetName.LUCKY_DEFAULT]
    );

    useImperativeHandle(ref, () => ({
      open: () => {
        setIsOpen(true);
      },
    }));

    const handleCancel = () => {
      setIsOpen(false);
      setCurrentConfig(subtitlePresets[SubtitlePresetName.LUCKY_DEFAULT]);
    };

    const handlePresetSelect = (preset: SubtitlePresetName) => {
      setSelectedPreset(preset);
      setCurrentConfig(subtitlePresets[preset]);
    };

    const handleApply = () => {
      onSave(currentConfig);
      setIsOpen(false);
    };

    return (
      <Modal
        title="Choose Subtitle Style"
        open={isOpen}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="apply"
            type="primary"
            onClick={handleApply}
            disabled={!selectedPreset}
          >
            Apply Style
          </Button>,
        ]}
        width={800}
      >
        <Row gutter={[24, 24]} style={{ marginBottom: '15px' }}>
          <Col span={24}>
            <Paragraph type="secondary" style={{ margin: 0 }}>
              Choose one of these presets as a starting point - you can
              customize it further after applying, everything is customizable.
            </Paragraph>
          </Col>

          <Col span={24}>
            <SubtitlePresetSelector
              selectedPreset={selectedPreset}
              onSelect={handlePresetSelect}
            />
          </Col>
        </Row>
      </Modal>
    );
  }
);

export default SubtitleStyleModal;
