import {
  BaseResponse,
  CrudService,
  HttpService,
} from '@monorepo/client-common';
import { NinjaJob, Voiceover } from '@monorepo/types';

const routeName = 'ninja-job';

class NinjaJobService extends CrudService<Voiceover> {
  protected override route = routeName;

  constructor({ httpService }: { httpService: HttpService }) {
    super({ route: routeName, httpService });
  }

  async getStatus(jobId: string) {
    const response = await this.httpService.get<
      BaseResponse<
        Pick<NinjaJob, 'status' | 'progress' | '_id' | 'resultPayload'>
      >
    >(`${this.path}/status/${jobId}`);

    return response.data;
  }

  async getByVideo(videoId: string) {
    const response = await this.httpService.get<BaseResponse<NinjaJob[]>>(
      `${this.path}/video/${videoId}`
    );

    return response.data;
  }
}

export default NinjaJobService;
