import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, Form } from 'antd';
import { useNavigate, Link, useParams } from 'react-router-dom';
import AuthLayout from './auth-layout';
import {
  FormPassword,
  Space,
  useLoading,
  LoadingState,
} from '@monorepo/react-components';
import { formPasswordValidation } from '@monorepo/types';
import { useStore } from '../../helpers/use-store';
import { useNotification } from '../../hooks/use-notification';
import { Helmet } from 'react-helmet';

interface ResetPasswordFormData {
  password: string;
  confirmPassword: string;
}

const ResetPasswordPage: React.FC = () => {
  const navigate = useNavigate();
  const form = useForm<ResetPasswordFormData>();
  const { loadingState, updateLoadingState } = useLoading(LoadingState.Initial);
  const notificationApi = useNotification();
  const {
    dataStore: { userStore },
  } = useStore();

  // Extract the token from the URL query parameters
  const { confirmCode: token } = useParams<{ confirmCode: string }>();

  const onSubmit = async (data: ResetPasswordFormData) => {
    if (!token) {
      notificationApi.error({
        placement: 'top',
        message: 'Invalid Token',
        description: 'The reset token is missing or invalid.',
      });
      return;
    }

    try {
      updateLoadingState(LoadingState.Loading);
      await userStore.resetPassword(token, data.password);

      notificationApi.success({
        placement: 'top',
        message: 'Success',
        description: 'Your password has been reset successfully.',
      });

      navigate('/login');
    } catch (e) {
      notificationApi.error({
        placement: 'top',
        message: 'Error',
        description: 'Failed to reset password. Please try again.',
      });
    } finally {
      updateLoadingState(LoadingState.Loaded);
    }
  };

  return (
    <AuthLayout title="Reset Password">
      <Helmet>
        <title>Create New Password | ShortsNinja</title>
        <meta
          name="description"
          content="Set up a new password for your ShortsNinja account. Get back to creating faceless videos with AI voiceover."
        />
        <meta
          name="keywords"
          content="new password, reset password, ShortsNinja, shorts management, faceless videos, ai video automation, ai voiceover, content automation"
        />
      </Helmet>
      <Form onFinish={form.handleSubmit(onSubmit)} layout="vertical">
        <Space size={'large'} direction={'vertical'}>
          <FormPassword
            placeholder="New Password"
            form={form}
            controllerProps={{
              name: 'password',
              rules: formPasswordValidation(),
            }}
          />
          <FormPassword
            placeholder="Confirm New Password"
            form={form}
            controllerProps={{
              name: 'confirmPassword',
              rules: {
                ...formPasswordValidation(),
                validate: (value: string) =>
                  value === form.getValues('password') ||
                  'The passwords do not match',
              },
            }}
          />
          <Button
            loading={loadingState === LoadingState.Loading}
            type="primary"
            htmlType="submit"
            block
          >
            Reset Password
          </Button>
        </Space>
      </Form>
      <div className="auth-link">
        Remembered your password? <Link to="/login">Login here</Link>
      </div>
    </AuthLayout>
  );
};

export default ResetPasswordPage;
