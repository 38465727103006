import {
  BaseResponse,
  CrudService,
  HttpService,
} from '@monorepo/client-common';
import {
  Asset,
  CreateImagePayload,
  CreateVideoAnimationPayload,
  NinjaProviderModel,
  SearchResult,
  Voiceover,
} from '@monorepo/types';

const routeName = 'asset';

class AssetService extends CrudService<Voiceover> {
  protected override route = routeName;

  constructor({ httpService }: { httpService: HttpService }) {
    super({ route: routeName, httpService });
  }

  async upload({
    file,
    sceneId,
    videoId,
  }: {
    file: File;
    sceneId?: string;
    videoId?: string;
  }) {
    const formData = new FormData();

    formData.append('asset', file);
    formData.append('sceneId', sceneId || '');
    formData.append('videoId', videoId || '');

    const response = await this.httpService.post<FormData, BaseResponse<Asset>>(
      `${this.path}/upload`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    return response.data;
  }

  async uploadByUrl(
    asset: Pick<
      Asset,
      'url' | 'type' | 'origin' | 'prompt' | 'sceneId' | 'videoId'
    >
  ) {
    const response = await this.httpService.post<
      Pick<Asset, 'url' | 'type' | 'origin' | 'sceneId' | 'videoId'>,
      BaseResponse<Asset>
    >(`${this.path}/upload-by-url`, asset);

    return response.data;
  }

  async generateAIImage(createPayload: CreateImagePayload) {
    const response = await this.httpService.post<
      CreateImagePayload,
      BaseResponse<string>
    >(`${this.path}/generate-ai-image`, createPayload);

    return response.data;
  }

  async generateAIVideoFromImage(payload: CreateVideoAnimationPayload) {
    const response = await this.httpService.post<
      CreateVideoAnimationPayload,
      BaseResponse<string>
    >(`${this.path}/generate-ai-video-from-image`, payload);

    return response.data;
  }

  async searchStockFootage(prompt: string) {
    const response = await this.httpService.get<BaseResponse<SearchResult[]>>(
      `${this.path}/search-stock-footage`,
      {
        params: { prompt },
      }
    );

    return response.data;
  }

  async bulkGet(ids: string[]) {
    const response = await this.httpService.get<BaseResponse<Asset[]>>(
      `${this.path}/bulk-get`,
      {
        params: { ids },
      }
    );

    return response.data;
  }
}

export default AssetService;
