import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Flex,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import SocialAccountSelect from '../../../social-accounts/social-account';
import React, { useRef, useState } from 'react';
import { useVideoContext } from '../../../../hooks/use-video';
import { useStore } from '../../../../helpers/use-store';
import { LoadingState, useLoading } from '@monorepo/react-components';
import dayjs from 'dayjs';
import { useNotification } from '../../../../hooks/use-notification';
import {
  DEFAULT_TIMEZONE,
  PrivacyStatus,
  SocialAccountMetadata,
  SocialProvider,
  tiktokDefaultMetadata,
  TiktokMetadata,
  Timezone,
  TimezoneLabels,
  VideoStatus,
  youtubeDefaultMetadata,
  YouTubeMetadata,
} from '@monorepo/types';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import TiktokForm from './tiktok-form/tiktok-form';
import TiktokAgreement from './tiktok-form/tiktok-agreement';
import { observer } from 'mobx-react-lite';
import YoutubeForm from './youtube-form/youtube-form';
import ShareModal, { ShareModalActions } from './manual-share';

dayjs.extend(utc);
dayjs.extend(timezone);

const SocialPublish = () => {
  const shareModalRef = useRef<ShareModalActions>(null);
  const { video, fetchVideoAndAssets } = useVideoContext();
  const {
    dataStore: { videoStore, socialAccountStore },
  } = useStore();
  const { loadingState, updateLoadingState } = useLoading();
  const {
    loadingState: socialAccountLoadingState,
    updateLoadingState: updateSocialAccountLoadingState,
  } = useLoading();
  const notificationApi = useNotification();
  const [publishAt, setPublishAt] = useState<Date | undefined>(
    video?.socialPublishInfo?.publishAt
  );
  const [socialAccountId, setSocialAccountId] = useState<string | undefined>(
    video?.socialPublishInfo?.socialAccountId
  );
  const [timezone, setTimezone] = useState<Timezone>(
    video?.socialPublishInfo?.timezone || DEFAULT_TIMEZONE
  );

  const selectedAccount = socialAccountStore.get(socialAccountId || '');

  const [socialAccountMetadata, updateSocialAccountMetada] =
    useState<SocialAccountMetadata>(selectedAccount?.metadata);

  const handleSocialAccountSelect = async (value) => {
    try {
      updateSocialAccountLoadingState(LoadingState.Loading);

      await socialAccountStore.updateSocialAccountInfo(value);
      await socialAccountStore.fetchAll();
    } catch (e) {
      console.error(`failed getting latest social account info`, e);
    } finally {
      updateSocialAccountLoadingState(LoadingState.Loaded);
      setSocialAccountId(value);
    }
  };

  const handleScheduleUpdate = async (datetime) => {
    if (!datetime) {
      try {
        await videoStore.cancelSchedule({ videoId: video?._id || '' });

        notificationApi.success({
          placement: 'top',
          message: 'Success',
          description: 'Video scheduled publish canceled.',
        });
      } catch (e) {
        console.error(`failed canceling schedule`, e);
      }
    }
    setPublishAt(datetime);
  };

  const publish = async () => {
    if (!video) {
      return;
    }

    if (!socialAccountId) {
      return;
    }

    try {
      updateLoadingState(LoadingState.Loading);

      let publishAtUTC;

      if (publishAt) {
        publishAtUTC = dayjs(publishAt).tz(timezone).utc().toDate();
      }

      const result = await videoStore.updateSocialPublish({
        videoId: video._id,
        socialAccountId,
        timezone,
        publishAt: publishAtUTC,
        metadata: socialAccountMetadata,
      });

      if (!result) {
        throw new Error(`failed publish`);
      }

      notificationApi.success({
        placement: 'top',
        message: publishAt
          ? 'Video Scheduled successfully'
          : 'Video published successfully',
        description: publishAt
          ? ''
          : `It may take a few minutes for the content to process and be visible on their profile`,
      });

      // TODO: just refetch video
      fetchVideoAndAssets();
    } catch (e) {
      notificationApi.error({
        placement: 'top',
        message: 'Publish Failed',
        description: 'We encountered an error during publish',
      });

      console.error(`failed publishing`, e);
    } finally {
      updateLoadingState(LoadingState.Loaded);
    }
  };

  const isMoreThanFiveMinutesBefore = video?.socialPublishInfo?.publishAt
    ? dayjs()
        .utc()
        .isBefore(
          dayjs(video?.socialPublishInfo.publishAt).utc().subtract(5, 'minutes')
        )
    : true;

  const isVideoPublished = video?.status === VideoStatus.Published;

  const handleTiktokMetadataUpdate = (updates: Partial<TiktokMetadata>) => {
    updateSocialAccountMetada((prev) => {
      return {
        ...tiktokDefaultMetadata,
        ...(selectedAccount?.metadata || {}),
        ...prev,
        ...updates,
      };
    });
  };

  const handleYoutubeMetadataUpdate = (
    updates: Partial<TiktokMetadata | YouTubeMetadata>
  ) => {
    updateSocialAccountMetada((prev) => {
      return {
        ...youtubeDefaultMetadata,
        ...(selectedAccount?.metadata || {}),
        ...prev,
        ...updates,
      };
    });
  };

  const getSocialProviderAdditionalInfo = () => {
    if (!selectedAccount) {
      return null;
    }

    const provider = selectedAccount.provider;

    if (provider === SocialProvider.TikTok) {
      return (
        <>
          <TiktokForm
            onUpdate={handleTiktokMetadataUpdate}
            metadata={
              socialAccountMetadata ||
              selectedAccount.metadata ||
              tiktokDefaultMetadata
            }
          />
          <Divider style={{ margin: '15px 0' }} />
        </>
      );
    }

    if (provider === SocialProvider.YouTube) {
      return (
        <>
          <YoutubeForm
            onUpdate={handleYoutubeMetadataUpdate}
            metadata={
              socialAccountMetadata ||
              selectedAccount.metadata ||
              youtubeDefaultMetadata
            }
          />
          <Divider style={{ margin: '15px 0' }} />
        </>
      );
    }

    return null;
  };

  const getSocialAgreement = () => {
    if (!selectedAccount) {
      return null;
    }

    const provider = selectedAccount.provider;

    if (provider === SocialProvider.TikTok) {
      return (
        <TiktokAgreement
          metadata={
            socialAccountMetadata ||
            selectedAccount.metadata ||
            tiktokDefaultMetadata
          }
        />
      );
    }

    return null;
  };

  const isMetadataValid = () => {
    if (!selectedAccount) {
      return {
        valid: false,
        message:
          '  🔗 Action Required: Select a social media account to publish your video • No account currently selected',
      };
    }

    const provider = selectedAccount.provider;

    if (provider === SocialProvider.TikTok) {
      const tiktokMetadata = (socialAccountMetadata ||
        tiktokDefaultMetadata) as TiktokMetadata;

      if (!tiktokMetadata.privacy) {
        return {
          valid: false,
          message:
            '🎯 Action Required: You need to choose who can view this video.',
        };
      }

      // Check if disclosure is enabled but no brand options selected
      if (
        tiktokMetadata.isDisclosureEnabled &&
        !tiktokMetadata.brandOptions.yourBrand &&
        !tiktokMetadata.brandOptions.brandedContent
      ) {
        return {
          valid: false,
          message:
            '🎯 Action Required: You need to indicate if your content promotes yourself, a third party, or both',
        };
      }
    }

    if (provider === SocialProvider.YouTube) {
      const youtubeMetadata = (socialAccountMetadata ||
        youtubeDefaultMetadata) as YouTubeMetadata;

      if (!youtubeMetadata.privacyStatus) {
        return {
          valid: false,
          message: '🎯 Action Required: You need to select privacy status.',
        };
      }
    }

    return {
      valid: true,
      message: '',
    };
  };

  const metadataValidity = isMetadataValid();

  const handleShare = () => {
    if (!video || !video?.videoUrl) {
      return;
    }
    shareModalRef.current?.open({
      title: video.title || '',
      description: video.description || '',
      url: video.videoUrl,
    });
  };

  return (
    <Card
      title="Social Media Publishing"
      extra={
        <Button disabled={!video?.videoUrl} onClick={handleShare}>
          Manual Share
        </Button>
      }
    >
      <Row gutter={[15, 15]}>
        <Col span={24}>
          <Typography.Text>Social Account</Typography.Text>
          <SocialAccountSelect
            loading={socialAccountLoadingState === LoadingState.Loading}
            onChange={handleSocialAccountSelect}
            value={socialAccountId}
          />
          <Divider style={{ margin: '15px 0' }} />
          {getSocialProviderAdditionalInfo()}
        </Col>

        <Col md={24} lg={12}>
          <span>
            Publish Date{' '}
            <Typography.Text type={'secondary'}>(Local Time)</Typography.Text>
          </span>
          <Flex style={{ flexDirection: 'column' }} justify={'space-between'}>
            <DatePicker
              disabled={!isMoreThanFiveMinutesBefore}
              style={{ width: '100%' }}
              placeholder="Select Publish Date"
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm"
              value={publishAt ? dayjs(publishAt) : null}
              onChange={handleScheduleUpdate}
              allowClear
            />
          </Flex>
        </Col>
        <Col md={24} lg={12}>
          <Typography.Text>Timezone</Typography.Text>
          <Select
            style={{ width: '100%' }}
            placeholder={'Timezone'}
            value={timezone}
            onChange={setTimezone}
          >
            {Object.entries(Timezone).map(([key, value]) => (
              <Select.Option key={value} value={value}>
                {TimezoneLabels[value]}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col md={24} lg={24}>
          <Typography.Text type="secondary">
            <ul style={{ listStyle: 'none', padding: 0, marginBottom: 0 }}>
              <li style={{ marginBottom: 8, marginLeft: 0 }}>
                ⚡️ If no date is selected, your video will be published
                immediately
              </li>
              <li style={{ marginLeft: 0 }}>
                🕐 You can modify the publish date anytime until 5 minutes
                before scheduled time
              </li>
            </ul>
          </Typography.Text>
        </Col>
        <Divider style={{ margin: '15px 0' }} />
        <Button
          onClick={publish}
          loading={loadingState === LoadingState.Loading}
          disabled={
            !metadataValidity.valid ||
            !socialAccountId ||
            isVideoPublished ||
            !isMoreThanFiveMinutesBefore ||
            (!video?.videoUrl && !publishAt)
          }
          style={{ width: '100%' }}
          type="primary"
        >
          {publishAt ? 'Schedule' : 'Publish Now'}
        </Button>
        <Space size={5} direction={'vertical'} style={{ width: '100%' }}>
          {isVideoPublished ? null : getSocialAgreement()}
          {!metadataValidity.valid ? (
            <Typography.Text type="secondary">
              {metadataValidity.message}
            </Typography.Text>
          ) : null}
          {!video?.videoUrl && !publishAt ? (
            <Typography.Text type="secondary">
              🎯 Action Required: Video must be rendered before publishing •
              Click "Render" to continue
            </Typography.Text>
          ) : null}
          {isVideoPublished ? (
            <Typography.Text type="secondary">
              Video is already published to the channel.
            </Typography.Text>
          ) : null}
        </Space>
      </Row>
      <ShareModal ref={shareModalRef} />
    </Card>
  );
};

export default observer(SocialPublish);
