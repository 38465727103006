import React, { useEffect, useState } from 'react';
import { Image, Skeleton } from 'antd';

import './image-preview.scss';
import { imageBlobManager } from '../../managers/image-blob-manager';

interface ErrorFallbackImageProps {
  src: string;
  alt: string;
  className?: string;
  style?: React.CSSProperties;
  preview?: boolean;
}

const ImagePreview: React.FC<ErrorFallbackImageProps> = ({
  src,
  alt,
  className = '',
  style = {},
  preview = false,
}) => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setHasError(false);
  }, [src]);

  const handleError = () => {
    setHasError(true);
  };

  if (hasError) {
    return <Skeleton.Image className={className} style={style} />;
  }

  return (
    <Image
      wrapperClassName={'image-preview'}
      preview={preview}
      placeholder={<Skeleton active={true} />}
      className={`asset-image ${className}`}
      alt={alt}
      src={imageBlobManager.getImage(`${src}`)}
      style={{ objectFit: 'contain', ...style }}
      onError={handleError}
    />
  );
};

export default ImagePreview;
