import { Audio, Series, useVideoConfig } from 'remotion';
import { AudioTrack } from '../remotion-types';

export const AudioTrackPlayer: React.FC<{
  track: AudioTrack;
}> = ({ track }) => {
  const src = track.src;

  return (
    <Audio
      pauseWhenBuffering={false}
      loop={track.loop ?? false}
      src={src}
      playbackRate={1}
      volume={track.volume ?? 1}
    />
  );
};

export const AudioTrackGroup: React.FC<{
  tracks: AudioTrack[];
}> = ({ tracks }) => {
  const { fps } = useVideoConfig();

  return (
    <Series>
      {tracks.map((track, index) => {
        const durationInFrames = (track.duration || 0) * fps;
        return (
          <Series.Sequence
            premountFor={20}
            key={index}
            durationInFrames={durationInFrames}
          >
            <AudioTrackPlayer track={track} />
          </Series.Sequence>
        );
      })}
    </Series>
  );
};
