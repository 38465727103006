import React from 'react';
import { Card, Row, Col, Typography } from 'antd';
import { SubtitlesConfig } from '@monorepo/types';
import {
  presetDisplayNames,
  SubtitlePresetName,
  subtitlePresets,
} from './subtitle-preset';

const { Title } = Typography;

interface SubtitlePresetSelectorProps {
  selectedPreset?: SubtitlePresetName;
  onSelect?: (preset: SubtitlePresetName) => void;
}

const WORDS = ['QUICK', 'BROWN', 'FOX', 'JUMPS', 'OVER', 'THE', 'LAZY', 'DOG'];

const SubtitlePresetSelector: React.FC<SubtitlePresetSelectorProps> = ({
  selectedPreset,
  onSelect,
}) => {
  const handleSelect = (presetName: SubtitlePresetName) => {
    if (onSelect) {
      onSelect(presetName);
    }
  };

  const renderPreviewText = (
    config: SubtitlesConfig,
    presetName: SubtitlePresetName
  ) => {
    // Get the specified number of words from the word list
    const previewWords = WORDS.slice(0, config.numberOfWords);

    // Create groups of 2 words
    const wordGroups = previewWords.reduce((acc: string[][], word, i) => {
      const groupIndex = Math.floor(i / 2);
      if (!acc[groupIndex]) {
        acc[groupIndex] = [];
      }
      acc[groupIndex].push(word);
      return acc;
    }, []);

    const outlineEffect =
      config.outline > 0
        ? `${config.outline}px ${config.outline}px 0 ${config.outlineColor},
     -${config.outline}px ${config.outline}px 0 ${config.outlineColor},
     -${config.outline}px -${config.outline}px 0 ${config.outlineColor},
     ${config.outline}px -${config.outline}px 0 ${config.outlineColor},
     ${config.outline * 2}px ${config.outline * 2}px ${
            config.outline * 3
          }px rgba(0, 0, 0, 0.6)`
        : 'none';

    return (
      <div
        style={{
          position: 'relative',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: '#f5f5f5',
          borderRadius: 8,
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            justifyContent: 'center',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            alignItems: 'center',
          }}
        >
          {wordGroups.map((group, groupIndex) => (
            <div
              key={groupIndex}
              style={{
                display: 'flex',
                gap: '8px',
                justifyContent: 'center',
              }}
            >
              {group.map((word, wordIndex) => (
                <span
                  key={wordIndex}
                  style={{
                    fontFamily: config.fontName,
                    fontSize: '18px',
                    color:
                      groupIndex * group.length + wordIndex === 1
                        ? config.highlightColor
                        : config.primaryColor,
                    textTransform: config.uppercase ? 'uppercase' : 'none',
                    textShadow: outlineEffect,
                    letterSpacing: '0.02em',
                    backgroundColor:
                      groupIndex * group.length + wordIndex === 1 &&
                      config.backColor
                        ? config.backColor
                        : 'transparent',
                  }}
                >
                  {word}
                </span>
              ))}
            </div>
          ))}
        </div>

        <div
          style={{
            position: 'absolute',
            bottom: 4,
            left: 0,
            right: 0,
            textAlign: 'center',
            color: '#666',
            fontSize: '14px',
            fontFamily: 'system-ui',
          }}
        >
          {presetDisplayNames[presetName]}
        </div>
      </div>
    );
  };

  return (
    <div style={{ maxWidth: 1200, margin: '0 auto' }}>
      <Row gutter={[16, 16]}>
        {Object.entries(subtitlePresets).map(([name, config]) => {
          const presetName = name as SubtitlePresetName;
          return (
            <Col xs={24} sm={12} lg={8} key={presetName}>
              <Card
                hoverable
                style={{
                  borderColor:
                    selectedPreset === presetName ? '#1890ff' : undefined,
                  cursor: 'pointer',
                  padding: 0,
                  height: 120,
                  background: '#fff',
                }}
                bodyStyle={{
                  padding: 0,
                  height: '100%',
                }}
                onClick={() => handleSelect(presetName)}
              >
                {renderPreviewText(config, presetName)}
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default SubtitlePresetSelector;
