import { WordWithMetadata } from './timeline';
import React, { FunctionComponent, memo } from 'react';
import TimelineText from './timeline-text';

interface Props {
  words: WordWithMetadata[];
  openModal: (word: WordWithMetadata) => void;
}

const TimelineWordsList: FunctionComponent<Props> = ({ words, openModal }) => {
  return words.map((word, index) => {
    return (
      <TimelineText
        key={`word-${index}`}
        index={index}
        word={word}
        openModal={openModal}
      />
    );
  });
};

export default memo(TimelineWordsList, (oldProps, newProps) => {
  return oldProps?.words === newProps?.words;
});
