import { OverlayThumbnail, OverlayTrackItem } from '../../remotion-overlays';
import { ThumbnailType } from '@monorepo/types';
import TiktokThumbnail from './thumbnail-types/tiktok-thumbnail';
import { FunctionComponent } from 'react';
import TextThumbnail from './thumbnail-types/text-thumbnail';

const Thumbnails: FunctionComponent<{
  item: OverlayTrackItem;
}> = ({ item }) => {
  const thumbnailData = item.data as OverlayThumbnail;

  if (thumbnailData.type === ThumbnailType.TikTok) {
    return <TiktokThumbnail data={thumbnailData} />;
  }
  if (thumbnailData.type === ThumbnailType.Text) {
    return <TextThumbnail data={thumbnailData} />;
  }

  return null;
};

export default Thumbnails;
