import React, { useEffect, useState, useRef } from 'react';
import { motionDisplayText } from '@monorepo/remotion-ninja';
import { imageBlobManager } from '../../../../managers/image-blob-manager';

export const MotionPreview: React.FC<{
  motion: (progress: number) => { transform: string };
  imageUrl: string;
  name: string;
  progress?: number;
}> = ({ motion, imageUrl, name, progress = 0 }) => {
  const [dynamicProgress, setDynamicProgress] = useState(progress);
  const [isHovering, setIsHovering] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const animationFrameRef = useRef<number>();

  useEffect(() => {
    if (isHovering) {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      return;
    }

    const start = Date.now();
    const animate = () => {
      const elapsed = (Date.now() - start) / 1000;
      const loopDuration = 5;
      const normalizedProgress = (elapsed % loopDuration) / loopDuration;
      const newProgress = normalizedProgress * 100;

      setDynamicProgress(newProgress);
      animationFrameRef.current = requestAnimationFrame(animate);
    };

    animationFrameRef.current = requestAnimationFrame(animate);
    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [isHovering]);

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!containerRef.current) return;
    const rect = containerRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const progress = (x / rect.width) * 100;
    setDynamicProgress(Math.min(Math.max(progress, 0), 100));
  };

  const motionStyle = motion(dynamicProgress);

  const isVideo = imageUrl.includes('mp4');

  return (
    <div
      ref={containerRef}
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        background: '#000',
      }}
      onMouseMove={handleMouseMove}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div
        style={{
          position: 'absolute',
          transformOrigin: 'center center',
          transition: 'transform 0.1s linear',
          width: '100%',
          height: '100%',
          ...motionStyle,
        }}
      >
        {isVideo ? (
          <video
            style={{
              width: '120%',
              height: '100%',
              objectFit: 'cover',
            }}
            src={imageBlobManager.getImage(imageUrl)}
          />
        ) : (
          <img
            src={imageBlobManager.getImage(imageUrl)}
            alt=""
            style={{
              width: '120%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        )}
      </div>

      {isHovering && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: `${dynamicProgress}%`,
            zIndex: 10,
            width: '2px',
            height: '100%',
            background: 'rgba(255, 255, 255, 0.8)',
            transition: 'transform 0.1s linear',
            pointerEvents: 'none',
          }}
        />
      )}

      <span
        style={{
          fontSize: '12px',
          position: 'absolute',
          bottom: 0,
          color: '#fff',
          textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)',
          padding: '4px 8px',
        }}
      >
        {motionDisplayText[name]}
      </span>
    </div>
  );
};
