import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Input, Modal, Typography, Form } from 'antd';
import { LoadingState, useLoading } from '@monorepo/react-components';

import { Video } from '@monorepo/types';
import { useNotification } from '../../../../hooks/use-notification';
import { useStore } from '../../../../helpers/use-store';

const { Text } = Typography;

export interface EditVideoTitleModalActions {
  open: (video: Video) => void;
}

interface Props {
  onClose: () => Promise<void>;
}

const EditVideoTitleModal = forwardRef<EditVideoTitleModalActions, Props>(
  ({ onClose }, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentVideo, setCurrentVideo] = useState<Video | null>(null);
    const [newTitle, setNewTitle] = useState('');
    const { loadingState, updateLoadingState } = useLoading();
    const notificationApi = useNotification();
    const {
      dataStore: { videoStore },
    } = useStore();

    useImperativeHandle(ref, () => ({
      open: (video: Video) => {
        setCurrentVideo(video);
        setNewTitle(video.title);
        setIsOpen(true);
      },
    }));

    const handleCancel = () => {
      setIsOpen(false);
      setCurrentVideo(null);
      setNewTitle('');
    };

    const handleSave = async () => {
      if (!currentVideo) return;

      try {
        updateLoadingState(LoadingState.Loading);

        await videoStore.updateMetadata(currentVideo._id.toString(), {
          title: newTitle,
        });

        notificationApi.success({
          placement: 'top',
          message: 'Success',
          description: `Video title updated successfully`,
        });

        handleCancel();
      } catch (error) {
        console.error('Failed to update video title:', error);
        notificationApi.error({
          placement: 'top',
          message: 'Failed to update video title',
          description:
            'An error occurred while updating the title. Please try again.',
        });
      } finally {
        updateLoadingState(LoadingState.Loaded);
        await onClose();
      }
    };

    if (!currentVideo) {
      return null;
    }

    return (
      <Modal
        title={
          <Typography.Title style={{ margin: 0 }} level={5}>
            Edit Video Title
          </Typography.Title>
        }
        open={isOpen}
        onCancel={(e) => {
          e.stopPropagation();
          handleCancel();
        }}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="save"
            type="primary"
            onClick={handleSave}
            loading={loadingState === LoadingState.Loading}
            disabled={!newTitle.trim() || newTitle === currentVideo.title}
          >
            Save Changes
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item>
            <Input
              value={newTitle}
              onChange={(e) => setNewTitle(e.target.value)}
              placeholder="Enter new video title"
              autoFocus
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
);

export default EditVideoTitleModal;
