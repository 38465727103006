import { Button, Col, Row, Tag, Tooltip, Typography } from 'antd';
import { MotionType, Scene } from '@monorepo/types';
import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useVideoContext } from '../../../hooks/use-video';
import TransitionSelector from './transition-selector/transition-selector';
import EditSceneText from './edit-scene-text/edit-scene-text';
import MotionModal, { MotionModalActions } from './motions/motion-modal';

import './edit-scene.scss';
import { motionDisplayText } from '@monorepo/remotion-ninja';
import SceneImage from '../scenes/scene-image/scene-image';

export type EditSceneForm = Pick<Scene, 'transition'>;

const EditScene = () => {
  const motionModalRef = useRef<MotionModalActions>(null);
  const form = useForm<EditSceneForm>();
  const {
    updateSceneDebounced,
    selectedScene: scene,
    getSceneStartFrame,
    assets,
    modalRef,
    setSceneId,
  } = useVideoContext();

  const resetForm = () => {
    if (scene) {
      form.reset({
        transition: scene.transition,
      });
    }
  };

  useEffect(() => {
    resetForm();
    //
    // if (scene) {
    //   const startFrame = getSceneStartFrame(scene._id);
    //   movePlayerToFrame(startFrame + 8, false);
    // }
  }, [scene?._id]);

  useEffect(() => {
    const { unsubscribe } = form.watch(async (value) => {
      const isChanged = scene?.transition !== form.getValues('transition');

      if (scene && isChanged) {
        const frame = getSceneStartFrame(scene._id);

        await updateSceneDebounced({
          sceneId: scene._id,
          videoId: scene.videoId,
          updateDto: value,
          frameToSeek: -1,
        });
      }
    });
    return () => unsubscribe();
  }, [form.watch()]);

  const handleOpenMotionModal = () => {
    if (!scene) {
      return;
    }

    motionModalRef.current?.open(scene);
  };

  if (!scene) {
    return null;
  }

  const sceneAsset = assets.find((asset) => asset._id === scene?.assetId);

  return (
    <Row className={'edit-scene'} gutter={[10, 15]}>
      <Col span={24}>
        <EditSceneText />
      </Col>

      <MotionModal ref={motionModalRef} />

      <Col span={24}>
        <Tag>Duration: {scene.audioDuration.toFixed(2)}s</Tag>
      </Col>
      <Col span={8}>
        <div>
          <SceneImage
            scene={scene}
            asset={sceneAsset}
            onAssetClick={() => {
              modalRef.current?.open();

              setSceneId(scene?._id);
            }}
          />
          <Typography.Text style={{ fontSize: '12px' }} type={'secondary'}>
            Click on the image to edit.
          </Typography.Text>
        </div>
      </Col>
      <Col span={16}>
        <Col span={24} style={{ marginBottom: '15px' }}>
          <Typography.Text type={'secondary'}>
            Effect: <b>{motionDisplayText[scene?.motion as MotionType]}</b>
            <br />
          </Typography.Text>
          <Tooltip
            placement={'right'}
            title={`Select your motion effect here.`}
          >
            <Button onClick={handleOpenMotionModal}>
              Update Motion Effect
            </Button>
          </Tooltip>
        </Col>
        <Col span={24}>
          <Typography.Title
            style={{ margin: 0, marginBottom: '5px' }}
            level={5}
          >
            Transition
          </Typography.Title>
          <TransitionSelector form={form} />
        </Col>
      </Col>
    </Row>
  );
};

export default EditScene;
