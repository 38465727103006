import React, { FunctionComponent } from 'react';
import { OverlayThumbnail } from '../../../remotion-overlays';

interface Props {
  data: OverlayThumbnail;
}

const TextThumbnail: FunctionComponent<Props> = ({ data }) => (
  <div
    style={{
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0 auto',
      maxWidth: '900px',
      transform: 'rotate(-6deg)',
      width: '100%',
    }}
  >
    <div
      style={{
        marginLeft: '0px',
        textAlign: 'center',
        display: 'inline-block',
        fontSize: '90px',
        fontFamily: 'Permanent Marker',
        textShadow:
          'rgb(0, 0, 0) 5px 5px 0px, rgb(0, 0, 0) -5px 5px 0px, rgb(0, 0, 0) -5px -5px 0px, rgb(0, 0, 0) 5px -5px 0px, rgba(0, 0, 0, 0.6) 10px 10px 15px',
        color: 'rgb(255, 255, 255)',
        backgroundColor: 'rgba(0, 0, 0, 0)',
      }}
    >
      {data.text}
    </div>
  </div>
);

export default TextThumbnail;
