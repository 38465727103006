import { NinjaJobModelNames, NinjaProviderModel } from '@monorepo/types';

export interface ModelPollingConfig {
  interval: number; // milliseconds
  maxAttempts: number;
}

export const VideoModelPollingConfig: Record<string, ModelPollingConfig> = {
  [NinjaProviderModel.StableVideoDiffusion]: {
    interval: 5000,
    maxAttempts: 10, // Total polling time: 1 minute
  },
  [NinjaProviderModel.LumaDreamMachine]: {
    interval: 5000,
    maxAttempts: 15, // Total polling time: 1 minute
  },
  [NinjaProviderModel.MinimaxVideoOneLive]: {
    interval: 20000,
    maxAttempts: 15, // Total polling time: 1 minute
  },
  [NinjaProviderModel.KlingStandard]: {
    interval: 20000,
    maxAttempts: 25, // Total polling time: 1 minute
  },
  [NinjaProviderModel.KlingPro]: {
    interval: 20000,
    maxAttempts: 25, // Total polling time: 1 minute
  },
  [NinjaProviderModel.RunwayML]: {
    interval: 10000,
    maxAttempts: 12, // Total polling time: 1 minute
  },
};

export interface VideoModelDetails {
  name: string;
  processingTime: string;
  creditsCost: number;
  disableManualInput?: boolean;
  options?: any;
}

export const VideoModelInfo: Record<string, VideoModelDetails> = {
  [NinjaProviderModel.StableVideoDiffusion]: {
    name: NinjaJobModelNames[NinjaProviderModel.StableVideoDiffusion],
    processingTime: '30-60 seconds',
    creditsCost: 1,
    disableManualInput: true,
  },
  [NinjaProviderModel.RunwayML]: {
    name: NinjaJobModelNames[NinjaProviderModel.RunwayML],
    processingTime: '15-30 seconds',
    creditsCost: 6,
  },
  [NinjaProviderModel.LumaDreamMachine]: {
    name: NinjaJobModelNames[NinjaProviderModel.LumaDreamMachine],
    processingTime: '30-90 seconds',
    creditsCost: 12,
  },
  [NinjaProviderModel.MinimaxVideoOneLive]: {
    name: NinjaJobModelNames[NinjaProviderModel.MinimaxVideoOneLive],
    processingTime: '2-4 minutes',
    creditsCost: 12,
  },
  [NinjaProviderModel.KlingStandard]: {
    name: NinjaJobModelNames[NinjaProviderModel.KlingStandard],
    processingTime: '5-7 minutes',
    creditsCost: 4,
  },
  [NinjaProviderModel.KlingPro]: {
    name: NinjaJobModelNames[NinjaProviderModel.KlingPro],
    processingTime: '5-7 minutes',
    creditsCost: 15,
  },
  [NinjaProviderModel.KlingOneFive]: {
    name: NinjaJobModelNames[NinjaProviderModel.KlingOneFive],
    processingTime: '5-7 minutes',
    creditsCost: 15,
  },
};
