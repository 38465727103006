import { NinjaModelsOptions, NinjaProviderModel } from '@monorepo/types';
import React, { FunctionComponent } from 'react';
import LumaOptions from './options/luma-options';
import { Flex, Typography } from 'antd';
import KlingOptions from './options/kling-options';
import RunwayMlOptions from './options/runway-ml-options';

interface Props {
  model: NinjaProviderModel;
  updateOptions: (options: NinjaModelsOptions) => void;
}

const AiVideoOptions: FunctionComponent<Props> = ({ model, updateOptions }) => {
  const getComponent = () => {
    let component;

    if (model === NinjaProviderModel.LumaDreamMachine) {
      component = <LumaOptions updateOptions={updateOptions} />;
    }
    if (model === NinjaProviderModel.RunwayML) {
      component = <RunwayMlOptions updateOptions={updateOptions} />;
    }
    if (
      [
        NinjaProviderModel.KlingStandard,
        NinjaProviderModel.KlingPro,
        NinjaProviderModel.KlingOneFive,
      ].includes(model)
    ) {
      component = <KlingOptions model={model} updateOptions={updateOptions} />;
    }

    if (!component) {
      return null;
    }

    return (
      <Flex align={'center'} justify={'start'} gap={15}>
        <Typography.Text>Ai Options:</Typography.Text>
        {component}
      </Flex>
    );
  };

  return getComponent();
};

export default AiVideoOptions;
