import React, { FunctionComponent, useState } from 'react';
import { Button, Col, Input, message, Row, Space, Typography } from 'antd';
import {
  Asset,
  CreateImageResponse,
  NinjaJobsMessage,
  NinjaProviderModel,
} from '@monorepo/types';
import { useStore } from '../../../../../helpers/use-store';
import { LoadingState, useLoading } from '@monorepo/react-components';
import { PartialAsset } from '../../../../../components/selectable-asset/selectable-asset';
import { useNotification } from '../../../../../hooks/use-notification';
import AssetComponent from '../../../../../components/asset/asset';

import './ai-image.scss';
import { useVideoContext } from '../../../../../hooks/use-video';

interface Props {
  asset: Asset | PartialAsset;
  onSelect: (partialAsset: Asset) => void;
  sceneId?: string;
  videoId?: string;
}

const AIImageGenerator: FunctionComponent<Props> = ({
  asset,
  onSelect,
  sceneId,
  videoId,
}) => {
  const [generatedAsset, setGeneratedAsset] = useState<Asset | null>(null);
  const [prompt, setPrompt] = useState(asset?.prompt || '');
  const { loadingState, updateLoadingState } = useLoading();
  const {
    dataStore: { assetStore, userStore },
  } = useStore();
  const notificationApi = useNotification();
  const { videoJobs } = useVideoContext();
  const [currentJobId, setCurrentJobId] = useState<string | null>(null);

  const isInProgress = videoJobs.isJobActive(currentJobId || '');

  const handleGenerate = async () => {
    if (!prompt.trim()) {
      message.warning('Please enter a prompt for the AI image');
      return;
    }

    updateLoadingState(LoadingState.Loading);
    try {
      const jobId = await assetStore.generateAIImage({
        prompt,
        model: NinjaProviderModel.FluxDev,
        videoId,
        sceneId,
      });

      setCurrentJobId(jobId);

      const finishLoading = () => {
        setCurrentJobId(null);
        updateLoadingState(LoadingState.Loaded);
      };

      videoJobs.startSinglePolling({
        jobId,
        options: {
          interval: 3000,
          maxAttempts: 20,
          onFinish: async (job) => {
            console.log(job);
            const newAssetId = (job.resultPayload as CreateImageResponse)
              .assetId;

            const newAsset = await assetStore.fetch(newAssetId);

            setGeneratedAsset(newAsset);

            finishLoading();

            notificationApi.success({
              placement: 'top',
              message: 'Success',
              description: NinjaJobsMessage[job.type].success,
            });

            userStore.getLatestUser();
          },
          onFail: (job) => {
            console.error('Job failed:', job);
            finishLoading();
            notificationApi.error({
              placement: 'top',
              message: 'Error',
              description: NinjaJobsMessage[job.type].fail,
            });
          },
        },
      });
    } catch (error) {
      console.error('Error generating AI image:', error);
      notificationApi.error({
        placement: 'top',
        message: 'Error',
        description:
          'Failed creating AI images, please contact support for help.',
      });
    }
  };

  const isLoading = loadingState === LoadingState.Loading;

  return (
    <Space
      className={'ai-image-generator'}
      direction="vertical"
      size="small"
      style={{ width: '100%' }}
    >
      <Input.TextArea
        rows={4}
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
        placeholder="Describe the image you want the AI to generate..."
      />
      <Button
        type="primary"
        style={{ width: '100%' }}
        onClick={handleGenerate}
        loading={isLoading || isInProgress}
      >
        Generate AI Image
      </Button>
      {
        <Typography.Text
          style={{
            height: '22px',
            width: '100%',
            display: 'block',
            textAlign: 'center',
          }}
          type={'secondary'}
        >
          {isLoading || isInProgress
            ? `🔄 Processing your request - stay here or check "My Assets" later to view results! 📂`
            : null}
        </Typography.Text>
      }
      <Row className={'generated-result'} justify={'center'}>
        <Col offset={1} style={{ textAlign: 'center' }} span={6}>
          <div className={'image-item'}>
            <Typography.Text style={{ fontSize: '16px' }} type={'secondary'}>
              🗂️ Current Image
            </Typography.Text>
            <AssetComponent
              width={'100%'}
              height={'100%'}
              shouldLazy={false}
              asset={asset}
            />
          </div>
        </Col>
        <Col offset={4} span={6}>
          <div className={'image-item'}>
            {generatedAsset ? (
              <>
                <Typography.Text
                  style={{ fontSize: '16px' }}
                  type={'secondary'}
                >
                  {' '}
                  🖼️ Generated Image
                </Typography.Text>
                <AssetComponent
                  width={'100%'}
                  height={'100%'}
                  shouldLazy={false}
                  asset={generatedAsset}
                />
                <Button
                  color={'primary'}
                  variant={'filled'}
                  className={'select-new-image'}
                  style={{ width: '100%' }}
                  type={'primary'}
                  onClick={() => onSelect(generatedAsset)}
                >
                  Select
                </Button>
              </>
            ) : null}
          </div>
        </Col>
      </Row>
    </Space>
  );
};

export default AIImageGenerator;
