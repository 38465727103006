import { EditOutlined } from '@ant-design/icons';
import React, { FunctionComponent } from 'react';
import { WordWithMetadata } from './timeline';

interface Props {
  openModal: (word: WordWithMetadata) => void;
  word: WordWithMetadata;
  index: number;
}

const TimelineText: FunctionComponent<Props> = ({ openModal, word, index }) => {
  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();

        if (word.disabled) {
          return;
        }

        openModal(word);
      }}
      id={`word-${index}`}
      style={{
        padding: '4px 8px',
        margin: '4px',
        display: 'inline-block',

        borderRadius: '5px',
        // width: `${width}px`,
        // minWidth: `${MIN_WORD_WIDTH}px`,
        textAlign: 'center',
      }}
      className={`word-button ${word.disabled ? 'disabled' : ''}`}
    >
      {word.word ? word.word : '----'}
      <EditOutlined
        disabled={word.disabled}
        style={{
          fontSize: '12px',
          marginLeft: '10px',
          pointerEvents: 'none',
        }}
      />
    </div>
  );
};

export default TimelineText;
