import React, { FunctionComponent } from 'react';
import { useYouTubeAuth } from './use-youtube-auth';
import { Button } from 'antd';
import { YoutubeFilled } from '@ant-design/icons';
import { useStore } from '../../../helpers/use-store';
import DisabledText from '../disabled-social-text';

export interface YouTubeAuthModalProps {
  onAccountAdded: () => void;
}

export const YouTubeAuthModal: FunctionComponent<YouTubeAuthModalProps> = ({
  onAccountAdded,
}) => {
  const {
    dataStore: { userStore },
  } = useStore();
  const { handleAuth } = useYouTubeAuth({
    onSuccess: onAccountAdded,
  });

  const isDisabled = userStore.currentUser.applicationConfig?.disableYoutube;

  return (
    <>
      <Button
        disabled={isDisabled}
        // type="primary"
        icon={<YoutubeFilled style={{ fontSize: '20px' }} />}
        block
        onClick={handleAuth}
        danger
      >
        <span style={{ width: '80px' }}>YouTube</span>
      </Button>
      {isDisabled ? <DisabledText /> : null}
    </>
  );
};
