import { Badge } from 'antd';
import { NotificationOutlined } from '@ant-design/icons';
import React, { FunctionComponent, useEffect } from 'react';
import { useStore } from '../../helpers/use-store';
import { observer } from 'mobx-react-lite';

export const CHANGELOG_KEY = 'changelog_date';

interface Props {
  marginRight?: string;
}

const Changelog: FunctionComponent<Props> = ({ marginRight = '0' }) => {
  const {
    dataStore: { userStore },
  } = useStore();
  const { changelogCount } = userStore;

  useEffect(() => {
    const currentChangeLog =
      userStore.currentUser.applicationConfig?.changelogDate;

    if (currentChangeLog) {
      const userChangelogDate = localStorage.getItem(CHANGELOG_KEY);

      if (userChangelogDate !== currentChangeLog) {
        userStore.setChangelogCount(1);
      }
    }
  }, [userStore.currentUser]);

  return (
    <Badge
      styles={{ root: { marginRight } }}
      size={'small'}
      count={changelogCount}
    >
      <NotificationOutlined />
    </Badge>
  );
};

export default observer(Changelog);
