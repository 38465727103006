import React, { FunctionComponent, useEffect, useState } from 'react';
import { Empty, Pagination, Row, Skeleton, Select } from 'antd';
import { useStore } from '../../../../../helpers/use-store';
import { Asset, PaginationResult } from '@monorepo/types';
import { LoadingState, useLoading } from '@monorepo/react-components';

import './my-assets.scss';
import SelectableAssetList from '../../../../../components/selectable-asset/selectable-asset-list';
import { PartialAsset } from '../../../../../components/selectable-asset/selectable-asset';

interface Props {
  selectedAsset: Asset | PartialAsset | null;
  onSelect: (asset: Asset) => void;
  videoId?: string;
  sceneId?: string;
}

type FilterType = 'all' | 'video' | 'scene' | 'recent';

const MyAssets: FunctionComponent<Props> = ({
  onSelect,
  selectedAsset,
  videoId,
  sceneId,
}) => {
  const {
    dataStore: { assetStore },
  } = useStore();

  const [paginationResult, setPaginationResult] = useState<
    PaginationResult<Asset>
  >({ results: [], total: -1 });
  const [currentPage, setCurrentPage] = useState(1);
  const [filterType, setFilterType] = useState<FilterType>('scene');
  const { loadingState, updateLoadingState } = useLoading();
  const pageSize = 20; // Fixed page size as per requirement

  const normalizeFilter = (type: FilterType) => {
    switch (type) {
      case 'video':
        return { filters: { videoId } };
      case 'scene':
        return { filters: { videoId, sceneId } };
      case 'recent':
        return { filters: { isManual: true } };
      default:
        return { filters: {} };
    }
  };

  useEffect(() => {
    const init = async () => {
      updateLoadingState(LoadingState.Loading);
      try {
        const { filters } = normalizeFilter(filterType);
        const result = await assetStore.paginate({
          filters,
          sort: { field: 'createdAt', direction: -1 },
          page: currentPage,
          limit: pageSize,
        });

        setPaginationResult(result);
      } catch (e) {
        console.error(`failed getting assets`, e);
      } finally {
        updateLoadingState(LoadingState.Loaded);
      }
    };

    init();
  }, [currentPage, assetStore, filterType]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const isEmpty =
    paginationResult.total === 0 && loadingState === LoadingState.Loaded;

  return (
    <div className="my-assets">
      <span>Filter By: </span>
      <Select
        value={filterType}
        onChange={setFilterType}
        style={{ marginBottom: '15px', width: '200px' }}
      >
        <Select.Option value="all">All</Select.Option>
        <Select.Option value="video">Video</Select.Option>
        <Select.Option value="scene">Scene</Select.Option>
        <Select.Option value="recent">Recent Uploads</Select.Option>
      </Select>

      {isEmpty ? (
        <Empty style={{ marginTop: '25px' }} description={'No Assets'} />
      ) : null}
      {paginationResult.total === -1 &&
      loadingState === LoadingState.Loading ? (
        <Skeleton active={true} />
      ) : null}
      <SelectableAssetList
        gridTemplateColumns={'repeat(4, 1fr)'}
        currentAsset={selectedAsset as Asset}
        assets={paginationResult.results}
        onSelect={(asset) => {
          onSelect(asset as Asset);
        }}
      />
      <Pagination
        showTotal={(total) => {
          return `${total} Images`;
        }}
        align={'start'}
        current={currentPage}
        total={paginationResult.total}
        pageSize={pageSize}
        onChange={handlePageChange}
        style={{ marginTop: '15px' }}
      />
    </div>
  );
};

export default MyAssets;
