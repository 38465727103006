interface JobCallbacks {
  onInterval: () => Promise<void>;
  onStart?: () => void;
  onFinish?: () => void;
  onFail?: (error: Error) => void;
}

interface ActiveJob {
  jobId: string;
  callbacks: JobCallbacks;
  interval: number;
  maxAttempts: number;
  attemptCount: number;
}

class PollingManager {
  private static instance: PollingManager;
  private activeJobs: Map<string, ActiveJob> = new Map();
  private intervals: Map<string, NodeJS.Timeout> = new Map();

  static getInstance(): PollingManager {
    if (!PollingManager.instance) {
      PollingManager.instance = new PollingManager();
    }
    return PollingManager.instance;
  }

  startPolling(
    jobId: string,
    callbacks: JobCallbacks,
    interval: number,
    maxAttempts: number
  ) {
    this.stopPolling(jobId);

    const job: ActiveJob = {
      jobId,
      callbacks,
      interval,
      maxAttempts,
      attemptCount: 0,
    };

    this.activeJobs.set(jobId, job);

    const poll = async () => {
      try {
        await callbacks.onInterval();
        job.attemptCount++;

        if (job.attemptCount >= maxAttempts) {
          console.warn(`Polling stopped after ${maxAttempts} attempts`);
          this.stopPolling(jobId);
          callbacks.onFinish?.();
        }
      } catch (error) {
        console.error('Error polling job:', error);
        callbacks.onFail?.(
          error instanceof Error
            ? error
            : new Error('Unknown error during polling')
        );
      }
    };

    // Initial poll
    poll();

    // Start interval
    const intervalId = setInterval(poll, interval);
    this.intervals.set(jobId, intervalId);

    callbacks.onStart?.();
  }

  stopPolling(jobId: string) {
    const interval = this.intervals.get(jobId);
    if (interval) {
      clearInterval(interval);
      this.intervals.delete(jobId);
    }
    const job = this.activeJobs.get(jobId);
    if (job) {
      job.callbacks.onFinish?.();
      this.activeJobs.delete(jobId);
    }
  }

  isBeingPolled(jobId: string): boolean {
    return this.activeJobs.has(jobId);
  }

  cleanup() {
    this.activeJobs.forEach((job) => {
      job.callbacks.onFinish?.();
    });
    this.intervals.forEach((interval) => clearInterval(interval));
    this.intervals.clear();
    this.activeJobs.clear();
  }

  get activeJobIds() {
    return Array.from(this.activeJobs.values()).map((i) => i.jobId);
  }
}

export const pollingManager = PollingManager.getInstance();
