import React, { useRef, useState } from 'react';
import { Button, Dropdown, Space } from 'antd';
import {
  BuildOutlined,
  CheckOutlined,
  DeleteOutlined,
  EyeOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import { Video, VideoStatus } from '@monorepo/types';
import { LoadingState, useLoading } from '@monorepo/react-components';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../../../helpers/use-store';
import DeleteVideoModal, {
  DeleteVideoModalActions,
} from '../../../videos/delete-video-modal';
import { useNotification } from '../../../../hooks/use-notification';
import type { MenuProps } from 'antd';

interface VideoActionsProps {
  video: Video;
  refetch: () => void;
}

const VideoActions: React.FC<VideoActionsProps> = ({ video, refetch }) => {
  const navigate = useNavigate();
  const notificationApi = useNotification();
  const {
    dataStore: { seriesStore, videoStore },
  } = useStore();
  const deleteModalRef = useRef<DeleteVideoModalActions>(null);
  const {
    loadingState: publishLoading,
    updateLoadingState: updatePublishLoadingState,
  } = useLoading();
  const {
    loadingState: createAssetsLoading,
    updateLoadingState: updateCreateAssetsLoadingState,
  } = useLoading();
  const [currentVideoCreatingAssetsId, updateCurrentVideoCreatingAssetsId] =
    useState<string | null>(null);

  const handleCreateAssets = async () => {
    if (!video.seriesId) {
      return;
    }

    updateCreateAssetsLoadingState(LoadingState.Loading);
    updateCurrentVideoCreatingAssetsId(video._id);

    try {
      await seriesStore.generateSeriesVideoAssets(video.seriesId, video._id);
      await refetch();

      notificationApi.success({
        placement: 'top',
        message: 'Success',
        description:
          'Assets are being created right now, come back in few minutes.',
      });
    } catch (e) {
      notificationApi.error({
        placement: 'top',
        icon: '',
        message: 'Error',
        description:
          'Failed creating video assets, validate your series is active, for further help please contact support.',
      });

      console.error(`failed creating assets for video: ${video._id}`, e);
    } finally {
      updateCreateAssetsLoadingState(LoadingState.Loaded);
      updateCurrentVideoCreatingAssetsId(null);
    }
  };

  const handlePublish = async () => {
    try {
      updatePublishLoadingState(LoadingState.Loading);
      await videoStore.updateGeneralInfo(video._id, {
        status: VideoStatus.Published,
      });
      refetch();

      notificationApi.success({
        placement: 'top',
        message: 'Success',
        description: 'Video published successfully.',
      });
    } catch (error) {
      console.error('Failed to publish video:', error);
      notificationApi.error({
        placement: 'top',
        message: 'Error',
        description: 'Failed to publish video.',
      });
    } finally {
      updatePublishLoadingState(LoadingState.Loaded);
    }
  };

  const handleDelete = () => {
    deleteModalRef.current?.open(video._id);
  };

  const isInitial = video.status === VideoStatus.Initial;
  const isPublished = video.status === VideoStatus.Published;
  const isLoading = createAssetsLoading === LoadingState.Loading;
  const canPublish = video.videoUrl && !isPublished;
  const isActionLoading =
    createAssetsLoading === LoadingState.Loading ||
    publishLoading === LoadingState.Loading;

  const items: MenuProps['items'] = [];

  if (isInitial) {
    items.push({
      key: 'create-assets',
      icon: <BuildOutlined />,
      label: 'Create Assets',
      disabled: isLoading && video._id !== currentVideoCreatingAssetsId,
      onClick: handleCreateAssets,
    });
  }

  if (!isInitial) {
    items.push({
      key: 'publish',
      icon: <CheckOutlined />,
      label: 'Mark as Published',
      disabled: !canPublish,
      onClick: handlePublish,
    });
  }

  items.push({
    key: 'delete',
    icon: <DeleteOutlined />,
    label: 'Delete',
    danger: true,
    onClick: handleDelete,
  });

  return (
    <Space>
      <Button
        disabled={isInitial}
        icon={<EyeOutlined />}
        onClick={() => navigate(`/videos/${video._id}`)}
      >
        View
      </Button>

      <Dropdown menu={{ items }} trigger={['click']}>
        <Button loading={isActionLoading}>
          <Space>
            Actions
            <MoreOutlined />
          </Space>
        </Button>
      </Dropdown>
      <DeleteVideoModal ref={deleteModalRef} onSuccess={refetch} />
    </Space>
  );
};

export default VideoActions;
