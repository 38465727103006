import { FunctionComponent } from 'react';
import { FormField, FormFieldProps } from './form-field';
import { Select, SelectProps } from 'antd';

export type FormSelectTagsProps = Omit<FormFieldProps, 'render'> &
  Omit<SelectProps, 'value' | 'onChange'> & {
    options?: SelectProps['options'];
  };

export const FormSelectTags: FunctionComponent<FormSelectTagsProps> = (
  formFieldProps
) => {
  const fieldProps: SelectProps = {
    mode: 'tags',
    placeholder: formFieldProps.placeholder || 'Tags Mode',
    style: { width: '100%' },
    options: formFieldProps.options,
    showSearch: formFieldProps.showSearch,
    allowClear: formFieldProps.allowClear,
  };

  return (
    <FormField
      {...formFieldProps}
      render={({ field }) => {
        return (
          <Select
            className="form-field__select"
            size="middle"
            {...fieldProps}
            {...field}
            style={formFieldProps.style}
            onChange={(val) => field.onChange(val || [])}
          />
        );
      }}
    />
  );
};

export default FormSelectTags;
