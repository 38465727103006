import React, { FunctionComponent } from 'react';
import { Asset } from '@monorepo/types';
import { Button } from 'antd';
import AssetComponent from '../asset/asset';

export type PartialAsset = Pick<Asset, 'url' | 'type' | 'prompt'>;

interface Props {
  asset: PartialAsset;
  isSelected?: boolean;
  onSelect: (partialAsset: PartialAsset) => void;
  height?: string;
}

const SelectableAsset: FunctionComponent<Props> = ({
  asset,
  onSelect,
  height,
  isSelected = false,
}) => {
  const { type, url } = asset;

  return (
    <div
      key={type}
      style={{
        height,
        minHeight: height,
        padding: '5px',
        position: 'relative',
        borderRadius: 4,
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          minHeight: '374px',
        }}
      >
        <AssetComponent
          shouldLazy={true}
          width={'100%'}
          height={'100%'}
          asset={asset}
        />
      </div>

      <Button
        color={'primary'}
        disabled={isSelected}
        variant={'filled'}
        size={'middle'}
        onClick={() => onSelect(asset)}
        style={{ width: '100%', border: isSelected ? 'none' : '' }}
      >
        {isSelected ? 'Selected' : 'Select'}
      </Button>
    </div>
  );
};

export default SelectableAsset;
