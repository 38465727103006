import { EntityStore } from '@monorepo/client-common';
import { getEnv } from '../../../helpers/mobx-easy-wrapper';
import { NinjaJob } from '@monorepo/types';
import NinjaJobService from '../../../services/entities/ninja-job-service';

export default class NinjaJobStore extends EntityStore<
  NinjaJobService,
  NinjaJob
> {
  constructor() {
    const {
      apiFactory: { ninjaJobService },
    } = getEnv();

    super(ninjaJobService);
  }

  getStatus(jobId: string) {
    return this.service.getStatus(jobId);
  }

  getByVideo(videoId: string) {
    return this.service.getByVideo(videoId);
  }
}
