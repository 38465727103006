import React, { FunctionComponent, useCallback } from 'react';
import { message, Skeleton, Upload, UploadFile } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd/es/upload/interface';
import { useStore } from '../../../../helpers/use-store';
import { Asset } from '@monorepo/types';
import { LoadingState, useLoading } from '@monorepo/react-components';
import { debounce } from 'lodash';

const { Dragger } = Upload;

interface Props {
  onUpload: (asset: Asset | Asset[]) => void;
  sceneId?: string;
  videoId?: string;
}

const UploadAndDisplayImage: FunctionComponent<Props> = ({
  onUpload,
  sceneId,
  videoId,
}) => {
  const { loadingState, updateLoadingState } = useLoading();
  const {
    dataStore: { assetStore },
  } = useStore();

  const beforeUpload = (file: UploadFile) => {
    const isAllowedType =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/webp' ||
      file.type === 'video/mp4' ||
      file.type === 'video/quicktime';
    if (!isAllowedType) {
      message.error(
        'You can only upload JPG/PNG/WebP image or MP4/MOV video files!'
      );
    }
    const isLt50M = (file.size || 0) / 1024 / 1024 < 50;
    if (!isLt50M) {
      message.error('File must be smaller than 50MB!');
    }
    return isAllowedType && isLt50M;
  };

  const handleUpload = useCallback(
    debounce(async (info: any) => {
      //Parameters<UploadProps['onChange']>
      const { fileList } = info;
      console.log(fileList);

      if (fileList.length > 10) {
        message.error('You can only upload up to 10 files at once');
        return;
      }

      const validFiles = fileList.filter((file) => beforeUpload(file));

      if (validFiles.length === 0) {
        return;
      }

      try {
        updateLoadingState(LoadingState.Loading);

        const uploadPromises = validFiles.map((file) => {
          return assetStore.upload({
            file: file.originFileObj,
            sceneId,
            videoId,
          });
        });

        const assets = await Promise.all(uploadPromises);

        if (assets.length === 1) {
          onUpload(assets[0]);
          message.success(`${validFiles[0].name} file uploaded successfully`);
        } else {
          onUpload(assets);
          message.success(`${assets.length} files uploaded successfully`);
        }
      } catch (e) {
        message.error(
          validFiles.length === 1
            ? `${validFiles[0].name} file upload failed.`
            : 'Some files failed to upload.'
        );
      } finally {
        updateLoadingState(LoadingState.Loaded);
      }
    }, 300),
    [sceneId, videoId]
  );

  const isLoading = loadingState === LoadingState.Loading;

  return (
    <div>
      <Dragger
        name="asset"
        multiple={true}
        beforeUpload={() => false}
        onChange={handleUpload}
        style={{
          border: '1px dashed #d9d9d9',
          borderRadius: '2px',
          background: '#fafafa',
          height: '200px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {isLoading ? (
          <Skeleton active={true} />
        ) : (
          <>
            <PlusOutlined style={{ fontSize: '24px', color: '#1890ff' }} />
            <p style={{ marginTop: '8px', color: '#666' }}>
              Click or drag files to this area to upload (max 10)
            </p>
          </>
        )}
      </Dragger>
    </div>
  );
};

export default UploadAndDisplayImage;
