import {
  createContext,
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Asset,
  Scene,
  SubtitlesConfig,
  ThumbnailConfig,
  Video,
  Word,
} from '@monorepo/types';
import { LoadingState, useLoading } from '@monorepo/react-components';
import { useStore } from '../helpers/use-store';
import { VideoPart } from '../features/video/video-editor-types';
import { debounce, orderBy } from 'lodash';
import { PlayerRef } from '@remotion/player';
import { AssetSelectionModalActions } from '../features/video/assets/assets-modal';
import { useVideoJobs } from './use-video-jobs';
import { UseVideoJobsResult } from './ninja-polling.types';
import { imageBlobManager } from '../managers/image-blob-manager';

export enum VideoPlayerActionType {
  UpdateSubtitles = 'update-subtitles',
}

export interface VideoPlayerAction {
  type: VideoPlayerActionType;
  dto: any;
}

interface VideoContextType {
  init: () => Promise<void>;
  fetchAsset: (assetId: string) => Promise<Asset>;
  loadingState: LoadingState;
  requestLoadingState: LoadingState;
  renderVideo: () => Promise<void>;
  video: Video | undefined;
  scenes: Scene[];
  scenesMap: Map<string, Scene>;
  assets: Asset[];
  download: () => Promise<void>;
  fetchVideoOnly: () => Promise<void>;
  updateSubtitles: (subtitlesConfig: SubtitlesConfig) => Promise<void>;
  updateMetadata: (
    metadata: Pick<Video, 'title' | 'description' | 'hashtags'>
  ) => Promise<void>;
  updateBackgroundMusic: (
    musicId?: string,
    backgroundMusicVolume?: number
  ) => Promise<void>;
  updateVoiceover: (voiceover: { voiceoverId: string }) => Promise<void>;
  updateScene: (updateDto: {
    sceneId: string;
    videoId: string;
    updateDto: Partial<Scene>;
    frameToSeek: number;
    shouldUpdateState?: boolean;
  }) => Promise<void>;
  updateSceneDebounced: (updateDto: {
    sceneId: string;
    videoId: string;
    updateDto: Partial<Scene>;
    frameToSeek: number;
  }) => Promise<void>;
  fetchVideoAndAssets: () => Promise<void>;
  currentVideoPart: VideoPart;
  setCurrentVideoPart: (videoPart: VideoPart) => void;
  selectedScene: Scene | undefined;
  setSceneId: (sceneId: string) => void;
  getSceneStartFrame: (sceneId: string) => number;
  playerRef: MutableRefObject<PlayerRef | null>;
  modalRef: MutableRefObject<AssetSelectionModalActions | null>;
  movePlayerToFrame: (frame: number, shouldStart?: boolean) => Promise<void>;
  isPlayerReady: boolean;
  setPlayerReady: (flag: boolean) => void;
  getSceneByCurrentFrame: (frame: number) => Scene | null;
  openSceneEdit: (scene: Scene) => void;
  scrollToScene: (scene: Scene) => void;
  updateSceneWords: (dto: {
    videoId: string;
    sceneId: string;
    words: Word[];
  }) => Promise<void>;
  videoJobs: UseVideoJobsResult;
  getCurrentFrame: () => number;
  updateGeneralInfo: (dto: Partial<Video>) => void;
  updateThumbnail: (dto: Partial<ThumbnailConfig>) => Promise<void>;
}

export const VideoContext = createContext<VideoContextType | undefined>(
  undefined
);

export const useVideoContext = () => {
  const context = useContext(VideoContext);

  if (context === undefined) {
    throw new Error('useVideoContext must be used within a VideoProvider');
  }

  return context;
};

export const useVideo = (videoId: string) => {
  const modalRef = useRef<AssetSelectionModalActions>(null);
  const playerRef = useRef<PlayerRef>(null);
  const [isPlayerReady, setPlayerReady] = useState(false);
  const [video, setVideo] = useState<Video>();
  const [selectedSceneId, setSceneId] = useState<string>('');
  const [scenesMap, setScenes] = useState<Map<string, Scene>>(new Map());
  const [assets, setAssets] = useState<Asset[]>([]);
  const [currentVideoPart, setCurrentVideoPart] = useState<VideoPart>(
    VideoPart.Scenes
  );
  const { loadingState, updateLoadingState } = useLoading();
  const {
    loadingState: requestLoadingState,
    updateLoadingState: updateRequestLoadingState,
  } = useLoading(LoadingState.Loaded);
  const {
    dataStore: { videoStore, assetStore },
  } = useStore();

  const fetchVideoAndAssets = async () => {
    const [videoResult, scenesResult] = await Promise.all([
      videoStore.fetch(videoId),
      videoStore.getScenes(videoId),
    ]);

    const scenesMapResult = new Map(
      scenesResult.map((item) => [item._id, item])
    );

    const sceneAssetIds = scenesResult.map((scene) => scene.assetId);
    const assetIdsToFetch = sceneAssetIds.filter((assetId) => {
      if (!assetId) {
        return false;
      }

      const foundAsset = assets.find((asset) => asset._id === assetId);

      return !foundAsset;
    });

    const idsToFetch = assetIdsToFetch.map((assetId) => {
      return assetId;
    });

    const assetsResult = await assetStore.bulkGet(idsToFetch);

    const newSet = new Set([...assetsResult, ...assets]);

    setAssets(Array.from(newSet.values()));
    setVideo(videoResult);
    setScenes(scenesMapResult);
  };

  const debouncedFetchVideoAndAssets = useCallback(
    debounce(async () => {
      try {
        const [videoResult, scenesResult] = await Promise.all([
          videoStore.fetch(videoId),
          videoStore.getScenes(videoId),
        ]);

        const scenesMapResult = new Map(
          scenesResult.map((item) => [item._id, item])
        );

        const sceneAssetIds = scenesResult.map((scene) => scene.assetId);
        const assetIdsToFetch = sceneAssetIds.filter((assetId) => {
          if (!assetId) {
            return false;
          }

          const foundAsset = assets.find((asset) => asset._id === assetId);
          return !foundAsset;
        });

        const idsToFetch = assetIdsToFetch.map((assetId) => {
          return assetId;
        });

        const assetsResult = await assetStore.bulkGet(idsToFetch);
        const newSet = new Set([...assetsResult, ...assets]);

        setAssets(Array.from(newSet.values()));
        setVideo(videoResult);
        setScenes(scenesMapResult);
      } catch (error) {
        console.error('Error fetching video and assets:', error);
        // Handle error appropriately
      }
    }, 1000), // 500ms delay, adjust as needed
    [videoId, assets] // Dependencies array
  );

  // TODO: add debounce
  const videoJobs = useVideoJobs(videoId, {
    refetchAssets: debouncedFetchVideoAndAssets,
  });

  useEffect(() => {
    videoJobs.startPolling();

    return () => {
      imageBlobManager.clearCache();
      videoJobs.stopPolling();
    };
  }, []);

  const scenes: Scene[] = Array.from(scenesMap.values());

  const fetchVideoOnly = async () => {
    const videoResult = await videoStore.fetch(videoId);

    setVideo(videoResult);
  };

  const fetchAsset = async (assetId: string): Promise<Asset> => {
    const asset = await assetStore.fetch(assetId);

    setAssets([...assets, asset]);

    return asset;
  };

  const init = async () => {
    if (!videoId) {
      return;
    }
    updateLoadingState(LoadingState.Loading);

    try {
      await fetchVideoAndAssets();
    } catch (e) {
      console.error(`failed getting video`, e);
    } finally {
      updateLoadingState(LoadingState.Loaded);
    }
  };

  const updateThumbnail = async (dto: Partial<ThumbnailConfig>) => {
    if (!video) {
      return;
    }

    const prevVideo = video;
    try {
      // updateRequestLoadingState(LoadingState.Loading);

      setVideo((prev) => ({
        ...(prev as Video),
        thumbnailConfig: {
          ...(prev as Video).thumbnailConfig,
          ...dto,
        },
      }));

      await videoStore.updateThumbnail(video._id, dto);
    } catch (e) {
      console.error(`failed updating thumbnail`, e);
      setVideo(prevVideo);
    } finally {
      // updateRequestLoadingState(LoadingState.Loaded);
    }
  };

  const updateGeneralInfo = useCallback(
    debounce(async (dto: Partial<Video>) => {
      if (!video) {
        return;
      }

      let prevVideo = video;
      try {
        updateRequestLoadingState(LoadingState.Loading);

        setVideo((prev) => ({
          ...(prev as Video),
          playbackRate: dto.playbackRate || 1,
        }));

        await videoStore.updateGeneralInfo(video._id, dto);

        prevVideo = {
          ...prevVideo,
          ...dto,
        };
      } catch (e) {
        console.error(`failed updating`, e);
      } finally {
        updateRequestLoadingState(LoadingState.Loaded);
        setVideo(prevVideo);
      }
    }, 500),
    [video] // only video as dependency since we need fresh reference when it changes
  );

  const renderVideo = async () => {
    await videoStore.render(videoId);

    // setVideo((prevVideo) => {
    //   return { ...(prevVideo as Video), status: VideoStatus.InProgress };
    // });
  };

  const download = async () => {
    // setIsDownloading(true);
    try {
      const response = await videoStore.download(videoId);

      const url = window.URL.createObjectURL(new Blob([response]));

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'download.mp4');
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Download failed:', error);
      alert('Failed to download the file. Please try again.');
    } finally {
      // setIsDownloading(false);
    }
  };

  const movePlayerToFrame = async (frame: number, shouldStart = true) => {
    playerRef?.current?.pause();
    playerRef.current?.seekTo(frame);
    // TODO: add player loading
    await Promise.resolve((resolve) => {
      setTimeout(() => {
        resolve();
      }, 1000);
    });

    if (shouldStart) {
      playerRef?.current?.play();
    }
  };

  const updateSceneWords = async (dto: {
    videoId: string;
    sceneId: string;
    words: Word[];
  }) => {
    const sceneResult = await videoStore.updateSceneWords(
      dto.sceneId,
      dto.videoId,
      dto.words
    );

    setScenes((prevScenes) => {
      return new Map(prevScenes.set(sceneResult._id, sceneResult as Scene));
    });
  };

  const updateScene = async ({
    sceneId,
    videoId,
    updateDto,
    frameToSeek,
    shouldUpdateState = false,
  }: {
    sceneId: string;
    videoId: string;
    updateDto: Partial<Scene>;
    frameToSeek: number;
    shouldUpdateState?: boolean;
  }) => {
    try {
      updateRequestLoadingState(LoadingState.Loading);

      const sceneResult = await videoStore.updateScene(
        sceneId,
        videoId,
        updateDto
      );

      if (shouldUpdateState) {
        setScenes((prevScenes) => {
          return new Map(prevScenes.set(sceneId, sceneResult as Scene));
        });
      }

      if (frameToSeek !== -1) {
        movePlayerToFrame(frameToSeek);
      }
    } catch (e) {
      console.error(`failed updating scene: ${sceneId}: ${e}`);
      throw e;
    } finally {
      updateRequestLoadingState(LoadingState.Loaded);
    }
  };

  const debouncedUpdateScene = useMemo(() => {
    return debounce(updateScene, 1000);
  }, [isPlayerReady]);

  const updateSceneDebounced = async ({
    sceneId,
    videoId,
    updateDto,
    frameToSeek,
  }: {
    sceneId: string;
    videoId: string;
    updateDto: Partial<Scene>;
    frameToSeek: number;
  }) => {
    let currentScene = scenesMap.get(sceneId);

    debouncedUpdateScene({ sceneId, videoId, updateDto, frameToSeek: -1 });

    if (currentScene) {
      currentScene = {
        ...currentScene,
        ...updateDto,
      };

      setScenes((prevScenes) => {
        return new Map(prevScenes.set(sceneId, currentScene as Scene));
      });
    }

    if (frameToSeek !== -1) {
      movePlayerToFrame(frameToSeek);
    }
  };

  const _updateSubtitlesRequest = async (subtitlesConfig: SubtitlesConfig) => {
    if (!video) {
      return;
    }

    try {
      updateRequestLoadingState(LoadingState.Loading);

      await videoStore.updateSubtitles(video._id.toString(), subtitlesConfig);
    } catch (e) {
      console.error(`failed`, e);
    } finally {
      updateRequestLoadingState(LoadingState.Loaded);
    }
  };

  const debouncedUpdateSubtitles = useMemo(() => {
    return debounce(_updateSubtitlesRequest, 1000);
  }, [isPlayerReady]);

  const updateSubtitles = async (subtitlesConfig: SubtitlesConfig) => {
    if (!video) {
      return;
    }

    debouncedUpdateSubtitles(subtitlesConfig);

    setVideo((prevVideo) => {
      return {
        ...(prevVideo as Video),
        subtitlesConfig,
      };
    });
  };

  const _updateMetadataRequest = async (
    metadata: Pick<Video, 'title' | 'description' | 'hashtags'>
  ) => {
    if (!video) {
      return;
    }

    try {
      updateRequestLoadingState(LoadingState.Loading);

      await videoStore.updateMetadata(video._id.toString(), metadata);
    } catch (e) {
      console.error(`failed`, e);
    } finally {
      updateRequestLoadingState(LoadingState.Loaded);
    }
  };

  const debouncedUpdateMetadata = useMemo(() => {
    return debounce(_updateMetadataRequest, 1000);
  }, [isPlayerReady]);

  const updateMetadata = async (
    metadata: Pick<Video, 'title' | 'description' | 'hashtags'>
  ) => {
    if (!video) {
      return;
    }

    debouncedUpdateMetadata(metadata);

    setVideo((prevVideo) => {
      return {
        ...(prevVideo as Video),
        ...metadata,
      };
    });
  };

  const updateVoiceover = async (voiceover: { voiceoverId: string }) => {
    if (!video) {
      return;
    }

    await videoStore.updateVoiceover(video._id.toString(), voiceover);
    await fetchVideoAndAssets();

    setVideo((prevVideo) => {
      return {
        ...(prevVideo as Video),
        voiceoverId: voiceover.voiceoverId,
      };
    });

    await movePlayerToFrame(0, false);
  };

  const updateBackgroundMusic = async (
    musicId?: string,
    backgroundMusicVolume?: number
  ) => {
    if (!video) {
      return;
    }

    await videoStore.updateBackgroundMusic(
      video._id.toString(),
      musicId,
      backgroundMusicVolume
    );
    await fetchVideoAndAssets();

    setVideo((prevVideo) => {
      return {
        ...(prevVideo as Video),
        backgroundMusicId: musicId || '',
        backgroundMusicVolume,
        backgroundMusicUrl: '',
      };
    });
  };

  const getSceneStartFrame = (sceneId: string) => {
    const scene = scenesMap.get(sceneId);

    if (!scene) {
      return 0;
    }

    const orderScenes = orderBy(scenes, 'index');

    // Find current scene index in ordered array
    const currentSceneIndex = orderScenes.findIndex((s) => s._id === sceneId);

    // Sum up durations of all scenes before this one
    const time = orderScenes
      .slice(0, currentSceneIndex)
      .reduce((sum, scene) => sum + scene.audioDuration, 0);
    // 15 buffer
    return time * 30;
  };

  const getCurrentFrame = () => {
    return playerRef.current?.getCurrentFrame() || 0;
  };

  const getSceneByCurrentFrame = (frame: number) => {
    let accumulatedFrames = 0;

    for (const scene of scenes) {
      const sceneDurationInFrames = scene.audioDuration * 30;
      const nextAccumulatedFrames = accumulatedFrames + sceneDurationInFrames;

      if (frame >= accumulatedFrames && frame < nextAccumulatedFrames) {
        return scene;
      }

      accumulatedFrames = nextAccumulatedFrames;
    }

    return null;
  };

  const openSceneEdit = (scene: Scene) => {
    setSceneId(scene._id);
    setCurrentVideoPart(VideoPart.EditScene);
  };

  const scrollToScene = (scene: Scene) => {
    if (currentVideoPart !== VideoPart.Scenes) {
      return;
    }

    const scenesContainer = document.querySelector('.video-container');

    if (!scenesContainer) {
      return;
    }

    const targetScene = scenesContainer.querySelector(
      `.scene-number-${scene.index + 1}`
    );

    if (!targetScene) {
      return;
    }

    const containerRect = scenesContainer.getBoundingClientRect();
    const targetRect = targetScene.getBoundingClientRect();

    // Check if any part of the element is outside the container's view
    const isPartiallyOutOfView =
      targetRect.bottom > containerRect.bottom ||
      targetRect.top < containerRect.top;

    // Scroll if element is even partially out of view
    if (isPartiallyOutOfView) {
      //@ts-expect-error zubi
      const topPos = targetScene.offsetTop;
      scenesContainer.scrollTo({
        top: topPos,
        behavior: 'smooth',
      });
    }
  };

  const selectedScene = scenesMap.get(selectedSceneId);

  return {
    init,
    loadingState,
    renderVideo,
    updateSubtitles,
    video,
    scenes,
    assets,
    download,
    updateScene,
    updateSceneDebounced,
    fetchVideoAndAssets,
    updateVoiceover,
    updateBackgroundMusic,
    currentVideoPart,
    setCurrentVideoPart,
    selectedScene,
    setSceneId,
    requestLoadingState,
    scenesMap,
    fetchAsset,
    getSceneStartFrame,
    playerRef,
    movePlayerToFrame,
    isPlayerReady,
    setPlayerReady,
    updateMetadata,
    fetchVideoOnly,
    updateSceneWords,
    getSceneByCurrentFrame,
    openSceneEdit,
    modalRef,
    videoJobs,
    scrollToScene,
    getCurrentFrame,
    updateGeneralInfo,
    updateThumbnail,
  };
};
