import React, { FunctionComponent, useEffect, useState } from 'react';
import { Select, Space, Typography } from 'antd';
import {
  PrivacyStatus,
  YoutubeCategories,
  YoutubeCategoryLabels,
  YouTubeMetadata,
} from '@monorepo/types';

const { Text, Title } = Typography;

interface Props {
  metadata: YouTubeMetadata;
  onUpdate: (metadata: Partial<YouTubeMetadata>) => void;
}

const YoutubeForm: FunctionComponent<Props> = ({ metadata, onUpdate }) => {
  const [localMetadata, setLocalMetadata] = useState<YouTubeMetadata>(metadata);

  const handlePrivacyChange = (value: PrivacyStatus) => {
    setLocalMetadata({ ...localMetadata, privacyStatus: value });
    onUpdate({ privacyStatus: value });
  };

  const handleCategoryUpdate = (value: YoutubeCategories) => {
    setLocalMetadata({ ...localMetadata, category: value });
    onUpdate({ category: value });
  };

  useEffect(() => {
    setLocalMetadata(metadata);
  }, [metadata]);

  const privacyLabels: Record<PrivacyStatus, string> = {
    [PrivacyStatus.Public]: 'Public - Anyone can search for and view',
    [PrivacyStatus.Private]: 'Private - Only you can view',
    [PrivacyStatus.Unlisted]: 'Unlisted - Anyone with the link can view',
  };

  return (
    <div>
      <Space direction="vertical" style={{ width: '100%' }} size="middle">
        <div>
          <Title level={5} style={{ margin: '0px' }}>
            Privacy Status
          </Title>
          <Select
            placeholder="Select privacy setting"
            style={{ width: '100%' }}
            value={localMetadata.privacyStatus}
            onChange={handlePrivacyChange}
          >
            {Object.values(PrivacyStatus).map((privacyStatus) => (
              <Select.Option key={privacyStatus} value={privacyStatus}>
                {privacyLabels[privacyStatus]}
              </Select.Option>
            ))}
          </Select>
          <Title level={5} style={{ margin: '0px' }}>
            Category{' '}
            <Typography.Text type={'secondary'}>
              (Choosing relevant category helps getting more views)
            </Typography.Text>
          </Title>
          <Select
            placeholder="Select Category"
            style={{ width: '100%' }}
            value={localMetadata.category}
            onChange={handleCategoryUpdate}
          >
            {Object.values(YoutubeCategories).map((category) => (
              <Select.Option key={category} value={category}>
                {YoutubeCategoryLabels[category]}
              </Select.Option>
            ))}
          </Select>
        </div>
      </Space>
    </div>
  );
};

export default YoutubeForm;
