import { Typography } from 'antd';
import { VoiceoverList } from '../voiceover-list/voiceover-list';
import { FunctionComponent } from 'react';
import { Languages, Voiceover } from '@monorepo/types';

// FormVoiceover component for use with react-hook-form
export const FormVoiceover: FunctionComponent<{
  form: any;
  gridColumns?: number;
  language: Languages;
  voiceovers: Voiceover[];
  controllerProps: any;
  text?: string;
}> = ({ form, voiceovers, gridColumns, controllerProps, text, language }) => {
  return (
    <div>
      {text && <Typography.Text>{text}</Typography.Text>}
      <VoiceoverList
        language={language}
        gridColumns={gridColumns}
        shouldDisplayFirst={false}
        voiceovers={voiceovers}
        voiceoverId={form.watch(controllerProps.name)}
        onVoiceoverSelect={(voiceoverId) => {
          form.setValue(controllerProps.name, voiceoverId);
        }}
      />
    </div>
  );
};
