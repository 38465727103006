import SelectableAsset, { PartialAsset } from './selectable-asset';
import React, { FunctionComponent } from 'react';
import { Asset } from '@monorepo/types';

interface Props {
  currentAsset: Asset | PartialAsset | null;
  assets: PartialAsset[] | Asset[];
  onSelect: (asset: PartialAsset | Asset) => void;
  height?: string;
  gridTemplateColumns?: string;
}

const SelectableAssetList: FunctionComponent<Props> = ({
  currentAsset,
  assets,
  gridTemplateColumns,
  onSelect,
  height,
}) => {
  return (
    <div
      className="scroller"
      style={{
        maxHeight: '550px',
        overflowY: 'auto',
        display: 'grid',
        textAlign: 'center',
        gridTemplateColumns: gridTemplateColumns || 'repeat(3, 1fr)',
        gap: 16,
      }}
    >
      {assets.map((asset, index) => {
        return (
          <SelectableAsset
            height={height}
            isSelected={asset.url === currentAsset?.url}
            onSelect={onSelect}
            key={asset._id ? asset._id : asset.url}
            asset={asset}
          />
        );
      })}
    </div>
  );
};

export default SelectableAssetList;
