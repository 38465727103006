export enum MotionType {
  // Zoom Motions
  NO_MOTION = 'NO_MOTION',
  GENTLE_RANDOM_MOTION = 'GENTLE_RANDOM_MOTION',
  GENTLE_ZOOM_FROM_TOP = 'GENTLE_ZOOM_FROM_TOP',
  ZOOM_INTO_TOP_LEFT = 'ZOOM_INTO_TOP_LEFT',
  ZOOM_OUT_FROM_CENTER = 'ZOOM_OUT_FROM_CENTER',
  DIAGONAL_MOVE_WITH_ZOOM_IN = 'DIAGONAL_MOVE_WITH_ZOOM_IN',
  ZOOM_INTO_BOTTOM_RIGHT = 'ZOOM_INTO_BOTTOM_RIGHT',
  ZOOM_IN_WHILE_PANNING_RIGHT = 'ZOOM_IN_WHILE_PANNING_RIGHT',
  ZOOM_OUT_WHILE_PANNING_UP = 'ZOOM_OUT_WHILE_PANNING_UP',
  SLOW_ZOOM_IN_AND_PAN_LEFT = 'SLOW_ZOOM_IN_AND_PAN_LEFT',
  FAST_ZOOM_OUT_WITH_SLIGHT_ROTATE = 'FAST_ZOOM_OUT_WITH_SLIGHT_ROTATE',
  HEARTBEAT_ZOOM = 'HEARTBEAT_ZOOM',
  ZOOM_IN_AND_ROTATE = 'ZOOM_IN_AND_ROTATE',
  PULSE_ZOOM_IN_AND_OUT = 'PULSE_ZOOM_IN_AND_OUT',

  // Pan Motions
  PAN_LEFT_TO_RIGHT = 'PAN_LEFT_TO_RIGHT',
  PAN_RIGHT_TO_LEFT = 'PAN_RIGHT_TO_LEFT',
  PAN_TOP_TO_BOTTOM = 'PAN_TOP_TO_BOTTOM',
  PAN_BOTTOM_TO_TOP = 'PAN_BOTTOM_TO_TOP',
  DIAGONAL_PAN_TOP_LEFT_TO_BOTTOM_RIGHT = 'DIAGONAL_PAN_TOP_LEFT_TO_BOTTOM_RIGHT',
  DIAGONAL_PAN_BOTTOM_RIGHT_TO_TOP_LEFT = 'DIAGONAL_PAN_BOTTOM_RIGHT_TO_TOP_LEFT',

  // Circular Motions
  CIRCULAR_CLOCKWISE = 'CIRCULAR_CLOCKWISE',
  CIRCULAR_COUNTERCLOCKWISE = 'CIRCULAR_COUNTERCLOCKWISE',
  SPIRAL_TOWARD_CENTER = 'SPIRAL_TOWARD_CENTER',
  SPIRAL_OUT_FROM_CENTER = 'SPIRAL_OUT_FROM_CENTER',
  FIGURE_EIGHT = 'FIGURE_EIGHT',

  // Random Motions
  RANDOM_MOTION_PATHS = 'RANDOM_MOTION_PATHS',
  BOUNCE_EFFECT = 'BOUNCE_EFFECT',
  SHAKE_EFFECT = 'SHAKE_EFFECT',
  ZIGZAG_MOTION = 'ZIGZAG_MOTION',
  WOBBLE_EFFECT = 'WOBBLE_EFFECT',
  SWAY_LEFT_AND_RIGHT = 'SWAY_LEFT_AND_RIGHT',
  SWAY_UP_AND_DOWN = 'SWAY_UP_AND_DOWN',
  RANDOM_JITTER = 'RANDOM_JITTER',
}

export const defaultMotion = MotionType.GENTLE_RANDOM_MOTION;
