import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Row,
  Skeleton,
  Space,
  Switch,
  Typography,
} from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import Faq from './faq';
import { useStore } from '../../../helpers/use-store';
import { BillingCycle, Plan, SubscriptionStatusName } from '@monorepo/types';
import { orderBy } from 'lodash';
import { observer } from 'mobx-react-lite';
import SubscriptionInfo from './subscription-info';
import { LoadingState, useLoading } from '@monorepo/react-components';
import { checkoutStyles } from './biiling-custom-css';
import PlanChangeModal, {
  PlanChangeModalActions,
} from './billing-modals/plan-change-modal';
import CouponDisplay from './coupon';
import { Helmet } from 'react-helmet';

const reactEnv = import.meta.env.VITE_APP_ENV || 'development';

const { Title, Text } = Typography;

export const BillingPage = () => {
  const {
    dataStore: { userStore, planStore },
  } = useStore();
  const [billingCycle, setBillingCycle] = useState<BillingCycle>(
    BillingCycle.Monthly
  );
  const { loadingState, updateLoadingState } = useLoading();
  const planChangeModalRef = useRef<PlanChangeModalActions>(null);

  useEffect(() => {
    const init = async () => {
      try {
        updateLoadingState(LoadingState.Loading);
        planStore.fetchAll();

        document.body.scrollIntoView({
          behavior: 'smooth',
        });
      } catch (e) {
        console.error(`failed loading plans`, e);
      } finally {
        updateLoadingState(LoadingState.Loaded);
      }
    };

    init();
  }, []);

  const plans = orderBy(
    planStore.items.filter((item) => item.billingCycle === billingCycle),
    'price',
    'asc'
  );

  const showCheckout = useCallback(
    (event: React.MouseEvent, plan: Plan) => {
      event.preventDefault();

      const productId = plan.externalId;
      let checkoutUrl = `https://store.payproglobal.com/checkout?billing-email=${userStore.currentUser.email}&products[1][id]=${productId}&page-template=19332&exfo=742&coupon-code-to-add=JAN_25`;

      // Add firstName if it exists
      if (userStore.currentUser.firstName) {
        checkoutUrl += `&billing-first-name=${userStore.currentUser.firstName}`;
      }

      // Add lastName if it exists
      if (userStore.currentUser.lastName) {
        checkoutUrl += `&billing-last-name=${userStore.currentUser.lastName}`;
      }

      if (reactEnv !== 'production') {
        checkoutUrl += `&use-test-mode=true&secret-key=WqhzzzE5k5`;
      }

      const body = document.getElementsByTagName('body')[0];

      const modalHtml = `
      <div class="ppg-checkout-modal ppg-show" id="ppg-checkout-modal">
        <div class="ppg-btn-close" id="ppg-btn-close">
          <img src="data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTIgNTEyIj48cGF0aCBkPSJNNDQzLjYgMzg3LjEgMzEyLjQgMjU1LjRsMTMxLjUtMTMwYzUuNC01LjQgNS40LTE0LjIgMC0xOS42bC0zNy40LTM3LjZjLTIuNi0yLjYtNi4xLTQtOS44LTQtMy43IDAtNy4yIDEuNS05LjggNEwyNTYgMTk3LjggMTI0LjkgNjguM2MtMi42LTIuNi02LjEtNC05LjgtNC0zLjcgMC03LjIgMS41LTkuOCA0TDY4IDEwNS45Yy01LjQgNS40LTUuNCAxNC4yIDAgMTkuNmwxMzEuNSAxMzBMNjguNCAzODcuMWMtMi42IDIuNi00LjEgNi4xLTQuMSA5LjggMCAzLjcgMS40IDcuMiA0LjEgOS44bDM3LjQgMzcuNmMyLjcgMi43IDYuMiA0LjEgOS44IDQuMSAzLjUgMCA3LjEtMS4zIDkuOC00LjFMMjU2IDMxMy4xbDEzMC43IDEzMS4xYzIuNyAyLjcgNi4yIDQuMSA5LjggNC4xIDMuNSAwIDcuMS0xLjMgOS44LTQuMWwzNy40LTM3LjZjMi42LTIuNiA0LjEtNi4xIDQuMS05LjgtLjEtMy42LTEuNi03LjEtNC4yLTkuN3oiIGZpbGw9IiNmZmZmZmYiIGNsYXNzPSJmaWxsLTAwMDAwMCI+PC9wYXRoPjwvc3ZnPg==" >
        </div>
        <div class="ppg-loader"></div>
        <iframe class="ppg-iframe" id="ppg-iframe" src="${checkoutUrl}" frameborder="0"></iframe>
      </div>
    `;

      body.insertAdjacentHTML('beforeend', modalHtml);

      const iframe = document.getElementById('ppg-iframe');
      const closeBtn = document.getElementById('ppg-btn-close');

      if (iframe) {
        iframe.onload = function () {
          const loader = document.querySelector('.ppg-loader');
          if (loader) loader.remove();
          if (closeBtn) closeBtn.classList.add('ppg-show');
        };
      }

      if (closeBtn) {
        //@ts-expect-error zain ab ain
        closeBtn.onclick = hideCheckout;
      }
    },
    [billingCycle]
  );

  const hideCheckout = useCallback((event?: React.MouseEvent) => {
    if (event) event.preventDefault();
    const modal = document.getElementById('ppg-checkout-modal');
    if (modal) modal.remove();

    userStore.getLatestUser();
  }, []);

  useEffect(() => {
    const styleElement = document.createElement('style');
    styleElement.innerHTML = checkoutStyles;
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  if (loadingState === LoadingState.Loading) {
    return <Skeleton active={true} />;
  }

  // const tryoutPlan = planStore.items.find(
  //   (item) => item.name.toLowerCase() === 'tryout'
  // );

  return (
    <div className={'billing-container'}>
      <Helmet>
        <title>Billing | ShortsNinja</title>
        <meta
          name="description"
          content="Manage your ShortsNinja subscription and billing details. Update your AI video automation plan."
        />
        <meta
          name="keywords"
          content="billing settings, subscription, ShortsNinja, faceless videos, ai video automation"
        />
      </Helmet>
      <Row gutter={[15, 15]} style={{ marginBottom: '15px' }}>
        {/*<Col lg={tryoutPlan && tryoutPlan.isActive ? 8 : 24} md={24}>*/}
        {/*  {tryoutPlan && tryoutPlan.isActive ? (*/}
        {/*    <Card*/}
        {/*      hoverable*/}
        {/*      style={{*/}
        {/*        background: '#f6ffed',*/}
        {/*        borderColor: '#52c41a',*/}
        {/*        width: '100%',*/}
        {/*      }}*/}
        {/*      bodyStyle={{ height: '100%' }}*/}
        {/*      bordered*/}
        {/*    >*/}
        {/*      <div*/}
        {/*        style={{*/}
        {/*          display: 'flex',*/}
        {/*          justifyContent: 'space-between',*/}
        {/*          alignItems: 'flex-start',*/}
        {/*          marginBottom: '12px',*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        <div>*/}
        {/*          <Title level={4} style={{ margin: 0 }}>*/}
        {/*            Try it out! 🚀*/}
        {/*          </Title>*/}
        {/*          <Text>1 video • 15 Credits • No Series</Text>*/}
        {/*        </div>*/}
        {/*        <Text style={{ color: '#52c41a' }}>*/}
        {/*          <CheckOutlined style={{ marginRight: 4 }} />*/}
        {/*          All features included*/}
        {/*        </Text>*/}
        {/*      </div>*/}

        {/*      <div style={{ textAlign: 'center', marginBottom: '16px' }}>*/}
        {/*        <Title level={3} style={{ margin: 0 }}>*/}
        {/*          $3.99*/}
        {/*        </Title>*/}
        {/*        <Text type="secondary" style={{ fontSize: '12px' }}>*/}
        {/*          one-time payment*/}
        {/*        </Text>*/}
        {/*      </div>*/}

        {/*      <Button*/}
        {/*        disabled={userStore.isSubscriptionActive}*/}
        {/*        type="primary"*/}
        {/*        block*/}
        {/*        onClick={(e) => showCheckout(e, tryoutPlan)}*/}
        {/*        style={{*/}
        {/*          background: '#52c41a',*/}
        {/*          borderColor: '#52c41a',*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        Get Started! ⚡️*/}
        {/*      </Button>*/}
        {/*    </Card>*/}
        {/*  ) : null}*/}
        {/*</Col>*/}
      </Row>
      <Row gutter={[25, 25]} style={{ marginBottom: '25px' }}>
        <Col md={24} lg={12}>
          <CouponDisplay />
        </Col>
        <Col md={24} lg={12}>
          <SubscriptionInfo />
        </Col>
      </Row>
      <div style={{ textAlign: 'center', marginBottom: 32 }}>
        <Title style={{ margin: 0 }} level={2}>
          Choose Your Plan
        </Title>
        <Space align="center">
          <Text>Monthly</Text>
          <Switch
            checked={billingCycle === BillingCycle.Annual}
            onChange={(value: boolean) => {
              if (value) {
                setBillingCycle(BillingCycle.Annual);
              } else {
                setBillingCycle(BillingCycle.Monthly);
              }
            }}
          />
          <Text>Annually</Text>
        </Space>
      </div>
      <Row gutter={[24, 24]}>
        {plans.map((plan) => {
          const isUserHasActivePlan =
            userStore.userSubscription.status === SubscriptionStatusName.Active;
          const isActivePlan =
            isUserHasActivePlan &&
            userStore.userSubscription.planId === plan._id;
          const buttonText = isUserHasActivePlan
            ? `Change To ${plan.price} $`
            : 'Buy Now!';

          const handlePayment = (e) => {
            if (isUserHasActivePlan) {
              planChangeModalRef.current?.open(plan._id);
              return;
            }
            if (
              userStore.userSubscription.status ===
              SubscriptionStatusName.Suspended
            ) {
              // TODO: open warning of suspended
              // return;
            }

            showCheckout(e, plan);
          };

          return (
            <Col xs={24} md={12} lg={6} key={plan.name}>
              <Card
                hoverable
                bodyStyle={{
                  padding: '5px 0px',
                }}
                style={{
                  height: '100%',
                  background: plan.name === 'Creator' ? '#f0f0ff' : 'white',
                }}
                bordered
              >
                <div style={{ padding: '0 15px' }}>
                  <Title style={{ margin: 0 }} level={3}>
                    {plan.name}
                  </Title>
                  <Text>{plan.videosPerMonth} videos per month</Text>

                  <div style={{ margin: '10px 0' }}>
                    <Title level={2} style={{ marginBottom: 0 }}>
                      {plan.originalPrice &&
                        plan.originalPrice !== plan.price && (
                          <span
                            style={{
                              textDecoration: 'line-through',
                              fontSize: '16px',
                              color: '#666',
                              marginRight: '8px',
                            }}
                          >
                            ${plan.originalPrice}
                          </span>
                        )}
                      ${plan.price}
                      <Text style={{ fontSize: 14, fontWeight: 'normal' }}>
                        {billingCycle === BillingCycle.OneTime
                          ? ''
                          : billingCycle === BillingCycle.Annual
                          ? '/year'
                          : '/month'}
                      </Text>
                    </Title>
                  </div>

                  <div style={{ marginBottom: 24 }}>
                    <div style={{ marginBottom: 8 }}>
                      {plan.credits} Credits
                    </div>
                    <div style={{ marginBottom: 8 }}>
                      {plan.series === 0 ? 'No' : plan.series} Series
                    </div>
                    {plan.features.map((feature, index) => (
                      <div
                        key={index}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: 8,
                        }}
                      >
                        <CheckOutlined
                          style={{ marginRight: 8, color: '#52c41a' }}
                        />
                        <Text>{feature}</Text>
                      </div>
                    ))}
                  </div>
                </div>
                <div style={{ padding: '0 15px' }}>
                  <Button
                    disabled={isActivePlan}
                    type="primary"
                    block
                    onClick={handlePayment}
                    style={
                      plan.name === 'Creator' ? { background: '#722ed1' } : {}
                    }
                  >
                    {buttonText}
                  </Button>
                </div>
              </Card>
            </Col>
          );
        })}
      </Row>
      <Faq />
      <PlanChangeModal ref={planChangeModalRef} />
    </div>
  );
};

export default observer(BillingPage);
