import {
  Button,
  Card,
  Col,
  Empty,
  Image,
  Row,
  Skeleton,
  Typography,
} from 'antd';
import { Asset, AssetType } from '@monorepo/types';
import React, { FunctionComponent } from 'react';
import { PartialAsset } from '../../../../components/selectable-asset/selectable-asset';
import AssetComponent from '../../../../components/asset/asset';

const { Title, Paragraph } = Typography;

interface Props {
  asset?: Asset | PartialAsset;
  originalAssetId?: string;
  revertToOriginalAsset: () => void;
}

const CurrentAsset: FunctionComponent<Props> = ({
  asset,
  originalAssetId,
  revertToOriginalAsset,
}) => {
  if (!asset) {
    return <Empty style={{ marginTop: '25px' }} description={'No Asset Yet'} />;
  }

  return (
    <Card key={asset.url} style={{ width: '100%', marginBottom: 16 }}>
      <Row gutter={16} align="top">
        <Col span={8} style={{ minHeight: '520px' }}>
          <AssetComponent
            width={'100%'}
            height={'100%'}
            shouldLazy={false}
            asset={asset}
          />
        </Col>
        <Col span={16}>
          <Title style={{ margin: 0 }} level={5}>
            Prompt
          </Title>
          <Paragraph ellipsis={{ rows: 20, expandable: true, symbol: 'more' }}>
            {asset.prompt ? asset.prompt : 'No prompt available'}
          </Paragraph>
          {originalAssetId &&
          originalAssetId &&
          (asset as Asset)?._id !== originalAssetId ? (
            <Button onClick={revertToOriginalAsset}>
              Revert to original asset.
            </Button>
          ) : null}
        </Col>
      </Row>
    </Card>
  );
};

export default CurrentAsset;
