import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {
  Button,
  Col,
  Input,
  Modal,
  Row,
  Select,
  Slider,
  Space,
  Typography,
} from 'antd';
import { UseFormReturn } from 'react-hook-form';
import { ClockCircleOutlined, GlobalOutlined } from '@ant-design/icons';
import { LoadingState, useLoading } from '@monorepo/react-components';
import { useStore } from '../../../helpers/use-store';
import { getLanguageOptions } from '../base-video-form';
import { Languages } from '@monorepo/types';

const { TextArea } = Input;
const { Text, Paragraph } = Typography;

const CREATIVE_PLACEHOLDER =
  "Explore the bizarre phenomenon of 'Crown Shyness' - where tree canopies mysteriously avoid touching each other, creating beautiful maze-like patterns in the forest ceiling";

interface ScriptGenerationState {
  prompt: string;
  language: Languages;
  duration: number;
}

export interface ScriptGenerateModalActions {
  open: () => void;
}

interface Props {
  form: UseFormReturn<any>;
}

const DEFAULT_STATE: ScriptGenerationState = {
  prompt: '',
  language: Languages.English,
  duration: 30,
};

const ScriptGenerateModal = forwardRef<ScriptGenerateModalActions, Props>(
  ({ form }, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const [state, setState] = useState<ScriptGenerationState>(DEFAULT_STATE);
    const { loadingState, updateLoadingState } = useLoading();
    const {
      dataStore: { videoStore },
    } = useStore();

    useImperativeHandle(ref, () => ({
      open: () => {
        setIsOpen(true);
      },
    }));

    const handleGenerate = async () => {
      try {
        updateLoadingState(LoadingState.Loading);

        const script = await videoStore.generateScript({
          text: state.prompt,
          languageCode: state.language,
          duration: state.duration,
        });

        form.setValue('script', script, {
          shouldDirty: true,
          shouldValidate: true,
        });
        handleCancel();
      } catch (error) {
        console.error('Failed to generate script:', error);
      } finally {
        updateLoadingState(LoadingState.Loaded);
      }
    };

    const handleCancel = () => {
      setIsOpen(false);
    };

    const updateState = (key: keyof ScriptGenerationState, value: any) => {
      setState((prev) => ({ ...prev, [key]: value }));
    };

    return (
      <Modal
        title="Generate Script with AI"
        open={isOpen}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="generate"
            type="primary"
            loading={loadingState === LoadingState.Loading}
            onClick={handleGenerate}
          >
            Generate Script
          </Button>,
        ]}
        width={800}
      >
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Text strong>What's your video about?</Text>
              <Paragraph type="secondary" style={{ margin: 0 }}>
                Describe your video idea in detail. The more specific you are,
                the better the script will be.
              </Paragraph>
              <TextArea
                rows={4}
                value={state.prompt}
                onChange={(e) => updateState('prompt', e.target.value)}
                placeholder={CREATIVE_PLACEHOLDER}
                style={{ width: '100%' }}
              />
            </Space>
          </Col>

          <Col span={12}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Text strong>Choose Language</Text>
              <Paragraph type="secondary" style={{ margin: 0 }}>
                Select the language for your video narration
              </Paragraph>
              <Select
                value={state.language}
                style={{ width: '100%' }}
                onChange={(value) => updateState('language', value)}
                options={getLanguageOptions()}
              />
            </Space>
          </Col>

          <Col span={12}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Text strong>Video Duration</Text>
              <Paragraph type="secondary" style={{ margin: 0 }}>
                Choose the length of your video
              </Paragraph>
              <Slider
                min={30}
                max={180}
                step={30}
                value={state.duration}
                marks={{
                  30: '30s',
                  60: '1m',
                  120: '2m',
                  180: '3m',
                }}
                onChange={(value) => updateState('duration', value)}
                style={{ marginTop: 8 }}
              />
            </Space>
          </Col>
        </Row>
      </Modal>
    );
  }
);

export default ScriptGenerateModal;
