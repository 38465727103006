import { Divider, Space, Typography } from 'antd';
import AvatarUpload from './avatar-upload';
import { UserInfo } from './user-info';
import UpdatePassword from './update-password';
import { useResponsiveReady } from '../../../hooks/use-responive-ready';
import React from 'react';
import { Helmet } from 'react-helmet';

const { Title } = Typography;

const Profile = () => {
  const { isMobile } = useResponsiveReady();

  return (
    <Space direction="vertical" size="large" style={{ display: 'flex' }}>
      <Helmet>
        <title>Profile | ShortsNinja</title>
        <meta
          name="description"
          content="Manage your ShortsNinja profile settings. Update your AI video creation preferences."
        />
        <meta
          name="keywords"
          content="profile settings, account settings, ShortsNinja, faceless videos, ai video automation"
        />
      </Helmet>
      <div>
        <Title level={4}>Personal Information</Title>
        <Space
          direction={isMobile ? 'vertical' : 'horizontal'}
          align="start"
          size={100}
          style={{ width: '100%' }}
        >
          <AvatarUpload />
          <UserInfo />
        </Space>
      </div>
      <Divider />
      <div>
        <Title level={4}>Update Password</Title>
        <UpdatePassword />
      </div>
    </Space>
  );
};

export default Profile;
