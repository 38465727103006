import {
  AbsoluteFill,
  continueRender,
  delayRender,
  useCurrentFrame,
  useVideoConfig,
} from 'remotion';
import { DEFAULT_CAPTION_SETTINGS, RemotionConfig } from '../remotion-types';
import { AssetTransitionSeries } from '../remotion-assets/remotion-assets';
import { AudioTrackGroup } from '../remotion-audio/remotion-audio';
import { SubtitleSeries } from '../remotion-subtitles/remotion-subtitles';
import { FunctionComponent, useEffect, useState } from 'react';
import * as React from 'react';
import { loadGoogleFont } from '../remotion-helpers/google-fonts';
import RemotionOverlays from '../remotion-overlays/remotion-overlays';

export const RemotionMainContainer: FunctionComponent<{
  config: RemotionConfig;
}> = ({ config }) => {
  const [handle] = useState(() => delayRender());
  const [isReady, setReady] = useState(false);
  const frame = useCurrentFrame();
  const { width, height } = useVideoConfig();
  const subtitlesSettings = {
    ...DEFAULT_CAPTION_SETTINGS,
    ...config.captionSettings,
  };

  const loadFont = async () => {
    if (subtitlesSettings.fontFamily) {
      // Basic usage
      await Promise.all([
        loadGoogleFont(subtitlesSettings.fontFamily),
        loadGoogleFont('PermanentMarker'),
      ]);

      // // Or with options
      // const loadedFontWithOptions = await loadGoogleFont(requestedFont);
      // setFontFamily(loadedFontWithOptions);
    }

    await new Promise((resolve) => setTimeout(resolve, 100));

    continueRender(handle);
    setReady(true);
  };

  useEffect(() => {
    loadFont();
  }, []);

  if (!isReady) {
    return false;
  }

  return (
    <AbsoluteFill
      style={{
        backgroundColor: 'black',
        width,
        height,
      }}
    >
      <AssetTransitionSeries assets={config.assets} />

      {config.audioTracks?.map((trackGroup, groupIndex) => (
        <AudioTrackGroup key={groupIndex} tracks={trackGroup} />
      ))}

      {config.subtitles && config.captionSettings?.display && (
        <SubtitleSeries
          isRtl={config.isRtl}
          absoluteFrame={frame}
          subtitles={config.subtitles}
          settings={subtitlesSettings}
        />
      )}

      {config.overlayTracks && (
        <RemotionOverlays overlayTracks={config.overlayTracks} />
      )}
    </AbsoluteFill>
  );
};
