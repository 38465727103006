import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Flex,
  Pagination,
  Row,
  Skeleton,
  Switch,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import {
  Asset as AssetTypez,
  AssetType,
  Video,
  VideoStatus,
} from '@monorepo/types';
import { useStore } from '../../helpers/use-store';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import AssetComponent from '../../components/asset/asset';
import { LoadingState, Space, useLoading } from '@monorepo/react-components';
import { upperFirst } from 'lodash';
import { getStatusColor } from '../series/series-videos/series-videos';
import { useResponsiveReady } from '../../hooks/use-responive-ready';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {
  CheckCircleFilled,
  CheckCircleTwoTone,
  ClockCircleOutlined,
  DeleteOutlined,
  StarFilled,
  StarTwoTone,
} from '@ant-design/icons';
import ProductShowcase from '../showcase/product-showcase';
import { Helmet } from 'react-helmet';
import { useNotification } from '../../hooks/use-notification';

import './videos.scss';
import DeleteVideoModal, {
  DeleteVideoModalActions,
} from './delete-video-modal';
import { imageBlobManager } from '../../managers/image-blob-manager';

dayjs.extend(relativeTime);

const VideoDuration = ({ duration }: { duration: number }) => (
  <div
    style={{
      position: 'absolute',
      bottom: '8px',
      right: '8px',
      background: 'rgba(0, 0, 0, 0.75)',
      color: 'white',
      padding: '2px 4px',
      borderRadius: '4px',
      fontSize: '12px',
    }}
  >
    {Math.floor(duration / 60)}:{String(duration % 60).padStart(2, '0')}
  </div>
);

const Videos = () => {
  const navigate = useNavigate();
  const deleteModalRef = useRef<DeleteVideoModalActions>(null);
  const { loadingState, updateLoadingState } = useLoading(LoadingState.Loading);
  const [totalVideos, setTotalVideos] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [videos, setVideos] = useState<Video[]>([]);
  const [showFavorites, setShowFavorites] = useState(false);
  const {
    dataStore: { videoStore },
  } = useStore();
  const { isMobile } = useResponsiveReady();
  const notification = useNotification();

  useEffect(() => {
    return () => {
      imageBlobManager.clearCache();
    };
  }, []);

  useEffect(() => {
    const init = async () => {
      try {
        updateLoadingState(LoadingState.Loading);
        const videosResult = await videoStore.paginate({
          page: currentPage,
          limit: 10,
          filters: showFavorites ? { isFavorite: true } : undefined,
        });
        setVideos(videosResult.results);
        setTotalVideos(videosResult.total);
      } catch (e) {
        console.error(`failed fetching videos`, e);
      } finally {
        updateLoadingState(LoadingState.Loaded);
      }
    };

    init();
  }, [currentPage, showFavorites]);

  const handleDelete = (videoId: string, e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent card click navigation
    deleteModalRef.current?.open(videoId);
  };

  const handlePublish = async (videoId: string, e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      await videoStore.updateGeneralInfo(videoId, {
        status: VideoStatus.Published,
      });

      const updatedVideos = await videoStore.paginate({
        page: currentPage,
        limit: 10,
        filters: showFavorites ? { isFavorite: true } : undefined,
      });
      setVideos(updatedVideos.results);

      notification.success({
        message: 'Success',
        description: 'Video published successfully.',
      });
    } catch (error) {
      console.error('Failed to publish video:', error);
      notification.error({
        message: 'Error',
        description: 'Failed to publish video.',
      });
    }
  };

  const handleFavorite = async (videoId: string, e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      const video = videos.find((v) => v._id === videoId);
      await videoStore.updateGeneralInfo(videoId, {
        isFavorite: !video?.isFavorite,
      });

      const updatedVideos = await videoStore.paginate({
        page: currentPage,
        limit: 10,
        filters: showFavorites ? { isFavorite: true } : undefined,
      });
      setVideos(updatedVideos.results);

      notification.success({
        message: 'Success',
        description: video?.isFavorite
          ? 'Removed from favorites.'
          : 'Added to favorites.',
      });
    } catch (error) {
      console.error('Failed to update favorite status:', error);
      notification.error({
        message: 'Error',
        description: 'Failed to update favorite status.',
      });
    }
  };

  const handleDeleteSuccess = (deletedVideoId: string) => {
    setVideos(videos.filter((v) => v._id !== deletedVideoId));
    setTotalVideos((prev) => prev - 1);
  };

  const isLoading = loadingState === LoadingState.Loading;

  if (!isLoading && videoStore.items.length === 0) {
    return <ProductShowcase />;
  }

  return (
    <div className="video-cards-container">
      <Helmet>
        <title>My Videos | ShortsNinja</title>
        <meta
          name="description"
          content="Manage your faceless videos and AI-powered content. View, edit, and track all your automated video creations."
        />
      </Helmet>

      <Flex
        style={{
          marginBottom: '16px',
          flexDirection: isMobile ? 'column' : 'row',
        }}
        justify={isMobile ? 'start' : 'space-between'}
        align={isMobile ? 'start' : 'center'}
      >
        <Flex
          style={{ flexDirection: isMobile ? 'column' : 'row' }}
          gap="small"
          align="center"
        >
          <Typography.Text>All</Typography.Text>
          <Switch
            checked={showFavorites}
            onChange={(val: boolean) => {
              setCurrentPage(1);
              setShowFavorites(val);
            }}
          />
          <Typography.Text>Favorites</Typography.Text>
        </Flex>
        <Pagination
          pageSize={10}
          showTotal={(total) => `${total} Videos`}
          current={currentPage}
          total={totalVideos}
          onChange={(page) => setCurrentPage(page)}
        />
      </Flex>

      {isLoading ? (
        <Skeleton active={true} />
      ) : (
        <Row gutter={[16, 16]}>
          {videos.map((video) => {
            const videoWithAsset = video as Video & {
              firstAsset: AssetTypez;
            };

            return (
              <Col xs={24} sm={12} md={12} lg={6} xl={4} key={video._id}>
                <Card
                  hoverable
                  onClick={() => navigate(`/videos/${video._id}`)}
                  bodyStyle={{ padding: '8px' }}
                  style={{ height: '100%' }}
                  className="video-card"
                  actions={[
                    <Tooltip title={'Mark as published'}>
                      <Button
                        disabled={video.status === VideoStatus.Published}
                        type="text"
                        icon={
                          video.status === VideoStatus.Published ? (
                            <CheckCircleFilled style={{ color: '#73d13d' }} />
                          ) : (
                            <CheckCircleTwoTone twoToneColor={'#73d13d'} />
                          )
                        }
                        onClick={(e) => handlePublish(video._id, e)}
                      />
                    </Tooltip>,
                    <Tooltip
                      title={
                        video.isFavorite
                          ? 'Remove from favorites'
                          : 'Add to favorites'
                      }
                    >
                      <Button
                        type="text"
                        icon={
                          video.isFavorite ? (
                            <StarFilled style={{ color: '#FFB800' }} />
                          ) : (
                            <StarTwoTone twoToneColor={'#FFB800'} />
                          )
                        }
                        onClick={(e) => handleFavorite(video._id, e)}
                      />
                    </Tooltip>,
                    <Tooltip title={'Delete Video'}>
                      <Button
                        type="text"
                        danger
                        icon={<DeleteOutlined />}
                        onClick={(e) => handleDelete(video._id, e)}
                      />
                    </Tooltip>,
                  ]}
                >
                  <div
                    style={{
                      position: 'relative',
                      paddingTop: isMobile ? '120%' : '177.77%', // Reduced height for mobile
                      marginBottom: '8px',
                      background: '#f0f0f0',
                      borderRadius: '4px',
                      overflow: 'hidden',
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                      }}
                    >
                      <AssetComponent
                        width="100%"
                        height="100%"
                        preview={false}
                        shouldLazy={false}
                        asset={
                          video.videoUrl
                            ? { url: video.videoUrl, type: AssetType.Video }
                            : videoWithAsset.firstAsset
                        }
                      />
                      {video.duration && (
                        <VideoDuration duration={video.duration} />
                      )}
                    </div>
                  </div>

                  <Space
                    direction="vertical"
                    size={4}
                    style={{ width: '100%' }}
                  >
                    <Typography.Paragraph
                      style={{
                        margin: 0,
                        fontSize: '13px',
                        lineHeight: '1.4',
                        fontWeight: 500,
                        height: isMobile ? '36px' : '40px',
                      }}
                      ellipsis={{ rows: 2 }}
                    >
                      {video.title}
                    </Typography.Paragraph>

                    <Flex justify="space-between" align="center">
                      <Tag
                        color={getStatusColor(video.status)}
                        style={{
                          margin: 0,
                          fontSize: '11px',
                          padding: '0 4px',
                        }}
                      >
                        {upperFirst(video.status)}
                      </Tag>
                      <Typography.Text
                        type="secondary"
                        style={{ fontSize: '11px' }}
                      >
                        {dayjs(video.createdAt).fromNow()}
                      </Typography.Text>
                    </Flex>

                    <Flex align="center" style={{ fontSize: '11px' }}>
                      <ClockCircleOutlined
                        style={{ marginRight: '4px', color: '#8c8c8c' }}
                      />
                      <Typography.Text type="secondary">
                        Created {dayjs(video.createdAt).format('MMM D, YYYY')}
                      </Typography.Text>
                    </Flex>
                  </Space>
                </Card>
              </Col>
            );
          })}
        </Row>
      )}
      <DeleteVideoModal ref={deleteModalRef} onSuccess={handleDeleteSuccess} />
    </div>
  );
};

export default observer(Videos);
