import AppLayout from '../layout/layout';
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { LoadingState, useLoading } from '@monorepo/react-components';
import { Skeleton } from 'antd';
import { useStore } from '../../helpers/use-store';
import FeaturebaseApp from '../featurebase/featurebase';

const reactEnv = import.meta.env.VITE_APP_ENV || 'development';
const isProduction = reactEnv === 'production';

const AppLoader = () => {
  const {
    dataStore: { voiceoverStore, musicStore, socialAccountStore, seriesStore },
  } = useStore();
  const { isLoading, updateLoadingState } = useLoading(LoadingState.Loading);

  useEffect(() => {
    const init = async () => {
      try {
        await Promise.all([
          seriesStore.fetchAll(),
          musicStore.fetchAll(),
          voiceoverStore.fetchAll(),
          socialAccountStore.fetchAll(),
        ]);
      } catch (e) {
        console.error(`app loading failed`, e);
      } finally {
        updateLoadingState(LoadingState.Loaded);
      }
    };

    init();
  }, []);

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <AppLayout>
      <Outlet />
    </AppLayout>
  );
};

export default AppLoader;
