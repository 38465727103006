import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Modal, Typography } from 'antd';
import { LoadingState, useLoading } from '@monorepo/react-components';
import { useStore } from '../../helpers/use-store';
import { useNotification } from '../../hooks/use-notification';

const { Text } = Typography;

export interface DeleteVideoModalActions {
  open: (videoId: string) => void;
}

interface Props {
  onSuccess?: (videoId: string) => void;
}

const DeleteVideoModal = forwardRef<DeleteVideoModalActions, Props>(
  ({ onSuccess }, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const [videoId, setVideoId] = useState<string | null>(null);
    const { loadingState, updateLoadingState } = useLoading();
    const {
      dataStore: { videoStore },
    } = useStore();
    const notification = useNotification();

    useImperativeHandle(ref, () => ({
      open: (id: string) => {
        setVideoId(id);
        setIsOpen(true);
      },
    }));

    const handleDelete = async () => {
      if (!videoId) return;

      try {
        updateLoadingState(LoadingState.Loading);
        await videoStore.delete(videoId);

        notification.success({
          message: 'Success',
          description: 'Video has been deleted',
        });

        onSuccess?.(videoId);
        handleCancel();
      } catch (error) {
        console.error('Failed to delete video:', error);
        notification.error({
          message: 'Error',
          description: 'Failed to delete video. Please try again.',
        });
      } finally {
        updateLoadingState(LoadingState.Loaded);
      }
    };

    const handleCancel = () => {
      setIsOpen(false);
      setVideoId(null);
    };

    return (
      <Modal
        title="Delete Video"
        open={isOpen}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="delete"
            danger
            type="primary"
            loading={loadingState === LoadingState.Loading}
            onClick={handleDelete}
          >
            Delete
          </Button>,
        ]}
      >
        <Text>
          Are you sure you want to delete this video? This action cannot be
          undone.
        </Text>
      </Modal>
    );
  }
);

export default DeleteVideoModal;
