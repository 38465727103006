import { Link, useParams } from 'react-router-dom';
import { LoadingState } from '@monorepo/react-components';
import { Button, Card, Col, Empty, Layout, Row, Spin, Typography } from 'antd';
import { useVideo, VideoContext } from '../../hooks/use-video';
import React, { useEffect } from 'react';
import VideoPlayer from './video-player/video-player';
import VideoHeader from './video-layout/video-header/video-header';
import VideoSidebar from './video-layout/video-sidebar/video-sidebar';
import VideoContentContainer from './video-layout/video-content-container/video-content-container';

import './video.scss';
import { useResponsiveReady } from '../../hooks/use-responive-ready';
import HideMobile from '../../components/responsiveness/hide-mobile';
import OnlyMobile from '../../components/responsiveness/only-mobile';
import { Helmet } from 'react-helmet';
import TimelineContainer from './timeline-container/timeline-container';
import AssetSelectionModal from './assets/assets-modal';
import VideoMetadata from './metadata/video-metadata';
import VideoCreationModal from './video-creation-modal/video-creation-modal';

const VideoComponent = () => {
  const { videoId } = useParams();
  const videoHook = useVideo(videoId as string);
  const { init, loadingState, video } = videoHook;
  const { isMobile } = useResponsiveReady();

  useEffect(() => {
    init();
  }, []);

  const isLoaded = loadingState === LoadingState.Loaded;
  const isLoading = loadingState === LoadingState.Loading;

  if (isLoading) {
    return (
      <div
        style={{
          width: '100wh',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin size={'large'} />
      </div>
    );
  }

  if (isLoaded && !video) {
    return (
      <div
        style={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Empty description={'Video Not Found'}>
          <Link to="/">
            <Button type="primary">Return Home</Button>
          </Link>
        </Empty>
      </div>
    );
  }

  if (!video) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{video?.title || ''} | ShortsNinja</title>
        <meta
          name="description"
          content="View and manage your faceless video details. Edit AI voiceover, automation settings, and more."
        />
        <meta
          name="keywords"
          content="video details, ShortsNinja, faceless videos, ai video automation, ai voiceover"
        />
      </Helmet>
      {/*<BreadcrumbComponent seriesId={video.seriesId} videoId={video._id} />*/}
      <VideoContext.Provider value={videoHook}>
        <Layout
          className={`layout videos-container ${isMobile ? 'mobile' : ''}`}
        >
          <HideMobile>
            <VideoSidebar />
          </HideMobile>
          <Layout>
            <VideoHeader />
            <Layout.Content>
              <Row style={{ position: 'relative' }}>
                <OnlyMobile>
                  <Col span={24}>
                    <Typography.Text
                      type={'secondary'}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '15px',
                        margin: 0,
                      }}
                    >
                      🎬 For the best video editing experience, please switch to
                      your desktop! Mobile playback may be limited and video
                      controls might not work as expected.
                    </Typography.Text>
                  </Col>
                </OnlyMobile>
                <HideMobile>
                  <Col className="scrollable-container" span={12}>
                    <VideoContentContainer />
                  </Col>
                </HideMobile>
                <Col span={isMobile ? 24 : 12}>
                  <VideoPlayer />
                </Col>
                <OnlyMobile>
                  <Col span={24}>
                    <Card>
                      <VideoMetadata />
                    </Card>
                  </Col>
                </OnlyMobile>
              </Row>
              <AssetSelectionModal
                ref={videoHook.modalRef}
                videoId={videoHook.selectedScene?.videoId}
              />
              <VideoCreationModal />
            </Layout.Content>
            <HideMobile>
              <TimelineContainer />
            </HideMobile>
          </Layout>
        </Layout>
      </VideoContext.Provider>
    </>
  );
};

export default VideoComponent;
