import { Player, PlayerRef } from '@remotion/player';
import { forwardRef, memo, useEffect, useState } from 'react';
import { calculateDurationInFrames } from './remotion-helpers/calculate-duration-frames';
import { RemotionMainContainer } from './remotion-containers/remotion-main-container';
import { RemotionConfig } from './remotion-types';
import { Skeleton } from 'antd';
import { prefetch } from 'remotion';
import { preloadAudio, preloadImage, preloadVideo } from '@remotion/preload';
import { AssetType } from '@monorepo/types';

interface Props {
  config: RemotionConfig;
  playbackRate?: number;
  isMobile?: boolean;
  style?: any;
  width?: string;
}

const _RemotionPlayer = forwardRef<PlayerRef, Props>(
  (
    { config, isMobile = false, style = {}, width = '500px', playbackRate = 1 },
    playerRef
  ) => {
    const [assetsLoading, setAssetsLoading] = useState(true);
    const durationInFrames = calculateDurationInFrames(config);

    useEffect(() => {
      const freeFns: any = [];

      const init = async () => {
        const promises: any = [];

        config.assets.forEach((asset) => {
          // if (asset.type === AssetType.Image) {
          //   preloadImage(asset.src);
          // }
          // if (asset.type === AssetType.Image) {
          //   preloadVideo(asset.src);
          // }

          const { free, waitUntilDone } = prefetch(asset.src, {
            method: 'blob-url',
            contentType: asset.contentType,
          });

          promises.push(
            waitUntilDone().catch((e) => console.error(`failed`, e))
          );
          freeFns.push(free);
        });

        config.audioTracks?.forEach((audioTrack) => {
          audioTrack.forEach((track) => {
            preloadAudio(track.src);
            const { free, waitUntilDone } = prefetch(track.src, {
              method: 'blob-url',
              contentType: `audio/mp3`,
            });

            promises.push(
              waitUntilDone().catch((e) => console.error(`failed`, e))
            );
            freeFns.push(free);
          });
        });

        await Promise.all(promises);

        console.log('done loading all');

        setTimeout(() => {
          setAssetsLoading(false);
        }, 500);

        return freeFns;
      };

      init();

      return () => {
        freeFns.forEach((fn) => fn());
      };
    }, []);

    return (
      <div
        style={{
          margin: '0 auto',
          width,
          height: '60vh',
          position: 'relative',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: 'auto',
            aspectRatio: `${config.width} / ${config.height}`,
            maxHeight: '100%',
            maxWidth: '100%',
          }}
        >
          {assetsLoading ? (
            <Skeleton />
          ) : (
            <Player
              numberOfSharedAudioTags={isMobile ? 0 : 50}
              ref={playerRef}
              playbackRate={playbackRate}
              className={'remotion-ninja-player'}
              component={RemotionMainContainer}
              durationInFrames={durationInFrames}
              compositionWidth={config.width}
              compositionHeight={config.height}
              fps={30}
              style={{ width: '100%', borderRadius: '12px', ...style }}
              controls={isMobile}
              inputProps={{ config: { ...config } }}
            />
          )}
        </div>
      </div>
    );
  }
);

export const RemotionPlayer = memo(_RemotionPlayer, (prevProps, nextProps) => {
  // console.log(`oldProps`, prevProps);
  // console.log(`nextProps`, nextProps);
  // console.log(
  //   `diff: ${
  //     JSON.stringify(prevProps.config) === JSON.stringify(nextProps.config)
  //   }`
  // );
  return (
    JSON.stringify(prevProps.config) === JSON.stringify(nextProps.config) &&
    prevProps.playbackRate === nextProps.playbackRate
  );
});
