import React, { FunctionComponent, useEffect } from 'react';
import {
  Col,
  ColorPicker,
  Flex,
  Form,
  Row,
  Select,
  Slider,
  Switch,
} from 'antd';
import { subtitleFonts, SubtitlesConfig } from '@monorepo/types';

import './subtitles-editor.scss';

const { Option } = Select;

interface Props {
  subtitlesConfig: SubtitlesConfig;
  onUpdate: (config: SubtitlesConfig) => void;
}

const SubtitlesEditor: FunctionComponent<Props> = ({
  subtitlesConfig,
  onUpdate,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(subtitlesConfig);
  }, [subtitlesConfig]);

  const handleStyleChange = (changedValues, allValues) => {
    const updatedStyle = { ...allValues };

    ['primaryColor', 'outlineColor', 'backColor', 'highlightColor'].forEach(
      (colorKey) => {
        const colorObj = allValues[colorKey];
        if (colorObj) {
          if (colorObj.cleared) {
            updatedStyle[colorKey] = '';
          } else {
            updatedStyle[colorKey] = convertColorToHex(allValues[colorKey]);
          }
        }
      }
    );

    onUpdate(updatedStyle);
  };

  const convertColorToHex = (colorObj) => {
    if (colorObj && colorObj.metaColor) {
      const { r, g, b } = colorObj.metaColor;

      // Convert each color component to a two-digit hex string
      const rHex = r.toString(16).padStart(2, '0');
      const gHex = g.toString(16).padStart(2, '0');
      const bHex = b.toString(16).padStart(2, '0');

      // Combine the hex values and add the '#' prefix
      return `#${rHex}${gHex}${bHex}`;
    }
    return colorObj;
  };

  return (
    <Row className={'subtitles-editor'} gutter={24}>
      <Col span={24} style={{ height: '100%', paddingRight: '12px' }}>
        <div
          className="scrollable-container "
          style={{
            height: '100%',
            maxHeight: '600px',
            overflowY: 'hidden',
            paddingRight: '12px',
          }}
        >
          <Form
            form={form}
            layout="vertical"
            initialValues={subtitlesConfig}
            onValuesChange={handleStyleChange}
          >
            <Flex justify={'start'}>
              <Form.Item
                name="display"
                valuePropName="checked"
                label="Show Subtitles"
              >
                <Switch />
              </Form.Item>
              <Form.Item
                name="uppercase"
                valuePropName="checked"
                label="Uppercase"
              >
                <Switch />
              </Form.Item>
            </Flex>
            <Form.Item name="fontName" label="Font">
              <Select showSearch optionFilterProp="value">
                {subtitleFonts.map((font) => (
                  <Option key={font} value={font}>
                    <span style={{ fontFamily: font }}>{font}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Flex justify={'space-between'}>
              <Form.Item
                style={{ width: '100%' }}
                name="numberOfWords"
                label="Number Of Words"
              >
                <Slider min={1} max={8} step={1} />
              </Form.Item>

              <Form.Item
                style={{ width: '100%' }}
                name="fontSize"
                label="Font Size"
              >
                <Slider min={40} max={150} step={5} />
              </Form.Item>
            </Flex>

            <Form.Item
              style={{ width: '100%' }}
              name="positionY"
              label="Position Y"
            >
              <Slider min={10} max={100} step={1} />
            </Form.Item>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="primaryColor" label="Text Color">
                  <ColorPicker allowClear />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="highlightColor" label="Highlight Color">
                  <ColorPicker allowClear />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="outlineColor" label="Shadow Color">
                  <ColorPicker allowClear />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="backColor" label="Highlight Background">
                  <ColorPicker allowClear />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              style={{ width: '100%' }}
              name="outline"
              label="Shadow Width"
            >
              <Slider min={0} max={10} step={1} />
            </Form.Item>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default SubtitlesEditor;
