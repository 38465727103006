import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  MutableRefObject,
} from 'react';
import { Button, Divider, Select, Slider, Tooltip } from 'antd';
import {
  PlayCircleOutlined,
  PauseCircleOutlined,
  SoundOutlined,
  StopOutlined,
  FullscreenOutlined,
  RetweetOutlined,
  ThunderboltOutlined,
  StepForwardOutlined,
  StepBackwardOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
} from '@ant-design/icons';
import './timeline.scss';
import TimelineTrack from './timeline-track';
import { useVideoContext } from '../../../../hooks/use-video';
import TimelineTextTrack from './timeline-text-track';
import { Word } from '@monorepo/types';
import { PlayerRef } from '@remotion/player';
import TimelineControls from './timeline-controls';
import { throttle } from 'lodash';
import TimelineMarkers from './timeline-markers';

export const formatTime = (frames: number) => {
  const seconds = Math.floor(frames / 30);
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes.toString().padStart(2, '0')}:${remainingSeconds
    .toString()
    .padStart(2, '0')}`;
};

export type WordWithMetadata = Word & {
  sceneId: string;
  originalStartTime: number;
  originalEndTime: number;
  disabled?: boolean;
};

export type TimelineImage = {
  startTime: number;
  endTime: number;
  url: string;
};

interface VideoTimelineProps {
  playerRef: PlayerRef;
  totalFrames: number;
  fps?: number;
  images?: TimelineImage[];
  words: WordWithMetadata[];
}

const VideoTimeline: React.FC<VideoTimelineProps> = ({
  playerRef,
  words,
  totalFrames,
  fps = 30,
  images = [],
}) => {
  const {
    isPlayerReady,
    getSceneByCurrentFrame,
    openSceneEdit,
    scrollToScene,
  } = useVideoContext();

  const [currentFrame, setCurrentFrame] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const timelineRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [zoomLevel, setZoomLevel] = useState(3);

  useEffect(() => {
    const handleSpace = () => {
      const isPlayingLocal = playerRef.isPlaying();

      if (isPlayingLocal) {
        playerRef?.pause();
        setIsPlaying(false);
      } else {
        playerRef?.play();
        setIsPlaying(true);
      }
    };

    const handleKeyDown = (event) => {
      if (
        event.target.tagName === 'INPUT' ||
        event.target.tagName === 'TEXTAREA' ||
        event.target.isContentEditable
      ) {
        return;
      }

      if (event.code === 'Space' || event.key === ' ') {
        event.preventDefault();
        handleSpace();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    // Cleanup
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (!playerRef) return;

    const interval = setInterval(() => {
      if (isPlaying && !isDragging) {
        const frame = playerRef.getCurrentFrame();
        setCurrentFrame(frame);
      }
    }, 1000 / fps);

    return () => clearInterval(interval);
  }, [isPlaying, isDragging, fps]);

  useEffect(() => {
    if (!playerRef) return;

    const onPlay = () => {
      setIsPlaying(true);
    };

    const onPause = () => {
      setIsPlaying(false);
    };

    const onTimeUpdate = (e) => {
      const seekedFrame = e.detail.frame;

      setCurrentFrame(seekedFrame);
      const currentScene = getSceneByCurrentFrame(seekedFrame);

      if (currentScene) {
        scrollToScene(currentScene);
      }
    };

    playerRef.addEventListener('play', onPlay);
    playerRef.addEventListener('pause', onPause);
    playerRef.addEventListener('frameupdate', onTimeUpdate);

    return () => {
      playerRef.removeEventListener('play', onPlay);
      playerRef.removeEventListener('pause', onPause);
      playerRef.removeEventListener('frameupdate', onTimeUpdate);
    };
  }, [isPlayerReady]);

  useEffect(() => {
    if (!containerRef.current || !timelineRef.current || !isPlaying) return;

    const container = containerRef.current;
    const timelineRect = timelineRef.current.getBoundingClientRect();
    const playheadPosition = (currentFrame / totalFrames) * timelineRect.width;

    const visibleStart = container.scrollLeft;
    const visibleEnd = visibleStart + container.clientWidth;
    const buffer = container.clientWidth * 0.3;

    const isDone = currentFrame >= totalFrames;

    if (
      playheadPosition < visibleStart + buffer ||
      playheadPosition > visibleEnd - buffer ||
      isDone
    ) {
      const newScrollPosition = playheadPosition - container.clientWidth / 2;
      container.scrollTo({
        left: Math.max(0, newScrollPosition),
        behavior: 'smooth',
      });
    }

    if (isDone) {
      setIsPlaying(false);
    }
  }, [currentFrame, isPlaying, totalFrames]);

  const calculateFrame = useCallback(
    (clientX: number): number => {
      if (!timelineRef.current) return 0;

      const rect = timelineRef.current.getBoundingClientRect();
      const trackStart = rect.left + 16;
      const trackEnd = rect.right - 16;
      const trackWidth = trackEnd - trackStart;

      const x = Math.max(0, Math.min(clientX - trackStart, trackWidth));
      const percentage = x / trackWidth;
      return Math.floor(percentage * totalFrames);
    },
    [totalFrames]
  );

  const handleDragStart = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      setIsDragging(true);

      const handleDrag = (e: MouseEvent) => {
        e.preventDefault();
        const frame = calculateFrame(e.clientX);
        playerRef?.seekTo(frame);
      };

      const handleDragEnd = () => {
        setIsDragging(false);
        document.removeEventListener('mousemove', handleDrag);
        document.removeEventListener('mouseup', handleDragEnd);
      };

      document.addEventListener('mousemove', handleDrag);
      document.addEventListener('mouseup', handleDragEnd);
    },
    [calculateFrame]
  );

  const handleTimelineClick = useCallback(
    (e: React.MouseEvent) => {
      if (!isDragging) {
        const frame = calculateFrame(e.clientX);
        const currentScene = getSceneByCurrentFrame(frame);

        playerRef?.seekTo(frame);
        if (currentScene) {
          openSceneEdit(currentScene);
        }
      }
    },
    [isDragging, calculateFrame]
  );

  return (
    <div className={`video-timeline ${isDragging ? 'dragging' : ''}`}>
      <TimelineControls
        playerRef={playerRef}
        isPlaying={isPlaying}
        totalFrames={totalFrames}
        currentFrame={currentFrame}
        setIsPlaying={setIsPlaying}
        zoom={zoomLevel}
        setZoom={setZoomLevel}
      />
      <div ref={containerRef} className="timeline-scroll-container">
        <div className="timeline-wrapper">
          <TimelineMarkers zoom={zoomLevel} totalFrames={totalFrames} />

          <div className="tracks-container">
            <div
              ref={timelineRef}
              className="timeline-track"
              // onClick={handleTimelineClick}
            >
              <TimelineTextTrack
                isDragging={isDragging}
                fps={fps}
                currentFrame={currentFrame}
                words={words}
                totalFrames={totalFrames}
              />
              <TimelineTrack
                onClick={handleTimelineClick}
                images={images}
                totalFrames={totalFrames}
              />
              <div
                className="playhead"
                style={{
                  left: `${(currentFrame / totalFrames) * 100}%`,
                }}
              >
                <div
                  className="playhead-handle"
                  onMouseDown={handleDragStart}
                />
                <div className="playhead-line" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoTimeline;
