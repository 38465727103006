import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Button, List, Slider, Space, Tag, Typography } from 'antd';
import {
  LANGUAGE_METADATA,
  Languages,
  Voiceover,
  VoiceoverProvider,
} from '@monorepo/types';
import CardWithSound from '../card-with-sound/card-with-sound';

import './voiceover-list.scss';
import VoiceoverFiltersModal, {
  FilterModalActions,
  VoiceoverFilterState,
} from './voiceover-filters/voiceover-filters-modal';
import { FilterOutlined } from '@ant-design/icons';
import { useResponsiveReady } from '../../hooks/use-responive-ready';
import { orderBy } from 'lodash';

const providerNames = {
  [VoiceoverProvider.OpenAi]: 'OpenAI',
  [VoiceoverProvider.Speechify]: 'Speechify',
  [VoiceoverProvider.ElevenLabs]: 'Eleven Labs',
};

const providerRanks = {
  [VoiceoverProvider.ElevenLabs]: 1,
  [VoiceoverProvider.Speechify]: 2,
  [VoiceoverProvider.OpenAi]: 3,
};

interface VoiceoverListProps {
  voiceoverId?: string;
  gridColumns?: number;
  shouldDisplayFirst?: boolean;
  voiceovers: Voiceover[];
  language: Languages;
  onVoiceoverSelect?: (voiceoverId: string) => void;
  onButtonClick?: (voiceoverId: string) => void;
  showUpdateButton?: boolean;
  updateButtonText?: string;
  updateDescription?: string;
  loading?: boolean;
  showLanguage?: boolean;
}

export const sortItemsById = <T extends { _id: string }>(
  items: T[],
  priorityId?: string
): T[] => {
  if (!items || items.length === 0) {
    return [];
  }

  return [...items].sort((a, b) => {
    if (!priorityId) return 0;
    if (a._id === priorityId) return -1;
    if (b._id === priorityId) return 1;
    return 0;
  });
};

export const VoiceoverList: FunctionComponent<VoiceoverListProps> = ({
  voiceoverId,
  language = Languages.English,
  voiceovers,
  onVoiceoverSelect,
  onButtonClick,
  showLanguage = false,
  gridColumns = 1,
  shouldDisplayFirst = true,
  showUpdateButton = false,
  updateButtonText = 'Update',
  updateDescription,
  loading = false,
}) => {
  const [selectedVoice, setSelectedVoice] = useState<string | null>(
    voiceoverId || null
  );
  const [playingVoice, setPlayingVoice] = useState<string | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [filters, setFilters] = useState<VoiceoverFilterState>({
    language, // Changed from languages array to single string
    tags: [],
    provider: [
      VoiceoverProvider.ElevenLabs,
      VoiceoverProvider.Speechify,
      VoiceoverProvider.OpenAi,
    ],
    gender: [],
  });
  const filterRef = useRef<FilterModalActions>(null);
  const { isMobile } = useResponsiveReady();

  useEffect(() => {
    setFilters({
      ...filters,
      language,
    });
  }, [language]);

  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
      }
    };
  }, []);

  const selectVoice = (id: string) => {
    setSelectedVoice(id);
    onVoiceoverSelect && onVoiceoverSelect(id);
  };

  const toggleAudio = (id: string, url: string, event: React.MouseEvent) => {
    event.stopPropagation();

    if (playingVoice === id) {
      audioRef.current?.pause();
      setPlayingVoice(null);
    } else {
      if (audioRef.current) {
        audioRef.current.pause();
      }
      audioRef.current = new Audio(url);
      audioRef.current.playbackRate = 1;
      audioRef.current.play();
      audioRef.current.onended = () => setPlayingVoice(null);
      setPlayingVoice(id);
    }
  };

  const filterVoices = () => {
    // Start with language filter since it's required
    let filtered = voiceovers.filter((voice) => {
      return voice.languages.some((language) => {
        const split = language.split('-');

        return split[0] === filters.language || language === filters.language;
      });
    });

    // Filter by tags if any selected
    if (filters.tags.length > 0) {
      filtered = filtered.filter((voice) =>
        filters.tags.every((tag) => voice.tags.includes(tag))
      );
    }

    // Filter by provider if any selected
    if (filters.provider.length > 0) {
      filtered = filtered.filter((voice) =>
        filters.provider.includes(voice.provider)
      );
    }

    // Filter by gender if any selected
    if (filters.gender.length > 0) {
      filtered = filtered.filter((voice) =>
        filters.gender.includes(voice.gender)
      );
    }

    return orderBy(
      filtered,
      [
        (item) => providerRanks[item.provider] || Number.MAX_VALUE,
        (item) => item.order,
      ],
      ['asc', 'asc']
    );
  };

  const filtered = filterVoices();

  const sortedItems = shouldDisplayFirst
    ? sortItemsById(filtered, voiceoverId)
    : filtered;

  const handleApplyFilters = (newFilters: VoiceoverFilterState) => {
    setFilters({ ...newFilters });
  };

  const handleReset = () => {
    const resetFilters = {
      language: Languages.English,
      tags: [],
      provider: [VoiceoverProvider.Speechify],
      gender: [],
    };
    handleApplyFilters(resetFilters);
  };

  const renderFilterTags = () => {
    const filterTags: ReactNode[] = [];

    filterTags.push(
      <Tag key="language" color="blue">
        Language: {LANGUAGE_METADATA[filters.language].name}
      </Tag>
    );

    if (filters.tags.length > 0) {
      filterTags.push(
        <Tag key="tags" color="green">
          Tags: {filters.tags.join(', ')}
        </Tag>
      );
    }

    if (filters.provider.length > 0) {
      filterTags.push(
        <Tag key="providers" color="purple">
          Providers: {filters.provider.join(', ')}
        </Tag>
      );
    }

    if (filters.gender.length > 0) {
      filterTags.push(
        <Tag key="gender" color="orange">
          Gender: {filters.gender.join(', ')}
        </Tag>
      );
    }

    return filterTags;
  };

  return (
    <div
      className="voiceover"
      style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
    >
      {' '}
      <Space
        wrap
        align="center"
        style={{ justifyContent: 'space-between', width: '100%' }}
      >
        <Space wrap>
          <Button
            shape={'round'}
            size={'small'}
            icon={<FilterOutlined />}
            onClick={() => filterRef.current?.open()}
          >
            Filters
          </Button>
          {filters.tags.length > 0 ||
          filters.provider.length > 0 ||
          filters.gender.length > 0 ? (
            <Button shape={'round'} size={'small'} onClick={handleReset}>
              Reset Filters
            </Button>
          ) : null}
        </Space>
        <Space wrap>{renderFilterTags()}</Space>
      </Space>
      <VoiceoverFiltersModal
        showLanguage={showLanguage}
        ref={filterRef}
        voiceovers={voiceovers}
        filters={filters}
        onApplyFilters={handleApplyFilters}
      />
      <div className="scroller-container">
        <div
          style={{
            overflowX: 'hidden',
          }}
          className="voiceover-content scroller"
        >
          <List
            grid={{
              gutter: 5,
              column: gridColumns,
            }}
            pagination={{
              showTotal: (total) => {
                return `${total} Voiceovers`;
              },
              size: isMobile ? 'small' : 'default',
              position: 'top',
              align: 'start',
              showSizeChanger: false,
              pageSize: 30,
            }}
            dataSource={sortedItems}
            renderItem={(item: Voiceover) => {
              const description = providerNames[item.provider];
              const tags = [item.gender, ...item.tags];

              return (
                <List.Item
                  className={`list-item ${
                    selectedVoice === item._id ? 'selected' : ''
                  }`}
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'stretch',
                    marginBottom: '5px',
                  }}
                >
                  <CardWithSound
                    id={item._id}
                    name={item.name}
                    avatar={item.imageUrl}
                    description={description}
                    audioUrl={item.audioUrl}
                    tags={tags.slice(0, 5)}
                    isSelected={selectedVoice === item._id}
                    isPlaying={playingVoice === item._id}
                    onPlay={toggleAudio}
                    onSelect={selectVoice}
                  />
                </List.Item>
              );
            }}
          />
        </div>
      </div>
      {showUpdateButton && (
        <>
          <Button
            disabled={selectedVoice === voiceoverId}
            loading={loading}
            onClick={() =>
              selectedVoice && onButtonClick && onButtonClick(selectedVoice)
            }
            type="primary"
            style={{ width: '100%' }}
          >
            {updateButtonText}
          </Button>
          {updateDescription && (
            <Typography.Text type="secondary">
              {updateDescription}
            </Typography.Text>
          )}
        </>
      )}
    </div>
  );
};
