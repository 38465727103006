import { Button, Divider, Popover, Select, Slider, Tooltip } from 'antd';
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  FullscreenOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
  SoundOutlined,
  StopOutlined,
  ThunderboltOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { PlayerRef } from '@remotion/player';
import { useVideoContext } from '../../../../hooks/use-video';
import { formatTime } from './timeline';

interface Props {
  playerRef: PlayerRef;
  isPlaying: boolean;
  totalFrames: number;
  currentFrame: number;
  setIsPlaying: (isPlaying: boolean) => void;
  zoom: number;
  setZoom: (zoom: number) => void;
}

const TimelineControls: FunctionComponent<Props> = ({
  playerRef,
  isPlaying,
  setIsPlaying,
  totalFrames,
  currentFrame,
  zoom,
  setZoom,
}) => {
  const { video, updateGeneralInfo } = useVideoContext();
  const [isMuted, setIsMuted] = useState(false);
  const [localPlaybackRate, setLocalPlaybackRate] = useState(
    video?.playbackRate || 1
  );

  // Update local state when video changes
  useEffect(() => {
    if (video?.playbackRate) {
      setLocalPlaybackRate(video.playbackRate);
    }
  }, [video?.playbackRate]);

  return (
    <div className="controls">
      <div className="center-controls">
        <div className="control-group">
          <div className="control-player" style={{ marginRight: '15px' }}>
            <Tooltip title={'Jump to start'}>
              <Button
                type="text"
                icon={<DoubleLeftOutlined />}
                onClick={() => {
                  return playerRef?.seekTo(0);
                }}
              />
            </Tooltip>
            <Tooltip title={`${isPlaying ? 'Pause' : 'Play'}(Space Button)`}>
              <Button
                type="text"
                icon={
                  isPlaying ? <PauseCircleOutlined /> : <PlayCircleOutlined />
                }
                onClick={() => {
                  if (isPlaying) {
                    playerRef?.pause();
                  } else {
                    playerRef?.play();
                  }
                  setIsPlaying(!isPlaying);
                }}
              />
            </Tooltip>
            <Tooltip title={'Jump to end'}>
              <Button
                type="text"
                icon={<DoubleRightOutlined />}
                onClick={() => {
                  const lastFrame = Math.max(0, totalFrames - 5);
                  return playerRef?.seekTo(lastFrame);
                }}
              />
            </Tooltip>
            <Tooltip title={isMuted ? 'Unmute' : 'Mute'}>
              <Button
                type="text"
                icon={isMuted ? <StopOutlined /> : <SoundOutlined />}
                onClick={() => {
                  if (isMuted) {
                    playerRef?.unmute();
                  } else {
                    playerRef?.mute();
                  }
                  setIsMuted(!isMuted);
                }}
              />
            </Tooltip>
          </div>
          <Divider
            type={'vertical'}
            style={{ margin: 0, marginRight: '10px', height: '25px' }}
          />
          <span className="time-display">
            <Slider
              value={currentFrame}
              min={0}
              tooltip={{
                formatter: (value) => {
                  return formatTime(value || 0);
                },
              }}
              max={totalFrames}
              onChange={(value) => {
                playerRef?.seekTo(value);
              }}
              style={{ width: 100, margin: '0', marginRight: '10px' }}
            />
            <span style={{ width: '40px', display: 'inline-block' }}>
              {formatTime(currentFrame)}
            </span>{' '}
            /{' '}
            <span style={{ width: '40px', display: 'inline-block' }}>
              {formatTime(totalFrames)}
            </span>
          </span>
          <Popover
            content={
              <div style={{ width: 200, padding: '0 12px' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '8px',
                  }}
                >
                  <span>0.5x</span>
                  <span style={{ color: '#666' }}>Playback Rate</span>
                  <span>2x</span>
                </div>
                <Slider
                  min={0.5}
                  max={2}
                  step={0.05}
                  value={localPlaybackRate}
                  onChange={(value) => {
                    setLocalPlaybackRate(value);
                    updateGeneralInfo({
                      playbackRate: value,
                    });
                  }}
                  tooltip={{
                    formatter: (val) => (val ? `${val.toFixed(2)}x` : '1.0x'),
                  }}
                />
              </div>
            }
            trigger={['click', 'hover']}
            placement="top"
          >
            <div
              style={{
                width: '80px',
                border: '1px solid #e3e3e3',
                borderRadius: '8px',
                padding: '4px 8px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: 'pointer',
                background: 'white',
              }}
            >
              <span>
                {video?.playbackRate
                  ? `${video.playbackRate.toFixed(2)}x`
                  : '1.0x'}
              </span>
              <ThunderboltOutlined style={{ fontSize: '18px' }} />
            </div>
          </Popover>
        </div>
      </div>
      <div className="right-side">
        <div className="zoom-control">
          <ZoomOutOutlined
            onClick={() => {
              if (zoom === 1) {
                return;
              }
              setZoom(zoom - 1);
            }}
            className="zoom-icon"
            style={{ marginRight: '5px' }}
          />
          <Slider
            style={{ width: '100px' }}
            min={1}
            max={5}
            step={1}
            value={zoom}
            onChange={setZoom}
            tooltip={{
              formatter: (value) => `${value}x`,
            }}
          />
          <ZoomInOutlined
            style={{ marginLeft: '5px' }}
            onClick={() => {
              if (zoom === 5) {
                return;
              }
              setZoom(zoom + 1);
            }}
            className="zoom-icon"
          />
        </div>
        <Tooltip title={'Full screen'}>
          <Button
            style={{ marginLeft: '15px' }}
            type="text"
            icon={<FullscreenOutlined />}
            onClick={() => playerRef?.requestFullscreen()}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default TimelineControls;
