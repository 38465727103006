import React, { FunctionComponent } from 'react';

import './voiceover.scss';
import { useStore } from '../../../helpers/use-store';
import { useVideoContext } from '../../../hooks/use-video';
import { LoadingState, useLoading } from '@monorepo/react-components';
import { VoiceoverList } from '../../../components/voiceover-list/voiceover-list';
import { useNotification } from '../../../hooks/use-notification';
import { Languages } from '@monorepo/types';

interface Props {
  voiceoverId: string;
}

const VideoVoiceover: FunctionComponent<Props> = ({ voiceoverId }) => {
  const {
    dataStore: { voiceoverStore, userStore },
  } = useStore();
  const { updateVoiceover, video } = useVideoContext();
  const { updateLoadingState, loadingState } = useLoading();
  const notificationApi = useNotification();

  const handleVoiceoverUpdate = async (selectedVoiceId: string) => {
    try {
      updateLoadingState(LoadingState.Loading);
      await updateVoiceover({ voiceoverId: selectedVoiceId });

      userStore.getLatestUser();

      notificationApi.success({
        placement: 'top',
        message: 'Success',
        description:
          'Voiceover updated successfully, you can find it at the top of the list.',
      });
    } catch (e) {
      notificationApi.error({
        placement: 'top',
        icon: '',
        message: 'Error',
        description:
          'An error occurred. This could be due to insufficient credits or a server issue. Please check your balance or contact support for assistance.',
      });

      console.error('failed updating voiceover', e);
    } finally {
      updateLoadingState(LoadingState.Loaded);
    }
  };

  return (
    <VoiceoverList
      language={(video?.language as Languages) || Languages.English}
      voiceoverId={voiceoverId}
      voiceovers={voiceoverStore.items}
      onButtonClick={handleVoiceoverUpdate}
      showUpdateButton
      updateButtonText="Update"
      updateDescription="Updating the voice will regenerate all voices and subtitles in the video."
      loading={loadingState === LoadingState.Loading}
    />
  );
};

export default VideoVoiceover;
