import VideoTimeline, {
  WordWithMetadata,
} from '../video-player/timeline/timeline';
import React, { useCallback } from 'react';
import { useVideoContext } from '../../../hooks/use-video';
import { Skeleton } from 'antd';

const TimelineContainer = () => {
  const { video, playerRef, isPlayerReady, scenes, assets } = useVideoContext();

  const getTimelineData = () => {
    let audioTime = 0;
    const timelineImages: any[] = [];
    const words: WordWithMetadata[] = [];

    scenes.forEach((scene, index) => {
      const startTime = audioTime * 30;
      let endTime = (audioTime + scene.audioDuration) * 30;
      const sceneAsset = assets.find((asset) => scene.assetId === asset._id);

      if (index === 0 && video?.thumbnailConfig.enabled) {
        const thumbnailDurationInSeconds =
          video.thumbnailConfig.enableVoiceover &&
          video.thumbnailConfig.voiceoverUrl
            ? video.thumbnailConfig.duration
            : 0.05;

        endTime = endTime + thumbnailDurationInSeconds * 30;

        words.push({
          sceneId: 'thumbnail',
          start: 0,
          end: thumbnailDurationInSeconds,
          word: 'thumbnail',
          disabled: true,
          originalStartTime: 0,
          originalEndTime: 0,
        });

        audioTime += thumbnailDurationInSeconds;
      }

      timelineImages.push({
        startTime,
        endTime,
        url: sceneAsset?.url,
      });

      scene.words.forEach((word) => {
        words.push({
          word: word.word,
          start: audioTime + word.start,
          end: audioTime + word.end,
          sceneId: scene._id,
          originalStartTime: word.start,
          originalEndTime: word.end,
        });
      });

      audioTime += scene.audioDuration;
    });

    return { audioTime, timelineImages, words };
  };

  const { audioTime, timelineImages, words } = getTimelineData();

  const totalFrames = audioTime * 30;

  return (
    <div
      style={{
        position: 'absolute',
        bottom: '0px',
        left: 0,
        width: '100%',
        zIndex: 999,
      }}
    >
      {isPlayerReady && playerRef.current ? (
        <VideoTimeline
          words={words}
          playerRef={playerRef.current}
          totalFrames={totalFrames}
          fps={30}
          images={timelineImages}
        />
      ) : (
        <Skeleton
          style={{
            height: '247px',
            backgroundColor: '#fff',
            borderTop: '1px solid #e3e3e3',
          }}
          active={true}
        />
      )}
    </div>
  );
};

export default TimelineContainer;
