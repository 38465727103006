import { Layout, Menu, Tooltip } from 'antd';
import Logo from '../../../../components/logo/logo';
import {
  AudioOutlined,
  ClockCircleOutlined,
  CustomerServiceOutlined,
  FontSizeOutlined,
  InfoCircleOutlined,
  OrderedListOutlined,
  PictureOutlined,
} from '@ant-design/icons';
import React, { FunctionComponent } from 'react';
import { VideoPart } from '../../video-editor-types';
import { useVideoContext } from '../../../../hooks/use-video';
import Changelog, {
  CHANGELOG_KEY,
} from '../../../../components/changelog/changelog';
import { useStore } from '../../../../helpers/use-store';

const VideoSidebar: FunctionComponent = () => {
  const {
    dataStore: { userStore },
  } = useStore();
  const { currentVideoPart, setCurrentVideoPart } = useVideoContext();

  let actualSelected: VideoPart = currentVideoPart;

  if (currentVideoPart === VideoPart.EditScene) {
    actualSelected = VideoPart.Scenes;
  }

  return (
    <Layout.Sider width={90}>
      <div className="logo">
        <Logo />
      </div>
      <Menu
        selectedKeys={[actualSelected]}
        mode="inline"
        defaultSelectedKeys={[VideoPart.Scenes]}
        onClick={(item) => {
          if (item.key === 'changelog') {
            localStorage.setItem(
              CHANGELOG_KEY,
              userStore.currentUser.applicationConfig?.changelogDate || ''
            );
            userStore.setChangelogCount(0);

            window.open(
              'https://shortsninja.featurebase.app/changelog',
              '_blank'
            );
            return;
          }

          setCurrentVideoPart(item.key as VideoPart);
        }}
        items={[
          {
            key: VideoPart.Thumbnail,
            label: 'Thumbnail',
            icon: <PictureOutlined />,
          },
          {
            key: VideoPart.Scenes,
            label: 'Scenes',
            icon: <OrderedListOutlined />,
          },
          {
            key: VideoPart.Subtitles,
            label: 'Subtitles',
            icon: <FontSizeOutlined />,
          },
          {
            key: VideoPart.Voiceover,
            label: 'Voiceover',
            icon: <AudioOutlined />,
          },
          // {
          //   key: VideoPart.Effects,
          //   label: 'Effects',
          //   icon: <StarOutlined />,
          // },
          {
            key: VideoPart.Music,
            label: 'Music',
            icon: <CustomerServiceOutlined />,
          },
          {
            key: VideoPart.Metadata,
            label: (
              <Tooltip
                placement={'right'}
                title={`Title, Description, Hashtags`}
              >
                <div>Metadata</div>
              </Tooltip>
            ),
            icon: <InfoCircleOutlined />,
          },
          {
            key: VideoPart.Jobs,
            label: (
              <Tooltip placement={'right'} title={`AI asynchronous jobs`}>
                <div>Jobs</div>
              </Tooltip>
            ),
            icon: <ClockCircleOutlined />,
          },
          {
            key: 'changelog',
            icon: <Changelog />,
            label: (
              <Tooltip
                placement={'right'}
                title={`Check out our latest features!`}
              >
                <div>Changelog</div>
              </Tooltip>
            ),
          },
        ]}
      />
    </Layout.Sider>
  );
};

export default VideoSidebar;
