import React from 'react';
import { Typography, Button, Space } from 'antd';
import { RocketOutlined, StarOutlined } from '@ant-design/icons';
import { useResponsiveReady } from '../../hooks/use-responive-ready';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../helpers/use-store';
import VideoCarousel from './video-craousel';

const { Title, Text } = Typography;

const ProductShowcase: React.FC = () => {
  const {
    dataStore: { userStore },
  } = useStore();
  const { isMobile } = useResponsiveReady();
  const navigate = useNavigate();

  const shorts = [
    {
      url: 'https://assets.shortsninja.com/video/675fdc054486d88268f0f08d/video.mp4',
      title: '🌟 Trending Story',
    },
    {
      url: 'https://assets.shortsninja.com/video/675ea7fb0e10bed65c8205d7/video.mp4',
      title: '🔥 Viral Hit',
    },
    {
      url: 'https://assets.shortsninja.com/video/6759430d3c7370cc20cd1565/video.mp4',
      title: '✨ Creative Magic',
    },
    {
      url: 'https://assets.shortsninja.com/video/675095ca06df5af303fc92b8/video.mp4',
      title: '🚀 Growth Hack',
    },
  ];

  const onBtnClick = () => {
    if (userStore.isSubscriptionActive) {
      const isSeries = window.location.pathname.includes('series');
      navigate(`/${isSeries ? 'series' : 'videos'}/form/new`);
      return;
    }

    navigate('/settings/billing');
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        maxWidth: '1200px',
        margin: '0 auto',
        textAlign: 'center',
      }}
    >
      <Space direction="vertical" size="small" style={{ width: '100%' }}>
        <div>
          <Title
            level={3}
            style={{
              margin: '0 0 4px 0',
              fontSize: isMobile ? '20px' : '24px',
            }}
          >
            👑 Welcome to ShortsNinja! 🎉
          </Title>
          <Text
            type="secondary"
            style={{ fontSize: isMobile ? '14px' : '16px' }}
          >
            ✨ Unleash Your Creativity with AI-Powered Videos!
          </Text>
        </div>

        <VideoCarousel />

        {/*<div*/}
        {/*  style={{*/}
        {/*    margin: '20px 0',*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <div*/}
        {/*    style={{*/}
        {/*      background: '#fff',*/}
        {/*      borderRadius: '12px',*/}
        {/*      overflow: 'hidden',*/}
        {/*      boxShadow: '0 2px 8px rgba(0,0,0,0.1)',*/}
        {/*      height: isMobile ? 'auto' : '350px',*/}
        {/*      maxWidth: '800px',*/}
        {/*      margin: '0 auto',*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <Text*/}
        {/*      strong*/}
        {/*      style={{*/}
        {/*        marginBottom: '8px',*/}
        {/*        display: 'block',*/}
        {/*        fontSize: isMobile ? '14px' : '16px',*/}
        {/*        padding: '8px',*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      ✨ Craft Your Video Story*/}
        {/*    </Text>*/}
        {/*    <video*/}
        {/*      autoPlay*/}
        {/*      muted*/}
        {/*      loop*/}
        {/*      playsInline*/}
        {/*      style={{*/}
        {/*        width: '100%',*/}
        {/*        height: 'calc(100% - 40px)',*/}
        {/*        objectFit: 'cover',*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      <source*/}
        {/*        src="https://assets.shortsninja.com/product-demos/full-with-logo.mp4"*/}
        {/*        type="video/mp4"*/}
        {/*      />*/}
        {/*    </video>*/}
        {/*  </div>*/}
        {/*</div>*/}

        <div
          style={{
            width: '100%',
            maxWidth: isMobile ? '100%' : '400px',
            margin: '0 auto',
          }}
        >
          <Button
            type="primary"
            size="large"
            icon={<RocketOutlined />}
            style={{
              height: '44px',
              fontSize: isMobile ? '14px' : '16px',
              width: '100%',
            }}
            onClick={onBtnClick}
          >
            Get Started Now!
          </Button>

          <div style={{ marginTop: '8px' }}>
            <Text
              type="secondary"
              style={{
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'center',
                gap: '4px',
                fontSize: isMobile ? '12px' : '14px',
              }}
            >
              <StarOutlined style={{ marginTop: '4px' }} />
              Generate viral-ready videos in minutes ⚡️ No editing skills
              needed
            </Text>
          </div>
        </div>
      </Space>
    </div>
  );
};

export default ProductShowcase;
