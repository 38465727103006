import React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

const DisabledText = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <Text type="secondary" style={{ fontSize: 12 }}>
        ✨ Coming soon to make your experience even better! 🚀
      </Text>
    </div>
  );
};

export default DisabledText;
