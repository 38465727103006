import React from 'react';
import { Card, Flex, Space, Statistic, Tag, Typography } from 'antd';
import { useStore } from '../../../helpers/use-store';
import { SubscriptionStatusName } from '@monorepo/types';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import SubscriptionActions from './subscription-actions';

const getStatusColor = (status?: SubscriptionStatusName) => {
  switch (status) {
    case SubscriptionStatusName.Active:
      return 'success';
    case SubscriptionStatusName.Suspended:
    case SubscriptionStatusName.Terminated:
    case SubscriptionStatusName.Finished:
      return 'default';
    default:
      return 'default';
  }
};

const getDateLabel = (status?: SubscriptionStatusName) => {
  switch (status) {
    case SubscriptionStatusName.Active:
      return 'Next Renewal';
    case SubscriptionStatusName.Suspended:
    case SubscriptionStatusName.Terminated:
    case SubscriptionStatusName.Finished:
      return 'Subscription End';
    default:
      return 'Subscription End';
  }
};

const SubscriptionInfo = () => {
  const {
    dataStore: { userStore, planStore },
  } = useStore();
  const subscription = userStore.userSubscription;

  const { credits, allowedSeriesAmount, status, nextChargeDate, planId } =
    subscription;

  const plan = planStore.get(planId || '');

  const isActive = status === SubscriptionStatusName.Active;
  const isBeforeRenewal = dayjs().isBefore(dayjs(nextChargeDate));

  return (
    <Card
      style={{ height: '100%' }}
      title={
        <div style={{ padding: '15px 0' }}>
          <Flex align={'center'} justify={'start'} gap={15}>
            <Typography.Title style={{ margin: 0 }} level={5}>
              Subscription Details
            </Typography.Title>
            <Tag color={getStatusColor(status)}>
              {isActive ? 'Active' : 'Inactive'}
            </Tag>
          </Flex>
          {userStore.userSubscription.status ===
          SubscriptionStatusName.Suspended ? (
            <Typography.Text type={'secondary'}>
              You can renew your subscription at any time, just click on the 3
              dots on the right and the renew button.
            </Typography.Text>
          ) : null}
        </div>
      }
      extra={<SubscriptionActions />}
    >
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <Space size={50} wrap>
          <Statistic
            title={'Plan'}
            value={plan && isActive ? plan.name : 'Free'}
          />
          <Statistic title="Available Credits" value={credits} precision={1} />
          <Statistic
            title="Series Limit"
            value={allowedSeriesAmount}
            precision={0}
          />
          {nextChargeDate && isActive ? (
            <Statistic
              title={getDateLabel(status)}
              value={new Date(nextChargeDate).toLocaleDateString()}
            />
          ) : null}
          {nextChargeDate && isBeforeRenewal && !isActive ? (
            <Statistic
              title={getDateLabel(status)}
              value={new Date(nextChargeDate).toLocaleDateString()}
            />
          ) : null}
        </Space>
      </Space>
    </Card>
  );
};

export default observer(SubscriptionInfo);
