import { OverlayThumbnail } from '../../../remotion-overlays';
import { FunctionComponent } from 'react';

const TiktokThumbnail: FunctionComponent<{
  data: OverlayThumbnail;
}> = ({ data }) => {
  return (
    <div
      style={{
        fontSize: '75px',
        position: 'absolute',
        textAlign: 'center',
        boxSizing: 'border-box',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        flexFlow: 'wrap',
        color: '#000',
        left: '50%',
        transform: 'translateX(-50%) rotate(-6deg)',
        width: '100%',
        maxWidth: '80%',
        backgroundColor: 'rgb(255, 255, 255)',
        borderRadius: '10px',
      }}
    >
      <div
        style={{
          padding: '80px',
          minHeight: '250px',
          width: '100%',
          position: 'relative',
          zIndex: 1,
          backgroundSize: '100% 35px, 100% 35px, 35px 100%, 35px 100%',
          backgroundRepeat: 'no-repeat, no-repeat, no-repeat, no-repeat',
          backgroundPosition:
            'center top, center bottom, left center, right center',
          backgroundImage: `
            linear-gradient(to left, rgb(112, 226, 255) 0px, rgb(112, 226, 255) 33.3%, rgb(112, 226, 255) 33.3%, rgb(112, 226, 255) 66.6%, rgb(112, 226, 255) 66.6%, rgb(112, 226, 255)),
            linear-gradient(to right, rgb(0, 0, 0) 0px, rgb(0, 0, 0) 20%, rgb(255, 73, 73) 20%, rgb(255, 73, 73) 66.6%, rgb(255, 73, 73) 66.6%, rgb(255, 73, 73)),
            linear-gradient(to top, rgb(0, 0, 0) 0px, rgb(0, 0, 0) 33.3%, rgb(112, 226, 255) 33.3%, rgb(112, 226, 255) 66.6%, rgb(112, 226, 255) 66.6%, rgb(112, 226, 255)),
            linear-gradient(to top, rgb(255, 73, 73) 0px, rgb(255, 73, 73) 33.3%, rgb(112, 226, 255) 33.3%, rgb(112, 226, 255) 66.6%, rgb(112, 226, 255) 66.6%, rgb(112, 226, 255))
          `,
        }}
      >
        <span
          style={{
            fontFamily: 'Permanent Marker',
            display: 'block',
            overflowWrap: 'break-word',
            whiteSpace: 'normal',
            wordBreak: 'break-word',
          }}
        >
          {data.text}
        </span>
      </div>
    </div>
  );
};

export default TiktokThumbnail;
