// ImageBlobManager.ts
class ImageBlobManager {
  private maxSize: number;
  private urlCache: Map<string, string> = new Map();
  private loadingImages: Map<string, Promise<string>> = new Map();

  constructor(maxSize = 100) {
    this.maxSize = maxSize;
  }

  public getImage(imageUrl: string): string {
    if (this.urlCache.has(imageUrl)) {
      return this.urlCache.get(imageUrl)!;
    }

    if (this.urlCache.size >= this.maxSize) {
      const firstKey = this.urlCache.keys().next().value || '';
      URL.revokeObjectURL(this.urlCache.get(firstKey)!);
      this.urlCache.delete(firstKey);
    }

    this.loadImageInBackground(imageUrl);
    return imageUrl;
  }

  private async loadImageInBackground(imageUrl: string): Promise<string> {
    // If already loading, return existing promise
    if (this.loadingImages.has(imageUrl)) {
      return this.loadingImages.get(imageUrl)!;
    }

    const loadPromise = (async () => {
      try {
        const response = await fetch(imageUrl);
        const blob = await response.blob();

        const blobUrl = URL.createObjectURL(blob);
        this.urlCache.set(imageUrl, blobUrl);

        return blobUrl;
      } catch (error) {
        console.error('Error loading image:', error);
        return imageUrl;
      } finally {
        // Clean up loading promise after completion
        this.loadingImages.delete(imageUrl);
      }
    })();

    // Store the loading promise
    this.loadingImages.set(imageUrl, loadPromise);

    return loadPromise;
  }

  public preloadImage(imageUrl: string): Promise<string> {
    if (this.urlCache.has(imageUrl)) {
      return Promise.resolve(this.urlCache.get(imageUrl)!);
    }
    return this.loadImageInBackground(imageUrl);
  }

  public clearCache(): void {
    this.urlCache.forEach((blobUrl) => {
      URL.revokeObjectURL(blobUrl);
    });
    this.urlCache.clear();
    this.loadingImages.clear();
  }

  public removeFromCache(imageUrl: string): void {
    if (this.urlCache.has(imageUrl)) {
      URL.revokeObjectURL(this.urlCache.get(imageUrl)!);
      this.urlCache.delete(imageUrl);
    }
    this.loadingImages.delete(imageUrl);
  }
}

export const imageBlobManager = new ImageBlobManager(100);
