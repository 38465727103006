import { IdIdentifier, Timestamps } from '../common';

export type Voiceover = IdIdentifier &
  Timestamps & {
    name: string;
    externalId: string;
    audioUrl: string;
    provider: VoiceoverProvider;
    tags: string[];
    languages: string[];
    models: string[];
    description?: string;
    order?: number;
    imageUrl?: string;
    gender: string;
  };

export enum OpenAiVoiceOverName {
  Adam = 'adam',
  Alloy = 'alloy',
  Echo = 'echo',
  Fable = 'fable',
  Onyx = 'onyx',
  Nova = 'nova',
  Shimmer = 'shimmer',
}

export enum VoiceoverProvider {
  Google = 'google',
  GoogleTranslate = 'free-google',
  AmazonPolly = 'amazon-polly',
  OpenAi = 'open-ai',
  Speechify = 'speechify',
  ElevenLabs = 'eleven-labs',
}

export interface Word {
  word: string; // The transcribed word
  start: number; // Start time of the word in seconds
  end: number; // End time of the word in seconds
}
