// Default spring timing configuration
import { TransitionEffect } from '@monorepo/types';
import { linearTiming } from '@remotion/transitions';
import { fade } from '@remotion/transitions/fade';
import { slide } from '@remotion/transitions/slide';
import { wipe } from '@remotion/transitions/wipe';
import { flip } from '@remotion/transitions/flip';
import { clockWipe } from '@remotion/transitions/clock-wipe';
import { none } from '@remotion/transitions/none';
import {
  pullInTransition,
  rotateCwOne,
  zoomIn,
} from '../remotion-custom-transitions';
import { glTransition } from '../remotion-custom-transitions/gl-transition';
import { Easing } from 'remotion';

const DEFAULT_SPRING_CONFIG = {
  damping: 200,
  mass: 0.8,
  stiffness: 80,
};

export const getDefaultDurationInFrames = (
  effect: TransitionEffect,
  fps: number
) => {
  switch (effect) {
    case TransitionEffect.ZoomIn:
      return Math.round(fps * 1.5); // 1.5 seconds for zoom in
    case TransitionEffect.Slide:
      return Math.round(fps * 0.5); // 0.5 seconds for a quick slide
    case TransitionEffect.Wipe:
      return Math.round(fps * 1); // 1 second for a standard wipe
    case TransitionEffect.Flip:
      return Math.round(fps * 2); // 2 seconds for a more dramatic flip
    case TransitionEffect.ClockWipe:
      return Math.round(fps * 1.5); // 1.5 seconds for clock wipe
    case TransitionEffect.ClockWise:
      return Math.round(fps * 1.5); // 1.5 seconds for clockwise rotation
    case TransitionEffect.TestGL:
      return Math.round(fps * 1.5); // 1.5 seconds for clockwise rotation
    case TransitionEffect.PullIn:
      return Math.round(fps * 3); // 1 second for pull-in effect
    case TransitionEffect.None:
      return fps * 0.1; // No transition duration for 'None'
    case TransitionEffect.Fade:
    default:
      return Math.round(fps * 1); // Default to 1 second for fades and unrecognized effects
  }
};

export const createTransition = ({
  effect = TransitionEffect.Fade,
  width,
  height,
  fps = 30,
  fromSrc,
  toSrc,
}) => {
  const durationInFrames = effect === TransitionEffect.None ? 5 : 20;

  const timing = linearTiming({
    durationInFrames,
  });

  switch (effect) {
    case TransitionEffect.ZoomIn:
      return {
        timing,
        presentation: zoomIn(),
        durationInFrames,
      };
    case TransitionEffect.Slide:
      return {
        timing,
        presentation: slide({
          direction: 'from-left',
        }),
        durationInFrames,
      };

    case TransitionEffect.Wipe:
      return {
        timing,
        presentation: wipe({
          direction: 'from-left',
        }),
        durationInFrames,
      };

    case TransitionEffect.Flip:
      return {
        timing,
        presentation: flip({
          perspective: 1000,
        }),
        durationInFrames,
      };

    case TransitionEffect.ClockWipe:
      return {
        timing,
        presentation: clockWipe({
          width,
          height,
        }),
        durationInFrames,
      };
    case TransitionEffect.ClockWise:
      return {
        timing,
        presentation: rotateCwOne(),
        durationInFrames,
      };
    case TransitionEffect.TestGL:
      return {
        timing,
        presentation: glTransition({
          width,
          height,
          transitionName: 'CrossZoom',
          fromSrc,
          toSrc,
        }),
        durationInFrames,
      };
    case TransitionEffect.PullIn:
      return {
        timing,
        presentation: pullInTransition({
          width,
          height,
        }),
        durationInFrames,
      };

    case TransitionEffect.None:
      return {
        timing,
        presentation: none(),
        durationInFrames: 5,
      };

    case TransitionEffect.Fade:
    default:
      return {
        timing,
        presentation: fade(),
        durationInFrames,
      };
  }
};
