import { observer } from 'mobx-react-lite';
import SocialAccountSelect from '../../social-accounts/social-account';
import React from 'react';
import { Helmet } from 'react-helmet';

const SocialAccountsTab = () => {
  return (
    <div className="social-accounts-tab">
      <Helmet>
        <title>Social Accounts | ShortsNinja</title>
        <meta
          name="description"
          content="Connect and manage your social media accounts. Automate your faceless video distribution."
        />
        <meta
          name="keywords"
          content="social accounts, account connection, ShortsNinja, faceless videos, ai video automation"
        />
      </Helmet>
      <SocialAccountSelect />
    </div>
  );
};

export default observer(SocialAccountsTab);
