export interface ThumbnailConfig {
  enabled: boolean;
  text: string;
  type: ThumbnailType;
  // TODO: implement
  enableVoiceover: boolean;
  voiceoverUrl: string;
  duration: number;
}

export enum ThumbnailType {
  Reddit = 'reddit',
  TikTok = 'tiktok',
  Text = 'text',
}

export const defaultThumbnailConfig: ThumbnailConfig = {
  enabled: false,
  type: ThumbnailType.TikTok,
  enableVoiceover: false,
  duration: 0,
  text: '',
  voiceoverUrl: '',
};
