import { baseSubtitles, SubtitlesConfig } from '@monorepo/types';

export enum SubtitlePresetName {
  LUCKY_DEFAULT = 'LUCKY_DEFAULT',
  TITAN_GAMING = 'TITAN_GAMING',
  BUNGEE_FRESH = 'BUNGEE_FRESH',
  BAKBAK_BOLD = 'BAKBAK_BOLD',
  BANGERS_POP = 'BANGERS_POP',
  CREEPSTER_DARK = 'CREEPSTER_DARK',
  MARKER_NEON = 'MARKER_NEON',
  RAMPART_STREET = 'RAMPART_STREET',
  RANCHERS_WILD = 'RANCHERS_WILD',
  KNEWAVE_SPLASH = 'KNEWAVE_SPLASH',
  JUA_KAWAII = 'JUA_KAWAII',
  BEBAS_CLEAN = 'BEBAS_CLEAN',
}

export const presetDisplayNames: Record<SubtitlePresetName, string> = {
  [SubtitlePresetName.LUCKY_DEFAULT]: 'Default Style',
  [SubtitlePresetName.TITAN_GAMING]: 'Gaming Bold',
  [SubtitlePresetName.BUNGEE_FRESH]: 'Fresh Green',
  [SubtitlePresetName.BAKBAK_BOLD]: 'Bold Impact',
  [SubtitlePresetName.BANGERS_POP]: 'Pop Style',
  [SubtitlePresetName.CREEPSTER_DARK]: 'Dark Mode',
  [SubtitlePresetName.MARKER_NEON]: 'Neon Marker',
  [SubtitlePresetName.RAMPART_STREET]: 'Street Art',
  [SubtitlePresetName.RANCHERS_WILD]: 'Wild West',
  [SubtitlePresetName.KNEWAVE_SPLASH]: 'Splash Wave',
  [SubtitlePresetName.JUA_KAWAII]: 'Kawaii Style',
  [SubtitlePresetName.BEBAS_CLEAN]: 'Clean Modern',
};

export const subtitlePresets: Record<SubtitlePresetName, SubtitlesConfig> = {
  [SubtitlePresetName.LUCKY_DEFAULT]: {
    ...baseSubtitles,
    numberOfWords: 2,
    fontName: 'Luckiest Guy',
    fontSize: 58,
    primaryColor: '#FFFFFF',
    highlightColor: '#FF5722',
    outline: 2,
    outlineColor: '#000000',
    uppercase: true,
    positionY: 35,
  },
  [SubtitlePresetName.TITAN_GAMING]: {
    ...baseSubtitles,
    numberOfWords: 2,
    fontName: 'Titan One',
    fontSize: 58,
    primaryColor: '#FFFFFF',
    highlightColor: '#2196F3',
    outline: 2,
    outlineColor: '#000000',
    backColor: undefined,
    uppercase: true,
    positionY: 35,
  },
  [SubtitlePresetName.BUNGEE_FRESH]: {
    ...baseSubtitles,
    numberOfWords: 2,
    fontName: 'Bungee',
    fontSize: 58,
    primaryColor: '#FFFFFF',
    highlightColor: '#4CAF50', // Orange like in the image
    outline: 2,
    outlineColor: '#000000',
    backColor: undefined,
    uppercase: true,
    positionY: 35,
  },
  [SubtitlePresetName.BAKBAK_BOLD]: {
    ...baseSubtitles,
    numberOfWords: 3,
    fontName: 'Bakbak One',
    fontSize: 58,
    primaryColor: '#E91E63',
    highlightColor: '#E91E63',
    outline: 0,
    outlineColor: '#000000',
    backColor: undefined,
    uppercase: true,
    positionY: 35,
  },
  [SubtitlePresetName.BANGERS_POP]: {
    ...baseSubtitles,
    numberOfWords: 4,
    fontName: 'Bangers',
    fontSize: 58,
    primaryColor: '#FFFFFF',
    highlightColor: '#FF4081',
    outline: 2,
    outlineColor: '#000000',
    backColor: '#00000080',
    uppercase: true,
    positionY: 35,
  },
  [SubtitlePresetName.CREEPSTER_DARK]: {
    ...baseSubtitles,
    numberOfWords: 2,
    fontName: 'Creepster',
    fontSize: 58,
    primaryColor: '#FFFFFF',
    highlightColor: '#fa0000',
    outline: 2,
    outlineColor: '#000000',
    backColor: undefined,
    uppercase: true,
    positionY: 35,
  },
  [SubtitlePresetName.MARKER_NEON]: {
    ...baseSubtitles,
    numberOfWords: 2,
    fontName: 'Permanent Marker',
    fontSize: 58,
    primaryColor: '#673AB7',
    highlightColor: '#673AB7',
    outline: 0,
    outlineColor: '#000000',
    backColor: undefined,
    uppercase: true,
    positionY: 35,
  },
  [SubtitlePresetName.RAMPART_STREET]: {
    ...baseSubtitles,
    numberOfWords: 1,
    fontName: 'Rampart One',
    fontSize: 58,
    primaryColor: '#FFFFFF',
    highlightColor: '#FF5722',
    outline: 1,
    outlineColor: '#000000',
    uppercase: true,
    positionY: 35,
  },
  [SubtitlePresetName.RANCHERS_WILD]: {
    ...baseSubtitles,
    numberOfWords: 4,
    fontName: 'Ranchers',
    fontSize: 58,
    primaryColor: '#FFC107',
    highlightColor: '#FF9800',
    outline: 2,
    outlineColor: '#000000',
    backColor: undefined,
    uppercase: true,
    positionY: 35,
  },
  [SubtitlePresetName.KNEWAVE_SPLASH]: {
    ...baseSubtitles,
    numberOfWords: 2,
    fontName: 'Knewave',
    fontSize: 58,
    primaryColor: '#3F51B5',
    highlightColor: '#3F51B5',
    outline: 2,
    outlineColor: '#FFFFFF',
    backColor: undefined,
    uppercase: true,
    positionY: 35,
  },
  [SubtitlePresetName.JUA_KAWAII]: {
    ...baseSubtitles,
    numberOfWords: 2,
    fontName: 'Jua',
    fontSize: 58,
    primaryColor: '#9C27B0',
    highlightColor: '#7B1FA2',
    outline: 0,
    outlineColor: '#000000',
    backColor: undefined,
    uppercase: true,
    positionY: 35,
  },
  [SubtitlePresetName.BEBAS_CLEAN]: {
    ...baseSubtitles,
    numberOfWords: 2,
    fontName: 'Bebas Neue',
    fontSize: 58,
    primaryColor: '#212121',
    highlightColor: '#F44336',
    outline: 2,
    outlineColor: '#FFFFFF',
    backColor: undefined,
    uppercase: true,
    positionY: 35,
  },
};
