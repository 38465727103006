import React from 'react';

import './video-carousel.scss';

const VideoCarousel = () => {
  const videos = [
    {
      poster:
        'https://assets.shortsninja.com/user/6707de22976dc499ff1491b8/mQqBXdmo5kTb9n4EjRB3.jpeg?date=1737106392122',
      source:
        'https://assets.shortsninja.com/video/678a20f9923ec86a7ad81c49/video.mp4',
      style: 'Cinematic',
      title: "A Bold Pirate's Tale! ☠️👑 🐕",
    },
    {
      poster:
        'https://assets.shortsninja.com/user/6707de22976dc499ff1491b8/XFtGZlhgiE6RqO5CU13X.jpeg?date=1737102393918',
      source:
        'https://assets.shortsninja.com/video/678982c938706f39ab94d7a4/video.mp4',
      style: 'Japanese Inc',
      title: 'Epic life of Miyamoto Musashi ⚔️🌟',
    },
    {
      poster:
        'https://assets.shortsninja.com/user/6707de22976dc499ff1491b8/2t63p0KOPuqD9SYV1LP7.jpeg?date=1737101672980',
      source:
        'https://assets.shortsninja.com/video/6789966ab30ab4eac2cf5b55/video.mp4',
      style: 'Lego',
      title: 'Star Wars Character 🚀✨',
    },
    {
      poster:
        'https://assets.shortsninja.com/user/6707de22976dc499ff1491b8/NQts11P6fiSFzAuCvQkO.jpeg?date=1737105283888',
      source:
        'https://assets.shortsninja.com/video/678a1c40e46fe7b169fa39a6/video.mp4',
      style: 'Playground',
      title: "Magic of Ella's Moonlit Adventure! 🌙🐘",
    },
    {
      poster:
        'https://assets.shortsninja.com/user/6707de22976dc499ff1491b8/soGg6x7d8yMtFav4eUqI.jpeg?date=1737102940679',
      source:
        'https://assets.shortsninja.com/video/6789820e38706f39ab94d61e/video.mp4',
      style: 'Motivational',
      title: 'Unleash Your Inner Champion! 🏆🔥',
    },
    {
      poster:
        'https://assets.shortsninja.com/user/6707de22976dc499ff1491b8/TY4GuLUNJFaHOncblbcc.jpeg?date=1737108583677',
      source:
        'https://assets.shortsninja.com/video/678a2b819916abd1a19ad14f/video.mp4',
      style: 'Neon Futuristic',
      title: 'Epic Chase Scene 🥷',
    },
    {
      poster:
        'https://assets.shortsninja.com/user/6707de22976dc499ff1491b8/GF6NSRe06sKrqC6yFQzQ.jpeg?date=1737107307072',
      source:
        'https://assets.shortsninja.com/video/678a2533923ec86a7ad81fb2/video.mp4',
      style: 'Realistic',
      title: 'Perfume Smells Like Heaven! ✨',
    },
    {
      poster:
        'https://assets.shortsninja.com/user/6707de22976dc499ff1491b8/hYKrSRdsDjw8J2bcJe3D.jpeg?date=1737108245131',
      source:
        'https://assets.shortsninja.com/video/678a2a159916abd1a19acf99/video.mp4',
      style: 'Analog',
      title: 'Life After Love 💔',
    },
  ];
  return (
    <div className="video-carousel">
      <div className="carousel-container">
        {videos.concat(videos).map((video, index) => (
          <div key={index} className="carousel-slide">
            <div className="video-container">
              <video poster={video.poster} autoPlay loop muted playsInline>
                <source src={video.source} type="video/mp4" />
              </video>
              <div className="slide-content">
                <div className="style-label">Style: {video.style}</div>
                <div className="slide-title">{video.title}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VideoCarousel;
