import { MotionType } from '@monorepo/types';

export const motionDisplayText: Record<MotionType, string> = {
  // Circular Motions
  [MotionType.CIRCULAR_CLOCKWISE]: 'Circular Clockwise',
  [MotionType.CIRCULAR_COUNTERCLOCKWISE]: 'Circular Counterclockwise',
  [MotionType.SPIRAL_TOWARD_CENTER]: 'Spiral Inward',
  [MotionType.SPIRAL_OUT_FROM_CENTER]: 'Spiral Outward',
  [MotionType.FIGURE_EIGHT]: 'Figure Eight',

  // Pan Motions
  [MotionType.PAN_LEFT_TO_RIGHT]: 'Pan Left to Right',
  [MotionType.PAN_RIGHT_TO_LEFT]: 'Pan Right to Left',
  [MotionType.PAN_TOP_TO_BOTTOM]: 'Pan Top to Bottom',
  [MotionType.PAN_BOTTOM_TO_TOP]: 'Pan Bottom to Top',
  [MotionType.DIAGONAL_PAN_TOP_LEFT_TO_BOTTOM_RIGHT]:
    'Diagonal Pan Top-Left to Bottom-Right',
  [MotionType.DIAGONAL_PAN_BOTTOM_RIGHT_TO_TOP_LEFT]:
    'Diagonal Pan Bottom-Right to Top-Left',

  // Random Motions
  [MotionType.RANDOM_MOTION_PATHS]: 'Random Motion',
  [MotionType.BOUNCE_EFFECT]: 'Bounce',
  [MotionType.SHAKE_EFFECT]: 'Shake',
  [MotionType.ZIGZAG_MOTION]: 'Zigzag',
  [MotionType.WOBBLE_EFFECT]: 'Wobble',
  [MotionType.SWAY_LEFT_AND_RIGHT]: 'Sway Left-Right',
  [MotionType.SWAY_UP_AND_DOWN]: 'Sway Up-Down',
  [MotionType.RANDOM_JITTER]: 'Random Jitter',

  // Zoom Motions
  [MotionType.NO_MOTION]: 'No Motion',
  [MotionType.GENTLE_RANDOM_MOTION]: 'Gentle Random Motion',
  [MotionType.ZOOM_INTO_TOP_LEFT]: 'Zoom Into Top-Left',
  [MotionType.GENTLE_ZOOM_FROM_TOP]: 'Gentle Zoom From Top',
  [MotionType.ZOOM_OUT_FROM_CENTER]: 'Zoom Out From Center',
  [MotionType.DIAGONAL_MOVE_WITH_ZOOM_IN]: 'Diagonal Zoom In',
  [MotionType.ZOOM_INTO_BOTTOM_RIGHT]: 'Zoom Into Bottom-Right',
  [MotionType.ZOOM_IN_WHILE_PANNING_RIGHT]: 'Zoom In Pan Right',
  [MotionType.ZOOM_OUT_WHILE_PANNING_UP]: 'Zoom Out Pan Up',
  [MotionType.SLOW_ZOOM_IN_AND_PAN_LEFT]: 'Slow Zoom In Pan Left',
  [MotionType.FAST_ZOOM_OUT_WITH_SLIGHT_ROTATE]: 'Fast Zoom Out with Rotation',
  [MotionType.HEARTBEAT_ZOOM]: 'Heartbeat Zoom',
  [MotionType.ZOOM_IN_AND_ROTATE]: 'Zoom In and Rotate',
  [MotionType.PULSE_ZOOM_IN_AND_OUT]: 'Pulse Zoom',
};

export interface MotionResult {
  disableConnectToNext?: boolean;
  transform: string;
  recommendedEasing: (t: number) => number;
  startPosition: {
    scale: number;
    translateX: number;
    translateY: number;
    rotate: number;
  };
  currentPosition: {
    scale: number;
    translateX: number;
    translateY: number;
    rotate: number;
  };
}
