export enum Languages {
  Afrikaans = 'af',
  Arabic = 'ar',
  Armenian = 'hy',
  Azerbaijani = 'az',
  Belarusian = 'be',
  Bosnian = 'bs',
  Bulgarian = 'bg',
  Catalan = 'ca',
  Chinese = 'zh',
  Croatian = 'hr',
  Czech = 'cs',
  Danish = 'da',
  Dutch = 'nl',
  English = 'en',
  Estonian = 'et',
  Finnish = 'fi',
  French = 'fr',
  Galician = 'gl',
  German = 'de',
  Greek = 'el',
  Hebrew = 'he',
  Hindi = 'hi',
  Hungarian = 'hu',
  Icelandic = 'is',
  Indonesian = 'id',
  Italian = 'it',
  Japanese = 'ja',
  Kannada = 'kn',
  Kazakh = 'kk',
  Korean = 'ko',
  Latvian = 'lv',
  Lithuanian = 'lt',
  Macedonian = 'mk',
  Malay = 'ms',
  Marathi = 'mr',
  Maori = 'mi',
  Nepali = 'ne',
  Norwegian = 'no',
  Persian = 'fa',
  Polish = 'pl',
  Portuguese = 'pt',
  Romanian = 'ro',
  Russian = 'ru',
  Serbian = 'sr',
  Slovak = 'sk',
  Slovenian = 'sl',
  Spanish = 'es',
  Swahili = 'sw',
  Swedish = 'sv',
  Tagalog = 'tl',
  Tamil = 'ta',
  Thai = 'th',
  Turkish = 'tr',
  Ukrainian = 'uk',
  Urdu = 'ur',
  Vietnamese = 'vi',
  Welsh = 'cy',
  EnglishAustralia = 'en-AU',
  EnglishCanada = 'en-CA',
  FrenchCanada = 'fr-CA',
  SpanishMexico = 'es-MX',
  Filipino = 'fil-PH',
  ArabicUAE = 'ar-AE',
}

export interface LanguageMetadata {
  name: string;
  flag: string;
  nativeText: string;
  locale: string;
}

export const LANGUAGE_METADATA: Record<Languages, LanguageMetadata> = {
  [Languages.Afrikaans]: {
    name: 'Afrikaans',
    flag: '🇿🇦',
    nativeText: 'Afrikaans',
    locale: 'af-ZA',
  },
  [Languages.Arabic]: {
    name: 'Arabic',
    flag: '🇸🇦',
    nativeText: 'العربية',
    locale: 'ar-AE',
  },
  [Languages.Armenian]: {
    name: 'Armenian',
    flag: '🇦🇲',
    nativeText: 'Հայերեն',
    locale: 'hy-AM',
  },
  [Languages.Azerbaijani]: {
    name: 'Azerbaijani',
    flag: '🇦🇿',
    nativeText: 'Azərbaycan',
    locale: 'az-AZ',
  },
  [Languages.Belarusian]: {
    name: 'Belarusian',
    flag: '🇧🇾',
    nativeText: 'Беларуская',
    locale: 'be-BY',
  },
  [Languages.Bosnian]: {
    name: 'Bosnian',
    flag: '🇧🇦',
    nativeText: 'Bosanski',
    locale: 'bs-BA',
  },
  [Languages.Bulgarian]: {
    name: 'Bulgarian',
    flag: '🇧🇬',
    nativeText: 'Български',
    locale: 'bg-BG',
  },
  [Languages.Catalan]: {
    name: 'Catalan',
    flag: '🏴󠁥󠁳󠁣󠁴󠁿',
    nativeText: 'Català',
    locale: 'ca-ES',
  },
  [Languages.Chinese]: {
    name: 'Chinese',
    flag: '🇨🇳',
    nativeText: '中文',
    locale: 'zh-CN',
  },
  [Languages.Croatian]: {
    name: 'Croatian',
    flag: '🇭🇷',
    nativeText: 'Hrvatski',
    locale: 'hr-HR',
  },
  [Languages.Czech]: {
    name: 'Czech',
    flag: '🇨🇿',
    nativeText: 'Čeština',
    locale: 'cs-CZ',
  },
  [Languages.Danish]: {
    name: 'Danish',
    flag: '🇩🇰',
    nativeText: 'Dansk',
    locale: 'da-DK',
  },
  [Languages.Dutch]: {
    name: 'Dutch',
    flag: '🇳🇱',
    nativeText: 'Nederlands',
    locale: 'nl-NL',
  },
  [Languages.English]: {
    name: 'English',
    flag: '🇬🇧',
    nativeText: 'English',
    locale: 'en-US',
  },
  [Languages.Estonian]: {
    name: 'Estonian',
    flag: '🇪🇪',
    nativeText: 'Eesti',
    locale: 'et-EE',
  },
  [Languages.Finnish]: {
    name: 'Finnish',
    flag: '🇫🇮',
    nativeText: 'Suomi',
    locale: 'fi-FI',
  },
  [Languages.French]: {
    name: 'French',
    flag: '🇫🇷',
    nativeText: 'Français',
    locale: 'fr-FR',
  },
  [Languages.Galician]: {
    name: 'Galician',
    flag: '🇪🇸',
    nativeText: 'Galego',
    locale: 'gl-ES',
  },
  [Languages.German]: {
    name: 'German',
    flag: '🇩🇪',
    nativeText: 'Deutsch',
    locale: 'de-DE',
  },
  [Languages.Greek]: {
    name: 'Greek',
    flag: '🇬🇷',
    nativeText: 'Ελληνικά',
    locale: 'el-GR',
  },
  [Languages.Hebrew]: {
    name: 'Hebrew',
    flag: '🇮🇱',
    nativeText: 'עברית',
    locale: 'he-IL',
  },
  [Languages.Hindi]: {
    name: 'Hindi',
    flag: '🇮🇳',
    nativeText: 'हिन्दी',
    locale: 'hi-IN',
  },
  [Languages.Hungarian]: {
    name: 'Hungarian',
    flag: '🇭🇺',
    nativeText: 'Magyar',
    locale: 'hu-HU',
  },
  [Languages.Icelandic]: {
    name: 'Icelandic',
    flag: '🇮🇸',
    nativeText: 'Íslenska',
    locale: 'is-IS',
  },
  [Languages.Indonesian]: {
    name: 'Indonesian',
    flag: '🇮🇩',
    nativeText: 'Bahasa Indonesia',
    locale: 'id-ID',
  },
  [Languages.Italian]: {
    name: 'Italian',
    flag: '🇮🇹',
    nativeText: 'Italiano',
    locale: 'it-IT',
  },
  [Languages.Japanese]: {
    name: 'Japanese',
    flag: '🇯🇵',
    nativeText: '日本語',
    locale: 'ja-JP',
  },
  [Languages.Kannada]: {
    name: 'Kannada',
    flag: '🇮🇳',
    nativeText: 'ಕನ್ನಡ',
    locale: 'kn-IN',
  },
  [Languages.Kazakh]: {
    name: 'Kazakh',
    flag: '🇰🇿',
    nativeText: 'Қазақ',
    locale: 'kk-KZ',
  },
  [Languages.Korean]: {
    name: 'Korean',
    flag: '🇰🇷',
    nativeText: '한국어',
    locale: 'ko-KR',
  },
  [Languages.Latvian]: {
    name: 'Latvian',
    flag: '🇱🇻',
    nativeText: 'Latviešu',
    locale: 'lv-LV',
  },
  [Languages.Lithuanian]: {
    name: 'Lithuanian',
    flag: '🇱🇹',
    nativeText: 'Lietuvių',
    locale: 'lt-LT',
  },
  [Languages.Macedonian]: {
    name: 'Macedonian',
    flag: '🇲🇰',
    nativeText: 'Македонски',
    locale: 'mk-MK',
  },
  [Languages.Malay]: {
    name: 'Malay',
    flag: '🇲🇾',
    nativeText: 'Bahasa Melayu',
    locale: 'ms-MY',
  },
  [Languages.Marathi]: {
    name: 'Marathi',
    flag: '🇮🇳',
    nativeText: 'मराठी',
    locale: 'mr-IN',
  },
  [Languages.Maori]: {
    name: 'Maori',
    flag: '🇳🇿',
    nativeText: 'Māori',
    locale: 'mi-NZ',
  },
  [Languages.Nepali]: {
    name: 'Nepali',
    flag: '🇳🇵',
    nativeText: 'नेपाली',
    locale: 'ne-NP',
  },
  [Languages.Norwegian]: {
    name: 'Norwegian',
    flag: '🇳🇴',
    nativeText: 'Norsk',
    locale: 'nb-NO',
  },
  [Languages.Persian]: {
    name: 'Persian',
    flag: '🇮🇷',
    nativeText: 'فارسی',
    locale: 'fa-IR',
  },
  [Languages.Polish]: {
    name: 'Polish',
    flag: '🇵🇱',
    nativeText: 'Polski',
    locale: 'pl-PL',
  },
  [Languages.Portuguese]: {
    name: 'Portuguese',
    flag: '🇵🇹',
    nativeText: 'Português',
    locale: 'pt-PT',
  },
  [Languages.Romanian]: {
    name: 'Romanian',
    flag: '🇷🇴',
    nativeText: 'Română',
    locale: 'ro-RO',
  },
  [Languages.Russian]: {
    name: 'Russian',
    flag: '🇷🇺',
    nativeText: 'Русский',
    locale: 'ru-RU',
  },
  [Languages.Serbian]: {
    name: 'Serbian',
    flag: '🇷🇸',
    nativeText: 'Српски',
    locale: 'sr-RS',
  },
  [Languages.Slovak]: {
    name: 'Slovak',
    flag: '🇸🇰',
    nativeText: 'Slovenčina',
    locale: 'sk-SK',
  },
  [Languages.Slovenian]: {
    name: 'Slovenian',
    flag: '🇸🇮',
    nativeText: 'Slovenščina',
    locale: 'sl-SI',
  },
  [Languages.Spanish]: {
    name: 'Spanish',
    flag: '🇪🇸',
    nativeText: 'Español',
    locale: 'es-ES',
  },
  [Languages.Swahili]: {
    name: 'Swahili',
    flag: '🇰🇪',
    nativeText: 'Kiswahili',
    locale: 'sw-KE',
  },
  [Languages.Swedish]: {
    name: 'Swedish',
    flag: '🇸🇪',
    nativeText: 'Svenska',
    locale: 'sv-SE',
  },
  [Languages.Tagalog]: {
    name: 'Tagalog',
    flag: '🇵🇭',
    nativeText: 'Tagalog',
    locale: 'tl-PH',
  },
  [Languages.Tamil]: {
    name: 'Tamil',
    flag: '🇮🇳',
    nativeText: 'தமிழ்',
    locale: 'ta-IN',
  },
  [Languages.Thai]: {
    name: 'Thai',
    flag: '🇹🇭',
    nativeText: 'ไทย',
    locale: 'th-TH',
  },
  [Languages.Turkish]: {
    name: 'Turkish',
    flag: '🇹🇷',
    nativeText: 'Türkçe',
    locale: 'tr-TR',
  },
  [Languages.Ukrainian]: {
    name: 'Ukrainian',
    flag: '🇺🇦',
    nativeText: 'Українська',
    locale: 'uk-UA',
  },
  [Languages.Urdu]: {
    name: 'Urdu',
    flag: '🇵🇰',
    nativeText: 'اردو',
    locale: 'ur-PK',
  },
  [Languages.Vietnamese]: {
    name: 'Vietnamese',
    flag: '🇻🇳',
    nativeText: 'Tiếng Việt',
    locale: 'vi-VN',
  },
  [Languages.Welsh]: {
    name: 'Welsh',
    flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
    nativeText: 'Cymraeg',
    locale: 'cy-GB',
  },
  [Languages.EnglishAustralia]: {
    name: 'English (Australia)',
    flag: '🇦🇺',
    nativeText: 'English (Australia)',
    locale: 'en-AU',
  },
  [Languages.EnglishCanada]: {
    name: 'English (Canada)',
    flag: '🇨🇦',
    nativeText: 'English (Canada)',
    locale: 'en-CA',
  },
  [Languages.FrenchCanada]: {
    name: 'French (Canada)',
    flag: '🇨🇦',
    nativeText: 'Français (Canada)',
    locale: 'fr-CA',
  },
  [Languages.SpanishMexico]: {
    name: 'Spanish (Mexico)',
    flag: '🇲🇽',
    nativeText: 'Español (México)',
    locale: 'es-MX',
  },
  [Languages.Filipino]: {
    name: 'Filipino',
    flag: '🇵🇭',
    nativeText: 'Filipino',
    locale: 'fil-PH',
  },
  [Languages.ArabicUAE]: {
    name: 'Arabic (UAE)',
    flag: '🇦🇪',
    nativeText: 'العربية (الإمارات)',
    locale: 'ar-AE',
  },
};

// Helper function to get language options with flags for select component

// Helper function to get full language info
export const getLanguageInfo = (code: Languages): LanguageMetadata => {
  return LANGUAGE_METADATA[code];
};

export const isRTL = (languageCode: Languages): boolean => {
  const rtlLanguages = [
    Languages.Arabic,
    Languages.Hebrew,
    Languages.Persian,
    Languages.Urdu,
  ];
  return rtlLanguages.includes(languageCode);
};
