import React from 'react';
import {
  zoomMotions,
  panMotions,
  circularMotions,
  randomMotions,
} from '@monorepo/remotion-ninja';
import { MotionPreview } from './motion-preview';
import { MotionType } from '@monorepo/types';

interface Props {
  imageUrl: string;
  selectedMotion?: MotionType;
  onSelect?: (motion: MotionType) => void;
}

export const MotionGroupList: React.FC<Props> = ({
  imageUrl,
  selectedMotion,
  onSelect,
}) => {
  const motionGroups = [
    { title: 'Zoom Motions', motions: zoomMotions },
    { title: 'Pan Motions', motions: panMotions },
    { title: 'Circular and Complex Motions', motions: circularMotions },
    { title: 'Oscillation and Random Effects', motions: randomMotions },
  ];

  return (
    <div>
      {motionGroups.map((group) => (
        <div key={group.title} style={{ marginBottom: '20px' }}>
          <h2
            style={{
              fontSize: '20px',
              fontWeight: 'bold',
              marginBottom: '10px',
            }}
          >
            {group.title}
          </h2>
          <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
            {Object.keys(group.motions).map((motion) => {
              const isSelected = selectedMotion === motion;
              return (
                <div
                  key={motion}
                  onClick={() => onSelect?.(motion as MotionType)}
                  style={{
                    width: '150px',
                    height: '250px',
                    borderRadius: '8px',
                    overflow: 'hidden',
                    cursor: 'pointer',
                    outline: isSelected ? '5px solid #1890ff' : 'none',
                  }}
                >
                  <MotionPreview
                    motion={group.motions[motion]}
                    progress={0.5}
                    imageUrl={imageUrl}
                    name={motion}
                  />
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};
