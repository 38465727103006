import React, { FunctionComponent, useState } from 'react';
import { Select, Typography, Tooltip, Space } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  defaultKlingOptions,
  KlingOptions as KlingOptionsType,
  NinjaModelsOptions,
  NinjaProviderModel,
} from '@monorepo/types';
import { VideoModelInfo } from '../../ai-video.types';

const { Text } = Typography;

interface Props {
  updateOptions: (options: NinjaModelsOptions) => void;
  model: NinjaProviderModel;
}

const DurationInfo = {
  5: {
    duration: '5 seconds',
  },
  10: {
    duration: '10 seconds',
  },
};

const KlingOptions: FunctionComponent<Props> = ({ updateOptions, model }) => {
  const [options, setOptions] = useState<KlingOptionsType>(defaultKlingOptions);
  const modelInfo = VideoModelInfo[model];

  const handleSelectChange = (value: number) => {
    setOptions((prev) => {
      const newOptions = {
        ...prev,
        duration: value,
      };

      updateOptions(newOptions);

      return newOptions;
    });
  };

  return (
    <Space style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <Text>Duration</Text>
      <Select
        style={{ width: '100%' }}
        value={options.duration}
        onChange={handleSelectChange}
        labelRender={(option) =>
          option.value ? (
            `${DurationInfo[option.value].duration}`
          ) : (
            <span>Select duration</span>
          )
        }
        options={[5, 10].map((duration) => ({
          value: duration,
          label: (
            <Space direction="vertical" size="small">
              <Typography.Text strong>
                {DurationInfo[duration].duration}
              </Typography.Text>
              <Space>
                <Typography.Text type="secondary">
                  💎{' '}
                  {duration === 10
                    ? modelInfo.creditsCost * 2
                    : modelInfo.creditsCost}{' '}
                  credits
                </Typography.Text>
              </Space>
            </Space>
          ),
        }))}
      />
      <Tooltip
        placement={'right'}
        title={
          <span style={{ fontSize: '12px' }}>
            Choose between 5 or 10 seconds duration. 10 seconds will consume
            double credits.
          </span>
        }
      >
        <InfoCircleOutlined style={{ cursor: 'pointer' }} />
      </Tooltip>
    </Space>
  );
};

export default KlingOptions;
