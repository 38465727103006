import { EntityStore } from '@monorepo/client-common';
import {
  Languages,
  Scene,
  SubtitlesConfig,
  ThumbnailConfig,
  Timezone,
  Video,
  Word,
} from '@monorepo/types';
import { getEnv } from '../../../helpers/mobx-easy-wrapper';
import VideoService, {
  UpdateSocialPublishParams,
} from '../../../services/entities/video-service';

export default class VideoStore extends EntityStore<VideoService, Video> {
  constructor() {
    const {
      apiFactory: { videoService },
    } = getEnv();

    super(videoService);
  }

  getScenes(videoId: string) {
    return this.service.getScenes(videoId);
  }

  getProgress(videoId: string) {
    return this.service.getProgress(videoId);
  }

  render(videoId: string) {
    return this.service.render(videoId);
  }

  download(videoId: string) {
    return this.service.download(videoId);
  }

  updateGeneralInfo(videoId: string, updateDto: Partial<Video>) {
    return this.service.updateGeneralInfo(videoId, updateDto);
  }

  updateThumbnail(videoId: string, updateDto: Partial<ThumbnailConfig>) {
    return this.service.updateThumbnail(videoId, updateDto);
  }

  async updateScene(
    sceneId: string,
    videoId: string,
    updateDto: Partial<Scene>
  ) {
    return this.service.updateScene(sceneId, videoId, updateDto);
  }
  async updateSceneWords(sceneId: string, videoId: string, words: Word[]) {
    return this.service.updateSceneWords(sceneId, videoId, words);
  }

  async updateSubtitles(videoId: string, subtitlesConfig: SubtitlesConfig) {
    return this.service.updateSubtitles(videoId, subtitlesConfig);
  }

  async updateMetadata(
    videoId: string,
    metadata: Pick<Video, 'title' | 'description'>
  ) {
    return this.service.updateMetadata(videoId, metadata);
  }

  async updateVoiceover(
    videoId: string,
    voiceoverConfig: { voiceoverId: string }
  ) {
    return this.service.updateVoiceover(videoId, voiceoverConfig);
  }

  async updateBackgroundMusic(
    videoId: string,
    musicId?: string,
    backgroundMusicVolume?: number
  ) {
    return this.service.updateBackgroundMusic(
      videoId,
      musicId,
      backgroundMusicVolume
    );
  }

  async updateSocialPublish(params: UpdateSocialPublishParams) {
    return this.service.updateSocialPublish(params);
  }

  async cancelSchedule(payload: { videoId: string }) {
    return this.service.cancelSchedule(payload);
  }

  async uploadMusic(videoId: string, form: FormData) {
    return this.service.uploadMusic(videoId, form);
  }

  async generateScript(params: {
    text: string;
    languageCode: Languages;
    duration: number;
  }) {
    return this.service.generateScript(params);
  }
}
