import { MotionType } from '@monorepo/types';
import { Easing } from 'remotion';
import { MotionResult } from './effects-types';

export const panMotions: Record<string, (progress: number) => MotionResult> = {
  // Pans from left to right
  [MotionType.PAN_LEFT_TO_RIGHT]: (progress: number): MotionResult => {
    const translateX = 25 - (progress / 100) * 50;
    return {
      transform: `translate3d(${translateX}%, 0%, 0px) scale(1.7)`,
      recommendedEasing: Easing.cubic,
      startPosition: {
        scale: 1.7,
        translateX: 25,
        translateY: 0,
        rotate: 0,
      },
      currentPosition: {
        scale: 1.7,
        translateX,
        translateY: 0,
        rotate: 0,
      },
    };
  },

  // Pans from right to left
  [MotionType.PAN_RIGHT_TO_LEFT]: (progress: number): MotionResult => {
    const translateX = -25 + (progress / 100) * 50;
    return {
      transform: `translate3d(${translateX}%, 0%, 0px) scale(1.7)`,
      recommendedEasing: Easing.cubic,
      startPosition: {
        scale: 1.7,
        translateX: -25,
        translateY: 0,
        rotate: 0,
      },
      currentPosition: {
        scale: 1.7,
        translateX,
        translateY: 0,
        rotate: 0,
      },
    };
  },

  // Pans from top to bottom
  [MotionType.PAN_TOP_TO_BOTTOM]: (progress: number): MotionResult => {
    const translateY = 25 - (progress / 100) * 50;
    return {
      transform: `translate3d(0%, ${translateY}%, 0px) scale(1.7)`,
      recommendedEasing: Easing.cubic,
      startPosition: {
        scale: 1.7,
        translateX: 0,
        translateY: 25,
        rotate: 0,
      },
      currentPosition: {
        scale: 1.7,
        translateX: 0,
        translateY: translateY,
        rotate: 0,
      },
    };
  },

  // Pans from bottom to top
  [MotionType.PAN_BOTTOM_TO_TOP]: (progress: number): MotionResult => {
    const translateY = -25 + (progress / 100) * 50;
    return {
      transform: `translate3d(0%, ${translateY}%, 0px) scale(1.7)`,
      recommendedEasing: Easing.cubic,
      startPosition: {
        scale: 1.7,
        translateX: 0,
        translateY: -25,
        rotate: 0,
      },
      currentPosition: {
        scale: 1.7,
        translateX: 0,
        translateY: translateY,
        rotate: 0,
      },
    };
  },

  // Diagonal pan from top-left to bottom-right
  [MotionType.DIAGONAL_PAN_TOP_LEFT_TO_BOTTOM_RIGHT]: (
    progress: number
  ): MotionResult => {
    const translateX = 25 - (progress / 100) * 50;
    const translateY = 25 - (progress / 100) * 50;
    return {
      transform: `translate3d(${translateX}%, ${translateY}%, 0px) scale(1.7)`,
      recommendedEasing: Easing.cubic,
      startPosition: {
        scale: 1.7,
        translateX: 25,
        translateY: 25,
        rotate: 0,
      },
      currentPosition: {
        scale: 1.7,
        translateX,
        translateY,
        rotate: 0,
      },
    };
  },

  // Diagonal pan from bottom-right to top-left
  [MotionType.DIAGONAL_PAN_BOTTOM_RIGHT_TO_TOP_LEFT]: (
    progress: number
  ): MotionResult => {
    const translateX = -25 + (progress / 100) * 50;
    const translateY = -25 + (progress / 100) * 50;
    return {
      transform: `translate3d(${translateX}%, ${translateY}%, 0px) scale(1.7)`,
      recommendedEasing: Easing.cubic,
      startPosition: {
        scale: 1.7,
        translateX: -25,
        translateY: -25,
        rotate: 0,
      },
      currentPosition: {
        scale: 1.7,
        translateX,
        translateY,
        rotate: 0,
      },
    };
  },
};
