import React, { useCallback } from 'react';
import { useVideoContext } from '../../../hooks/use-video';
import { buildConfig, RemotionPlayer } from '@monorepo/remotion-ninja';

import './video-player.scss';
import { calculateDurationInFrames } from '@monorepo/remotion-ninja';
import { useStore } from '../../../helpers/use-store';
import { Languages, Video, VideoStatus } from '@monorepo/types';
import { useResponsiveReady } from '../../../hooks/use-responive-ready';
import OnlyMobile from '../../../components/responsiveness/only-mobile';
import PlayPause from './controls/play-pause';
import { TimeDisplay } from './controls/time-display';
import { SeekBar } from './controls/seek-bar';
import { MuteButton } from './controls/mute';
import { Flex, Skeleton } from 'antd';
import { divider } from '../video-layout/video-header/video-header';

const VideoPlayer = () => {
  const {
    dataStore: { musicStore },
  } = useStore();
  const { video, scenes, assets, playerRef, setPlayerReady } =
    useVideoContext();
  const { isMobile } = useResponsiveReady();

  const music = musicStore.items.find(
    (music) => music._id === video?.backgroundMusicId
  );

  const config = buildConfig({
    backgroundMusicUrl: video?.backgroundMusicUrl
      ? video.backgroundMusicUrl
      : music?.audioUrl,
    backgroundMusicVolume: video?.backgroundMusicVolume,
    subtitlesConfig: video?.subtitlesConfig,
    video: video as Video,
    scenes,
    assets,
    language: video?.language as Languages,
  });

  const myRef = useCallback((node) => {
    if (node !== null) {
      playerRef.current = node; // Update state or trigger any action
      setPlayerReady(true);
    }
  }, []);

  console.log('config', config);

  const durationInFrames = calculateDurationInFrames(config);

  const isCreating = video?.status === VideoStatus.Initial;

  return (
    <div
      className="video-player"
      style={{
        position: 'relative',
        marginTop: isMobile ? 0 : '15px',
      }}
    >
      <div>
        {isCreating ? (
          <Skeleton />
        ) : (
          <RemotionPlayer
            isMobile={isMobile}
            playbackRate={video?.playbackRate || 1}
            width={isMobile ? 'auto' : '500px'}
            ref={myRef}
            config={config}
          />
        )}
      </div>
      {/*<OnlyMobile>*/}
      {/*  <div className="controls">*/}
      {/*    <Flex justify={'center'} align={'center'}>*/}
      {/*      <PlayPause />*/}
      {/*      {divider}*/}
      {/*      <TimeDisplay durationInFrames={durationInFrames} />*/}
      {/*      {divider}*/}
      {/*      <SeekBar durationInFrames={durationInFrames} />*/}
      {/*      {divider}*/}
      {/*      <MuteButton />*/}
      {/*    </Flex>*/}
      {/*  </div>*/}
      {/*</OnlyMobile>*/}
    </div>
  );
};

export default VideoPlayer;
