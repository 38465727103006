import React, { ReactNode } from 'react';
import {
  CommentOutlined,
  DesktopOutlined,
  DollarOutlined,
  MessageOutlined,
  NotificationOutlined,
  PlayCircleOutlined,
  SettingOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { Flex } from 'antd';
import Changelog from '../components/changelog/changelog';

const HeaderWithIcon = ({ icon, text }) => {
  return (
    <Flex align={'center'} justify={'center'}>
      <span style={{ fontSize: '24px', color: '#1890ff', marginRight: '15px' }}>
        {icon}
      </span>
      <span>{text}</span>
    </Flex>
  );
};

export interface LayoutItem {
  key: string;
  icon?: ReactNode;
  disableClick?: boolean;
  label: string | ReactNode;
  hidden?: boolean;
  createButton?: {
    path: string;
  };
  children?: LayoutItem[];
}

export const layoutItems: LayoutItem[] = [
  {
    key: 'series',
    icon: <DesktopOutlined />,
    label: 'Series',
    createButton: {
      path: 'series/form/new',
    },
  },
  {
    key: 'videos',
    icon: <PlayCircleOutlined />,
    label: 'Videos',
    createButton: {
      path: 'videos/form/new',
    },
  },
  {
    key: 'series-form',
    label: 'New Series',
    hidden: true,
  },
  {
    key: 'videos-form',
    label: (
      <HeaderWithIcon icon={<PlayCircleOutlined />} text={'Create Video'} />
    ),
    hidden: true,
  },
  {
    key: 'settings',
    icon: <SettingOutlined />,
    label: 'Settings',
  },
  {
    key: 'affiliate',
    icon: <DollarOutlined />,
    label: 'Affiliate',
  },
  {
    key: 'changelog',
    icon: <Changelog marginRight={'10px'} />,
    label: 'Changelog',
  },
  {
    key: 'feedback',
    icon: <MessageOutlined />,
    label: 'Feedback',
  },
  {
    key: 'settings-profile',
    icon: <SettingOutlined />,
    label: 'Settings',
    hidden: true,
  },
  {
    key: 'settings-billing',
    icon: <SettingOutlined />,
    label: 'Settings',
    hidden: true,
  },
  {
    key: 'settings-social-accounts',
    icon: <SettingOutlined />,
    label: 'Settings',
    hidden: true,
  },
];

const getRouteKey = (pathname: string) => {
  const segments = pathname
    .split('/')
    .filter((segment) => segment && segment !== 'new');

  if (segments.length === 0) {
    return ['videos'];
  }

  return segments;
};

const useCurrentPage = () => {
  const location = useLocation();
  const currentPage = getRouteKey(location.pathname);
  const firstPage = currentPage[0];

  const menuItem = layoutItems.find((item) => {
    const pathItems = item.key.split('-');

    if (pathItems.length < currentPage.length) {
      return false;
    }

    return item.key === currentPage.join('-');
  });

  const firstPageTitle = menuItem?.label || 'Not Found';

  return {
    currentPage,
    isSinglePage: currentPage.length === 1,
    firstPage,
    firstPageTitle,
    menuItem,
  };
};

export { useCurrentPage };
