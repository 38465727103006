import React, { FunctionComponent, useState } from 'react';
import {
  Button,
  Col,
  Flex,
  Input,
  message,
  Row,
  Select,
  Space,
  Switch,
  Typography,
} from 'antd';
import {
  Asset,
  AssetType,
  CreateImageResponse,
  NinjaJobsMessage,
  NinjaModelsOptions,
  NinjaProviderModel,
  VideoAvailableModels,
} from '@monorepo/types';
import { useStore } from '../../../../../helpers/use-store';
import { LoadingState, useLoading } from '@monorepo/react-components';
import { PartialAsset } from '../../../../../components/selectable-asset/selectable-asset';
import { useNotification } from '../../../../../hooks/use-notification';
import AssetComponent from '../../../../../components/asset/asset';

import './ai-video.scss';
import { VideoModelInfo, VideoModelPollingConfig } from './ai-video.types';
import AiVideoOptions from './ai-video-options/ai-video-options';
import { useVideoContext } from '../../../../../hooks/use-video';
import dayjs from 'dayjs';

interface Props {
  asset: Asset | PartialAsset;
  onSelect: (partialAsset: Asset) => void;
  sceneId?: string;
  videoId?: string;
}

const AIVideoGenerator: FunctionComponent<Props> = ({
  asset,
  onSelect,
  sceneId,
  videoId,
}) => {
  const [generatedAsset, setGeneratedAsset] = useState<Asset | null>(null);
  const [prompt, setPrompt] = useState('');
  const [modelOptions, setModelOptions] = useState<NinjaModelsOptions | null>();
  const [isAutoPrompt, setIsAutoPrompt] = useState(true);
  const [selectedModel, setSelectedModel] = useState<NinjaProviderModel>(
    VideoAvailableModels[2]
  );
  const { loadingState, updateLoadingState } = useLoading();
  const {
    dataStore: { assetStore, userStore },
  } = useStore();
  const { videoJobs } = useVideoContext();
  const [currentJobId, setCurrentJobId] = useState<string | null>(null);

  const isInProgress = videoJobs.isJobActive(currentJobId || '');

  const notificationApi = useNotification();

  const handleGenerate = async () => {
    if ((asset as Asset).createdAt) {
      const isBefore = dayjs((asset as Asset).createdAt).isBefore(
        '2025-01-04T15:30:35.688Z'
      );

      if (isBefore && selectedModel === NinjaProviderModel.RunwayML) {
        console.log(asset);
        message.warning(
          'Runway ML works only on assets created after 04/01/2025'
        );
        return;
      }
    }
    if (!isAutoPrompt && !prompt.trim()) {
      message.warning('Please enter a prompt for the AI video');
      return;
    }

    updateLoadingState(LoadingState.Loading);
    try {
      const jobId = await assetStore.generateAIVideoFromImage({
        prompt: isAutoPrompt ? 'AUTO' : prompt,
        isAutoPrompt,
        imgUrl: asset.url,
        model: selectedModel,
        sceneId,
        videoId,
        options: modelOptions,
      });

      setCurrentJobId(jobId);

      const finishLoading = () => {
        setCurrentJobId(null);
        updateLoadingState(LoadingState.Loaded);
      };

      const pollingConfig = VideoModelPollingConfig[selectedModel];

      videoJobs.startSinglePolling({
        jobId,
        options: {
          interval: pollingConfig.interval,
          maxAttempts: pollingConfig.maxAttempts,
          onFinish: async (job) => {
            const newAssetId = (job.resultPayload as CreateImageResponse)
              .assetId;
            const newAsset = await assetStore.fetch(newAssetId);
            setGeneratedAsset(newAsset);
            finishLoading();

            notificationApi.success({
              placement: 'top',
              message: 'Success',
              description: NinjaJobsMessage[job.type].success,
            });

            userStore.getLatestUser();
          },
          onFail: (job) => {
            console.error('Job failed:', job);
            finishLoading();
            notificationApi.error({
              placement: 'top',
              message: 'Error',
              description: NinjaJobsMessage[job.type].fail,
            });
          },
        },
      });
    } catch (error) {
      console.error('Error generating AI video:', error);
      updateLoadingState(LoadingState.Loaded);
      notificationApi.error({
        placement: 'top',
        message: 'Error',
        description:
          'Failed creating AI video, please contact support for help.',
      });
    }
  };

  const isLoading = loadingState === LoadingState.Loading;

  const modelInfo = VideoModelInfo[selectedModel];
  const isPromptAllowed = modelInfo.disableManualInput ? true : isAutoPrompt;

  return (
    <Space
      className={'ai-video-generator '}
      direction="vertical"
      size="small"
      style={{ width: '100%' }}
    >
      <Flex justify={'start'} align="center" gap={15}>
        <Typography.Text style={{ marginRight: '0px', minWidth: '130px' }}>
          AI Prompt Mode:
        </Typography.Text>
        <Switch
          checked={isPromptAllowed}
          style={{ minWidth: '80px' }}
          onChange={setIsAutoPrompt}
          disabled={modelInfo.disableManualInput}
          checkedChildren="Auto"
          unCheckedChildren="Manual"
        />
        <Select
          style={{ width: '100%' }}
          value={selectedModel}
          onChange={setSelectedModel}
          labelRender={(props) =>
            props.label ? (
              VideoModelInfo[props.value].name
            ) : (
              <span>No model selected</span>
            )
          }
          options={VideoAvailableModels.map((model) => ({
            value: model,
            label: (
              <Space direction="vertical" size="small">
                <Typography.Text strong>
                  {VideoModelInfo[model].name}
                </Typography.Text>
                <Space>
                  <Typography.Text type="secondary">
                    ⏱️ {VideoModelInfo[model].processingTime}
                  </Typography.Text>
                  <Typography.Text type="secondary">
                    💎 {VideoModelInfo[model].creditsCost} credits
                  </Typography.Text>
                </Space>
              </Space>
            ),
          }))}
        />
      </Flex>

      <AiVideoOptions updateOptions={setModelOptions} model={selectedModel} />

      {modelInfo.disableManualInput ? (
        <Typography.Text type="secondary">
          🤖 This model doesn’t support prompts.
        </Typography.Text>
      ) : null}
      {!isPromptAllowed && (
        <Input.TextArea
          style={{ marginBottom: '15px' }}
          maxLength={500}
          showCount={true}
          rows={4}
          disabled={isPromptAllowed}
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          placeholder="Describe the video you want the AI to generate..."
        />
      )}

      {isAutoPrompt && (
        <Typography.Text type="secondary">
          Our AI will automatically generate an appropriate prompt based on the
          image content in context with the video and scene scripts.
        </Typography.Text>
      )}

      <Button
        disabled={asset.type === AssetType.Video}
        type="primary"
        style={{ width: '100%' }}
        onClick={handleGenerate}
        loading={isLoading || isInProgress}
      >
        Generate AI Video
      </Button>

      <Typography.Text
        style={{
          height: '22px',
          width: '100%',
          display: 'block',
          textAlign: 'center',
        }}
        type="secondary"
      >
        {isLoading || isInProgress
          ? `🔄 Processing your request - stay here or check "My Assets" later to view results! 📂`
          : null}
      </Typography.Text>

      <Row justify="center" gutter={[32, 16]}>
        <Col span={8}>
          <div className={'video-item'} style={{ textAlign: 'center' }}>
            <Typography.Text style={{ fontSize: '16px' }} type="secondary">
              🗂️ Current Image
            </Typography.Text>
            <AssetComponent
              width="100%"
              height="100%"
              shouldLazy={false}
              asset={asset}
            />
          </div>
        </Col>
        <Col span={8}>
          <div className={'video-item'} style={{ textAlign: 'center' }}>
            {generatedAsset && (
              <>
                <Typography.Text style={{ fontSize: '16px' }} type="secondary">
                  🎥 Generated Video
                </Typography.Text>
                <AssetComponent
                  width="100%"
                  height="100%"
                  shouldLazy={false}
                  asset={generatedAsset}
                />
                <Button
                  className={'select-new-video'}
                  color={'primary'}
                  variant={'filled'}
                  type="primary"
                  style={{ width: '100%' }}
                  onClick={() => onSelect(generatedAsset)}
                >
                  Select
                </Button>
              </>
            )}
          </div>
        </Col>
      </Row>
    </Space>
  );
};

export default AIVideoGenerator;
