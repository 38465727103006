import React, { FunctionComponent } from 'react';
import { AbsoluteFill, Sequence } from 'remotion';
import { OverlayTrackItem, OverlayTrackType } from '../remotion-overlays';
import Thumbnails from './thumbnails/thumbnails';

const TextOverlay: FunctionComponent<{
  item: OverlayTrackItem;
}> = ({ item }) => {
  const { top, left, width, height, data } = item;

  return (
    <div
      style={{
        position: 'absolute',
        left: left ?? '50%',
        top: top ?? '50%',
        width,
        height,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        fontSize: '100px',
        transform: left || top ? 'none' : 'translate(-50%, -50%)',
      }}
    >
      {data.text}
    </div>
  );
};

const renderOverlayByType = (track: OverlayTrackItem): React.ReactNode => {
  const { top, left, width, height } = track;

  let component: any = null;

  if (track.type === OverlayTrackType.Text) {
    component = <TextOverlay item={track} />;
  }
  if (track.type === OverlayTrackType.Thumbnail) {
    component = <Thumbnails item={track} />;
  }

  // Add other types as needed:
  // if (track.type === OverlayTrackType.Image) {
  //   return <ImageOverlay item={track} />;
  // }

  // if (track.type === OverlayTrackType.Video) {
  //   return <VideoOverlay item={track} />;
  // }

  return (
    <AbsoluteFill
      style={{
        top,
        height,
        left,
        width,
      }}
    >
      {component}
    </AbsoluteFill>
  );
};

const RemotionOverlays: FunctionComponent<{
  overlayTracks: OverlayTrackItem[];
}> = ({ overlayTracks }) => {
  return (
    <>
      {overlayTracks.map((track, index) => {
        const overlayContent = renderOverlayByType(track);

        if (!overlayContent) {
          return null;
        }

        return (
          <Sequence
            key={index}
            from={track.from}
            durationInFrames={track.durationInFrames}
            layout="none"
          >
            {overlayContent}
          </Sequence>
        );
      })}
    </>
  );
};

export default RemotionOverlays;
