import { random } from 'remotion';
import { MotionType } from '@monorepo/types';
import { Easing } from 'remotion';
import { MotionResult } from './effects-types';

export const randomMotions: Record<string, (progress: number) => MotionResult> =
  {
    [MotionType.RANDOM_MOTION_PATHS]: (progress: number): MotionResult => {
      const normalizedProgress = progress / 100;
      const laggedProgress = Math.max(0, normalizedProgress - 0.35);
      const rangeX = 30 + random('amplitude-x') * 5;
      const rangeY = 30 + random('amplitude-y') * 5;
      const xProgress = Math.sin(normalizedProgress * Math.PI * 2);
      const yProgress = Math.sin(laggedProgress * Math.PI * 2);
      const currentX = rangeX * xProgress;
      const currentY = rangeY * yProgress;

      return {
        transform: `translate3d(${currentX}%, ${currentY}%, 0px) scale(1.7)`,
        recommendedEasing: Easing.cubic,
        startPosition: {
          scale: 1.7,
          translateX: 0,
          translateY: 0,
          rotate: 0,
        },
        currentPosition: {
          scale: 1.7,
          translateX: currentX,
          translateY: currentY,
          rotate: 0,
        },
      };
    },

    [MotionType.BOUNCE_EFFECT]: (progress: number): MotionResult => {
      const normalizedProgress = progress / 100;
      const amplitude = 25;
      const frequency = 2;
      const translateY =
        Math.sin(normalizedProgress * Math.PI * frequency) * amplitude;

      return {
        transform: `translate3d(0%, ${translateY}%, 0px) scale(1.7)`,
        recommendedEasing: Easing.bounce,
        startPosition: {
          scale: 1.7,
          translateX: 0,
          translateY: 0,
          rotate: 0,
        },
        currentPosition: {
          scale: 1.7,
          translateX: 0,
          translateY: translateY,
          rotate: 0,
        },
      };
    },

    [MotionType.SHAKE_EFFECT]: (progress: number): MotionResult => {
      const normalizedProgress = progress / 100;
      const amplitude = 5;
      const frequency = 20;
      const shakeX =
        Math.sin(normalizedProgress * Math.PI * frequency) * amplitude;
      const shakeY =
        Math.cos(normalizedProgress * Math.PI * frequency) * amplitude +
        amplitude;

      return {
        transform: `translate3d(${shakeX}%, ${shakeY}%, 0px) scale(1.2)`,
        recommendedEasing: Easing.cubic,
        startPosition: {
          scale: 1.2,
          translateX: 0,
          translateY: amplitude,
          rotate: 0,
        },
        currentPosition: {
          scale: 1.2,
          translateX: shakeX,
          translateY: shakeY,
          rotate: 0,
        },
      };
    },

    [MotionType.ZIGZAG_MOTION]: (progress: number): MotionResult => {
      const normalizedProgress = progress / 100;
      const xRange = 33;
      const xFrequency = 4;
      const zigzagX =
        Math.sin(normalizedProgress * Math.PI * xFrequency) * xRange;
      const yRange = 20;
      const yFrequency = 1;
      const zigzagY =
        Math.sin(normalizedProgress * Math.PI * yFrequency) * yRange - yRange;

      return {
        transform: `translate3d(${zigzagX}%, ${zigzagY}%, 0px) scale(1.7)`,
        recommendedEasing: Easing.cubic,
        startPosition: {
          scale: 1.7,
          translateX: 0,
          translateY: -yRange,
          rotate: 0,
        },
        currentPosition: {
          scale: 1.7,
          translateX: zigzagX,
          translateY: zigzagY,
          rotate: 0,
        },
      };
    },

    [MotionType.WOBBLE_EFFECT]: (progress: number): MotionResult => {
      const normalizedProgress = progress / 100;
      const rotationAmplitude = 10;
      const frequency = 6;
      const rotation =
        Math.sin(normalizedProgress * Math.PI * frequency) * rotationAmplitude;

      return {
        transform: `rotate(${rotation}deg) scale(1.4)`,
        recommendedEasing: Easing.elastic(1),
        startPosition: {
          scale: 1.4,
          translateX: 0,
          translateY: 0,
          rotate: 0,
        },
        currentPosition: {
          scale: 1.4,
          translateX: 0,
          translateY: 0,
          rotate: rotation,
        },
      };
    },

    [MotionType.SWAY_LEFT_AND_RIGHT]: (progress: number): MotionResult => {
      const normalizedProgress = progress / 100;
      const amplitude = 15;
      const frequency = 3;
      const swayX =
        Math.sin(normalizedProgress * Math.PI * frequency) * amplitude;

      return {
        transform: `translate3d(${swayX}%, 0%, 0px) scale(1.7)`,
        recommendedEasing: Easing.cubic,
        startPosition: {
          scale: 1.7,
          translateX: 0,
          translateY: 0,
          rotate: 0,
        },
        currentPosition: {
          scale: 1.7,
          translateX: swayX,
          translateY: 0,
          rotate: 0,
        },
      };
    },

    [MotionType.SWAY_UP_AND_DOWN]: (progress: number): MotionResult => {
      const normalizedProgress = progress / 100;
      const amplitude = 15;
      const frequency = 3;
      const swayY =
        Math.sin(normalizedProgress * Math.PI * frequency) * amplitude;

      return {
        transform: `translate3d(0%, ${swayY}%, 0px) scale(1.7)`,
        recommendedEasing: Easing.cubic,
        startPosition: {
          scale: 1.7,
          translateX: 0,
          translateY: 0,
          rotate: 0,
        },
        currentPosition: {
          scale: 1.7,
          translateX: 0,
          translateY: swayY,
          rotate: 0,
        },
      };
    },

    [MotionType.RANDOM_JITTER]: (progress: number): MotionResult => {
      const normalizedProgress = progress / 100;
      const jitterRange = 3;
      const frameNumber = Math.floor(normalizedProgress * 60);
      const jitterX = (random(`jitter-x-${frameNumber}`) * 2 - 1) * jitterRange;
      const jitterY = (random(`jitter-y-${frameNumber}`) * 2 - 1) * jitterRange;

      return {
        transform: `translate3d(${jitterX}%, ${jitterY}%, 0px) scale(1.2)`,
        recommendedEasing: Easing.linear,
        startPosition: {
          scale: 1.2,
          translateX: 0,
          translateY: 0,
          rotate: 0,
        },
        currentPosition: {
          scale: 1.2,
          translateX: jitterX,
          translateY: jitterY,
          rotate: 0,
        },
      };
    },
  };
