import { ThumbnailConfig } from '@monorepo/types';

export enum OverlayTrackType {
  Thumbnail = 'thumbnail',
  Image = 'image',
  Text = 'text',
  Video = 'video',
  Gif = 'gif',
  Audio = 'audio',
}

export type OverlayThumbnail = Pick<ThumbnailConfig, 'text' | 'type'>;

export type OverlayTrackItemData = OverlayThumbnail | any;

export interface OverlayTrackItem {
  type: OverlayTrackType;
  from: number;
  durationInFrames: number;
  left: number | string;
  top: number | string;
  height: number | string;
  width: number | string;
  data: OverlayTrackItemData;
}
