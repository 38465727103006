import React from 'react';
import { Flex, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Asset, Scene } from '@monorepo/types';
import AssetComponent from '../../../../components/asset/asset';

import './scene-image.scss';

interface SceneImageContainerProps {
  asset?: Asset;
  scene: Scene;
  onAssetClick: (scene: Scene, asset?: Asset) => void;
}

const SceneImage: React.FC<SceneImageContainerProps> = ({
  asset,
  scene,
  onAssetClick,
}) => (
  <div className={'scene-image-container'}>
    <div
      style={{
        marginRight: '25px',
        minWidth: '150px',
      }}
      className="scene-image"
      onClick={() => onAssetClick(scene, asset)}
    >
      {!asset ? (
        <Flex
          style={{
            height: '100%',
            background: 'linear-gradient(135deg, #f6f8fc 0%, #f0f4f8 100%)',
            border: '2px solid #dbe1e8',
            borderRadius: '8px',
            marginBottom: '16px',
            flexDirection: 'column',
          }}
          justify={'center'}
          align={'center'}
        >
          <div>
            <Typography.Text
              style={{
                color: '#6b7280',
                fontSize: '16px',
                fontWeight: 500,
              }}
            >
              No Asset
            </Typography.Text>
          </div>
          <div>
            <Typography.Text type={'secondary'}>
              Click here to edit
            </Typography.Text>
          </div>
        </Flex>
      ) : null}
      <AssetComponent
        shouldLazy={false}
        width={'100%'}
        height={'100%'}
        preview={false}
        asset={asset}
      />
    </div>
    <div className="scene-image-overlay">
      <EditOutlined className="scene-image-edit" />
      <div style={{ color: '#fff' }}>Edit</div>
    </div>
  </div>
);

export default SceneImage;
