import { Button, Card, Flex, Typography } from 'antd';
import React, { FunctionComponent, useEffect } from 'react';
import Scenes from '../../scenes/scenes';
import { isEmpty } from 'lodash';
import { baseSubtitles } from '@monorepo/types';
import VideoVoiceover from '../../voiceover/voiceover';
import VideoMusic from '../../music/music';
import { useVideoContext } from '../../../../hooks/use-video';
import {
  BACK_BY_SCENE,
  TITLE_BY_VIDEO_PAGE,
  VideoPart,
} from '../../video-editor-types';
import EditScene from '../../edit-scene/edit-scene';

import './video-content-container.scss';
import { CaretLeftOutlined } from '@ant-design/icons';
import ReplayScene from '../../scenes/replay-scene/replay-scene';
import VideoMetadata from '../../metadata/video-metadata';
import VideoSubtitles from '../../subtitles/video-subtitles';
import Jobs from '../../jobs/jobs';
import VideoThumbnail from '../../video-thumbnail/video-thumbnail';

const VideoContentContainer: FunctionComponent = () => {
  const {
    video,
    currentVideoPart,
    selectedScene,
    setCurrentVideoPart,
    updateSubtitles,
  } = useVideoContext();

  useEffect(() => {
    if (currentVideoPart === VideoPart.Scenes) {
      return;
    }

    const container = document.querySelector('.video-container');
    if (!container) return;

    container.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [currentVideoPart]);

  if (!video) {
    return null;
  }

  const backPart = BACK_BY_SCENE[currentVideoPart];

  const getComponentByPart = () => {
    if (currentVideoPart === VideoPart.Scenes) {
      return <Scenes />;
    }

    if (currentVideoPart === VideoPart.EditScene) {
      return <EditScene />;
    }

    if (currentVideoPart === VideoPart.Subtitles) {
      return (
        <VideoSubtitles
          updateSubtitles={updateSubtitles}
          subtitlesConfig={
            isEmpty(video.subtitlesConfig)
              ? baseSubtitles
              : video.subtitlesConfig
          }
        />
      );
    }

    if (currentVideoPart === VideoPart.Voiceover) {
      return <VideoVoiceover voiceoverId={video.voiceoverId} />;
    }

    if (currentVideoPart === VideoPart.Music) {
      return (
        <VideoMusic
          backgroundMusicUrl={video.backgroundMusicUrl}
          backgroundMusicVolume={video.backgroundMusicVolume || 0.3}
          musicId={video.backgroundMusicId}
        />
      );
    }

    if (currentVideoPart === VideoPart.Metadata) {
      return <VideoMetadata />;
    }

    if (currentVideoPart === VideoPart.Jobs) {
      return <Jobs />;
    }

    if (currentVideoPart === VideoPart.Thumbnail) {
      return <VideoThumbnail />;
    }

    return 'Not Implemented';
  };

  const getExtraTitle = () => {
    if (currentVideoPart === VideoPart.EditScene) {
      if (!selectedScene) {
        return null;
      }

      return ` #${selectedScene.index + 1}`;
    }
    return null;
  };

  const TIMELINE_HEIGHT = 247;
  const HEADER_HEIGHT = 64;
  const availableHeight = window.innerHeight - TIMELINE_HEIGHT - HEADER_HEIGHT;

  return (
    <Card
      classNames={{
        body: 'scroller video-container',
      }}
      styles={{
        body: {
          height: availableHeight,
        },
      }}
      className="video-content-container"
      style={{ minHeight: '100vh', borderRadius: 0 }}
    >
      <Flex justify={'space-between'} align={'center'}>
        <Flex
          justify={'start'}
          align={'start'}
          style={{ margin: '10px 0', flexDirection: 'column' }}
        >
          <Typography.Title
            style={{ marginTop: 0 }}
            className="content-title"
            //@ts-expect-error zain ba ain
            strong={true}
            level={5}
          >
            {TITLE_BY_VIDEO_PAGE[currentVideoPart]}
            {getExtraTitle()}
          </Typography.Title>
          {backPart ? (
            <Button
              style={{ fontSize: '12px', paddingLeft: 0 }}
              size={'small'}
              onClick={() => setCurrentVideoPart(backPart)}
              type={'text'}
              icon={<CaretLeftOutlined />}
            >
              Back
            </Button>
          ) : null}
          {/*<Typography.Text type='secondary'>Description</Typography.Text>*/}
        </Flex>
        {backPart ? <ReplayScene /> : null}
      </Flex>
      {/*<Divider style={{ margin: '10px 0' }} />*/}
      <div style={{ paddingTop: '5px' }}>{getComponentByPart()}</div>
    </Card>
  );
};

export default VideoContentContainer;
