import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { WordWithMetadata } from './timeline';
import EditWordModal, {
  EditWordModalActions,
} from './edit-word/edit-word-modal';
import { debounce } from 'lodash';
import TimelineText from './timeline-text';
import TimelineWordsList from './timeline-words-list';

interface TextTrackProps {
  words: WordWithMetadata[];
  totalFrames: number;
  currentFrame: number;
  fps: number;
  isDragging: boolean;
}

const TimelineTextTrack: FunctionComponent<TextTrackProps> = ({
  words,
  totalFrames,
  isDragging,
  currentFrame,
  fps = 30,
}) => {
  const modalRef = useRef<EditWordModalActions>(null);
  const trackRef = useRef<HTMLDivElement>(null);

  // Debounce the scroll effect when dragging
  const debouncedScroll = useCallback(
    debounce(
      (wordElement: HTMLElement, playhead: Element, container: Element) => {
        if (trackRef.current) {
          const wordRect = wordElement.getBoundingClientRect();
          const trackRect = trackRef.current.getBoundingClientRect();
          const playheadRect = playhead.getBoundingClientRect();

          const offset =
            wordRect.left -
            trackRect.left -
            (playheadRect.left - trackRect.left);

          requestAnimationFrame(() => {
            trackRef.current?.scrollBy({
              left: isLast ? offset + 100 : offset,
              behavior: 'smooth',
            });
          });
        }
      },
      300
    ), // 150ms debounce time
    [trackRef]
  );

  // Get current word based on time
  const getCurrentWordIndex = () => {
    const currentTime = currentFrame / fps;
    return words.findIndex((word, index) => {
      const nextWord = words[index + 1];
      const wordEnd =
        word.end || (nextWord ? nextWord.start : totalFrames / fps);
      return currentTime >= word.start && currentTime < wordEnd;
    });
  };

  const currentWordIndex = getCurrentWordIndex();
  const isLast = currentWordIndex === words.length - 1;

  useEffect(() => {
    const currentId = `word-${currentWordIndex}`;
    const wordElement = document.getElementById(currentId);
    const playhead = document.querySelector('.playhead');
    const container = document.querySelector('.timeline-track');

    if (wordElement && playhead && container) {
      if (isDragging) {
        debouncedScroll(wordElement, playhead, container);
      } else {
        const trackRect = trackRef.current?.getBoundingClientRect();
        const playheadRect = playhead.getBoundingClientRect();
        const wordRect = wordElement.getBoundingClientRect();

        const previousActive = document.querySelector('.word-button.active');
        if (previousActive) {
          previousActive.classList.remove('active');
        }

        if (trackRect) {
          const offset =
            wordRect.left -
            trackRect.left -
            (playheadRect.left - trackRect.left);

          requestAnimationFrame(() => {
            const currentWord = document.getElementById(
              `word-${currentWordIndex}`
            );
            if (currentWord) {
              currentWord.classList.add('active');
            }

            trackRef.current?.scrollBy({
              left: isLast ? offset + 100 : offset,
              behavior: 'smooth',
            });
          });
        }
      }
    }
  }, [currentWordIndex, isDragging, debouncedScroll]);

  return (
    <>
      <div
        className="text-track"
        ref={trackRef}
        style={{
          top: 0,
          left: 0,
          right: 0,
          height: '40px',
          paddingRight: '15px',
          overflowX: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        <div
          style={{
            display: 'flex',
            position: 'relative',
            // left: `${getContainerPosition()}px`,
            transition: 'left 0.1s linear',
          }}
        >
          <TimelineWordsList
            words={words}
            openModal={(word) => modalRef.current?.open(word)}
          />
        </div>
      </div>
      <EditWordModal ref={modalRef} />
    </>
  );
};

export default TimelineTextTrack;
