import { createBrowserRouter, Navigate } from 'react-router-dom';
import React from 'react';
import Series from '../features/series/series';
import Affiliate from '../features/affiliate/affiliate';
import Settings from '../features/settings/settings';
import AppLoader from '../features/app-loader/app-loader';
import Video from '../features/video/video';
import Videos from '../features/videos/videos';
import VideoForm from '../features/video-form/video-form';
import TikTokCallback from '../features/social-accounts/tiktok/tiktok-callback';
import { CreateSeriesPage } from '../features/series/create-series/create-series-page';
import SeriesPage from '../features/series/series-page/series-page';
import DiscountBanner from '../features/ads/first-ad-test';

const reactEnv = import.meta.env.VITE_APP_ENV || 'development';
const isProd = reactEnv === 'production';

const childrenRoutes = [
  // {
  //   path: 'dashboard',
  //   element: <Dashboard />,
  // },
  {
    path: '/',
    element: <Videos />,
  },
  {
    path: '/series',
    element: <Series />,
  },
  {
    path: '/videos',
    element: <Videos />,
  },
  {
    path: '/series/form/:id',
    element: <CreateSeriesPage />,
  },
  {
    path: '/videos/form/:id',
    element: <VideoForm />,
  },
  {
    path: '/series/:seriesId',
    element: <SeriesPage />,
  },
  {
    path: '/videos/:videoId',
    element: <Video />,
  },
  {
    path: '/affiliate',
    element: <Affiliate />,
  },
  {
    path: '/settings/*',
    element: <Settings />,
  },
  {
    path: 'tiktok-callback',
    element: <TikTokCallback />,
  },
];

if (!isProd) {
  childrenRoutes.push({
    path: '/ad1',
    element: <DiscountBanner />,
  });
}

export const loggedInRouter = createBrowserRouter([
  {
    path: '/',
    element: <AppLoader />,
    children: childrenRoutes,
  },
  {
    path: '*',
    element: <Navigate to="/videos" />,
  },
]);
