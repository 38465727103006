import React, { useRef } from 'react';
import { Button, Divider, Flex, Layout, Space, Typography } from 'antd';
import {
  CaretLeftOutlined,
  CloudOutlined,
  ExportOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { useVideoContext } from '../../../../hooks/use-video';
import { Link } from 'react-router-dom';
import { LoadingState } from '@monorepo/react-components';
import PublishVideoModal, {
  PublishVideoModalRef,
} from '../../export-modal/export-modal';
import HideMobile from '../../../../components/responsiveness/hide-mobile';
import VideoCredits from '../../../subscription/video-credits';

export const divider = (
  <Divider style={{ borderColor: '#e3e3e3', height: '18px' }} type="vertical" />
);

const dividerz = divider;

const VideoHeader = () => {
  const exportModalRef = useRef<PublishVideoModalRef>(null);
  const { video, requestLoadingState } = useVideoContext();

  const hasSeries = Boolean(video?.seriesId);
  const isLoading = requestLoadingState === LoadingState.Loading;

  return (
    <>
      <Layout.Header>
        <Flex
          justify={'space-between'}
          style={{ flexDirection: 'column' }}
          className="video-header"
        >
          <Typography.Title style={{ margin: 0 }} level={4}>
            Video Editor
          </Typography.Title>
          <Typography.Text style={{ fontSize: '12px' }} type={'secondary'}>
            <Link to={hasSeries ? `/series/${video?.seriesId}` : `/videos`}>
              <CaretLeftOutlined /> Back to {hasSeries ? 'Series' : 'Videos'}
            </Link>
          </Typography.Text>
        </Flex>

        <Flex align="center" justify="end" style={{ height: '48px' }}>
          <Space size={8} align="center">
            <HideMobile>
              <Flex align="center">
                <VideoCredits />
                {dividerz}
              </Flex>
            </HideMobile>

            <HideMobile>
              <Flex align="center" gap={8}>
                {isLoading ? (
                  <LoadingOutlined
                    style={{ fontSize: '18px', color: '#1890ff' }}
                  />
                ) : (
                  <CloudOutlined
                    style={{ fontSize: '18px', color: '#8c8c8c' }}
                  />
                )}
                {dividerz}
              </Flex>
            </HideMobile>

            <Button
              size="middle"
              type="primary"
              onClick={() => exportModalRef.current?.open()}
              icon={<ExportOutlined />}
              style={{ display: 'flex', alignItems: 'center', gap: 4 }}
            >
              Export / Share
            </Button>
          </Space>
        </Flex>
      </Layout.Header>
      <PublishVideoModal ref={exportModalRef} />
    </>
  );
};

export default VideoHeader;
