import { orderBy } from 'lodash';
import {
  Asset as RemotionAsset,
  AUDIO_DELAY_FRAMES,
  AudioTrack,
  CaptionSettings,
  RemotionConfig,
  Subtitle,
} from '../remotion-types';
import {
  Asset,
  defaultThumbnailConfig,
  isRTL,
  Languages,
  MotionType,
  Scene,
  SubtitlesConfig,
  TransitionEffect,
  Video,
} from '@monorepo/types';
import { calculateDurationInFrames } from '../remotion-helpers/calculate-duration-frames';
import { OverlayTrackItem, OverlayTrackType } from '../remotion-overlays';

function createSubtitlesWithWordGroups(
  orderedScenes: any[],
  wordsPerGroup = 4,
  thumbnailDelta = 0
): Subtitle[] {
  let currentTime = thumbnailDelta;
  const subtitles: Subtitle[] = [];

  if (thumbnailDelta) {
    subtitles.push({
      startTime: 0,
      endTime: thumbnailDelta,
      words: [],
    });
  }

  orderedScenes.forEach((scene, sceneIndex) => {
    const additionInSeconds = AUDIO_DELAY_FRAMES / 30;

    for (let i = 0; i < scene.words.length; i += wordsPerGroup) {
      const wordGroup = scene.words.slice(i, i + wordsPerGroup);

      if (wordGroup.length > 0) {
        const startTime = currentTime + wordGroup[0].start;
        const endTime = currentTime + wordGroup[wordGroup.length - 1].end;

        let subtitle: Subtitle = {
          startTime,
          endTime,
          words: [],
        };

        for (let j = 0; j < wordGroup.length; j++) {
          const word = wordGroup[j];
          const wordStartTime = currentTime + word.start;
          const wordEndTime = currentTime + word.end;
          const nextWordStartTime = currentTime + wordGroup[j + 1]?.start;
          const gapDiff = nextWordStartTime - wordEndTime;
          const isGap = wordEndTime !== nextWordStartTime && gapDiff > 0.09;

          // ZERO TIME WORD GAP -> SHOULD BE SYNC WITH ALSO SOUNDS OR IT"S A PROBLEM
          // const isZero = wordEndTime - wordStartTime === 0;
          //
          // if (isZero) {
          //   wordEndTime += 0.3;
          //   currentTime+= 0.3;
          //   console.log(wordStartTime, wordEndTime, word);
          // }

          subtitle.words.push({
            startTime: wordStartTime,
            endTime: wordEndTime,
            text: word.word,
          });

          if (isGap && wordGroup[j + 1]) {
            const nextWordEndTimeTime = currentTime + wordGroup[j + 1].end;

            subtitle.endTime = wordEndTime;

            subtitles.push(subtitle);
            subtitle = {
              startTime: nextWordStartTime,
              endTime: nextWordEndTimeTime,
              words: [],
            };
          }
        }

        subtitles.push(subtitle);
      }
    }

    // 25 frames
    currentTime += scene.audioDuration;

    // subtitles.push({
    //   startTime:  currentTime,
    //   endTime: currentTime + additionInSeconds,
    //   words: []
    // });
    //
    // currentTime += additionInSeconds;
  });

  return subtitles;
}

export function buildConfig({
  backgroundMusicUrl,
  backgroundMusicVolume,
  subtitlesConfig,
  video,
  assets,
  scenes,
  language = Languages.English,
}: {
  backgroundMusicUrl?: string;
  backgroundMusicVolume?: number;
  video: Video;
  scenes: Scene[];
  subtitlesConfig?: SubtitlesConfig;
  assets: Asset[];
  language?: Languages;
}) {
  // Create asset URL lookup map
  const assetMap = new Map<string, Asset>(
    assets.map((asset) => [asset._id.toString(), asset])
  );

  // Sort scenes by index
  const sortedScenes = orderBy(scenes, 'index', 'asc');

  // Calculate total duration for timing
  let cumulativeDuration = 0;

  const audioTracks: AudioTrack[][] = [
    sortedScenes
      .filter((scene) => {
        return Boolean(scene.audioUrl);
      })
      .map((scene) => {
        const track = {
          src: `${scene.audioUrl}?date=${scene.audioDuration}`,
          startAt: cumulativeDuration,
          duration: scene.audioDuration,
          volume: 1,
        };
        cumulativeDuration += scene.audioDuration;
        return track;
      }),
  ];

  const thumbnailConfig = video.thumbnailConfig || defaultThumbnailConfig;
  const thumbnailDurationInSeconds =
    thumbnailConfig.enableVoiceover && thumbnailConfig.voiceoverUrl
      ? thumbnailConfig.duration
      : 0.05;

  if (
    thumbnailConfig.enabled &&
    thumbnailConfig.enableVoiceover &&
    thumbnailConfig.voiceoverUrl
  ) {
    audioTracks[0] = [
      {
        src: `${video.thumbnailConfig.voiceoverUrl}?date=${thumbnailDurationInSeconds}`,
        startAt: 0,
        duration: video.thumbnailConfig.duration,
        volume: 1,
      },
      ...audioTracks[0],
    ];
  }

  if (backgroundMusicUrl) {
    audioTracks.push([
      {
        src: backgroundMusicUrl,
        startAt: 0,
        volume: backgroundMusicVolume || 0.1,
        loop: true,
        duration: cumulativeDuration,
      },
    ]);
  }

  let captionSettings: Partial<CaptionSettings> = {};

  if (subtitlesConfig) {
    captionSettings = {
      display: subtitlesConfig.display,
      numberOfWords: subtitlesConfig.numberOfWords,
      uppercase: subtitlesConfig.uppercase,
      fontSize: subtitlesConfig.fontSize,
      fontFamily: subtitlesConfig.fontName,
      textColor: subtitlesConfig.primaryColor,
      currentWordColor: subtitlesConfig.highlightColor,
      shadowColor: subtitlesConfig.outlineColor,
      shadowBlur: subtitlesConfig.outline,
      currentWordBackgroundColor: subtitlesConfig.backColor || 'transparent',
      positionY: subtitlesConfig.positionY,
    };
  }

  const remotionAssets: RemotionAsset[] = sortedScenes.map((scene) => {
    const asset = assetMap.get(scene.assetId);
    const type = asset?.type || 'image';
    const src = asset?.url || '';
    const format = asset?.format || '';

    return {
      type,
      src,
      contentType: format,
      duration: scene.audioDuration,
      transition: scene.transition,
      motion: scene.motion || MotionType.NO_MOTION,
    };
  });

  if (thumbnailConfig.enabled && remotionAssets[0]) {
    const firstAssetDuration = remotionAssets[0].duration as number;

    remotionAssets[0].duration =
      firstAssetDuration + thumbnailDurationInSeconds;
  }

  const thumbnailDelta = thumbnailConfig.enabled
    ? thumbnailDurationInSeconds
    : 0;

  const overlayTracks: OverlayTrackItem[] = [];

  if (thumbnailConfig.enabled) {
    overlayTracks.push({
      // BACKWARD COMPATIABLITY TO EMPTY THUMBNAIL CONFIG DONT FORGET
      from: 0,
      durationInFrames: thumbnailDurationInSeconds * 30,
      left: 0,
      top: '40%',
      width: '100%',
      height: 'auto',
      type: OverlayTrackType.Thumbnail,
      data: {
        type: video.thumbnailConfig.type,
        text: video.thumbnailConfig.text,
      },
    });
  }

  const config: RemotionConfig = {
    overlayTracks: overlayTracks,
    assets: remotionAssets,
    audioTracks,
    subtitles: createSubtitlesWithWordGroups(
      sortedScenes,
      captionSettings?.numberOfWords || 2,
      thumbnailDelta
    ),
    isRtl: isRTL(language),
    captionSettings: captionSettings,
    width: 1080,
    height: 1920,
    fps: 30,
    durationInFrames: 90,
  };

  const durationInFrames = calculateDurationInFrames(config);

  config.durationInFrames = durationInFrames;

  return config;
}
