import { Series } from '@monorepo/types';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../../helpers/use-store';
import { useNotification } from '../../../hooks/use-notification';
import SeriesForm from '../../series-form/series-form';
import { Typography } from 'antd';
import React from 'react';
import { Helmet } from 'react-helmet';

export const CreateSeriesPage = () => {
  const navigate = useNavigate();
  const notificationApi = useNotification();
  const {
    dataStore: { seriesStore },
  } = useStore();

  const onSubmitHandler = async (data: Partial<Series>) => {
    try {
      const series = await seriesStore.create(data);

      notificationApi.success({
        placement: 'top',
        message: 'Success',
        description: 'Series created successfully.',
      });

      navigate(`/series/${series._id}`);
    } catch (e) {
      notificationApi.error({
        placement: 'top',
        icon: '',
        message: 'Error',
        description:
          'An error occurred. This could be due to insufficient series amount or a server issue. Please check your balance or contact support for assistance.',
      });
      console.error(`failed handling series`, e);
    }
  };

  return (
    <>
      <Helmet>
        <title>Create Automated Series | ShortsNinja</title>
        <meta
          name="description"
          content="Set up automated video series to generate and post AI faceless videos on autopilot. Schedule and automate your content creation workflow."
        />
        <meta
          name="keywords"
          content="automated series, content automation, scheduled posting, ShortsNinja, faceless videos, ai video automation, ai voiceover, auto-posting"
        />
      </Helmet>
      <SeriesForm
        useCollapse={false}
        loadingButtonText={
          <Typography.Text
            style={{ display: 'flex', justifyContent: 'center' }}
            type={'secondary'}
          >
            Creating your series... This may take a few minutes ⚡️
          </Typography.Text>
        }
        onSubmit={onSubmitHandler}
      />
    </>
  );
};
