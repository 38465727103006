import React, { FunctionComponent } from 'react';
import { Row, Col, Flex } from 'antd';
import { TransitionEffect } from '@monorepo/types';
import { UseFormReturn } from 'react-hook-form';
import { FormSelect } from '@monorepo/react-components';
import { EditSceneForm } from '../edit-scene';

interface Props {
  form: UseFormReturn<EditSceneForm>;
}

const TransitionSelector: FunctionComponent<Props> = ({ form }) => {
  return (
    <Row gutter={[15, 15]}>
      <Col span={24}>
        <FormSelect
          allowClear={true}
          style={{ height: '60px' }}
          form={form}
          placeholder={'Select a transition effect'}
          controllerProps={{
            name: 'transition',
          }}
          options={[
            ...Object.entries(TransitionEffect)
              .filter(([key, value]) => {
                return ![TransitionEffect.TestGL].includes(value);
              })
              .map(([key, value]) => {
                const normalizedVal =
                  value === TransitionEffect.None ? '' : value;

                return {
                  value: normalizedVal,
                  label: (
                    <Flex align={'center'} justify={'space-between'}>
                      <span>{key}</span>
                    </Flex>
                  ),
                };
              }),
          ]}
        />
      </Col>
    </Row>
  );
};

export default TransitionSelector;
