import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, Divider, Form } from 'antd';
import { GoogleOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import AuthLayout from './auth-layout';
import {
  FormInput,
  FormPassword,
  LoadingState,
  Space,
  useLoading,
} from '@monorepo/react-components';
import { formEmailValidation, formPasswordValidation } from '@monorepo/types';
import { useStore } from '../../helpers/use-store';
import { useNotification } from '../../hooks/use-notification';
import GoogleLoginButton from './social/google-login-button';
import { Helmet } from 'react-helmet';

interface RegisterFormData {
  email: string;
  password: string;
  confirmPassword: string;
}

const RegisterPage: React.FC = () => {
  const navigate = useNavigate();
  const {
    dataStore: { userStore },
  } = useStore();
  const form = useForm<RegisterFormData>();
  const { loadingState, updateLoadingState } = useLoading(LoadingState.Initial);
  const notificationApi = useNotification();

  const onSubmit = async (data: RegisterFormData) => {
    try {
      updateLoadingState(LoadingState.Loading);
      await userStore.register(data);

      notificationApi.success({
        placement: 'top',
        icon: '',
        message: 'Success',
        description:
          'Confirmation email has been sent, please confirm your email to login.',
      });

      navigate('/login');
    } catch (e) {
      notificationApi.error({
        placement: 'top',
        icon: '',
        message: 'Error',
        description: 'Failed registering, please contact support.',
      });
    } finally {
      updateLoadingState(LoadingState.Loaded);
    }
  };

  const handleGoogleRegister = () => {
    console.log('Google register clicked');
    // Handle Google registration logic here
  };

  return (
    <AuthLayout title="Register">
      <Helmet>
        <title>Create Account | ShortsNinja</title>
        <meta
          name="description"
          content="Join ShortsNinja today. Create automated faceless videos with AI voiceover to scale your content creation."
        />
        <meta
          name="keywords"
          content="sign up, create account, register, ShortsNinja, short video management, faceless videos, ai video automation, ai voiceover, content automation"
        />
      </Helmet>
      <Form onFinish={form.handleSubmit(onSubmit)} layout="vertical">
        <Space size={'large'} direction={'vertical'}>
          <FormInput
            placeholder="Email"
            form={form}
            controllerProps={{
              name: 'email',
              rules: formEmailValidation(),
            }}
          />
          <FormPassword
            placeholder="Password"
            form={form}
            controllerProps={{
              name: 'password',
              rules: formPasswordValidation(),
            }}
          />
          <FormPassword
            placeholder="Confirm Password"
            form={form}
            controllerProps={{
              name: 'confirmPassword',
              rules: {
                ...formPasswordValidation(),
                validate: (value: string) =>
                  value === form.getValues('password') ||
                  'The passwords do not match',
              },
            }}
          />
          <Button
            loading={loadingState === LoadingState.Loading}
            type="primary"
            htmlType="submit"
            block
          >
            Register
          </Button>
        </Space>
      </Form>
      <Divider plain>Or</Divider>
      <GoogleLoginButton />
      <div className="auth-link">
        Already have an account? <Link to="/login">Login here</Link>
      </div>
    </AuthLayout>
  );
};

export default RegisterPage;
