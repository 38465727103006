import React, { useState, useEffect } from 'react';
import { Upload, message, Typography, Button, Flex } from 'antd';
import {
  UploadOutlined,
  FileOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import type { UploadFile } from 'antd/es/upload/interface';
import { LoadingState, useLoading } from '@monorepo/react-components';
import './upload-music.scss';
import { useStore } from '../../../../helpers/use-store';
import { useVideoContext } from '../../../../hooks/use-video';
import musicalNote from '../../../../components/music-list/musical-note.svg';

const { Title, Text } = Typography;

interface UploadMusicProps {
  existingUrl?: string;
  cancelMusic: () => Promise<void>;
}

const UploadMusic: React.FC<UploadMusicProps> = ({
  existingUrl,
  cancelMusic,
}) => {
  const {
    dataStore: { videoStore },
  } = useStore();
  const { video, fetchVideoAndAssets } = useVideoContext();
  const { updateLoadingState, loadingState } = useLoading();

  // Helper function to get filename from URL
  const getFileNameFromUrl = (url: string) => {
    try {
      const urlParts = url.split('/');
      return decodeURIComponent(urlParts[urlParts.length - 1]);
    } catch {
      return url;
    }
  };

  const [fileList, setFileList] = useState<UploadFile[]>(() => {
    if (existingUrl) {
      return [
        {
          uid: '-1',
          name: getFileNameFromUrl(existingUrl),
          status: 'done',
          url: existingUrl,
        },
      ];
    }
    return [];
  });

  useEffect(() => {
    if (existingUrl) {
      setFileList([
        {
          uid: '-1',
          name: getFileNameFromUrl(existingUrl),
          status: 'done',
          url: existingUrl,
        },
      ]);
    } else {
      setFileList([]);
    }
  }, [existingUrl]);

  const beforeUpload = (file: File) => {
    const fileTypes = ['audio/mpeg', 'audio/mp3', 'video/mp4'];
    const isValid = fileTypes.includes(file.type);

    if (!isValid) {
      message.error('You can only upload MP3 or MP4 files!');
      return false;
    }

    const isLessThan30MB = file.size / 1024 / 1024 < 30;
    if (!isLessThan30MB) {
      message.error('File must be smaller than 30MB!');
      return false;
    }

    return true;
  };

  const handleUpload = async (options: any) => {
    if (!video) {
      return;
    }

    const { file } = options;

    try {
      updateLoadingState(LoadingState.Loading);

      const formData = new FormData();
      formData.append('music', file);

      await videoStore.uploadMusic(video._id, formData);
      await fetchVideoAndAssets();

      setFileList([
        {
          uid: '-1',
          name: file.name,
          status: 'done',
          url: URL.createObjectURL(file),
        },
      ]);
      message.success('Upload completed successfully');
    } catch (error) {
      console.error('Upload failed:', error);
      message.error('Upload failed');
    } finally {
      updateLoadingState(LoadingState.Loaded);
    }
  };

  const handleRemove = async () => {
    await cancelMusic();
    setFileList([]);

    message.success('Removed successfully');
    return true;
  };

  const getDisplayNameFromUrl = (url?: string) => {
    if (!url) {
      return `music.mp3`;
    }

    try {
      const urlObj = new URL(url);
      const displayName = urlObj.searchParams.get('displayName');
      return displayName ? decodeURIComponent(displayName) : 'music file';
    } catch {
      return 'music file';
    }
  };

  return (
    <div className="upload-music">
      <Flex gap={20} justify={'space-between'} align={'flex-start'}>
        <Upload
          fileList={fileList}
          beforeUpload={beforeUpload}
          customRequest={handleUpload}
          onRemove={handleRemove}
          maxCount={1}
          accept=".mp3,.mp4"
          disabled={loadingState === LoadingState.Loading}
          showUploadList={false}
          style={{
            display: fileList.length > 0 ? 'none' : 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Button loading={loadingState === LoadingState.Loading}>
            <UploadOutlined style={{ fontSize: '16px', color: '#1890ff' }} />
            Upload Music
          </Button>
          <Typography.Text style={{ fontSize: '12px' }} type={'secondary'}>
            Support for MP3 or MP4 files.
          </Typography.Text>
          <Typography.Text style={{ fontSize: '12px' }} type={'secondary'}>
            Maximum file size: 30MB.
          </Typography.Text>
        </Upload>
        <Flex justify="space-between" align="center">
          {fileList.length > 0 && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '8px 12px',
                backgroundColor: '#f0f2f5',
                borderRadius: '6px',
                gap: '12px',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                <img
                  src={musicalNote}
                  style={{
                    color: '#000',
                    fontSize: '12px',
                    width: '16px',
                    height: '16px',
                  }}
                />
                <Text
                  ellipsis
                  style={{
                    maxWidth: '180px',
                    color: '#434343',
                    margin: 0,
                  }}
                >
                  {getDisplayNameFromUrl(existingUrl)}
                </Text>
              </div>
              <Button
                variant={'filled'}
                color={'danger'}
                size="small"
                icon={<DeleteOutlined />}
                onClick={handleRemove}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '28px',
                  padding: '0 8px',
                }}
              />
            </div>
          )}
        </Flex>
      </Flex>
    </div>
  );
};

export default UploadMusic;
