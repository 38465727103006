import React, { useEffect, useState } from 'react';
import { Button, Tag, Typography, Flex, Divider } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { VideoPart } from '../../video-editor-types';
import { useVideoContext } from '../../../../hooks/use-video';
import AssetComponent from '../../../../components/asset/asset';
import { Asset, Scene } from '@monorepo/types';
import ReplayScene from '../replay-scene/replay-scene';
import SceneImage from '../scene-image/scene-image';

const { Text } = Typography;

type SceneItemProps = {
  sceneId: string;
  sceneNumber: number;
  currentTime: number;
  displayDivider: boolean;
};

const SceneItem = ({
  sceneId,
  sceneNumber,
  currentTime,
  displayDivider,
}: SceneItemProps) => {
  const [asset, setAsset] = useState<Asset>();
  const {
    setSceneId,
    setCurrentVideoPart,
    assets: scenesAssets,
    scenesMap,
    fetchAsset,
    getSceneStartFrame,
    movePlayerToFrame,
    modalRef,
  } = useVideoContext();

  const scene = scenesMap.get(sceneId);

  useEffect(() => {
    const init = async () => {
      if (scene && scene.assetId) {
        let asset = scenesAssets.find((asset) => asset._id === scene.assetId);

        if (!asset) {
          asset = await fetchAsset(scene.assetId);
        }

        setAsset(asset);
      }
    };

    init();
  }, [scene?.assetId]);

  if (!scene) {
    return null;
  }

  const firstAsset = asset;
  const sceneStartTime = currentTime;
  const sceneEndTime = sceneStartTime + scene.audioDuration;

  const handleSelect = () => {
    setCurrentVideoPart(VideoPart.EditScene);
    setSceneId(scene._id);
    const startFrame = getSceneStartFrame(scene._id);
    movePlayerToFrame(startFrame + 15, false);
  };

  return (
    <div className={`scene scene-number-${sceneNumber}`} key={scene._id}>
      <Flex align={'baseline'} justify={'space-between'}>
        <Flex align={'baseline'}>
          <Text
            style={{ marginRight: '15px' }}
            type={'secondary'}
            underline={true}
          >
            {sceneNumber}
          </Text>
          <span style={{ flex: 4, maxWidth: '400px' }}>
            <div style={{ marginBottom: '15px' }}>
              <Tag>Duration: {(sceneEndTime - sceneStartTime).toFixed(2)}s</Tag>
              {/*<Tag style={{ borderRadius: '5px' }}>*/}
              {/*  In {sceneStartTime.toFixed(2)}s*/}
              {/*</Tag>*/}
              {/*<Tag style={{ borderRadius: '5px' }}>*/}
              {/*  Out {sceneEndTime.toFixed(2)}s*/}
              {/*</Tag>*/}
            </div>
          </span>
        </Flex>
        <Flex style={{ flexDirection: 'row' }} align={'center'}>
          <Button
            type={'text'}
            onClick={handleSelect}
            shape={'round'}
            icon={
              <EditOutlined style={{ marginTop: '2px', fontSize: '18px' }} />
            }
          />
          <ReplayScene sceneId={scene._id} />
        </Flex>
      </Flex>
      <Flex gap={25} justify="start" align="start">
        <SceneImage
          scene={scene}
          asset={firstAsset}
          onAssetClick={() => {
            modalRef.current?.open();

            setSceneId(scene._id);
          }}
        />
        <Text>{scene.text}</Text>
      </Flex>

      {displayDivider && <Divider style={{ marginBottom: 0 }} />}
    </div>
  );
};

export default SceneItem;
