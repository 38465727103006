import { Button, notification } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { ReactNode } from 'react';

interface CopyTextProps {
  text: string;
  children?: ReactNode;
  placement?:
    | 'top'
    | 'bottom'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight';
}

const CopyText = ({
  text,
  children,
  placement = 'topRight',
}: CopyTextProps) => {
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(text);
      notification.success({
        message: 'Copied!',
        description: 'Text has been copied to clipboard',
        placement,
        duration: 2,
      });
    } catch (err) {
      notification.error({
        message: 'Failed to copy',
        description: 'Please try again',
        placement,
      });
    }
  };

  return (
    <span style={{ display: 'inline-block' }}>
      {children}
      <Button
        type="text"
        icon={<CopyOutlined style={{ fontSize: '14px' }} />}
        onClick={handleCopy}
        title="Copy to clipboard"
      />
    </span>
  );
};

export default CopyText;
