import { Button, Card, Flex, Progress, Tooltip, Typography } from 'antd';
import { LoadingState, useLoading } from '@monorepo/react-components';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useVideoContext } from '../../../../hooks/use-video';
import { useStore } from '../../../../helpers/use-store';
import dayjs from 'dayjs';
import { VideoStatus } from '@monorepo/types';
import { ReloadOutlined } from '@ant-design/icons';

export interface VideoPreviewRef {
  startPolling: (flag: boolean) => void;
  endPolling: () => void;
}

interface Props {
  isOpen: boolean;
}

const VideoPreview = forwardRef<VideoPreviewRef, Props>(({ isOpen }, ref) => {
  const { video, renderVideo, fetchVideoAndAssets, download } =
    useVideoContext();
  const [isRendering, setRendering] = useState(false);
  const [renderRequested, setRenderRequest] = useState(video?.renderRequested);
  const [renderStartDate, setRenderStartDate] = useState(
    video?.renderStartDate
  );
  const [renderProgress, setRenderProgress] = useState(0);
  const pollingInterval = useRef<number>();
  const {
    dataStore: { videoStore },
  } = useStore();
  const {
    loadingState: downloadLoadingState,
    updateLoadingState: updateDownloadLoadingState,
  } = useLoading();
  const {
    loadingState: renderLoadingState,
    updateLoadingState: updateRenderLoadingState,
  } = useLoading();
  const {
    loadingState: refreshLoadingState,
    updateLoadingState: updateRefreshLoadingState,
  } = useLoading();

  const videoId = video?._id;

  const videoUrl = useMemo(() => {
    const date = video?.renderDate ? Date.now() : new Date().getTime();
    return `${video?.videoUrl}?date=${date}`;
  }, [video?.renderDate]);

  useEffect(() => {
    if (isOpen) {
      setRendering(false);
      setRenderProgress(0);
    }
  }, [isOpen]);

  const endPolling = async (shouldReFetch = false) => {
    if (pollingInterval.current) {
      clearInterval(pollingInterval.current);
      pollingInterval.current = -1;
    }

    if (!videoId) {
      return;
    }

    if (shouldReFetch) {
      // TODO: just refetch video
      fetchVideoAndAssets();
    }

    updateRenderLoadingState(LoadingState.Loaded);
    setRenderProgress(0);
    setRendering(false);
  };

  const startPolling = async (shouldReFetch = false) => {
    if (!videoId) {
      return;
    }

    if (pollingInterval.current && pollingInterval.current !== -1) {
      endPolling();
    }

    const pollingAction = async () => {
      const progressResult = await videoStore.getProgress(videoId);
      const progress = progressResult.renderProgress;

      if (!progressResult.isRendering && !progressResult.renderRequested) {
        endPolling(true);
      }

      setRenderProgress(progress);
      setRendering(progressResult.isRendering);
      setRenderStartDate(progressResult.renderStartDate);
      setRenderRequest(progressResult.renderRequested);
      if (progressResult.isRendering) {
        updateRenderLoadingState(LoadingState.Loading);
      }
    };

    //@ts-expect-error stupid Timer
    pollingInterval.current = setInterval(pollingAction, 2500);

    pollingAction();

    setTimeout(() => {
      if (pollingInterval.current) {
        endPolling();
      }
      // 5 minutes
    }, 60000 * 5);
  };

  useEffect(() => {
    if (video) {
      // setRenderProgress(progress);
      setRendering(video?.isRendering);
      setRenderStartDate(video?.renderStartDate);
      setRenderRequest(video?.renderRequested);
    }
  }, [video]);

  useImperativeHandle(ref, () => ({
    endPolling,
    startPolling,
  }));

  const refresh = async () => {
    try {
      updateRefreshLoadingState(LoadingState.Loading);
      await fetchVideoAndAssets();
    } catch (e) {
      console.error(`Error loading: ${e}`);
    } finally {
      updateRefreshLoadingState(LoadingState.Loaded);
    }
  };

  const isRenderRequestInProgress =
    renderLoadingState === LoadingState.Loading || renderRequested;
  const isEnoughTimePassedFromLastRender = renderStartDate
    ? dayjs().diff(renderStartDate, 'minutes') > 30
    : true;

  return (
    <Card
      styles={{ body: { paddingTop: '10px' } }}
      title={
        <Flex justify={'space-between'} align={'center'}>
          <span>Video Preview</span>
          <Tooltip title={`Refresh the video to see the latest status.`}>
            <Button
              loading={refreshLoadingState === LoadingState.Loading}
              onClick={refresh}
              disabled={video?.status === VideoStatus.Published}
              icon={<ReloadOutlined />}
            />
          </Tooltip>
          {/*{video?.renderDate ? <Typography.Text type={'secondary'}>Render Date:{dayjs(video.renderDate).format('MM/DD/YYYY hh:mm')}</Typography.Text> : null}*/}
        </Flex>
      }
    >
      <Flex
        gap={15}
        justify={'space-between'}
        style={{ flexDirection: 'column' }}
      >
        <Typography.Text type="secondary">
          🎬 Preview your rendered video in this section <br />⏳ Rendering
          takes a few minutes to complete (limited to 1 render per 30 minutes)
        </Typography.Text>

        <Flex align={'center'} style={{ flexDirection: 'column' }}>
          <video
            src={videoUrl}
            controls
            style={{
              width: '100%',
              borderRadius: '8px',
              maxWidth: '330px',
              minHeight: '500px',
              // marginBottom: '1rem',
              margin: '0 auto',
            }}
          />
          <Typography.Text
            style={{
              wordBreak: 'break-word',
              whiteSpace: 'initial',
              marginTop: 0,
            }}
            type={'secondary'}
          >
            <div>✨ Enjoy your first two renders on us!</div>
            <div>🔄 Each additional render costs just 1 credit.</div>
          </Typography.Text>
        </Flex>
      </Flex>

      <Flex
        justify={'space-between'}
        gap={5}
        style={{ width: '100%', flexDirection: 'column' }}
      >
        <div style={{ minHeight: '22px' }}>
          {(isRendering || isRenderRequestInProgress) && renderProgress > 0 && (
            <Progress
              percent={renderProgress || 0}
              status="active"
              strokeColor={{
                '0%': '#108ee9',
                '100%': '#87d068',
              }}
            />
          )}
        </div>
        <Flex gap={15} style={{ width: '100%' }}>
          <Button
            loading={isRenderRequestInProgress}
            disabled={
              isRenderRequestInProgress ||
              !isEnoughTimePassedFromLastRender ||
              video?.status === VideoStatus.Published
            }
            style={{ width: '100%' }}
            type="primary"
            onClick={async () => {
              try {
                updateRenderLoadingState(LoadingState.Loading);
                await renderVideo();
                setRendering(true);
                startPolling(true);
              } catch (e) {
                endPolling();
                console.error(`failed rendering`, e);
              }
            }}
          >
            Render
          </Button>
          <Button
            disabled={!video?.videoUrl}
            loading={downloadLoadingState === LoadingState.Loading}
            style={{ width: '100%' }}
            onClick={async () => {
              try {
                updateDownloadLoadingState(LoadingState.Loading);
                await download();
              } catch (e) {
                console.error(`failed download`, e);
              } finally {
                updateDownloadLoadingState(LoadingState.Loaded);
              }
            }}
          >
            Download
          </Button>
        </Flex>
        {renderRequested ? (
          <Typography.Text type={'secondary'}>
            📤 Your render request has been submitted!
            <br /> ✨ No need to wait here - keep creating! ⏳ We’ll process it
            shortly and notify you via email when it’s ready.
          </Typography.Text>
        ) : null}
        {!isEnoughTimePassedFromLastRender && !isRendering ? (
          <Typography.Text type={'secondary'}>
            You can render the video again in:{' '}
            {dayjs(renderStartDate).add(30, 'minutes').diff(dayjs(), 'minutes')}{' '}
            minutes.
          </Typography.Text>
        ) : null}
      </Flex>
    </Card>
  );
});

export default VideoPreview;
