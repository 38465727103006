import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Modal, Space, Typography } from 'antd';
import { LoadingState, useLoading } from '@monorepo/react-components';
import { useNotification } from '../../../hooks/use-notification';
import { useStore } from '../../../helpers/use-store';
import {
  WarningOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined,
} from '@ant-design/icons';

export interface UpdateSeriesStatusModalActions {
  open: (seriesId: string, currentlyActive: boolean) => void;
}

const UpdateSeriesStatus = forwardRef<UpdateSeriesStatusModalActions>(
  (_, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const [seriesId, setSeriesId] = useState<string | null>(null);
    const [isCurrentlyActive, setIsCurrentlyActive] = useState<boolean>(false);
    const { loadingState, updateLoadingState } = useLoading();
    const {
      dataStore: { seriesStore },
    } = useStore();
    const notification = useNotification();

    useImperativeHandle(ref, () => ({
      open: (id: string, currentlyActive: boolean) => {
        setSeriesId(id);
        setIsCurrentlyActive(currentlyActive);
        setIsOpen(true);
      },
    }));

    const handleToggle = async () => {
      if (!seriesId) return;

      const newIsActive = !isCurrentlyActive;

      try {
        updateLoadingState(LoadingState.Loading);
        await seriesStore.update(seriesId, {
          isActive: newIsActive,
        });

        notification.success({
          message: 'Success',
          description: `Series ${
            newIsActive ? 'activated' : 'disabled'
          } successfully`,
        });

        handleCancel();
      } catch (error) {
        console.error(
          `Failed ${newIsActive ? 'activating' : 'disabling'} series:`,
          error
        );
        notification.error({
          message: 'Error',
          description: `${newIsActive ? 'Activating' : 'Disabling'} failed`,
        });
      } finally {
        updateLoadingState(LoadingState.Loaded);
      }
    };

    const handleCancel = () => {
      setIsOpen(false);
      setSeriesId(null);
    };

    const getModalContent = () => {
      if (isCurrentlyActive) {
        return {
          title: 'Disable Series',
          icon: (
            <PauseCircleOutlined
              style={{ color: '#faad14', fontSize: '16px' }}
            />
          ),
          message: 'Are you sure you want to disable this series?',
          buttonText: 'Disable',
          consequences: [
            'Pause all scheduled video publishing',
            'Keep all videos in the series',
            'Maintain all series settings and data',
            'You can re-enable the series at any time',
          ],
        };
      }
      return {
        title: 'Enable Series',
        icon: (
          <PlayCircleOutlined style={{ color: '#52c41a', fontSize: '16px' }} />
        ),
        message: 'Are you sure you want to enable this series?',
        buttonText: 'Enable',
        consequences: [
          'Resume scheduled video publishing from tomorrow',
          'Reschedule will be done according to seleced videos amount per day',
          'Start generating new content after midnight',
        ],
      };
    };

    const content = getModalContent();

    return (
      <Modal
        title={content.title}
        open={isOpen}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="toggle"
            type="primary"
            danger={isCurrentlyActive}
            loading={loadingState === LoadingState.Loading}
            onClick={handleToggle}
          >
            {content.buttonText}
          </Button>,
        ]}
      >
        <Space direction="vertical" size={16} style={{ width: '100%' }}>
          <Typography.Text strong style={{ fontSize: '16px' }}>
            {content.message}
          </Typography.Text>
          <div
            style={{
              background: isCurrentlyActive ? '#fffbe6' : '#f6ffed',
              border: `1px solid ${isCurrentlyActive ? '#ffe58f' : '#b7eb8f'}`,
              borderRadius: '6px',
              padding: '12px',
            }}
          >
            <Space align="start">
              {content.icon}
              <div>
                <Typography.Text
                  strong
                  style={{
                    color: isCurrentlyActive ? '#d48806' : '#389e0d',
                    display: 'block',
                    marginBottom: '4px',
                  }}
                >
                  This will:
                </Typography.Text>
                <ul style={{ margin: '0', paddingLeft: '16px', color: '#666' }}>
                  {content.consequences.map((consequence, index) => (
                    <li key={index}>{consequence}</li>
                  ))}
                </ul>
              </div>
            </Space>
          </div>
        </Space>
      </Modal>
    );
  }
);

export default UpdateSeriesStatus;
