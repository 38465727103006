import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Flex, Modal, Typography } from 'antd';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { getEnv } from '../../helpers/mobx-easy-wrapper';
import { YouTubeAuthModal } from './youtube/youtube-auth';
import TikTokAuth from './tiktok/tiktok-auth';

export interface SocialAccountsModalRef {
  open: () => void;
  close: () => void;
}

export interface SocialAccountsModalProps {
  onAccountAdded: () => void;
}

export const SocialAccountsModal = forwardRef<
  SocialAccountsModalRef,
  SocialAccountsModalProps
>(({ onAccountAdded }, ref) => {
  const {
    envConfig: { youtube_client_id },
  } = getEnv();

  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  useImperativeHandle(ref, () => ({
    open: openModal,
    close: closeModal,
  }));

  return (
    <Modal
      maskClosable={false}
      onCancel={closeModal}
      footer={null}
      title={'Add Social Account'}
      open={isOpen}
    >
      <Flex gap={15} style={{ flexDirection: 'column' }}>
        <div>
          <GoogleOAuthProvider clientId={youtube_client_id}>
            <YouTubeAuthModal onAccountAdded={onAccountAdded} />
          </GoogleOAuthProvider>
        </div>
        <div className="tiktok">
          <TikTokAuth onAccountAdded={onAccountAdded} />
        </div>
        <div>
          <Typography.Text type="secondary" style={{ fontSize: 12 }}>
            💡 Have questions? Our support team is here to help!
          </Typography.Text>
        </div>
      </Flex>
    </Modal>
  );
});
