import { action, computed, makeObservable, observable } from 'mobx';
import { getEnv } from '../../../helpers/mobx-easy-wrapper';
import {
  ClientUser,
  RegisterRequest,
  SubscriptionStatusName,
  User,
  UserSubscription,
} from '@monorepo/types';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';

export default class UserStore {
  @observable
  isLoggedIn = false;

  @observable
  currentUser: ClientUser = {} as ClientUser;

  @observable
  changelogCount = 0;

  constructor() {
    makeObservable(this);
  }

  @action
  setChangelogCount(count: number) {
    this.changelogCount = count;
  }

  async register(data: RegisterRequest) {
    try {
      const {
        apiFactory: { authService },
      } = getEnv();

      const result = await authService.register(data);

      return result;
    } catch (e) {
      console.error(`Failed registering user`, e);
      throw e;
    }
  }

  async confirm(token: string) {
    try {
      const {
        apiFactory: { authService },
      } = getEnv();

      const user = await authService.confirm(token);

      this.setCurrentUser(user);
      this.setLoginState(true);
    } catch (e) {
      console.error(`Failed registering user`, e);
      throw e;
    }
  }

  async googleCallback(token: string) {
    try {
      const {
        apiFactory: { authService },
      } = getEnv();

      const user = await authService.googleCallback(token);

      this.setCurrentUser(user);
      this.setLoginState(true);
    } catch (e) {
      console.error(`Failed google auth`, e);
      throw e;
    }
  }

  async resetPassword(token: string, newPassword: string): Promise<boolean> {
    try {
      const {
        apiFactory: { authService },
      } = getEnv();

      return await authService.resetPassword(token, newPassword);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async forgetPassword(email: string): Promise<boolean> {
    try {
      const {
        apiFactory: { authService },
      } = getEnv();

      return await authService.forgetPassword(email);
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async logout() {
    const {
      apiFactory: { authService },
    } = getEnv();

    await authService.logout();

    this.setCurrentUser({} as ClientUser);
    this.setLoginState(false);

    localStorage.removeItem('initialUrl');
  }

  async login(email?: string, password?: string) {
    const storedAuth = localStorage.getItem('isAuthenticated');

    if (storedAuth !== 'true' && !email && !password) {
      return true;
    }

    try {
      const {
        apiFactory: { authService },
      } = getEnv();

      const user = await authService.login({ email, password });

      this.setCurrentUser(user);
      this.setLoginState(true);

      return user;
    } catch (e) {
      if ((e as AxiosError).status === 401) {
        localStorage.removeItem('isAuthenticated');
      }
      console.error(e);
      throw e;
    }
  }

  async uploadAvatar(form: FormData) {
    const {
      apiFactory: { userService },
    } = getEnv();

    const user = await userService.uploadAvatar(form);

    this.setCurrentUser({ ...this.currentUser, ...user });
  }

  async updateDetails(userDto: Pick<User, 'firstName' | 'lastName' | 'email'>) {
    const {
      apiFactory: { userService },
    } = getEnv();

    const user = await userService.updateDetails(userDto);

    this.setCurrentUser({ ...this.currentUser, ...user });
  }

  async updatePassword(newPassword: string) {
    const {
      apiFactory: { userService },
    } = getEnv();

    return userService.updatePassword(newPassword);
  }

  async cancelSubscription() {
    const {
      apiFactory: { paymentsService },
    } = getEnv();

    return paymentsService.cancelSubscription();
  }

  async renewSubscription() {
    const {
      apiFactory: { paymentsService },
    } = getEnv();

    return paymentsService.renewSubscription();
  }

  async updatePaymentDetails() {
    const {
      apiFactory: { paymentsService },
    } = getEnv();

    return paymentsService.updatePaymentDetails();
  }

  async changePlan(planId: string) {
    const {
      apiFactory: { paymentsService },
    } = getEnv();

    return paymentsService.changePlan(planId);
  }

  async getLatestUser() {
    const {
      apiFactory: { userService },
    } = getEnv();

    const user = await userService.getAll();

    this.setCurrentUser({ ...this.currentUser, ...user });
  }

  @action
  setCurrentUser(data: ClientUser) {
    this.currentUser = data;
  }

  @action
  setLoginState(loginState: boolean) {
    this.isLoggedIn = loginState;

    localStorage.setItem('isAuthenticated', loginState.toString());
  }

  @computed
  get fullName() {
    return `${this.currentUser.firstName.trim()} ${this.currentUser.lastName.trim()}`;
  }

  @computed
  get userSubscription(): UserSubscription {
    const defaultSubscription = {
      allowedSeriesAmount: 0,
      credits: 0,
    };

    return {
      ...defaultSubscription,
      ...(this.currentUser.subscription || {}),
    };
  }

  @computed
  get isSubscriptionActive() {
    const statusActive =
      this.userSubscription.status === SubscriptionStatusName.Active;
    const dateActive = this.userSubscription.nextChargeDate
      ? dayjs().isBefore(dayjs(this.userSubscription.nextChargeDate))
      : false;

    return statusActive || dateActive;
  }
}
