import { IdIdentifier, Timestamps, UserIdentifier } from '../common';
import {
  defaultKlingOptions,
  defaultLumaOptions,
  KlingOptions,
  LumaOptions,
  RunwayMlOptions,
} from './model-options';
import { VoiceoverProvider } from '../voiceover/voiceover';

export type NinjaJob = IdIdentifier &
  Timestamps &
  UserIdentifier & {
    type: NinjaJobType;
    provider: NinjaJobProvider;
    status: NinjaJobStatus;
    retryAmount: number;
    progress?: number;
    requestPayload?: any;
    resultPayload?: any;
    videoId?: string;
    sceneId?: string;
    assetId?: string;
  };

export enum NinjaJobProvider {
  OpenAi = 'openai',
  Speechify = 'speechify',
  ElevenLabs = 'eleven-labs',
  FalAi = 'fal-ai',
  RunwayML = 'runway-ml',
}

export enum NinjaProviderModel {
  FluxSchnell = 'flux-schnell',
  FluxDev = 'flux-dev',
  StableVideoDiffusion = 'stable-diffusion',
  LumaDreamMachine = 'luma-dream-machine',
  MinimaxVideoOneLive = 'minimax-video-01-live',
  KlingStandard = 'kling-standard',
  KlingPro = 'kling-pro',
  KlingOneFive = 'kling-1.5',
  RunwayML = 'runway-ml',
}
export enum NinjaJobStatus {
  Registered = 'registered',
  InProgress = 'in-progress',
  Finished = 'finished',
  Failed = 'failed',
}

export enum NinjaJobType {
  CreateScenes = 'create-scenes',
  CreateSceneAsset = 'create-scene-asset',
  CreateSceneVoiceover = 'create-scene-voiceover',
  CreateThumbnailVoiceover = 'create-thumbnail-voiceover',
  CreateVoiceover = 'create-voiceover',
  CreateImage = 'create-image',
  AnimateImage = 'animate-image',
}

export const VideoAvailableModels = [
  NinjaProviderModel.StableVideoDiffusion,
  NinjaProviderModel.KlingStandard,
  NinjaProviderModel.RunwayML,
  NinjaProviderModel.LumaDreamMachine,
  NinjaProviderModel.MinimaxVideoOneLive,
  NinjaProviderModel.KlingPro,
  NinjaProviderModel.KlingOneFive,
];

export const ninjaJobModelToProvider = {
  [NinjaProviderModel.StableVideoDiffusion]: NinjaJobProvider.FalAi,
  [NinjaProviderModel.RunwayML]: NinjaJobProvider.RunwayML,
  [NinjaProviderModel.LumaDreamMachine]: NinjaJobProvider.FalAi,
  [NinjaProviderModel.MinimaxVideoOneLive]: NinjaJobProvider.FalAi,
  [NinjaProviderModel.KlingStandard]: NinjaJobProvider.FalAi,
  [NinjaProviderModel.KlingPro]: NinjaJobProvider.FalAi,
  [NinjaProviderModel.KlingOneFive]: NinjaJobProvider.FalAi,
};

export const NinjaJobModelNames: Record<string, string> = {
  [NinjaProviderModel.FluxDev]: 'Flux Dev',
  [NinjaProviderModel.RunwayML]: 'RunwayML',
  [NinjaProviderModel.StableVideoDiffusion]: 'Stable Diffusion',
  [NinjaProviderModel.LumaDreamMachine]: 'Luma Dream Machine',
  [NinjaProviderModel.MinimaxVideoOneLive]: 'Hailuo Minimax',
  [NinjaProviderModel.KlingStandard]: 'Kling 1.0',
  [NinjaProviderModel.KlingPro]: 'Kling 1.0 (Pro)',
  [NinjaProviderModel.KlingOneFive]: 'Kling 1.5(Pro)',
};

export const NinjaJobsMessage: Record<
  string,
  { success: string; fail: string }
> = {
  [NinjaJobType.CreateVoiceover]: {
    success: `Your voiceover has been generated successfully.`,
    fail: `Failed to generate voiceover. Please try again or contact support.`,
  },
  [NinjaJobType.CreateImage]: {
    success: `Your AI image has been generated successfully.`,
    fail: `Failed to generate AI image. Please try again or contact support.`,
  },
  [NinjaJobType.AnimateImage]: {
    success: `Your image animation has been created successfully.`,
    fail: `Failed to animate image. Please try again or contact support.`,
  },
};

export type NinjaModelsOptions = LumaOptions | KlingOptions | RunwayMlOptions;

export const NinjaModelDefaults = {
  [NinjaProviderModel.LumaDreamMachine]: defaultLumaOptions,
  [NinjaProviderModel.KlingStandard]: defaultKlingOptions,
  [NinjaProviderModel.KlingPro]: defaultKlingOptions,
  [NinjaProviderModel.KlingOneFive]: defaultKlingOptions,
};

export const ninjaJobByVoiceover = {
  [VoiceoverProvider.ElevenLabs]: NinjaJobProvider.ElevenLabs,
  [VoiceoverProvider.Speechify]: NinjaJobProvider.Speechify,
  [VoiceoverProvider.OpenAi]: NinjaJobProvider.OpenAi,
};
