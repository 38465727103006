import logoLight from './logo-light.svg';
import logoDark from './logo-dark.svg';

interface LogoProps {
  variant?: 'light' | 'dark';
}

const Logo = ({ variant = 'light' }: LogoProps) => {
  const logoSrc = variant === 'dark' ? logoDark : logoLight;

  return (
    <img
      style={{
        width: '35px',
        height: '35px',
      }}
      src={logoSrc}
      alt="Logo"
    />
  );
};

export default Logo;
