import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Alert, Button, Modal, Space, Typography } from 'antd';
import { LoadingState, useLoading } from '@monorepo/react-components';
import { useNavigate } from 'react-router-dom';
import { useNotification } from '../../../hooks/use-notification';
import { useStore } from '../../../helpers/use-store';
import { WarningOutlined } from '@ant-design/icons';

export interface DeleteSeriesModalActions {
  open: (seriesId: string) => void;
}

const DeleteSeriesModal = forwardRef<DeleteSeriesModalActions>((_, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [seriesId, setSeriesId] = useState<string | null>(null);
  const { loadingState, updateLoadingState } = useLoading();
  const {
    dataStore: { seriesStore },
  } = useStore();
  const notification = useNotification();
  const navigate = useNavigate();

  useImperativeHandle(ref, () => ({
    open: (id: string) => {
      setSeriesId(id);
      setIsOpen(true);
    },
  }));

  const handleDelete = async () => {
    if (!seriesId) return;

    try {
      updateLoadingState(LoadingState.Loading);
      await seriesStore.delete(seriesId);

      notification.success({
        message: 'Success',
        description: 'Series deleted successfully',
      });

      navigate('/series');
    } catch (error) {
      console.error('Failed to delete series:', error);
      notification.error({
        message: 'Error',
        description: 'Failed to delete series. Please try again.',
      });
    } finally {
      updateLoadingState(LoadingState.Loaded);
      handleCancel();
    }
  };

  const handleCancel = () => {
    setIsOpen(false);
    setSeriesId(null);
  };

  return (
    <Modal
      title="Delete Series"
      open={isOpen}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="delete"
          danger
          type="primary"
          loading={loadingState === LoadingState.Loading}
          onClick={handleDelete}
        >
          Delete
        </Button>,
      ]}
    >
      <Space direction="vertical" size={16} style={{ width: '100%' }}>
        <Typography.Text strong style={{ fontSize: '16px' }}>
          Are you sure you want to delete?
        </Typography.Text>
        <div
          style={{
            background: '#fffbe6',
            border: '1px solid #ffe58f',
            borderRadius: '6px',
            padding: '12px',
          }}
        >
          <Space align="start">
            <WarningOutlined style={{ color: '#faad14', fontSize: '16px' }} />
            <div>
              <Typography.Text
                strong
                style={{
                  color: '#d48806',
                  display: 'block',
                  marginBottom: '4px',
                }}
              >
                This will:
              </Typography.Text>
              <ul style={{ margin: '0', paddingLeft: '16px', color: '#666' }}>
                <li>Cancel all scheduled video publishing dates</li>
                <li>Remove all videos from this series</li>
                <li>Delete all series settings and data</li>
                <li>Videos and their assets will be kept</li>
              </ul>
            </div>
          </Space>
        </div>
      </Space>
    </Modal>
  );
});

export default DeleteSeriesModal;
