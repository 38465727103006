import React from 'react';
import {
  List,
  Tag,
  Typography,
  Descriptions,
  Button,
  Badge,
  DescriptionsProps,
  Empty,
} from 'antd';
import { SyncOutlined, ClockCircleOutlined } from '@ant-design/icons';
import {
  NinjaJob,
  NinjaJobModelNames,
  NinjaJobStatus,
  NinjaJobType,
  NinjaProviderModel,
} from '@monorepo/types';
import dayjs from 'dayjs';
import { useVideoContext } from '../../../hooks/use-video';

const { Text } = Typography;

const jobTypeToLabel = {
  [NinjaJobType.CreateVoiceover]: 'Voiceover',
  [NinjaJobType.CreateImage]: 'Image Generation',
  [NinjaJobType.AnimateImage]: 'Image Animation',
  [NinjaJobType.CreateSceneVoiceover]: 'Voiceover Creation',
  [NinjaJobType.CreateSceneAsset]: 'Asset Creation',
  [NinjaJobType.CreateScenes]: 'Scenes Creation',
  [NinjaJobType.CreateThumbnailVoiceover]: 'Thumbnail Voiceover',
};

const statusToText = {
  [NinjaJobStatus.Registered]: 'Queued',
  [NinjaJobStatus.InProgress]: 'Processing',
  [NinjaJobStatus.Finished]: 'Completed',
  [NinjaJobStatus.Failed]: 'Failed',
};

const statusToColor = {
  [NinjaJobStatus.Registered]: 'blue',
  [NinjaJobStatus.InProgress]: 'processing',
  [NinjaJobStatus.Finished]: 'success',
  [NinjaJobStatus.Failed]: 'error',
};

const RunningTimer: React.FC<{ startDate: string }> = ({ startDate }) => {
  const [duration, setDuration] = React.useState('');

  React.useEffect(() => {
    const updateDuration = () => {
      const start = new Date(startDate).getTime();
      const now = new Date().getTime();
      const diff = Math.floor((now - start) / 1000); // difference in seconds

      const minutes = Math.floor(diff / 60);
      const seconds = diff % 60;

      setDuration(`${minutes}m ${seconds}s`);
    };

    // Update immediately and then every second
    updateDuration();
    const interval = setInterval(updateDuration, 1000);

    return () => clearInterval(interval);
  }, [startDate]);

  return (
    <Text type="secondary" style={{ fontSize: '12px' }}>
      <ClockCircleOutlined /> Running for: {duration}
    </Text>
  );
};

const JobItem: React.FC<{ job: NinjaJob }> = ({ job }) => {
  const model = job.requestPayload?.model as NinjaProviderModel;
  const modelInfo = model ? NinjaJobModelNames[model] : null;
  const isActive =
    job.status === NinjaJobStatus.InProgress ||
    job.status === NinjaJobStatus.Registered;
  const shouldDisplayTime =
    Math.abs(dayjs().diff(dayjs(job.updatedAt), 'minutes')) < 15;

  const items: DescriptionsProps['items'] = [
    {
      key: 'jobType',
      label: 'Job Type',
      children: (
        <Tag color="purple">{jobTypeToLabel[job.type] || job.type}</Tag>
      ),
    },
    {
      key: 'status',
      label: 'Status',
      children: (
        <Badge
          status={statusToColor[job.status] as any}
          text={statusToText[job.status]}
        />
      ),
    },
    {
      key: 'model',
      label: 'Model',
      children: modelInfo ? <Tag color="gold">{modelInfo}</Tag> : '-',
    },
    {
      key: 'time',
      label: 'Time',
      children:
        isActive && shouldDisplayTime ? (
          <RunningTimer startDate={job.updatedAt.toString()} />
        ) : (
          <Text type="secondary" style={{ fontSize: '12px' }}>
            {new Date(job.updatedAt).toLocaleString()}
          </Text>
        ),
    },
  ];

  return (
    <List.Item>
      <Descriptions
        size="small"
        layout="vertical"
        items={items}
        column={2}
        style={{ width: '100%' }}
      />
    </List.Item>
  );
};

const Jobs: React.FC = () => {
  const { videoJobs } = useVideoContext();
  const { allJobs, startPolling, stopPolling } = videoJobs;

  // Sort jobs by updatedAt, most recent first
  const sortedJobs = [...allJobs].sort((a, b) => {
    // First compare status priority
    const statusOrder = {
      [NinjaJobStatus.Registered]: 0,
      [NinjaJobStatus.InProgress]: 1,
      [NinjaJobStatus.Finished]: 2,
      [NinjaJobStatus.Failed]: 2, // Same priority as Finished
    };

    const statusDiff = statusOrder[a.status] - statusOrder[b.status];

    // If status is different, return status comparison
    if (statusDiff !== 0) return statusDiff;

    // If same status, sort by updatedAt (most recent first)
    return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
  });

  // Cleanup on unmount
  React.useEffect(() => {
    startPolling();
  }, []);

  return (
    <div>
      <div
        style={{
          marginBottom: 16,
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button icon={<SyncOutlined />} onClick={() => startPolling()}>
          Refresh Jobs
        </Button>
      </div>
      <List
        bordered={true}
        dataSource={sortedJobs}
        renderItem={(job) => <JobItem job={job} />}
        locale={{ emptyText: <Empty description={'No jobs'} /> }}
      />
    </div>
  );
};

export default Jobs;
