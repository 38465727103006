import { Empty, Flex } from 'antd';
import React, { FunctionComponent, useEffect } from 'react';
import { useVideoContext } from '../../../hooks/use-video';
import SceneItem from './scene/scene';

const Scenes: FunctionComponent = () => {
  const { scenes, scrollToScene, getCurrentFrame, getSceneByCurrentFrame } =
    useVideoContext();

  useEffect(() => {
    if (scenes.length === 0) {
      return;
    }

    const currentFrame = getCurrentFrame();
    const currentScene = getSceneByCurrentFrame(currentFrame);

    if (currentScene) {
      scrollToScene(currentScene);
    }
  }, []);

  if (scenes.length === 0) {
    return <Empty description={'No Scenes yet'} />;
  }

  let currentTime = 0;

  return (
    <div className="scenes">
      <Flex vertical gap="large">
        {scenes.map((scene, index) => {
          const isLast = index === scenes.length - 1;
          const sceneTime = currentTime;
          currentTime += scene.audioDuration;

          return (
            <SceneItem
              key={scene._id}
              sceneId={scene._id}
              sceneNumber={index + 1}
              currentTime={sceneTime}
              displayDivider={!isLast}
            />
          );
        })}
      </Flex>
    </div>
  );
};

export default Scenes;
