import React, { ReactNode, useState } from 'react';
import { Button, Typography, Tag, Avatar, Tooltip } from 'antd';
import {
  CheckOutlined,
  PauseCircleFilled,
  PauseCircleOutlined,
  PlayCircleFilled,
  PlayCircleOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { upperFirst } from 'lodash';
import { useResponsiveReady } from '../../hooks/use-responive-ready';

import './card-with-sound.scss';

const { Text } = Typography;

interface VoiceoverListItemProps {
  id: string;
  name: string;
  description?: string;
  audioUrl: string;
  avatar?: string;
  tags?: string[];
  isSelected: boolean;
  isPlaying: boolean;
  onPlay: (id: string, url: string, event: React.MouseEvent) => void;
  onSelect: (id: string) => void;
  avatarIcon?: ReactNode;
}

const CardWithSound: React.FC<VoiceoverListItemProps> = ({
  id,
  name,
  description,
  avatar,
  audioUrl,
  tags = [],
  isSelected,
  isPlaying,
  onPlay,
  onSelect,
  avatarIcon,
}) => {
  const { isMobile } = useResponsiveReady();
  const [isCardHovered, setIsCardHovered] = useState(false);

  return (
    <div
      className={`card-with-sound ${isSelected ? 'selected' : ''}`}
      style={{
        padding: '15px',
        cursor: 'pointer',
        display: 'flex',

        flexDirection: 'column',
        alignItems: 'stretch',
      }}
      onMouseEnter={() => setIsCardHovered(true)}
      onMouseLeave={() => setIsCardHovered(false)}
      onClick={(e) => onPlay(id, audioUrl, e)}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          width: '100%',
          marginBottom: '8px',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            gap: 5,
            marginRight: '16px',
          }}
        >
          <div
            style={{
              position: 'relative',
              marginRight: '10px',
              overflow: 'hidden',
            }}
          >
            <div style={{ position: 'relative' }}>
              <Avatar
                className={'hoverable'}
                style={{
                  cursor: 'pointer',
                  transition: 'all 0.3s ease',
                }}
                icon={avatarIcon ? avatarIcon : <UserOutlined />}
                src={avatar}
                onClick={(e) => {
                  //@ts-expect-error huy
                  e.stopPropagation();
                  //@ts-expect-error huy
                  onPlay(id, audioUrl, e);
                }}
              />
              <div
                style={{
                  position: 'absolute',
                  inset: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: 'rgba(0, 0, 0, 0.3)',
                  borderRadius: '50%',
                  opacity: isPlaying || isCardHovered ? 1 : 0,
                  transition: 'opacity 0.2s',
                  pointerEvents: 'none',
                }}
              >
                {isPlaying ? (
                  <PauseCircleOutlined
                    style={{
                      fontSize: '24px',
                      color: 'rgba(255, 255, 255, 0.85)',
                    }}
                  />
                ) : (
                  <PlayCircleOutlined
                    style={{
                      fontSize: '24px',
                      color: 'rgba(255, 255, 255, 0.85)',
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              minWidth: 0,
            }}
          >
            <Tooltip title={upperFirst(name)}>
              <Text className={'sound-name'} strong>
                {upperFirst(name)}
              </Text>
            </Tooltip>
            <Text
              type="secondary"
              style={{
                fontSize: '12px',
                width: '100%',
              }}
            >
              {description}
            </Text>
          </div>
        </div>
        <Button
          shape={'round'}
          size="small"
          style={{
            width: '100px',
            flexShrink: 0,
          }}
          type={isSelected ? 'primary' : 'default'}
          onClick={(e) => {
            e.stopPropagation();
            onSelect(id);
          }}
          icon={isSelected ? <CheckOutlined /> : null}
        >
          {isSelected ? 'Selected' : 'Select'}
        </Button>
      </div>
      {tags && tags.length > 0 && (
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 0,
            minHeight: '32px',
          }}
        >
          {tags.map((tag, index) => (
            <Tag key={index} color="blue">
              {upperFirst(tag)}
            </Tag>
          ))}
        </div>
      )}
    </div>
  );
};

export default CardWithSound;
