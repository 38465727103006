import React, { FunctionComponent, ReactNode, useState } from 'react';
import { Button, Card, Col, Collapse, Form, Row, Typography } from 'antd';
import { useForm, UseFormReturn } from 'react-hook-form';
import {
  AiAssetStyles,
  AssetOrigin,
  DEFAULT_TIMEZONE,
  defaultThumbnailConfig,
  Series,
  SeriesSubject,
  SocialAccount,
  SocialProvider,
  Timezone,
  TimezoneLabels,
} from '@monorepo/types';
import './series-form.scss';
import BaseVideoForm, {
  BaseVideoFormType,
  SectionBorder,
  SectionTitle,
} from '../base-video-form/base-video-form';
import {
  CaretRightOutlined,
  FieldNumberOutlined,
  FieldTimeOutlined,
  GlobalOutlined,
  SendOutlined,
  SettingOutlined,
  ThunderboltOutlined,
} from '@ant-design/icons';
import { useStore } from '../../helpers/use-store';
import {
  FormSelect,
  FormSlider,
  LoadingState,
  useLoading,
} from '@monorepo/react-components';
import { FormSocialSelect } from './form-social-select';
import { replace, startCase } from 'lodash';
import SubscribeCTA from '../../components/subscribe-cta/subscribe-cta';
import { SeriesOptionsForm } from './series-settings';

const { Text } = Typography;

const iconStyle = {
  fontSize: '20px',
  color: '#1890ff',
};
const sections = ['duration', 'social', 'videosPerDay', 'timezone'];

function getExtraContentSection(form: UseFormReturn<Series>) {
  return (
    <FormSelect
      style={{ marginBottom: '15px' }}
      form={form}
      defaultValue={SeriesSubject.RandomAIStory}
      placeholder={'Select a subject'}
      controllerProps={{
        name: 'subjectType',
        rules: { required: 'Please select a series subject' },
      }}
      options={Object.values(SeriesSubject).map((subject) => {
        return {
          label: startCase(replace(subject, '-', ' ')),
          value: subject,
        };
      })}
    />
  );
}

const getSectionsText = (
  form: UseFormReturn<Series>,
  socialAccount?: SocialAccount
) => {
  const socialSectionText =
    form.getValues('socialAccountId') && socialAccount
      ? startCase(socialAccount?.provider)
      : '';
  const durationSectionText = form.getValues('duration')
    ? `${form.getValues('duration')} seconds`
    : '';
  const videosPerDaySectionText = form.getValues('videosPerDay')
    ? `${form.getValues('videosPerDay')}  Videos Per day`
    : '';
  return { socialSectionText, durationSectionText, videosPerDaySectionText };
};

interface Props {
  collapsed?: boolean;
  sectionsText?: boolean;
  loadingButtonText?: ReactNode;
  series?: Series;
  onSubmit: (date: Partial<Series>) => Promise<void>;
  useCollapse?: boolean;
}

const SeriesForm: FunctionComponent<Props> = ({
  collapsed,
  series,
  onSubmit,
  sectionsText,
  loadingButtonText,
  useCollapse = true,
}) => {
  const {
    dataStore: { voiceoverStore, userStore, socialAccountStore, seriesStore },
  } = useStore();

  const [expandedSection, setExpandedSections] = useState<string[]>(
    collapsed ? [] : sections
  );
  const { loadingState, updateLoadingState } = useLoading(LoadingState.Initial);

  const form = useForm<Series>({
    mode: 'all',
    defaultValues: {
      subjectType: SeriesSubject.RandomAIStory,
      assetOrigin: AssetOrigin.AIImages,
      assetStyle: AiAssetStyles.CINEMATIC,
      timezone: DEFAULT_TIMEZONE,
      language: 'en',
      duration: 60,
      voiceoverId:
        voiceoverStore.items.find((voiceover) => voiceover.name === 'alloy')
          ?._id || '',
      ...series,
      thumbnailConfig: {
        ...defaultThumbnailConfig,
        enabled: true,
        enableVoiceover: true,
      },
    },
  });

  const _onSubmit = async (data: Partial<Series>) => {
    try {
      updateLoadingState(LoadingState.Loading);

      await onSubmit(data);

      userStore.getLatestUser();
    } catch (e) {
      console.error(`failed series submit`, e);
      throw e;
    } finally {
      updateLoadingState(LoadingState.Loaded);
    }
  };

  const selectedVideosLength = form.watch('duration');
  const seriesSubject = form.watch('subjectType');
  const socialAccount = socialAccountStore.get(
    form.getValues('socialAccountId')
  );
  const { socialSectionText, durationSectionText, videosPerDaySectionText } =
    getSectionsText(form, socialAccount);

  const frequencyAllowed = [1, 2, 3];

  const isLoading = loadingState === LoadingState.Loading;

  const isUpdate = Boolean(series?._id);

  const isSubscriptionAmountGood = isUpdate
    ? userStore.userSubscription.allowedSeriesAmount >= seriesStore.items.length
    : userStore.userSubscription.allowedSeriesAmount > seriesStore.items.length;

  const isAllowedToCreate =
    userStore.isSubscriptionActive && isSubscriptionAmountGood;

  const formItems = [
    {
      key: 'duration',
      label: (
        <SectionTitle
          icon={<FieldTimeOutlined style={iconStyle} />}
          text="Video Length"
        />
      ),
      extra: sectionsText && (
        <Text type="secondary">{durationSectionText}</Text>
      ),
      children: (
        <FormSlider
          label={`Video length: ${selectedVideosLength} seconds`}
          form={form}
          props={{
            step: 5,
            min: 30,
          }}
          controllerProps={{
            name: 'duration',
            rules: {
              min: {
                value: 30,
                message: 'Minimum Video length is 30 seconds',
              },
              max: {
                value: 90,
                message: 'Maximum Video length is 90 seconds',
              },
            },
          }}
        />
      ),
    },
    {
      key: 'social',
      label: (
        <SectionTitle
          icon={<SendOutlined style={iconStyle} />}
          text="Social Media Account"
        />
      ),
      extra: sectionsText && <Text type="secondary">{socialSectionText}</Text>,
      children: (
        <FormSocialSelect
          form={form}
          controllerProps={{
            name: 'socialAccountId',
            rules: { required: 'Please select a social account' },
          }}
          onChange={(socialAccountId) => {
            form.setValue('socialAccountId', socialAccountId);
            form.trigger('socialAccountId');
          }}
        />
      ),
    },
    {
      key: 'videosPerDay',
      label: (
        <SectionTitle
          icon={<FieldNumberOutlined style={iconStyle} />}
          text="Posting Frequency"
        />
      ),
      extra: sectionsText && (
        <Text type="secondary">{videosPerDaySectionText}</Text>
      ),
      children: (
        <FormSelect
          form={form}
          placeholder={'select publication frequency'}
          controllerProps={{
            name: 'videosPerDay',
            rules: {
              required: 'Please select amount of videos per day',
            },
          }}
          options={frequencyAllowed.map((videosPerDay) => ({
            value: videosPerDay,
            label: `${videosPerDay} ${
              videosPerDay > 1 ? 'Videos' : 'Video'
            } per day`,
          }))}
        />
      ),
    },
    {
      key: 'timezone',
      label: (
        <SectionTitle
          icon={<GlobalOutlined style={iconStyle} />}
          text="Time Zone"
        />
      ),
      extra: sectionsText && (
        <Text type="secondary">
          {TimezoneLabels[form.getValues('timezone')]}
        </Text>
      ),
      children: (
        <FormSelect
          form={form}
          placeholder={'Select Timezone'}
          controllerProps={{
            name: 'timezone',
          }}
          options={Object.entries(Timezone).map(([key, value]) => ({
            value: value,
            label: TimezoneLabels[value],
          }))}
        />
      ),
    },
    {
      key: 'seriesOptions',
      label: (
        <SectionTitle
          icon={<SettingOutlined style={iconStyle} />}
          text="Series Settings"
        />
      ),
      children: <SeriesOptionsForm form={form} />,
    },
  ];

  return (
    <Form
      className={'series-form'}
      layout="vertical"
      onFinish={form.handleSubmit(_onSubmit)}
    >
      <Row justify={'center'}>
        <Col span={24}>
          <Card>
            <BaseVideoForm
              sectionText={sectionsText}
              extraContentSection={getExtraContentSection(form)}
              form={form as unknown as UseFormReturn<BaseVideoFormType>}
              hideScript={
                !series?._id && seriesSubject !== SeriesSubject.Custom
              }
              collapsed={collapsed}
              useCollapse={useCollapse}
            />

            {useCollapse ? (
              <Collapse
                className="custom-collapse"
                activeKey={expandedSection}
                expandIcon={({ isActive }) => {
                  return (
                    <Button
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '24px',
                        padding: 0,
                        border: 'none',
                      }}
                    >
                      <CaretRightOutlined
                        style={{ fontSize: '14px', marginTop: '2px' }}
                        rotate={isActive ? 90 : 0}
                      />
                    </Button>
                  );
                }}
                bordered={false}
                onChange={(keys) => setExpandedSections(keys)}
                items={formItems}
              />
            ) : (
              <div className="custom-collapse form-item">
                {formItems.map((item) => (
                  <div key={item.key} style={{ marginBottom: '24px' }}>
                    <div
                      className={'form-item-title'}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '16px',
                      }}
                    >
                      {item.label}
                      {item.extra}
                    </div>
                    {item.children}
                  </div>
                ))}
              </div>
            )}
          </Card>
        </Col>
        <Col span={24} style={{ marginTop: 20 }}>
          <Form.Item>
            <div className={'create-button'}>
              <Button
                loading={isLoading}
                disabled={!form.formState.isValid || !isAllowedToCreate}
                icon={
                  <ThunderboltOutlined
                    style={{ fontSize: '18px', marginTop: '2px' }}
                  />
                }
                type="primary"
                htmlType="submit"
                size="large"
                block
              >
                {series ? 'Update Series' : 'Create Series'}
              </Button>
              {!isAllowedToCreate ? <SubscribeCTA /> : null}
              {isLoading && loadingButtonText ? loadingButtonText : null}
            </div>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default SeriesForm;
