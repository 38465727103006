import { Flex, Tooltip } from 'antd';
import { CrownOutlined } from '@ant-design/icons';
import React, { FunctionComponent } from 'react';
import { useStore } from '../../helpers/use-store';
import { observer } from 'mobx-react-lite';

const VideoCredits: FunctionComponent = () => {
  const {
    dataStore: { userStore },
  } = useStore();

  return (
    <Flex
      justify={'center'}
      align={'center'}
      gap={0}
      style={{ flexDirection: 'column' }}
    >
      <Flex gap={10} className="subscription-info-item">
        <div>
          <Tooltip title={`Credits`}>
            <span style={{ marginRight: '5px' }}>
              {Math.floor(userStore.userSubscription.credits)}
            </span>
            <CrownOutlined />
          </Tooltip>
        </div>
      </Flex>
    </Flex>
  );
};

export default observer(VideoCredits);
