import AffiliateProgram from './affiliate-program';
import { useStore } from '../../helpers/use-store';
import { Button, Card, message, Typography } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

const { Text, Title } = Typography;

const Affiliate = () => {
  const {
    dataStore: { userStore },
  } = useStore();

  const shortLink = userStore.currentUser.affiliateInfo?.shortUrl;

  // Function to handle copying the short link to the clipboard
  const handleCopyLink = () => {
    if (shortLink) {
      navigator.clipboard.writeText(shortLink);
      message.success('Link copied to clipboard!');
    }
  };

  return (
    <div className="affiliate">
      {/* Display the short link card if it exists */}
      {/* Display the short link card if it exists */}
      {shortLink && (
        <Card
          style={{
            maxWidth: '400px',
            margin: '0 auto',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            borderRadius: '8px',
          }}
        >
          <Title level={4} style={{ marginBottom: '16px' }}>
            Your Referral URL
          </Title>
          <Text
            type="secondary"
            style={{ display: 'block', marginBottom: '16px' }}
          >
            Copy your link and share it anywhere
          </Text>
          <Text
            strong
            style={{
              display: 'block',
              fontSize: '16px',
              wordBreak: 'break-all',
              marginBottom: '16px',
              padding: '8px',
              backgroundColor: '#f5f5f5',
              borderRadius: '4px',
            }}
          >
            {shortLink}
          </Text>
          <Button
            type="primary"
            icon={<CopyOutlined />}
            onClick={handleCopyLink}
            style={{ width: '100%' }}
          >
            Copy Link
          </Button>
        </Card>
      )}

      <AffiliateProgram />
    </div>
  );
};

export default Affiliate;
