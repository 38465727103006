import { EntityStore } from '@monorepo/client-common';
import { getEnv } from '../../../helpers/mobx-easy-wrapper';
import {
  Asset,
  CreateImagePayload,
  CreateVideoAnimationPayload,
  NinjaProviderModel,
} from '@monorepo/types';
import AssetService from '../../../services/entities/asset-service';

export default class AssetStore extends EntityStore<AssetService, Asset> {
  constructor() {
    const {
      apiFactory: { assetService },
    } = getEnv();

    super(assetService);
  }

  async upload({
    file,
    sceneId,
    videoId,
  }: {
    file: any;
    sceneId?: string;
    videoId?: string;
  }) {
    return this.service.upload({ file, sceneId, videoId });
  }

  async uploadByUrl(
    asset: Pick<
      Asset,
      'url' | 'type' | 'origin' | 'prompt' | 'sceneId' | 'videoId'
    >
  ) {
    return this.service.uploadByUrl(asset);
  }

  async generateAIImage(createPayload: CreateImagePayload) {
    return this.service.generateAIImage(createPayload);
  }

  async generateAIVideoFromImage(payload: CreateVideoAnimationPayload) {
    return this.service.generateAIVideoFromImage(payload);
  }

  async searchStockFootage(prompt: string) {
    return this.service.searchStockFootage(prompt);
  }

  async bulkGet(ids: string[]) {
    return this.service.bulkGet(ids);
  }
}
