import { Button, Col, Row, Typography, Switch, Select, Tag, Flex } from 'antd';
import {
  FormTextArea,
  LoadingState,
  useLoading,
} from '@monorepo/react-components';
import { ThumbnailConfig, ThumbnailType } from '@monorepo/types';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNotification } from '../../../hooks/use-notification';
import { useVideoContext } from '../../../hooks/use-video';
import { useStore } from '../../../helpers/use-store';

export type EditThumbnailForm = Pick<
  ThumbnailConfig,
  'text' | 'type' | 'enabled' | 'enableVoiceover'
>;

const VideoThumbnail = () => {
  const { video, updateThumbnail, fetchVideoOnly } = useVideoContext();
  const { loadingState, updateLoadingState } = useLoading();
  const form = useForm<EditThumbnailForm>({
    defaultValues: {
      text: video?.thumbnailConfig.text || '',
      type: video?.thumbnailConfig.type,
      enabled: video?.thumbnailConfig.enabled,
      enableVoiceover: video?.thumbnailConfig.enableVoiceover,
    },
  });
  const notificationApi = useNotification();
  const {
    dataStore: { userStore },
  } = useStore();

  const thumbnailConfig = video?.thumbnailConfig as ThumbnailConfig;

  const isEnabled = form.watch('enabled');

  const resetForm = () => {
    if (thumbnailConfig) {
      form.reset({
        text: thumbnailConfig.text,
        type: thumbnailConfig.type,
        enabled: thumbnailConfig.enabled,
        enableVoiceover: thumbnailConfig.enableVoiceover,
      });
    }
  };

  useEffect(() => {
    resetForm();
  }, [thumbnailConfig]);

  const save = async () => {
    if (!thumbnailConfig) {
      return;
    }

    const values = form.getValues();

    try {
      updateLoadingState(LoadingState.Loading);

      await updateThumbnail({
        text: values.text,
        type: values.type,
        enabled: values.enabled,
        enableVoiceover: values.enableVoiceover,
      });

      await fetchVideoOnly();

      resetForm();
    } catch (e) {
      notificationApi.error({
        placement: 'top',
        icon: '',
        message: 'Error',
        description:
          'An error occurred. Please try again or contact support for assistance.',
      });

      console.error(`failed`, e);
    } finally {
      updateLoadingState(LoadingState.Loaded);
      form.reset(values);
    }
  };

  return (
    <Row gutter={[25, 25]}>
      <Col span={24}>
        <Flex justify={'space-between'}>
          {/* Enable/Disable Switch */}
          <div style={{ marginBottom: '15px' }}>
            <Typography.Text strong>Enabled</Typography.Text>
            <Switch
              checked={form.watch('enabled')}
              onChange={(checked) =>
                form.setValue('enabled', checked, { shouldDirty: true })
              }
              style={{ marginLeft: '10px' }}
            />
          </div>
          {/* Duration Display */}
          {isEnabled ? (
            <Typography.Text style={{ display: 'block', marginBottom: '15px' }}>
              <Tag>Duration: {(thumbnailConfig.duration || 0).toFixed(2)}s</Tag>
            </Typography.Text>
          ) : null}
        </Flex>

        {isEnabled ? (
          <>
            <div style={{ marginBottom: '15px' }}>
              <Typography.Text
                strong
                style={{ display: 'block', marginBottom: '5px' }}
              >
                Thumbnail Type
              </Typography.Text>
              <Select
                style={{ width: '100%' }}
                value={form.watch('type')}
                onChange={(value) =>
                  form.setValue('type', value, { shouldDirty: true })
                }
              >
                {/*<Select.Option value={ThumbnailType.Reddit}>Reddit</Select.Option>*/}
                <Select.Option value={ThumbnailType.TikTok}>
                  TikTok
                </Select.Option>
                <Select.Option value={ThumbnailType.Text}>Text</Select.Option>
              </Select>
            </div>

            <Flex justify={'space-between'}>
              <Typography.Text
                strong
                style={{ display: 'block', marginBottom: '5px' }}
              >
                Thumbnail Text
              </Typography.Text>
              <div style={{ marginBottom: '15px' }}>
                <Typography.Text strong>Voiceover</Typography.Text>
                <Switch
                  checked={form.watch('enableVoiceover')}
                  onChange={(checked) =>
                    form.setValue('enableVoiceover', checked, {
                      shouldDirty: true,
                    })
                  }
                  style={{ marginLeft: '10px' }}
                />
              </div>
            </Flex>
            <FormTextArea
              textareaProps={{
                rows: 3,
                placeholder: 'Enter thumbnail text',
              }}
              form={form}
              controllerProps={{
                name: 'text',
              }}
            />
          </>
        ) : null}
      </Col>
      <div style={{ width: '100%' }}>
        <Button
          type={'primary'}
          style={{ width: '100%', marginTop: '15px' }}
          loading={loadingState === LoadingState.Loading}
          onClick={save}
        >
          Update Thumbnail
        </Button>

        <Typography.Text
          style={{ margin: 0, fontSize: '12px' }}
          type={'secondary'}
        >
          Updating the text will regenerate the voiceover.
        </Typography.Text>
      </div>
    </Row>
  );
};

export default VideoThumbnail;
